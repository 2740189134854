import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Typography,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Input,
  Chip,
  FormControl,
  Select,
  Switch
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import QuotaSection from './QuotaSection';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const questionIdMap = {};

const DisplayRule = ({
  setList,
  displayRules,
  updateDisplayRule,
  allQuestions,
  feQId
}) => {
  allQuestions.forEach((q) => {
    questionIdMap[q.feQId] = q.questionText;
  });
  
  const [expanded, setExpanded] = useState(false);
  const [randomise, setRandomise] = useState(displayRules.inSetRandomization !== undefined ? displayRules.inSetRandomization : true);
  const [value, setValue] = useState(displayRules.numberOfSetUserSee !== undefined ? displayRules.numberOfSetUserSee : 1);
  const [optionLimit, setOptionLimit] = useState(displayRules.optionsRule !== undefined ? displayRules.optionsRule : {});
  const [questions, setQuestions] = useState(displayRules.questionsAfterMedia !== undefined ? displayRules.questionsAfterMedia : []);
  const [listOfQuestion, setListOfAllQuestion] = useState([]);
  const [quotaLimit, setQuotaLimit] = useState(displayRules?.optionsRule?.questionId?.length > 0);
  const classes = useStyles();

  const setListArray = [];
  setListArray[setList.length - 1] = 0;

  const toggleExpansion = () => {
    setExpanded((exp) => !exp);
  };

  const saveDisplayRule = (obj) => {
    updateDisplayRule({
      inSetRandomization: randomise,
      numberOfSetUserSee: value,
      optionsRule: optionLimit,
      questionsAfterMedia: questions,
      ...obj
    });
  };

  const changeSetRandomise = (event) => {
    setRandomise(event.target.checked);
    saveDisplayRule({
      inSetRandomization: event.target.checked
    });
  };

  const changeNumberToShow = (event) => {
    setValue(event.target.value);
    saveDisplayRule({
      numberOfSetUserSee: event.target.value
    });
  };

  const updateQuota = (obj) => {
    setOptionLimit(obj);
    saveDisplayRule({
      optionsRule: obj
    });
  };

  const toggleQuotaLimit = (event) => {
    const checked = event.target.checked;
    setQuotaLimit(checked);
    if (!checked) {
      saveDisplayRule({
        optionsRule: {}
      });
    }
  };

  const updateQuestionList = (event) => {
    setQuestions(event.target.value);
    saveDisplayRule({
      questionsAfterMedia: event.target.value
    });
  };

  useEffect(() => {
    const list = allQuestions.map((q) => {
      questionIdMap[q.feQId] = q.questionText;
      return {
        id: q.feQId,
        text: q.questionText
      };
    });
    setListOfAllQuestion(list);
  }, [allQuestions]);

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={toggleExpansion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="displayRule-content"
          id="displayRule-header"
        >
          <Typography className={classes.heading}>Display Rules</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ flexDirection: 'row', maxWidth: '100%' }}>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={randomise} onChange={changeSetRandomise} name="Randomise" />}
                label="Randomise in-set media"
              />
            </FormGroup>
            <div style={{ padding: '5px' }} />
            {/* <Typography>Number of sets to show to user?</Typography>
            <FormControl className={classes.formControl} disabled>
              <Select
                value={value}
                onChange={changeNumberToShow}
              >
                {setListArray.map((e, i) => (
                  <MenuItem value={i+1} key={i}>{i+1}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <Typography>Select questions to be asked after each Media</Typography>
            <FormControl fullWidth>
              <Select
                fullWidth
                multiple
                value={questions}
                onChange={updateQuestionList}
                input={<Input />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={questionIdMap[value]} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {listOfQuestion.map((ques) => (
                  <MenuItem key={ques.id} value={ques.id}>
                    {ques.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ padding: '5px' }} />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={quotaLimit}
                    onChange={toggleQuotaLimit}
                    name="quota"
                    color="primary"
                  />
                }
                label="Enable Quota Limit"
              />
            </FormGroup>
            {quotaLimit && (
              <QuotaSection
                setList={setList}
                updateQuota={updateQuota}
                optionLimit={optionLimit}
                allQuestions={allQuestions}
                feQId={feQId}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DisplayRule;
