import React, { useState, useEffect } from 'react';
import _, { truncate } from 'lodash';
import clsx from 'clsx';
import styled from 'styled-components';
import {
  Grid,
  Card,
  CardContent,
  Avatar,
  CardHeader ,Box , Divider ,IconButton
} from '@material-ui/core';
import CountUp from 'react-countup';
import VideoList from './VideoList';
import Summary from './Summary';
import Keywords from './Keywords';
import Gender from './Gender';
import AgeChart from './AgeChart';
import VideoModal from './VideoModal';
import {
  Survey
} from '../../../Store/ApplicationStore';
import { MAN_COLOR } from './constants';
import { scoreFormatter } from './util';
import Sentence from './Sentence';
import Category from './Category';
import Location from './Location';
import Aspect from './Aspect';
import { Hidden, Drawer, Paper } from '@material-ui/core';
import { MenuOpen, Close } from '@material-ui/icons';
import '../Manage/Manage.css'
import SentimentChart from './SentimentChart'

const INITIAL_FILTERS = {
  aspect: [],
  feature: [],
  attribute: [],
  gender: "",
  age: [],
  city: [],
  state: [],
  country: []
};

const videosObject = {};

const INITIAL_MODAL = {
  open: false,
  videoId: null,
  sentence: null,
  videoList: []
};

const INITIAL_INFO = {
  sentimentScore: 0,
  keywords: [],
  summary: ''
};

const INITIAL_DATA = {
  ageData: [
    {
      label: '< 18',
      min: 0,
      max: 18,
      count: 0
    },
    {
      label: '18 - 25',
      min: 18,
      max: 25,
      count: 0
    },
    {
      label: '25 - 35',
      min: 25,
      max: 35,
      count: 0
    },
    {
      label: '35 - 45',
      min: 35,
      max: 45,
      count: 0
    },
    {
      label: '45 >',
      min: 45,
      max: Infinity,
      count: 0
    }
  ],
  genderData: {
    male: 0,
    female: 0
  },
  city: [],
  state: [],
  country: [],
  type: {
    positive: false,
    negative: false,
    neutral: false
  },
  feature: [],
  attribute: [],
  sentence: [],
  videoList: []
};

const checkIfFilterApplied = (filters) => {
  if (filters.country.length > 0) {
    return true
  }
  if (filters.state.length > 0) {
    return true
  }
  if (filters.city.length > 0) {
    return true
  }
  if (filters.age.length > 0) {
    return true
  }
  if (filters.attribute.length > 0) {
    return true
  }
  if (filters.feature.length > 0) {
    return true
  }
  if (filters.aspect.length > 0) {
    return true
  }
  if (filters.gender.length > 0) {
    return true
  }
  return false;
};

const checkIfAnalyticsFilterApplied = (filters) => {
  if (filters.attribute.length > 0) {
    return true
  }
  if (filters.feature.length > 0) {
    return true
  }
  if (filters.aspect.length > 0) {
    return true
  }
  return false;
};

const getFilteredVideoIds = ({
  filters,
  videoList
}) => {
  const ids = [];
  videoList.forEach((video) => {
    let flag = true;
    if (filters.city.length > 0 && filters.city.indexOf(video.city) < 0) {
      return false;
    }
    if (filters.state.length > 0 && filters.state.indexOf(video.state) < 0) {
      return false;
    }
    if (filters.country.length > 0 && filters.country.indexOf(video.country) < 0) {
      return false;
    }
    if (filters.gender.length > 0 && parseInt(filters.gender) !== video.gender) {
      return false;
    }
    if (filters.age.length > 0) {
      flag = false;
      for (let i = 0; i < INITIAL_DATA.ageData.length; i += 1) {
        if (filters.age.indexOf(INITIAL_DATA.ageData[i].label) >= 0) {
          if (video.age < INITIAL_DATA.ageData[i].max && video.age >= INITIAL_DATA.ageData[i].min) {
            flag = true;
            break;
          }
        }
      }
    }

    if (flag) {
      ids.push(video.videoId);
    }
  });
  return ids;
};

const checkIfRowFiltered = ({
  filters,
  videoIds,
  row
}) => {
  if (filters.feature.length > 0 && filters.feature.indexOf(row.feature) < 0) {
    return false;
  }
  if (filters.attribute.length > 0 && filters.attribute.indexOf(row.attribute) < 0) {
    return false;
  }
  if (filters.aspect.length > 0 && filters.aspect.indexOf(row.type) < 0) {
    return false;
  }
  if (videoIds.length > 0 && videoIds.indexOf(row.videoId) < 0) {
    return false;
  }
  if (videoIds.length === 0) {
    return false;
  }
  return true;
};

const loadMainList = (mainList, obj, type) => {
  Object.keys(obj).forEach((attribute) => {
    obj[attribute].attrList.forEach((feature) => {
      feature.sentnese.forEach((sentenceObj) => {
        mainList.push({
          attribute,
          attributeScore: obj[attribute].scoreMean,
          feature: feature.attrKey,
          featureScore: feature.scoreMean,
          sentence: sentenceObj.sentence,
          sentenceScore: sentenceObj.sentimentScore,
          startIndex: sentenceObj.startIndex,
          startTime: sentenceObj.startTime,
          videoId: sentenceObj.videoId,
          endIndex: sentenceObj.endIndex,
          endTime: sentenceObj.endTime,
          age: sentenceObj.age,
          gender: sentenceObj.gender,
          type
        });
      });
    });
  });
};

const initializeData = async ({
  surveyId,
  questionId
}) => {
  const payload = {
    surveyId,
    questionId
  };
  const info = {
    sentimentScore: 0,
    keywords: [],
    summary: ''
  };
  let mainLists = [];
  let videoLists = [];
  let totalCount = 0
  try {
    const response = await Promise.all([
      Survey.getVideoListForQuestion(payload),
      Survey.getAttributesFromAnalysis(payload),
      Survey.getAnalyticsScore(payload)
    ]);
    info.sentimentScore = response[2].sentimentScore;
    info.summary = response[1].summary;
    info.keywords = response[1].keyWords;
    info.questionText = response[1].question;
    videoLists = response[0];
    totalCount = videoLists.length
    loadMainList(mainLists, response[1].positive, 'positive');
    loadMainList(mainLists, response[1].negative, 'negative');
    loadMainList(mainLists, response[1].neutral, 'neutral');
  } catch (err) {}
  return {
    info,
    mainLists,
    videoLists,
    totalCount
  };
};

const getFilteredData = ({
  list,
  videoList,
  filters
}) => {
  const data = _.cloneDeep(INITIAL_DATA);
  const isFiltered = checkIfFilterApplied(filters);
  const preFilterVideoIds = getFilteredVideoIds({
    filters,
    videoList
  });
  if (preFilterVideoIds.length > 0) {
    const filterVideoIds = [];
    const feature = {};
    const attribute = {};
    const sentence = {};
    list.forEach((entry) => {
      const pass = !isFiltered || checkIfRowFiltered({
        filters,
        videoIds: preFilterVideoIds,
        row: entry
      });
      if (pass) {
        if (filterVideoIds.indexOf(entry.videoId) < 0) {
          filterVideoIds.push(entry.videoId);
        }
        // feature
        if (feature[entry.feature]) {
          feature[entry.feature].score += entry.sentenceScore;
          feature[entry.feature].count++;
          if (feature[entry.feature].videoIds.indexOf(entry.videoId) < 0) {
            feature[entry.feature].videoIds.push(entry.videoId);
          }
        } else {
          feature[entry.feature] = {
            score: entry.sentenceScore,
            count: 1,
            text: entry.feature,
            videoIds: [entry.videoId]
          };
        }
        // attribute
        if (attribute[entry.attribute]) {
          attribute[entry.attribute].score += entry.sentenceScore;
          attribute[entry.attribute].count++;
          if (attribute[entry.attribute].videoIds.indexOf(entry.videoId) < 0) {
            attribute[entry.attribute].videoIds.push(entry.videoId);
          }
        } else {
          attribute[entry.attribute] = {
            score: entry.sentenceScore,
            count: 1,
            text: entry.attribute,
            videoIds: [entry.videoId]
          };
        }
        // sentence
        if (sentence[entry.sentence]) {
          sentence[entry.sentence].score += entry.sentenceScore;
          sentence[entry.sentence].count++;
          if (sentence[entry.sentence].videoIds.indexOf(entry.videoId) < 0) {
            sentence[entry.sentence].videoIds.push(entry.videoId);
          }
        } else {
          sentence[entry.sentence] = {
            score: entry.sentenceScore,
            count: 1,
            text: entry.sentence,
            videoIds: [entry.videoId],
            type: entry.type
          };
        }
        // aspect
        data[entry.type] = true;
      }
    });
    Object.keys(feature).forEach((name) => {
      const entry = feature[name];
      entry.score = scoreFormatter(entry.score / entry.count);
      entry.count = entry.videoIds.length;
      data.feature.push(entry);
    });
    Object.keys(attribute).forEach((name) => {
      const entry = attribute[name];
      entry.score = scoreFormatter(entry.score / entry.count);
      entry.count = entry.videoIds.length;
      data.attribute.push(entry);
    });
    Object.keys(sentence).forEach((name) => {
      const entry = sentence[name];
      entry.score = scoreFormatter(entry.score / entry.count);
      entry.count = entry.videoIds.length;
      data.sentence.push(entry);
    });
    const analyticsFiltered = checkIfAnalyticsFilterApplied(filters);
    const videoIdsFinal = analyticsFiltered ? filterVideoIds : preFilterVideoIds;
    videoIdsFinal.forEach((id) => {
      data.videoList.push(videosObject[id]);
      if (videosObject[id].gender === 0) {
        data.genderData.female += 1;
      } else if (videosObject[id].gender === 1) {
        data.genderData.male += 1;
      }
      for (let agI = 0; agI < data.ageData.length; agI += 1) {
        if (data.ageData[agI].max > videosObject[id].age && data.ageData[agI].min <= videosObject[id].age) {
          data.ageData[agI].count += 1;
          break;
        }
      }
      if (data.city.indexOf(videosObject[id].city) < 0) {
        data.city.push(videosObject[id].city);
      }
      if (data.state.indexOf(videosObject[id].state) < 0) {
        data.state.push(videosObject[id].state);
      }
      if (data.country.indexOf(videosObject[id].country) < 0) {
        data.country.push(videosObject[id].country);
      }
    });
  }
  data.videoList.sort((a, b) => {
    const aFav = a.isFavorite ? 0 : 1;
    const bFav = b.isFavorite ? 0 : 1;
    return aFav - bFav;
  });
  return data;
};

let mainListFixed = [];
let videoListFixed = [];
let filterObject = {
  ...INITIAL_FILTERS
};

const Dashboard = ({
  surveyId,
  questionId,
  questionText
}) => {
  const [filteredData, setFilteredData] = useState(INITIAL_DATA);
  const [info, setInfo] = useState(INITIAL_INFO);
  const [totalCount, setTotalCount] = useState(0);
  const [modalVideoList, setModalVideoList] = useState([]);
  const [modalSentence, setModalSentence] = useState('');
  const [sentimentScore, setSentimentScore]  = useState(0)
  const [expand, setExpand] = useState(false);

  const toggleExpansion = () => {
    setExpand((exp) => !exp);
  };
  const fetchData = async () => {
    const { info, videoLists, mainLists ,totalCount } = await initializeData({
      surveyId,
      questionId
    });
    videoLists.forEach((vid) => {
      videosObject[vid.videoId] = {
        ...vid
      };
    });
    
    mainListFixed = mainLists;
    videoListFixed = videoLists;
    const data = getFilteredData({
      list: mainListFixed,
      videoList: videoListFixed,
      filters: INITIAL_FILTERS
    });
    setInfo(info);
    setTotalCount(totalCount)
    setSentimentScore(parseFloat(scoreFormatter(info.sentimentScore)))
    setFilteredData(data);
  };

  const openVideo = (vidId) => {
    const modalVid = videoListFixed.filter((e) => e.videoId === vidId);
    setModalVideoList([...modalVid]);
  };

  const updateFilter = (obj) => {
    const newFilterObj = _.cloneDeep(filterObject);
    filterObject = {
      ...newFilterObj,
      ...obj
    };
    const data = getFilteredData({
      list: mainListFixed,
      videoList: videoListFixed,
      filters: filterObject
    });
    setFilteredData(data);
  };

  const setGenderFilter = (val) => {
    updateFilter({
      gender: val
    });
  };

  const setTypeFilter = (val) => {
    updateFilter({
      aspect: val
    });
  };

  const setAgeFilter = (val) => {
    updateFilter({
      age: [...val]
    });
  };

  const setFeatureFilter = (val) => {
    updateFilter({
      feature: [...val]
    });
  };

  const setAttributeFilter = (val) => {
    updateFilter({
      attribute: [...val]
    });
  };

  const setLocationFilter = (obj) => {
    updateFilter(obj);
  };

  const clickSentence = (text) => {
    setModalSentence(text);
    let vidIds = [];
    for (let i = 0; i < filteredData.sentence.length; i += 1) {
      if (filteredData.sentence[i].text === text) {
        vidIds = filteredData.sentence[i].videoIds
      }
    }
    const filteredVideos = videoListFixed.filter((e) => vidIds.indexOf(e.videoId) >= 0);
    setModalVideoList(filteredVideos);
  };

  useEffect(() => {
    if (surveyId && questionId) {
      fetchData();
    }
  }, []);





  return (
<>
    <Grid container spacing={2} style={{ marginTop: '-40px'}}>
      <Grid item sm={2} md={2} lg={2} style={{height: 'calc(100vh - 40px)'}}>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': false,
            'app-sidebar-wrapper-open': true,
            'app-sidebar-wrapper-fixed': false
          })}
          square
          open={true}
          elevation={1}>
          <div
            className={clsx({
              'app-sidebar-menu': true,
              'app-sidebar-collapsed': false
            })}>
            <div className="side-overflow">
              <Box bgcolor="white" p={0} m={0}>
              <Aspect
                    positive={filterObject.aspect.length === 0 || filterObject.aspect.indexOf('positive') >= 0}
                    negative={filterObject.aspect.length === 0 || filterObject.aspect.indexOf('negative') >= 0}
                    neutral={filterObject.aspect.length === 0 || filterObject.aspect.indexOf('neutral') >= 0}
                    setFilter={setTypeFilter}
                  />
                  <Divider />
                  <Gender
                  male={filteredData.genderData.male}
                  female={filteredData.genderData.female}
                  filters={filterObject.gender}
                  setFilter={setGenderFilter}
                  isChart={false}
                  totalCount={totalCount}
                />
                <Divider />
                <AgeChart
                  ageData={filteredData.ageData}
                  filters={filterObject.age}
                  setFilter={setAgeFilter}
                  isChart={false}
                  totalCount={totalCount}
                />
                <Divider />
                <Location
                    cityFilter={filterObject.city}
                    cityOptions={filteredData.city}
                    stateFilter={filterObject.state}
                    stateOptions={filteredData.state}
                    countryFilter={filterObject.country}
                    countryOptions={filteredData.country}
                    setFilter={setLocationFilter}
                  />
              </Box>
            </div>
        </div>
      </Paper>
      </Grid>
      {/*  transition: 'all .2s ease-in-out' */}
      <Grid item sm={7} md={expand ? 6: 8} lg={expand ? 6: 8} style={{backgroundColor: '#fafafa', height: 'calc(100vh - 40px)'}} >
      <div className="side-overflow">
          <div className="d-block p-2 w-100">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} >
                  <Card className="rounded-lg p-3 text-left bg-secondary">
                      <div className="d-flex align-items-center mb-0">
                           <h6 style={{ whiteSpace :"pre-wrap"}}>{info.questionText}</h6>
                      </div>
                  </Card>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <SentimentChart  sentimentScore={sentimentScore} totalCount={totalCount}/>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Gender
                  male={filteredData.genderData.male}
                  female={filteredData.genderData.female}
                  filters={filterObject.gender}
                  setFilter={setGenderFilter}
                  isChart={true}
                  totalCount={totalCount}
                />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <AgeChart
                  ageData={filteredData.ageData}
                  filters={filterObject.age}
                  setFilter={setAgeFilter}
                  isChart={true}
                  totalCount={totalCount}
                />
              </Grid>

              {info.keywords.length > 0 && (
                  <Grid item xs={ info.summary ? 6: 12} md={info.summary ? 6: 12} lg={info.summary ? 4: 12}>
                    <Keywords
                      words={info.keywords}
                    />
                  </Grid>
                )}
              {info.summary && (
                    <Grid item xs={info.keywords.length > 0 ? 6 : 12} md={info.keywords.length > 0 ? 6 : 12} lg={info.keywords.length > 0 ? 8 : 12}>
                      <Summary
                        text={info.summary}
                      />
                    </Grid>
              )}
              <Grid item sm={6} md={6} lg={6}  >
                <Category 
                      title="Themes"
                      catList={filteredData.attribute}
                      filters={filterObject.attribute}
                      setFilter={setAttributeFilter}
                      totalCount={totalCount}
                    />
              </Grid>

              <Grid item sm={6} md={6} lg={6} >
                <Category 
                    title="Keyword"
                    catList={filteredData.feature}
                    filters={filterObject.feature}
                    setFilter={setFeatureFilter}
                    totalCount={totalCount}
                  />
              </Grid>
              <Grid item sm={12} md={12} lg={12} >
                  <Sentence className="m-3"
                      sentences={filteredData.sentence}
                      clickSentence={clickSentence}
                    />
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      <Grid item sm={3} md={expand ? 4: 2} lg={ expand ? 4: 2}  style={{height: 'calc(100vh - 40px)'}}>
        <VideoList
            list={filteredData.videoList}
            openVideo={openVideo}
            expand={expand} toggleExpansion={toggleExpansion}
          />
      </Grid>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
    </Grid>

      <VideoModal
        videoList={modalVideoList}
        sentence={modalSentence}
        modalStatus={modalVideoList.length > 0}
        closeModal={() => setModalVideoList([])}
      />

    </>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: auto;
`;