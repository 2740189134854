import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  IconButton,
  Button,CircularProgress
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import WarningIcon from '@material-ui/icons/Warning';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Questionaire } from '../../../../Store/ApplicationStore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.danger,
  }
}));

const UploadMedia = ({
  surveyId,
  feQId,
  files,
  saveFiles,
  uploadFileList
}) => {
  let initialList = [];
  if (files?.length > 0) {
    initialList = files.map((e) => {
      return {
        fileName: e.fileName,
        fileUrl: e.fileUrl,
        type: 'link',
        uploaded: true
      };
    });
  }

  const [fileList, setFileList] = useState(initialList);
  const [expanded, setExpanded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [fullDataSubmit, setFullDataSubmit] = useState(null);
  const classes = useStyles();
  const fileUpload = useRef();

  const uploadFileNameList = (list) => {
    const nameArr = list.map(e => e.fileName);
    uploadFileList(nameArr);
  };

  const uploadToCloud = () => {
    const uploadList = [];
    const newFileStatus = [];
    fileList.forEach((file) => {
      if (file.type === 'file' && file.uploaded === false) {
        uploadList.push({
          ...file
        });
        newFileStatus.push({
          ...file,
          uploaded: true
        });
      } else {
        newFileStatus.push({
          ...file
        });
      }
    });
    setFileList(newFileStatus);
    if (uploadList.length > 0) {
      setUploadProgress(true);
      (async () => {
        const allPromiseURL = [];
        uploadList.forEach(element => {
          allPromiseURL.push(Questionaire.uploadFile(surveyId, feQId, element.file));
        });
        try {
          const urls = await Promise.all(allPromiseURL);
          setUploadProgress(false);
          const toSave = [];
          const newList = [];
          fileList.forEach((fil) => {
            let newElem = {
              ...fil
            };
            for (let i = 0; i < uploadList.length; i += 1) {
              if (uploadList[i].fileName === fil.fileName) {
                newElem = {
                  fileName: fil.fileName,
                  fileUrl: urls[i],
                  type: 'link',
                  uploaded: true
                };
                break;
              }
            }
            newList.push(newElem);
            if (newElem.type === 'link') {
              toSave.push({
                fileName: newElem.fileName,
                fileUrl: newElem.fileUrl
              });
            }
          });
          setFileList(newList);
          saveFiles(toSave);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  };

  const openFileLoader = () => {
    fileUpload.current.click();
  }

  const uploadFiles = (event) => {
    if (fileUpload.current.files.length > 0) {
      const newFiles = [...fileList];
      for (let i = 0;  i < fileUpload.current.files.length; i += 1) {
        const fileSel = fileUpload.current.files[i];
        const fileName = fileSel.name.replace(/[&\/\\#, +()$~%@'":*?<>{}]/g,"_").replace(" ","_");
        const newFile = new File([fileSel], fileName, { type: fileSel.type });
        const fileUrl = URL.createObjectURL(newFile);
        newFiles.push({
          fileName,
          fileUrl,
          type: 'file',
          uploaded: false,
          file: newFile
        });
      }
      setFileList(newFiles);
      uploadFileNameList(newFiles);
      setFullDataSubmit(false);
    }
  }
  
  const removeFile = (index) => {
    const newList = [...fileList];
    newList.splice(index, 1);
    setFileList(newList);
    saveFiles(newList)
    uploadFileNameList(newList);
  };

  const toggleExpansion = () => {
    setExpanded((exp) => !exp);
  };

  useEffect(() => {
    if (files?.length > 0) {
      const initialFileName = files.map((e) => e.fileName);
      uploadFileList(initialFileName);
    }
  }, []);

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={toggleExpansion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="uploadMedia-content"
          id="uploadMedia-header"
        >
          <Typography className={classes.heading}>Upload Media</Typography>
          {uploadProgress && (
            <LinearProgress />
          )}
          {fullDataSubmit === true && (
            <Info>
              <WarningIcon />
              <Typography className={classes.secondaryHeading}>Selected media needs to be saved</Typography>
            </Info>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <input
            type="file"
            multiple
            accept="video/mp4,video/x-m4v,video/*"
            onChange={uploadFiles}
            ref={fileUpload}
            style={{display:"none"}}
          />
          <div style={{ flexDirection: 'row', width: '100%' }}>
            <Typography variant="caption" color="error">Please keep all file names unique</Typography>
            <div style={{ padding: '5px' }} />
            <TableContainer component={Paper}>
              <Table aria-label="File List">
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell align="right">Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fileList.map((row, index) => (
                    <TableRow key={row.fileName}>
                      <TableCell component="th" scope="row">
                        {row.fileName}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeFile(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ padding: '5px' }} />
            <div>
              <Button
                variant="outlined"
                color="default"
                onClick={openFileLoader}
                startIcon={<AddIcon />}
              >
                Add Media
              </Button>
            </div>
            <div style={{ padding: '5px' }} />
            <div style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={uploadToCloud}
                startIcon={<CloudUploadIcon />}
              >
                Upload Media
              </Button>
              { uploadProgress ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default UploadMedia;

const Info = styled.div`
  display: flex;
  align-items: center;
`;