import React, { useState } from 'react';
import {
  TextField,
  Input
} from '@material-ui/core';

export default ({
  defaultValue,
  updateLimit,
  option,
  set
}) => {
  const [value, setValue] = useState(defaultValue);

  const saveLimit = () => {
    const nV = value || -1;
    updateLimit(parseInt(nV), option, set);
  };

  return (
    <Input
      type="number"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={saveLimit}
    />
  );
};