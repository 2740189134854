import React, { useEffect } from 'react'
import {Fragment} from 'react';
import {
    FormControl,
    FormControlLabel,
    Button,
    ButtonGroup,
    Radio,
    RadioGroup,
    FormLabel,
    TextField
} from '@material-ui/core';

import { StarBorderOutlined, Favorite, SentimentSatisfiedAlt , LooksOne} from '@material-ui/icons';

export default (props) => {
  const values = props.questionObject.hasOwnProperty('icon') ? props.questionObject : {
    max: "5",
    icon: 'star',
    startText: '',
    endText:'',
    min: "1"
  };
  
  const [max, setMax] = React.useState(values.max);
  const [startText, setStartText] = React.useState(values.startText);
  const [endText, setEndText] = React.useState(values.endText);
  const [min, setMin] = React.useState(values.min);
  const [icon, setIcon] = React.useState(values.icon);
  

  useEffect(() => {
    if (!props.questionObject.hasOwnProperty('icon')) {
      props.updateQuestionObject(values);
    }      
  },[]);

  const updateQuestionObject = (obj) => {
    props.updateQuestionObject(obj);
  }

  const submitDropDown=()=>{
    updateQuestionObject({ max: max , startText:startText, endText: endText, icon:icon, min: min})

  }

  const handelSetIcon = value =>{
    setIcon(value);
    updateQuestionObject({ ...values, icon: value })
  }

  return(
    <Fragment>
      <FormControl component="fieldset" className="d-block mb-3">
        <FormLabel component="legend">Rating Icon</FormLabel>
        <ButtonGroup color="primary" aria-label="rating type icon select">
          <Button variant={icon == "star" ? "contained" : "outlined" } startIcon={<StarBorderOutlined />} onClick={handelSetIcon.bind(null, "star")}>Star</Button>
          <Button variant={icon == "heart" ? "contained" : "outlined" } startIcon={<Favorite />} onClick={handelSetIcon.bind(null, "heart")}>Heart</Button>
          <Button variant={icon == "smile" ? "contained" : "outlined" } startIcon={<SentimentSatisfiedAlt />} onClick={handelSetIcon.bind(null, "smile")}>Smile</Button>
          <Button variant={icon == "number" ? "contained" : "outlined" } startIcon={<LooksOne />} onClick={handelSetIcon.bind(null, "number")}>Number</Button>
        </ButtonGroup>
      </FormControl>
      <FormControl component="fieldset" className="d-block">
      <TextField  variant="outlined"  className="mb-2" size="small"
                    label="Max value"
                    type="text"
                    fullWidth
                    value={max}
                    onChange={(event) => {
                      setMax(event.target.value)
                    }}
                    onBlur={submitDropDown}
                  />
      {(icon === 'number')?
      <Fragment>
        <TextField  variant="outlined"  className="mb-2"  size="small"
                    label="minimum value"
                    type="text"
                    fullWidth
                    value={min}
                    onChange={(event) => {
                      setMin(event.target.value)
                    }}
                    onBlur={submitDropDown}
                  />
      <TextField  variant="outlined"  className="mb-2"  size="small"
                    label="Start text"
                    type="text"
                    fullWidth
                    value={startText}
                    onChange={(event) => {
                    setStartText(event.target.value)
                    }}
                    onBlur={submitDropDown}
                  />
      
      <TextField  variant="outlined"  className="mb-2" size="small"
                    label="End text"
                    type="text"
                    fullWidth
                    value={endText}
                    onChange={(event) => {
                    setEndText(event.target.value)
                    }}
                    onBlur={submitDropDown}
                  />
        </Fragment>
        :null}
      </FormControl>
      
    </Fragment>
  )
}