export const SENTIMENTS = {
  neutral: {
    text: 'Neutral',
    color: '#AEB6BF',
    value: 4
  },
  sad: {
    text: 'Sad',
    color: '#A9CCE3',
    value: 3
  },
  angry: {
    text: 'Angry',
    color: '#EC7063',
    value: 2
  },
  disgust: {
    text: 'Disgust',
    color: '#C39BD3',
    value: 1
  },
  fear: {
    text: 'Fear',
    color: '#F7DC6F',
    value: 0
  },
  happy: {
    text: 'Happy',
    color: '#58D68D',
    value: 5
  },
  surprise: {
    text: 'Surprise',
    color: '#E59866',
    value: 6
  }
};
