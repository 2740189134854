import React, { PureComponent } from 'react';
import {Route, Redirect} from 'react-router-dom'
import {
  Tabs,
  Tab,
  Box,
  Button,
  Grid,
  LinearProgress
} from '@material-ui/core';
import { Save} from '@material-ui/icons';
import Design from './Design';
import Preview from './Preview';
import Panel from './Panel';
import TabPanel from '../../../components/TabPanel';
import { Authorization, Survey } from '../../../Store/ApplicationStore';
import Setting from './Setting';

const getRandomId = () => {
  const val = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
  return val;
};

export default class Manage extends PureComponent {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.state = {
      batchId: urlParams.get('id'),
      tab: 0,
      panel: {},
      questions: [],
      removedQuestions: [],
      title: "",
      submitProgress: false,
      surveyData:{},
      saveStatus:true,
      autoSave:false,
      unauthorised: false,
      permission: {
        isDownload: true,
        isForm: true,
        isAiAnalysis: true
      }
    }
    this.questionIdsMapping = [];
    this.answerIdsMapping = [];
  }

  componentDidMount() {
    Survey.getQuestionIdsMapping().then((data) => {
      this.questionIdsMapping = data;
    });
    Survey.getAnswerIdsMapping().then((data) => {
      this.answerIdsMapping = data;
    });
    this.fetchAllQuestions();
    this.fetchSurveyDetails();
    this.fetchPermissions();
    // this.interval = setInterval(() => { 
    //   if (this.state.autoSave){
    //     this.submitQuestions() 
    //   }
    // }, 1000* 30);
  }
  
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchPermissions = async () => {
    const per = await Authorization.getPermission();
    this.setState({
      permission: per
    });
  }

  fetchAllQuestions = () => {
    Survey.getAllQuestions(this.state.batchId).then((data) => {
      if (data == 'unauthorised'){
       this.setState({
         unauthorised: true,
         questions:[]
       })
      }
      this.setState({
        questions: data,
        removedQuestions: []
      })
    })
  }
  
  fetchSurveyDetails = () => {
    Survey.getAllSurveys(this.state.batchId).then((data) => {
      if (data.length != 0){
        let dataOut = data[0]
        dataOut.edited = false
        this.setState({
          surveyData: dataOut,
          title : dataOut.title
        })
      }
    })
  }

  handleChange = (event, newValue) => {
    this.setState({
      tab: newValue
    })
  };

  editSurvey = (surveyData) =>{
    this.setState({
      surveyData : surveyData,
      title:surveyData.title
    })
    this.setState({
      saveStatus: false
    })
  }

  addQuestions = (answerType = false) => {
    let id = getRandomId();
    let qObj = {
      feQId: id,
      questionId: false,
      questionText: "",
      questionSubText:"",
      questionType: 1,
      questionTypeName: "text",
      questionUrl: "",
      questionFile: false,
      answerType: answerType,
      condition: {
        status: false,
        list: []
      },
      orderId:0,
      questionObject: {},
      extraInfo: {}
    }
    switch (answerType) {
      case 'file':
      case 'singleTextSelect':
      case 'multiTextSelect':
      case 'singleImageSelect':
      case 'multiImageSelect':
        qObj.questionObject = {
          options: []
        }
    }
    this.setState((state, props) => {
      let newQues = [ ...state.questions ];
      newQues.push(qObj);
      return {
        questions: newQues,
        saveStatus: false
      }
    })
  }

  addQuestionTemplate = (answerObj) => {
    let id = getRandomId();
    answerObj.feQId = id
    if (answerObj.condition.hasOwnProperty("list")){
      answerObj.condition.list.map((e, i) => {
        if(e.hasOwnProperty('list')){
          for (let x = 0 ; x < e.list.length ; x++){
            if(e.list[x].hasOwnProperty('question')){
              e.list[x]['question'] = id
            }
          }
          }
        })
      }
    answerObj.condition.list[0].list[0].question =  id
    this.setState((state, props) => {
      let newQues = [ ...state.questions ];
      newQues.push(answerObj);
      return {
        questions: newQues,
        saveStatus: false
      }
    })
  }

  modifyQuestion = (index, obj) => {
    // if (obj.hasOwnProperty('questionTypeName')) {
    //   let qTypeName = obj.questionTypeName;
    //   for(let i = 0; i < this.questionIdsMapping.length; i++) {
    //     let lowercaseText = this.questionIdsMapping[i]["name"].toLowerCase();
    //     if (lowercaseText == qTypeName) {
    //       questions[index]["questionType"] = this.questionIdsMapping[i]["id"];
    //       break;
    //     }
    //   }
    // }
    
    this.setState((prevState) => {
      let questions = [ ...prevState.questions ];
      questions[index] = { ...obj, edited: true };
      return {
        ...prevState,
        questions,
        saveStatus: false
      };
    });
  }

  moveQuestion = (from, to) => {
    const newQuestions = [];
    for (let i = 0; i < this.state.questions.length; i += 1) {
      if ((i > to && i > from) || (i < from && i < to)) {
        newQuestions.push({
          ...this.state.questions[i],
          orderId: i + 1
        });
      } else if (i === to) {
        newQuestions.push({
          ...this.state.questions[from],
          orderId: i + 1,
          edited: true
        });
      } else if (to < from) {
        newQuestions.push({
          ...this.state.questions[i - 1],
          orderId: i + 1,
          edited: true
        });
      } else if (to > from) {
        newQuestions.push({
          ...this.state.questions[i + 1],
          orderId: i + 1,
          edited: true
        });
      }
    }
    this.setState({
      questions: newQuestions,
      saveStatus: false
    });

    // let newQuestions = [ ...this.state.questions ];
    // newQuestions.splice(from, 1);
    // newQuestions.splice(to, 0, { ...this.state.questions[from] });
    // for (let x = 0 ; x < newQuestions.length; x++){
    //   newQuestions[x].orderId = x + 1
    //   newQuestions[x].edited = true
    // }
  }

  removeQuestion = (index) => {
    let newQuestions = [ ...this.state.questions ];
    let remQues = newQuestions.splice(index, 1);
    let removedQuestions = remQues.concat(this.state.removedQuestions);
    this.setState({
      questions: newQuestions,
      removedQuestions: removedQuestions
    })
    this.setState({
      saveStatus: false
    })
  }

  submitQuestions = () => {
    this.setState({
      submitProgress: true
    })
    Survey.submitQuestions(this.state.questions, this.state.removedQuestions, this.state.batchId).then((data) => {
      this.fetchAllQuestions();
      this.setState({
        submitProgress: false
      })
    

      if (this.state.surveyData.edited === true){
        Survey.updateExistingSurvey(this.state.surveyData)
      }
      
    }).catch((err) => {
      console.log(err);
    })
  }

  
  submitDisabled = () => {
    if (this.state.removedQuestions.length > 0) {
      return false;
    }
    for(let i = 0; i < this.state.questions.length; i++) {
      if (this.state.questions[i]["questionId"] === false) {
        return false;
      }
      else if (this.state.questions[i]["questionId"] && this.state.questions[i]["edited"]) {
        return false;
      }
    }
    return true;
  }

  anlysisRoute = (e ,history)=>{
    e.preventDefault()
    history.push("/Dashboard?id="+ this.state.batchId) 
  }

  formRoute = (e ,history)=>{
    e.preventDefault() 
    history.push("/FormDashboard?id="+ this.state.batchId)

  }

  disableAutoSave =(e)=>{
    e.preventDefault()
    this.setState({
      autoSave: false
    })
  }
  enableAutoSave =(e)=>{
    e.preventDefault()
    this.setState({
      autoSave: true
    })
  }

  render() {
    if (this.state.unauthorised){
      return <Redirect to='/Home'/>;
    }
    else{
    return (
      
      <div className="d-flex flex-column" style={{marginTop:'-30px'}}>
        <Grid container spacing={0}  style={{backgroundColor:"#fff" ,borderBottom:"1px solid rgb(226 225 225)"}} justify="center">
            <Grid item xs={1} lg={1} md={1} className="mt-2 mb-2">
            </Grid>
            <Grid item xs={6} lg={6} md={6} className="mt-2 mb-2">
            <div className="app-page-title--heading">
              <h1>{this.state.title}</h1>
            </div>
            </Grid>

            <Grid item xs={1} lg={1} md={1} className="mt-2 mb-2">
                <Route render={({ history}) => (
                    <Button color="secondary"  variant="contained" 
                      style={{float:"right"}} onClick={(e) => this.anlysisRoute(e, history)} >
                      Analysis  
                    </Button>
                )} />
            </Grid>
            {this.state.permission.isForm && (
              <Grid item xs={1} lg={1} md={1} className="mt-2 mb-2">
                <Route render={({ history}) => (
                        <Button color="secondary" variant="contained" 
                            style={{marginLeft:"5px"}} onClick={(e) => this.formRoute(e, history)}>
                            Form 
                        </Button>
                    )} />
              </Grid>  
            )}
            
            {/* <Grid item xs={2} lg={2} md={2} className="mt-2 mb-2">
              {this.state.autoSave ? 
                <Button 
                  color="secondary"
                  variant="contained"
                  onClick={(e)=>this.disableAutoSave(e)}
                >
                  Disable Auto Save
                </Button>:
                <Button 
                  color="secondary"
                  variant="contained"
                  onClick={(e)=>this.enableAutoSave(e)}
                >
                  Enable Auto Save
                </Button>
              }
            </Grid> */}
            <Grid item xs={1} lg={1} md={1} className="mt-2 mb-2 ">
              <Button  style={{marginLeft:'5px'}}
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={this.submitQuestions}
              disabled={this.state.saveStatus}
            >
              Save
            </Button>
            
            </Grid>
          </Grid>
       {this.state.submitProgress ? <LinearProgress /> : null}
        <Box bgcolor="white" p={0} m={0} >
          <Tabs
            value={this.state.tab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            onChange={this.handleChange}>
            <Tab className="text-capitalize " label="Design QNR" />
            <Tab className="text-capitalize " label="Panel" />
            <Tab className="text-capitalize " label="Preview" />
            <Tab className="text-capitalize " label="Setting" />
          </Tabs>
          <Box borderTop={0.5} >
            <TabPanel value={this.state.tab} index={0}>
              <Design questions={this.state.questions} surveyId={this.state.batchId} moveQuestion={this.moveQuestion} modifyQuestion={this.modifyQuestion} removeQuestion={this.removeQuestion} addQuestions={this.addQuestions} addQuestionTemplate={this.addQuestionTemplate}/>
            </TabPanel>
            <TabPanel value={this.state.tab} index={1}>
              <Panel id={this.state.batchId} name={this.state.title} surveyData={this.state.surveyData} editSurvey={this.editSurvey}/>
            </TabPanel>
            <TabPanel value={this.state.tab} index={2}>
              <Preview surveyId={this.state.batchId} questions={this.state.questions} surveyData={this.state.surveyData} editSurvey={this.editSurvey}/>
            </TabPanel>
            <TabPanel value={this.state.tab} index={3}>
              <Setting surveyData={this.state.surveyData} editSurvey={this.editSurvey} surveyId={this.state.batchId}/>
            </TabPanel>
          </Box>
        </Box>
      </div>
    )
  }
}
}