import React, { Fragment, useEffect } from 'react';
import { Authorization } from '../../../Store/ApplicationStore';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import {
  Button,
  TextField,
} from '@material-ui/core';


const Register = (props) => {
    const [name, setName] = React.useState('');
    const [dob, setDob] = React.useState(new Date());
    const [gender, setGender] = React.useState('1');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [laititude, setLaititude] = React.useState(0);
    const [longitude, setLongitude] = React.useState(0);
    const [countryList, setCountryList] = React.useState([]);
    const [country, setCountry] = React.useState('');
    const [cityId, setCityId] = React.useState(0);
    const [cityList, setCityList] = React.useState([]);
    const [emailDoesnNotExists, setEmailDoesnNotExists] = React.useState(true);
    const [isValidate, setIsValidate] = React.useState(true);
    const [dobIsChanged, setDobIsChanged] = React.useState(false);
    const [validationText, setValidationText] = React.useState('');
    
   


    const handleCountryChange = (event, values) =>{
        setCountry(values.name)
          Authorization.getCity(values.name).then(response => {
            setCityList(response)
          })
      }

    const handleEmailChange = (value) =>{
        setEmailDoesnNotExists(true)
        if (value.length > 8)
        {
          if (validateEmail(value))
          {
            Authorization.checkEmailExists(value).then(response => {
                setEmailDoesnNotExists(response)
            })
          }
          else{
            setEmailDoesnNotExists(false)
          }
        }
        setEmail(value)
      }
      
     const validateEmail = (email) =>{
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      }
    
    const handleDob = (event , value)=>{
      setDob(value)
      setDobIsChanged(true)
    }

  const genderObjList= [
      {
          id:0,
          name: "Female"
      },
      {
        id:1,
        name: "Male"
    }
  ]
  const handleSubmit = (event) => {

    if (name &&
      email && password && 
       emailDoesnNotExists) {
          props.register(name ,  email,  password);
        }
        else{
          setIsValidate(false)
          let text = "Please Select a Valid "
          if (emailDoesnNotExists){
            text = text.concat(" Email,")
          }
          setValidationText(text)
        }
    }
  
    return (
      <Fragment>
  
        <p className="font-size-lg mb-5 text-black-50">
            Fill in the fields below and you'll be good to go.
        </p>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Email"
            fullWidth
            placeholder="Enter your email address"
            type="email"
            value={email}
            {...(!emailDoesnNotExists ? {error:true} : {})}
            {...(!emailDoesnNotExists ? { helperText:"email already exists or invalid emial"} : {})}
            onChange={(event) => {handleEmailChange(event.target.value)}}
            />
        </div>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Password"
            fullWidth
            placeholder="Enter your password"
            type="password"
            value={password}
            onChange={(event) => {setPassword(event.target.value)}}
            />
        </div>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Name"
            fullWidth
            placeholder="Enter your name"
            type="text"
            value={name}
            onChange={(event) => {setName(event.target.value)}}
            />
        </div>
        {/* <div className="mb3">
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker
                    inputVariant="outlined"
                    id="date-picker-inline"
                    className="MuiFormControl-fullWidth"
                    variant="outlined"
                    format="MM/dd/yyyy"
                    label="Date of Birth"
                    value={dob}
                    onChange={handleDob}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </MuiPickersUtilsProvider>
        </div> */}
        {/* <div className="mb3">
                  <Autocomplete className="my-3"
                    id="gender"
                    size="medium"
                    options={genderObjList}
                    getOptionLabel={option => option.name}
                    defaultValue={genderObjList[1]}
                    onChange={(event, values) => values ? setGender(values.id): null}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Gender"
                        placeholder="Gender"
                        fullWidth
                      />
                    )}
                  />
        </div> */}
        {/* <div className="mb3">
                <Autocomplete className="my-3"
                    id="country"
                    size="medium"
                    options={countryList}
                    getOptionLabel={option => option.name}
                    onChange={handleCountryChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Country"
                        placeholder="Country"
                        fullWidth
                      />
                    )}
                  />
        </div>

        <div className="mb3">
          { country ?  <Autocomplete className="my-3"
                    id="city"
                    size="medium"
                    options={cityList}
                    getOptionLabel={option => option.name}
                    onChange={(event, values) => values? setCityId(values.id) : null}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="City"
                        placeholder="City"
                        fullWidth
                      />
                    )}
                  />
                  : null}
        </div> */}
        { !isValidate ? <div className="w-100"><Alert severity="error">{validationText}</Alert></div> : null }
        <div className="form-group pt-2 mb-4">
            By clicking the <strong>Create account</strong>{' '}
            button below you agree to our terms of service and
            privacy statement.
        </div>

        <Button
            color="primary"
            size="large"
            variant="contained"
            className="mb-5" 
            onClick={ () => { handleSubmit() }}>
            Create Account
        </Button>
                       
      </Fragment>
    );
  
  
};
  export default Register;