
import React, { Fragment, useState } from "react";

import { Button, CircularProgress, Card, CardContent, List,
     ListItem ,Grid, FormControlLabel, Checkbox} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export default (props) => {

  const rowValue = props.questionObject.hasOwnProperty('row') ? props.questionObject.row: {};

  const orderBelow = props.questionObject.hasOwnProperty('orderBelow') ? props.questionObject.orderBelow : false ;
  const [answer, setAnswer] = useState({'name': ''});
  const [progressbar, setProgressbar] = useState(false);
  const [isAnswered,setIsAnswered] = useState(false)
  const handleCheckBoxChange = (event) =>{
    setAnswer(rowValue[event.target.name])
  }

  const submitAnswer = () => {
  if ( answer.name === ''){
    setIsAnswered(true)
  }
  else{
    setIsAnswered(false)
    setProgressbar(true)
  
    props.submit({
        value: [answer]
    })
  }
  }
  
    return (
      <Fragment>
        <Grid container spacing={4} justify="center">
            <Grid item xs={11} md={11} lg={11}>
            {
            Object.keys(rowValue).map((row, j) => (
              orderBelow?
                <Grid container  key={j}>
                <FormControlLabel key={j}
                  control={
                    <Checkbox color={props.theme.color}  onChange={handleCheckBoxChange} name={row} checked={answer['name'] === rowValue[row]['name']}/>
                    
                  }
                  label={<span style={{fontSize:props.theme.componentFontSize, color:props.theme.fontColor, fontFamily:props.theme.fontFamily, whiteSpace :"pre-wrap"
                    }}>{rowValue[row]['name']}</span>}
                  value={row}
                />
              </Grid>
              :
              <FormControlLabel key={j}
                  control={
                    <Checkbox color={props.theme.color}  onChange={handleCheckBoxChange} name={row} checked={answer['name'] === rowValue[row]['name'] }/>
                    
                  }
                  label={<span style={{fontSize:props.theme.componentFontSize, color:props.theme.fontColor, fontFamily:props.theme.fontFamily, whiteSpace :"pre-wrap"
                    }}>{rowValue[row]['name']}</span>}
                  value={row}
                />
                ))
            }
            </Grid>

      </Grid>
      {isAnswered ? <div>
          <Alert severity="error">Please Answer </Alert>
          </div> : null}
        <div className="d-block" >
          {props.preview ? null : (<Button className="mt-3" variant={props.theme.buttonVariant} color={props.theme.color}  onClick={submitAnswer}>Submit</Button>)}
          { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
        </div>
    </Fragment>
    );

}
