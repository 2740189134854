import React, { useState } from 'react';
import {
  Card,
  Checkbox,
  Grid,
  IconButton,
  Typography, Box, FormControl, FormControlLabel
} from '@material-ui/core';
import { IoManSharp, IoWomanSharp } from "react-icons/io5";
import {
  NEUTRAL_COLOR,
  MAN_COLOR,
  WOMAN_COLOR
} from './constants';
import {percentageConevertor} from './util'
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import { IoReloadSharp ,IoReorderThreeSharp } from "react-icons/io5";

const FONT_SIZE = "48px";

const Gender = ({
  male,
  female,
  filters,
  setFilter,
  isChart,
   totalCount
}) => {
  const [hideShow, setHideShow] = useState(true);

  const handleHideShowChange = () => {
    setHideShow((prev) => !prev);
  };

  const updateFilter = (gender) => {
    const type = gender === 'man' ? '1' : '0';
    if (type === filters) {
      setFilter('');
    }
    else{
      setFilter(type);
    }
  };

  return (
    <>
    { !isChart ?
    <div className="m-2 p-2">
    <div className={ clsx({"card-header pr-2 align-box-row w-100": true, "border-bottom":hideShow })} style={{padding:' 0rem 1.25rem'}}>
          <div className="card-header--title  font-size-md font-weight-bold align-box-row w-100" >
          Gender
          </div>
          <div className="ml-auto align-self-right">
              <IconButton
                color="primary"
                aria-label="refresh"
                onClick={() =>  setFilter([])}
                disabled={filters.length === 0}
              >
                <IoReloadSharp />
            </IconButton>
          </div>
          <div className="ml-auto align-self-right">
            <IconButton
              color="secondary"
              aria-label="refresh"
              onClick={handleHideShowChange}
            >
              <IoReorderThreeSharp />
            </IconButton>
          </div>
      </div>
      <Collapse in={hideShow}>
      <div className="d-flex opacity-9" >
         <FormControl className="d-flex" >
            <FormControlLabel 
              className="ml-4 mb-0 mt-0"
                  control={
                    <Checkbox
                      checked={filters == '1'}
                      onChange={() => updateFilter('man')}
                      name={'man'}
                      color="secondary"
                    />
                  }
                  label={'Male'}
                />
              
              <FormControlLabel 
              className="ml-4 mb-0 mt-0"
                  control={
                    <Checkbox
                      checked={filters == '0'}
                      onChange={() =>  updateFilter('woman')}
                      name={'woman'}
                      color="secondary"
                    />
                  }
                  label={'Female'}
                />

          </FormControl>
        </div>
      </Collapse>
      </div>
      :
    <Card className="card-box mb-4">
      <div className="card-header pr-2" style={{padding:' 0.25rem 1.25rem'}}>
          <div className="card-header--title mb-0 font-size-md font-weight-bold">
              Gender
          </div>
          <Box className="card-header--actions">
              <IconButton
                color="primary"
                aria-label="refresh"
                onClick={() => setFilter('')}
                disabled={filters.length === 0}
              >
                <IoReloadSharp />
            </IconButton>
          </Box>
      </div>

      <Grid container spacing={2} justifyContent="center" style={{minHeight: '235px' , maxHeight:'235px'}} className="p-2">
          <Grid item xs={6} md={6} lg={6} style={{ textAlign: 'center' }} className="mt-4">
            <IconButton
             className="mt-4"
              onClick={() => updateFilter('man')}
            >
              <IoManSharp
                color={filters !== '0' ? MAN_COLOR : NEUTRAL_COLOR}
                fontSize={FONT_SIZE}
              />
            </IconButton>
            <Typography variant="h4" style={{ color: MAN_COLOR }} className="ml-2">
              { `${percentageConevertor(male, totalCount)} %` }
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6} style={{ textAlign: 'center' }} className="mt-4">
            <IconButton
             className="mt-4"
              onClick={() => updateFilter('woman')}
            >
              <IoWomanSharp
                color={filters !== '1' ? WOMAN_COLOR : NEUTRAL_COLOR}
                fontSize={FONT_SIZE}
              />
            </IconButton>
            <Typography variant="h4" style={{ color: WOMAN_COLOR }} className="ml-2">
              { `${percentageConevertor(female, totalCount)} %`}
            </Typography>
          </Grid>
        </Grid>

  </Card>
}
    </>
  );
};

export default Gender;
