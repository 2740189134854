import React, { lazy, Suspense, Fragment, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation , useHistory} from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';
import { ClimbingBoxLoader } from 'react-spinners';
import MuiTheme from './theme';
import { Authorization } from './Store/ApplicationStore';
// Layout Blueprints

import {
  PageWrapper,
  MinimalLayout
} from './layout-blueprints';

import LandingPage from './containers/LandingPage/LandingPage';
import RegisterLogin from './containers/Authentication/Register/index';
import FormDashboard from './containers/Survey/FormDashboard';
import TranscriptDashboard from './containers/Survey/TranscriptDashboard';
import QuotaDashboard from './containers/Survey/QuotaDashboard'
import OperationLink from './containers/Survey/OperationLink'

const Routes = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [pathname, setRedirect] = useState('');
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(0)
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [permission, setPermission] = useState({
    isDownload: true,
    isForm: true,
    isAiAnalysis: true,
    isBrand: false,
    isAgnecy: false
  });

  const getUserPermission = async () => {
    const per = await Authorization.getPermission();
    setPermission(per);
  };

  useEffect(() => {
    const redirect = location.pathname.indexOf('Home') >= 0 || location.pathname.indexOf('Manage') >= 0 || location.pathname.indexOf('Dashboard') >= 0 ? location.pathname + location.search : '/Home';
    setRedirect(redirect);
    if (!isAuthenticated){
      Authorization.isAuthenticated().then(async res =>{
        if(res){
          setIsAuthenticated(res.auth);
          setUserName(res.userName);
          setUserId(res.userId)
          await getUserPermission();
          history.replace(redirect);
        }
      })
    }
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };
  
  const login = async (email, password) =>{
    try {
      let result = await Authorization.login(email, password);
      if (result.success) {
        setIsAuthenticated(true);
        setIsValidLogin(true)
        let out = await Authorization.getUserDetails();
        await getUserPermission();
        setUserName(out.userName)
        history.replace(pathname);
      }else{
        setIsValidLogin(false)
      }
      
    }
    catch(err) {
      console.log(err);
    }
  }
  const logout= async () =>{
    try {
      await Authorization.logout();
    }
    catch(err) {}
    setIsAuthenticated(false)
    history.replace('/Login');
  } 

  

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait 
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
          {!isAuthenticated ? (
            <Fragment>
            {/* <Redirect exact from="/Login" to="/Login" /> */}
              <Route exact path="/">
                <Redirect to="/Login" />
              </Route>
              <Route path={['/Login', '/Register']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Login" >
                        <RegisterLogin  login={login}  tab={0} isValidLogin={isValidLogin} showSignUp={false}/>
                      </Route>
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>
          </Fragment>
          ):(
            <Fragment>
              <Route path={['/Home', '/FormDashboard','/TranscriptDashboard', '/QuotaDashboard', '/operationLink']}>
                <PageWrapper userName={userName} logout={logout}>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      style={{ height: '100%' }}
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Home" >
                        <LandingPage isOperation={true} logout={logout}/>
                      </Route>
                      <Route path="/FormDashboard" >
                      {permission.isForm ? <FormDashboard isOperation={true} permission={permission}/> : null }
                      </Route>
                      <Route path="/TranscriptDashboard" >
                       {permission.isAiAnalysis ? <TranscriptDashboard isOperation={true}/> : null }
                      </Route>
                      <Route path="/QuotaDashboard" >
                      {permission.isForm ?  <QuotaDashboard isOperation={true} permission={permission}/> : null }
                      </Route>
                      <Route path="/operationLink" >
                        <OperationLink isOperation={true} permission={permission} userId={userId}/>
                      </Route>
                    </motion.div>
                  </Switch>
                </PageWrapper>
              </Route>
            </Fragment>
          )}
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;