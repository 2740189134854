function getSupportedMimeTypes() {
  const VIDEO_TYPES = [
    "webm", 
    // "ogg",
    // "mp4",
    // "x-matroska"
  ];
  const VIDEO_CODECS = [
    "vp9",
    "vp9.0",
    "vp8",
    "vp8.0",
    "avc1",
    "av1",
    "h265",
    "h.265",
    "h264",
    "h.264",
    "opus",
  ];

  const supportedTypes = [];
  VIDEO_TYPES.forEach((videoType) => {
    const type = `video/${videoType}`;
    VIDEO_CODECS.forEach((codec) => {
        const variations = [
        `${type};codecs=${codec}`,
        `${type};codecs:${codec}`,
        `${type};codecs=${codec.toUpperCase()}`,
        `${type};codecs:${codec.toUpperCase()}`,
        `${type}`
      ]
      variations.forEach(variation => {
        if(MediaRecorder.isTypeSupported(variation)) 
            supportedTypes.push(variation);
      })
    });
  });
  return supportedTypes;
}

const supportedMimeTypes = getSupportedMimeTypes();
// console.log('Best supported mime types by priority : ', supportedMimeTypes[0])
// console.log('All supported mime types ordered by priority : ', supportedMimeTypes)
export default supportedMimeTypes;