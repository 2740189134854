import React , { useEffect, useState, useRef } from 'react';
import {
    Typography, Button, CircularProgress, Grid
} from '@material-ui/core';
import { Questionaire } from '../../../Store/ApplicationStore';
import IosTrashOutline from 'react-ionicons/lib/IosTrashOutline';
import IosAdd from 'react-ionicons/lib/IosAdd';
import IosCloudUploadOutline from 'react-ionicons/lib/IosCloudUploadOutline';
import AddIcon from '@material-ui/icons/Add';
export default (props) => {

    const [fileUrl, setFileUrl] = useState(props.questionObject.hasOwnProperty('url') ? props.questionObject.url : null);
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(false);
    const fileUpload = useRef();
    
    const uploadToCloud = async () => {
          setUploadProgress(true);
            let out = await Questionaire.uploadFile(props.surveyId, props.feQId, file.file) 
            if (out != undefined){
              submitDropDown(out)
              setFileUrl(out)
              setUploadProgress(false)
            }
      };

    const submitDropDown = (url) => {
      props.updateQuestionObject({
        url: url
      })
    }
    
    const openFileLoader = () => {
    fileUpload.current.click();
    }
    const uploadFiles = (event) => {
        if (fileUpload.current.files.length > 0) {
            const fileSel = fileUpload.current.files[0];
            const fileName = fileSel.name.replace(/[&\/\\#, +()$~%@'":*?<>{}]/g,"_").replace(" ","_");
            const newFile = new File([fileSel], fileName, { type: fileSel.type });
            const fileUrl = URL.createObjectURL(newFile);
            let newFileObj ={
              fileName,
              fileUrl,
              file: newFile
            };
          setFile(newFileObj);
        }
      }

      const deleteMedia =()=>{
          setFile(null)
          setFileUrl(null)
          submitDropDown(null)
      }

    return (
        <>
        {fileUrl != null?
            <>
            <video className="rounded opacity-9 w-100" controls controlsList="nodownload" width="300px" height="300px">
            <source src={fileUrl} />
        </video>
        <div>
              <Button
                variant="outlined"
                color="danger"
                className="text-instagram m-2"
                onClick={deleteMedia}
                startIcon={<IosTrashOutline color="var(--danger)"/> }
              >
                Delete Media
              </Button>
            </div>
        </>
       :
       <>
      <Grid container spacing={1} >
      <Grid item xs={5} lg={2} md={2} >
        {file != null ?
              <span>{file.fileName}</span>
            : null }
        </Grid>
        <Grid item xs={5} lg={2} md={2} >
        <input
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={uploadFiles}
            ref={fileUpload}
            style={{display:"none"}}
          />
         
          <Button
                variant="outlined"
                color="primary"
                onClick={openFileLoader}
                startIcon={<IosAdd color="var(--primary)"/>}
              >
                Add Media
        </Button>
        </Grid>
        <Grid item xs={5} lg={3} md={3} >
        <Button
                variant="outlined"
                color="primary"
                onClick={uploadToCloud}
                startIcon={<IosCloudUploadOutline color="var(--primary)"/> }
              >
                Upload Media
              </Button>
              { uploadProgress ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
        </Grid>
      </Grid>

          </>

        }
        </>
    )
}
