import React, { useState, useRef, Fragment, useEffect } from 'react';
import QuestionList from './QuestionBank';

import { 
  TextField,
  Card,
  CardContent,
  IconButton,
  Button,
  ButtonGroup,
  MenuItem,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import IosTrashOutline from 'react-ionicons/lib/IosTrashOutline';
import IosVideocamOutline  from 'react-ionicons/lib/IosVideocam'
import IosMicOutline from 'react-ionicons/lib/IosMic'
import IosImagesOutline from 'react-ionicons/lib/IosImage'
import IosCloseCircleOutline from 'react-ionicons/lib/IosCloseCircleOutline'
import IosRemoveCircleOutline from 'react-ionicons/lib/IosRemoveCircleOutline'
import Dropzone from 'react-dropzone'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default (props) => {
  const [text, setText] = useState(props.data.questionText);
  const [subText, setSubText] = useState(props.data.questionSubText);
  const [type, setType] = useState(props.data.answerType || "");
  const [modal, setModal] = useState(false);
  const [condition, setCondition] = useState(props.data.condition);
  const [matchValue, setMatchValue] = useState({});
  const [tab, setTab] = useState(0);
  const [questionObj, setQuestionObj] = useState(props.data);
  let file = useRef();

  const toggleModal = () => {
    setModal(!modal);
  }

  const changeTab = (event, newValue) => {
    setTab(newValue);
  }

  const moveQuestion = (value) => {
    props.moveQuestion(props.index, value - 1);
  }

  const removeQuestionFile = () => {
    modifyQuestion({
      questionFile: false,
      questionUrl: "",
      questionTypeName: "text"
    })
  }

  const openFileLoader = (type) => {
    file.current.accept = type;
    file.current.click();
  }

  const fileUpload = (event) => {
    if (file.current.files.length > 0 && file.current.files[0].name) {
      let fileName = file.current.files[0].name.replace(/\s+/g,"_");
      let newFile = new File([file.current.files[0]] , fileName , { type: file.current.files[0].type });
      let fileType = event.target.files[0].type;

      fileUploadToQuestion(fileName,newFile,fileType)
    }
  }

  const fileUploadToQuestion=(fileName, newFile, fileType)=>{
    let questionTypeId = 1
    let qType = text != "" ? ' + text' : '';
      if (fileType.indexOf('image') >= 0) {
        qType = 'image' + qType;
        questionTypeId = 3
      }
      else if (fileType.indexOf('audio') >= 0) {
        qType = 'audio' + qType;
        questionTypeId = 2
      }
      else if (fileType.indexOf('video') >= 0) {
        qType = 'video' + qType;
        questionTypeId = 4
      }
      modifyQuestion({
        questionFile: newFile,
        questionUrl:  URL.createObjectURL(newFile),
        questionTypeName: qType,
        questionType:questionTypeId
      })
  }

  const selectAnswerType = (event) => {
    setType(event.target.value);
    modifyQuestion({
      answerType: event.target.value
    })
  }

  const updateQuestionObject = (obj) => {
    let newQuestionObject = { ...questionObj.questionObject, ...obj };
    let newQuestion = {
      questionObject: newQuestionObject
    };
    modifyQuestion(newQuestion);
  }

  const updateExtraQuestionInfo = (obj) => {
    const prevExtraInfo = questionObj.extraInfo;
    let extraInfo;
    if (typeof prevExtraInfo === 'object' && prevExtraInfo !== null) {
      extraInfo = {
        ...questionObj.extraInfo,
        ...obj
      };
    } else {
      extraInfo = {
        ...obj
      };
    }
    modifyQuestion({
      extraInfo
    });
  };

  const modifyQuestion = (obj) => {
    setQuestionObj((qObj) => {
      return {
        ...qObj,
        ...obj
      };
    });
  }

  const updateCondition = (cond) => {
    setCondition(cond);
    modifyQuestion({
      condition: { ...cond, status: cond.list.length > 0 }
    })
  }

  const updateJumpQuestion = (event, index) => {
    let newCondition = { ...condition };
    newCondition.list[index].jump = event.target.value;
    updateCondition(newCondition);
  }

  const updateLogicJoin = (event, cond, statement) => {
    let newCondition = { ...condition };
    newCondition.list[cond].list[statement].logic = event.target.value;
    updateCondition(newCondition);
  }

  const updateLineQuestion = (event, cond, statement) => {
    let newCondition = { ...condition };
    newCondition.list[cond].list[statement].question = event.target.value;
    let feId = event.target.value;
    if (feId != "" && !matchValue.hasOwnProperty(feId)) {
      for (let i = 0; i < props.allQuestions.length; i++) {
        if (props.allQuestions[i].feQId == feId) {
          let arr = [];
          let answerType = props.allQuestions[i].answerType;
          QuestionList.hasOwnProperty(answerType) && QuestionList[answerType]["condition"].forEach((e) => {
            arr.push(e.id);
          })
          setMatchValue( { ...matchValue, [feId]: arr } );
          break;
        }
      }
    }
    updateCondition(newCondition);
  }

  const getMatchValuewithfeqId = (feId) => {
    let arr = [];
    props.allQuestions.forEach((e)=>{
      if(e.feQId == feId){
        let answerType = e.answerType;
        QuestionList.hasOwnProperty(answerType) && QuestionList[answerType]["condition"].forEach((x) => {
          arr.push(x.id);
        })
      }
    })
    return arr
  }

  const updateLineQuestionValue = (event, cond, statement) => {
    let newCondition = { ...condition };
    newCondition.list[cond].list[statement].value = event.target.value;
    updateCondition(newCondition);
  }

  const updateLineQuestionCondition = (event, cond, statement) => {
    let newCondition = { ...condition };
    newCondition.list[cond].list[statement].condition = event.target.value;
    updateCondition(newCondition);
  }

  const removeCondition = (i) => {
    let newCondition = { ...condition };
    newCondition.list.splice(i, 1);
    updateCondition(newCondition);
  }

  const removeConditionStatement = (cond, statement) => {
    let newCondition = { ...condition };
    if (statement == 0 && newCondition.list[cond].list.length > 1) {
      newCondition.list[cond].list.splice(statement, 2);
    }
    else if (statement != 0) {
      newCondition.list[cond].list.splice(statement-1, 2);
    }
    else {
      newCondition.list[cond].list.splice(statement, 1);
    }
    updateCondition(newCondition);
  }

  const addCondition = () => {
    let newCondition = { ...condition };
    if (!newCondition.hasOwnProperty('list')) {
      newCondition.list = [];
    }
    newCondition.list.push({
      list: [
        {
          question: "",
          condition: "",
          value: ""
        }
      ],
      jump: ""
    })
    setCondition(newCondition);
  }

  const addExpression = (cond) => {
    let newCondition = { ...condition };
    if (newCondition.list[cond].list.length != 0) {
      newCondition.list[cond].list.push({
        logic: "and"
      })
    }
    newCondition.list[cond].list.push({
      question: "",
      condition: "",
      value: ""
    })
    setCondition(newCondition);
  }

  const updateMediaSize = (e, size)=>{
    let sizeObj = { mediaSize: size }
    let newQuestionObject = { ... questionObj.questionObject , ...sizeObj};
    let newQuestion = {
      questionObject: newQuestionObject
    };
    modifyQuestion(newQuestion);
  }

  const onDrop =(files) =>{  
    let fileName = files[0].name.replace(/\s+/g,"_");
    let newFile = new File([files[0]] , fileName , { type: files[0].type });
    let fileType = files[0].type;
    fileUploadToQuestion(fileName,newFile,fileType)
  }

  useEffect(() => {
    props.modifyQuestion(props.index, questionObj);
  }, [props.index, questionObj]);

  return (
    <Fragment>
      <Card className="card card-box mt-2 mb-4" variant="outlined">
      <div className="card-header justify-content-between">
      <Grid container spacing={0} >
        <Grid item xs={6} lg={9} md={9}>
          <Grid container spacing={0}>
            <Grid item xs={1} lg={1} md={1} style={{display: 'inline-grid', textAlign:'center', alignItems: 'center'}}>
            <span className="text-black-50 font-size-xl" >{`#${props.index+1}`}</span>
            </Grid>
            <Grid item xs={11} lg={11} md={11} >
              <div className="d-flex align-items-center" style={{flex: 1}}>
                <TextField fullWidth={true} value={text} multiline
                variant="outlined"
                onChange={(e) => {setText(e.target.value)}} 
                label="Question" onBlur={() => modifyQuestion({questionText: text})} />
              </div>
              <div className="d-flex mt-1 align-items-center" style={{flex: 1}}>
          
              <TextField fullWidth={true} value={subText} multiline
                variant="outlined" size="small"
                onChange={(e) => {setSubText(e.target.value)}} 
                label="Sub Section" onBlur={() => modifyQuestion({questionSubText: subText})} />
              </div>
            </Grid>
            </Grid>
        </Grid>
        
        
        <Grid item xs={6} lg={3} md={3} >
          <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} lg={6} md={11} >
                <FormControl  className="d-flex flex-row" variant="outlined">
                    {/* <InputLabel  htmlFor={`answer-type-${1}`}>Answer Type</InputLabel> */}
                    <Select
                      value={type}
                      fullWidth={true}
                      onChange={selectAnswerType}
                      inputProps={{
                        name: 'answer-type',
                        id: `answer-type-${1}`,
                      }}
                    >
                      {Object.keys(QuestionList).map((e) => (
                          <MenuItem value={e} key={e}>{QuestionList[e]["name"]}</MenuItem>
                        ))}
                    </Select>
                    
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4} md={8}>
                <FormControl className="d-flex flex-row">
                  {/* <InputLabel >Position</InputLabel> */}
                      <Select
                      variant="outlined"
                        value={props.index + 1}
                        onChange={(event) => moveQuestion(event.target.value)}
                      >
                        {props.allQuestions.map((e, i) => (
                          <MenuItem key={i} value={i+1}>{i+1}</MenuItem>
                        ))}
                  </Select>
                    <Tooltip arrow title="Delete Question">
                      <Button className="ml-2" variant="outlined" onClick={() => props.removeQuestion(props.index)}>
                        <span className="font-size-xl d-block mb-1">
                          <IosTrashOutline color="var(--danger)"/>
                        </span>
                      </Button>
                    </Tooltip>
        
                </FormControl>
              </Grid>

          </Grid>
        </Grid>
        
        </Grid>
        
        </div>
        <div className="card-body justify-content-between">
          <Grid container spacing={3}>
              <Grid item xs={4} md={4} lg={3} style={{borderRight:'1px solid #e0e0e2'}}>
                <div className="card-body justify-content-between">
                    {questionObj.questionUrl == "" ? (
                      <Card className="card-transparent mb-4 ">
                        <div className="card-img-wrapper w-100 h-100">
                          <div className="dropzone">
                              <Dropzone
                                  onDrop={onDrop}
                                  onFileDialogCancel={removeQuestionFile}
                              >
                                  {({getRootProps, getInputProps}) => (
                                      <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <div className="dz-message">
                                              <div className="dx-text">
                                                  Try dropping Audio or Video or Image Files.
                                              </div>
                                          </div>
                                      </div>
                                  )}
                              </Dropzone>
                          </div>
                          </div>
                          <Grid container spacing={0} justify='center' >
                            <Grid item xs={4} lg={4} md={5} className="mt-2">
                            <Tooltip arrow title="video Upload">
                              <Button  size="medium"  className="ml-1" variant="outlined" onClick={openFileLoader.bind(null, 'video/*')}  >
                                  <IosVideocamOutline  color="var(--primary)"/>
                              </Button>
                            </Tooltip> 
                            </Grid>
                            <Grid item xs={4} lg={4} md={5} className="mt-2">
                            <Tooltip arrow title="Audio Upload">
                              <Button size="medium"  className="ml-1" variant="outlined" onClick={openFileLoader.bind(null, 'audio/*')}>
                                  <IosMicOutline  color="var(--primary)"/>
                              </Button>
                            </Tooltip>
                            </Grid>
                            <Grid item xs={4} lg={4} md={5} className="mt-2">
                            <Tooltip arrow title="Image Upload">
                              <Button size="medium" className="ml-1" variant="outlined" onClick={openFileLoader.bind(null, 'image/*')}>
                                  <IosImagesOutline  color="var(--primary)"/>
                              </Button>
                            </Tooltip>
                            </Grid>
                          </Grid>
                          
                      </Card> 
              ) : (
                <Fragment>
                  <Card className="card-transparent mb-4 " style={{maxWidth:'250px' ,maxHeight:'250px' , minHeight:'200px'}}>
                    
                    <div className="card-img-wrapper w-100 h-100">
                        {questionObj.questionTypeName && questionObj.questionTypeName.indexOf('image') >= 0 ? (
                          <div className="card-img-wrapper">
                            <img src={questionObj.questionUrl}  className="card-img-top rounded" alt="..."/>
                        </div>
                        ) : (
                          questionObj.questionTypeName.indexOf('audio') >= 0 ?
                          <audio className="rounded opacity-9 w-100" controls controlsList="nodownload">
                              <source src={questionObj.questionUrl} />
                            </audio>
                            :
                          <video className="rounded opacity-9 w-100" controls controlsList="nodownload">
                            <source src={questionObj.questionUrl} />
                          </video>
                        )}
                      </div>
                  </Card>
                  <div>
                  <ButtonGroup size="small" color="primary" variant="outlined" orientation="horizontal" style={{float:"right"}}>
                    <Tooltip arrow title="Remove file">
                        <IconButton className="font-size-xxl d-block mb-1" onClick={removeQuestionFile}>
                          <IosCloseCircleOutline fontSize="25px" color="red"/>
                        </IconButton>
                    </Tooltip>
                  </ButtonGroup>
                  </div>
                  
                </Fragment>
              )}
              <input type="file" className="d-none" ref={file} onChange={fileUpload} />
            </div>
            <div style={{textAlign:"center", alignContent:"center"}}>
              <ButtonGroup  color="primary" aria-label="contained primary button group" size="small">
                    <Button variant={questionObj.questionObject.hasOwnProperty("mediaSize") ? 
                    questionObj.questionObject.mediaSize === 'small' ? "contained":"outlined" : "outlined"} 
                    onClick={(e)=>{updateMediaSize(e, 'small')}}>Small</Button>
                    <Button variant={questionObj.questionObject.hasOwnProperty("mediaSize") ? 
                    questionObj.questionObject.mediaSize === 'medium' ? "contained":"outlined" : "outlined"} 
                    onClick={(e)=>{updateMediaSize(e, 'medium')}}>Medium</Button>
                    <Button variant={questionObj.questionObject.hasOwnProperty("mediaSize") ? 
                    questionObj.questionObject.mediaSize === 'large' ? "contained":"outlined" : "outlined"} 
                    onClick={(e)=>{updateMediaSize(e, 'large')}} >Large</Button>
              </ButtonGroup>
            </div>
          </Grid>
          
          
          <Grid item xs={8} md={8} lg={9}>
              <CardContent className="p-3">
                  <div className="w-100 border-bottom">
                  <Tabs
                    value={tab}
                    onChange={changeTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="tabs"
                    >
                    <Tab label="Answer Options" />
                    <Tab label={(condition.hasOwnProperty('list') && condition.list.length !=0)? "jump logic *":"jump logic"} />
                  </Tabs>
                </div>
                    <TabPanel value={tab} index={0}>
                      <Card className="card-transparent mb-4 ">
                        <div className="card-body justify-content-between">
                          {QuestionList.hasOwnProperty(type) ? QuestionList[type]["create"]({
                              questionObject: questionObj.questionObject,
                              extraInfo: questionObj.extraInfo,
                              surveyId: props.surveyId,
                              feQId: questionObj.feQId,
                              updateQuestionObject: updateQuestionObject,
                              updateExtraQuestionInfo: updateExtraQuestionInfo,
                              allQuestions: props.allQuestions
                            }) : null}
                        </div>
                      </Card>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <div className="d-block">
                        Given this question is <b>answered</b>
                      </div>
                      {condition.hasOwnProperty('list') && condition.list.map((e, i) => (
                        <div key={i} className="d-block p-2 border rounded">
                          <div className="d-flex justify-content-between align-items-center">
                            <b>{`Jump Condition: ${i+1}`}</b>
                            <IconButton color="secondary" onClick={removeCondition.bind(null, i)}>
                              <IosTrashOutline fontSize="25px" color="red"/>
                            </IconButton>
                          </div>
                          {e.list.map((line, lineIndex) => {
                            if (line.hasOwnProperty('logic')) {
                              return (
                                <div className="d-flex w-100 mt-2 mb-2" key={lineIndex}>
                                  <FormControl size="small">
                                    <Select variant="outlined" value={line.logic} onChange={(event) => updateLogicJoin(event, i, lineIndex)}>
                                      <MenuItem value="and">And</MenuItem>
                                      <MenuItem value="or">Or</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              )
                            }
                            else {
                              return (
                                <Fragment key={i}>
                                <Grid container spacing={1} key={i} >
                                  <Grid item xs={1} lg={1} style={{textAlign:"center"}}>
                                      <span style={{fontSize:"22px"}}>If</span>
                                  </Grid>
                                  <Grid item xs={6} lg={6}>
                                      <FormControl size="small" variant="outlined"  fullWidth>
                                        <InputLabel>Select Question</InputLabel>
                                        <Select
                                          value={line.question}
                                          onChange={(event) => updateLineQuestion(event, i, lineIndex)}
                                          label="select question"
                                          
                                        >
                                          {props.allQuestions.map((q, qi) => {
                                            if (qi > props.index) {
                                              return null;
                                            }
                                            else {
                                              return <MenuItem key={q.feQId} value={q.feQId}>{`Question ${qi+1}: ${q.questionText}`}</MenuItem>
                                            }
                                          })}
                                        </Select>
                                      </FormControl>
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                      <FormControl size="small" variant="outlined" fullWidth>
                                        <InputLabel>condition</InputLabel>
                                        <Select
                                          value={line.condition}
                                          onChange={(event) => updateLineQuestionCondition(event, i, lineIndex)}
                                          label="condition"
                                        
                                        >
                                          {
                                          line.question == "" || (matchValue[line.question] != void(0) && matchValue[line.question].length == 0) ? (
                                            <MenuItem>has no condition</MenuItem>
                                          ) : 
                                              (matchValue[line.question] != void(0)) ? matchValue[line.question].map((m) => (
                                              <MenuItem key={m} value={m}>{m}</MenuItem>))  : getMatchValuewithfeqId(line.question).map((m) => (
                                                <MenuItem key={m} value={m}>{m}</MenuItem>)) 
                                          }
                                        </Select>
                                      </FormControl>
                                  </Grid>
                                  <Grid item xs={1} lg={1}>
                                      <span>following value</span>
                                  </Grid>
                                  <Grid item xs={1} lg={1}>
                                    <IconButton className="ml-2" onClick={removeConditionStatement.bind(null, i, lineIndex)}>
                                      <IosRemoveCircleOutline fontSize="25px" color="#ff4d4d"/>
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1} key={i} className="mb-2">
                                  <Grid item xs={11} lg={11}>
                                    <TextField className="ml-2 mr-2" size="small" multiline fullWidth={true} variant="outlined" value={line.value} onChange={(event) => updateLineQuestionValue(event, i, lineIndex)} />
                                  </Grid>
                                </Grid>
                                </Fragment>
                              )
                            }
                          })}
                          <div className="d-block">
                            <Button size="small" className="ml-2 mr-2" color="secondary" variant="outlined" onClick={addExpression.bind(null, i)}>Add Expression</Button>
                          </div>
                          <div className="d-block mt-3">
                            <FormControl variant="outlined" className="w-90" size="small" fullWidth>
                              <InputLabel>Jump To</InputLabel>
                              <Select
                              
                                value={e['jump']}
                                onChange={(event) => updateJumpQuestion(event, i)}
                                label="Jump To"
                              >
                                {props.allQuestions.map((q, qi) => {
                                  if (qi <= props.index) {
                                    return null;
                                  }
                                  else {
                                    return <MenuItem key={q.feQId} value={q.feQId}>{`Question ${qi+1}: ${q.questionText}`}</MenuItem>
                                  }
                                })}
                                <MenuItem value="terminate">Terminate</MenuItem>
                                <MenuItem value="end">Finish</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      ))}
                      <Button color="primary" variant="outlined" className="m-2" onClick={addCondition}>New Jump Condition</Button>
                    </TabPanel>
                  </CardContent>
              </Grid>
          </Grid>
        </div>                                
      </Card>
    </Fragment>
  )
}