import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { Grid , Hidden} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Favorite, StarBorder } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
export default (props) =>{
  const [rating , setRating] = useState(null)
  const [isAnswered,setIsAnswered] = useState(false)
  const startText = props.questionObject.startText || '';
  const endText = props.questionObject.endText || '';
  const maxValue = props.questionObject.max > 10 ? 10 : props.questionObject.max|| 5;
  const minValue = props.questionObject.min || 1;
  const icon = props.questionObject.icon;

  const [progressbar, setProgressbar] = useState(false);
  const StyledRating = withStyles({
    iconFilled: {
      color: '#ff6d75',
    },
    iconHover: {
      color: '#ff3d47',
    },
  })(Rating);
  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon fontSize="large" />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon fontSize="large" />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon fontSize="large" />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon fontSize="large" />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon fontSize="large" />,
      label: 'Very Satisfied',
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[maxValue > 5 ? Math.floor((value+1)/2) : value].icon}</span>;
  }
  
  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  const RatingIcon = (props) => {
    if (icon === 'smile') {
      return (
        <Rating
          {...props}
          getLabelText={value => customIcons[maxValue > 5 ? Math.floor((value+1)/2) : value].label}
          IconContainerComponent={IconContainer}
        />
      )
    }
    else if (icon === 'star') {
      return (
        <Rating
          {...props}
          emptyIcon={<StarBorder fontSize="large" />}
        />
      )
    }
    else if (icon === 'heart') {
      return (
        <StyledRating
          {...props}
          icon={<Favorite fontSize="large" />}
        />
      )
    }
    else if (icon === 'number') {
      return (
        <Fragment>
        <style>
        {`.rating_scale {
              display: block;
              text-align: center;
              font-size: 0;
              font-weight: 500;
          }
          .rating_scale label{
                cursor:pointer;
              }
              
          .rating_scale span {
                display: inline-block;
                box-sizing: border-box;
                padding: 1em 2em;
                font-size: 16px;
                color: `+ props.theme.colorValue +`;
                
                border-top: solid 1px `+ props.theme.colorValue +`;
                border-bottom: solid 1px `+ props.theme.colorValue +`;
                border-right: solid 1px `+ props.theme.colorValue +`;
                
              }
              
          .rating_scale label:first-child span {
                border-left: solid 1px `+ props.theme.colorValue +`;
                border-top-left-radius: 0.25em;
                border-bottom-left-radius: 0.25em;
          }
              
          .rating_scale label:last-child span {
                border-top-right-radius: 0.25em;
                border-bottom-right-radius: 0.25em;
              }
              
          .rating_scale input[type="radio"] {
                display: none;
                
          }
          .rating_scale input[type="radio"]:checked ~ span {
              background: `+ props.theme.colorValue +`;
              color: white;
              
            }`
        }
        </style>
        {/* {detectMob()? */}
       
        <Fragment>
        <Hidden   mdUp>
        <Grid container spacing={0} justify='center' >
              <Grid item xs={12} lg={1} md={1} className="mb-2">
                <span style={{
                 float:"left",
                  border:'none',color: props.theme.colorValue ,
                  fontSize: props.theme.componentFontSize }}>
                    {startText}
                </span>
              </Grid>
              <Grid item xs={12} lg={maxValue} md={maxValue} className="mb-2">
              <div className="rating_scale ">
                <NumberRating {...props} />
                </div>
              </Grid>
              <Grid item xs={12} lg={1} md={1} >
              <span style={{
                float:"right",
                border:'none',  color: props.theme.colorValue, 
                fontSize: props.theme.componentFontSize}}>
                  {endText}
                  </span>
              </Grid>
          </Grid>
          </Hidden>
          <Hidden smDown >

          <Grid container spacing={0} justify='center' >
              <Grid item xs={12} lg={1} md={1} className="mb-1">
                <span style={{
                  float:"right",
                  marginTop: '15px',
                  border:'none',color: props.theme.colorValue ,
                  fontSize: props.theme.componentFontSize }}>
                    {startText}
                </span>
              </Grid>
              <Grid item xs={12} lg={maxValue} md={maxValue} className="mb-1">
              <div className="rating_scale ">
                <NumberRating {...props} />
                </div>
              </Grid>
              <Grid item xs={12} lg={1} md={1} className="mb-1">
              <span style={{
                marginTop: '15px',
                border:'none', float:"left", color: props.theme.colorValue, 
                fontSize: props.theme.componentFontSize}}>
                  {endText}
                  </span>
              </Grid>
          </Grid>
          </Hidden>
          

          
          </Fragment> 
        </Fragment>
      )
    }
  }

  const NumberRating = (props) =>{
      var  rscale  = maxValue;
      let rButtons = [];
      for(var i= (minValue -1); i<rscale; i++){
        rButtons.push(<label key={i}>
              <input type="radio" value={i+1} name="rad" checked={i+1 === rating}  onChange={selectRatingnumber}/><span>{i+1}</span>
            </label>
        );
      }
      return rButtons
    }
  
  const selectRatingnumber = (event) => {
    let newValue = parseInt(event.target.value)
    setRating(newValue);
    if (!props.preview) {
      props.save({
        value: newValue
      })
    }
  }
  const selectRating = (event, newValue) => {
    setRating(newValue);
    if (!props.preview) {
      props.save({
        value: newValue
      })
    }
  }

  const submitRating = () => {
    if (rating === null )
    {
      setIsAnswered(true)
    }else{
      setProgressbar(true)
      props.submit({
        value: parseFloat(rating)
      })
    }
  }

  return (
    <Fragment>
      <ThemeProvider theme={props.theme.pallet}>
        
        <RatingIcon max={parseInt(props.questionObject.max)} theme={props.theme} name="rating" size="large" value={rating} onChange={selectRating} />
        <div className="d-block">
        {isAnswered ? <div>
          <Alert severity="error">Please Answer </Alert>
          </div> : null}
          {props.preview ? null : (<Button className="mt-3" color={props.theme.color} variant={props.theme.buttonVariant} onClick={submitRating}>Submit</Button>)}
          { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
        </div>
      </ThemeProvider>
    </Fragment>
  );
}