import React, { Fragment, useState } from 'react';
import { TextField, Button, FormGroup, FormControlLabel, Switch, MenuItem } from '@material-ui/core';
import Config from './Config';

export default (props) => {
  let emotionList = props.questionObject.options || [];
  const [emotions, setEmotions] = useState(emotionList);
  let groupNumber = "";
  for(let i = 0; i < Config.grouping; i++) {
    if (emotionList.length == Config.grouping[i].emotions.length) {
      groupNumber = i;
      break;
    }
  }
  const [group, setGroup] = useState(groupNumber);

  const [multiple, setMultiple] = useState(props.questionObject.hasOwnProperty('multiple') ? props.questionObject.multiple : false);

  const selectMultiple = (event) => {
    setMultiple(event.target.checked);
    props.updateQuestionObject({
      multiple: event.target.checked
    })
  }

  const handleChange = (event) => {
    setGroup(event.target.value);
    let emotions = Config.grouping[parseInt(event.target.value)].emotions;
    setEmotions(emotions);
    props.updateQuestionObject({
      options: emotions
    });
  }

  return (
    <Fragment>
      <div className="d-block">
        <TextField
          select
          label="Select"
          value={group}
          fullWidth
          variant="outlined" size="small"
          onChange={handleChange}
          helperText="Please select list of emotions"
        >
          {Config.grouping.map((option, index) => (
            <MenuItem key={index} value={index}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="d-block">
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={multiple} onChange={selectMultiple} name="Multiple Emotions" color="primary"/>}
            label="Allow user to select multiple emotions"
          />
        </FormGroup>
      </div>
      <div className="align-content-center justify-content-between">
        {emotions.map((e, i) => (
          <Button className="m-2" key={e} variant="outlined" color="primary" startIcon={<img src={Config.emotions[e].icon} style={{width: '1rem', height: '1rem'}} />}>{Config.emotions[e].text}</Button>
        ))}
      </div>
    </Fragment>
  )
}