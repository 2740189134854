import React ,{ useState , Fragment}from 'react';

import {
  Box,
  Fab,
  Card,
  FormControl,
  TextField,
  InputAdornment,
  Grid ,FormLabel, FormGroup, FormControlLabel, Checkbox, Button ,CircularProgress ,CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import { Link } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Survey } from '../../../../Store/ApplicationStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLink, faFile, faUsers } from '@fortawesome/free-solid-svg-icons';
import BuyPanel from './buyPanel'
import styled from 'styled-components';
import '../Manage.css'
export default (props) => {

  const getDate = ()=>{
    let now = new Date();
    let out = null
    if (now.getMonth() == 11) {
        let _year = now.getFullYear() + 2
        out= 1 + '/' + now.getDate() + '/' + _year; 
    } else {
        let _month = now.getMonth() + 2
        out = _month + '/' + now.getDate() + '/' + now.getFullYear() ; 
    }
    return(out)
  }

  const changeDate =(data)=>{
    if (data == null){
     return null
    }
    let b = data.split("-")
    let c = b[1] + '/' + b[2] + '/' + b[0]
    return c
  }

  const HoverDiv = styled.div`
	:hover {
		cursor: pointer;
  }
  transition: all ease 0.3s`
  
  const [anomyousToken, setAnomyousToken] = useState(props.surveyData.token ?? '');
  const [surveyType, setSurveyType] = useState(props.surveyData.surveyTypeId ?? 1);
  const [progessbar, setProgessBar] = useState(false);
  const [expectedEndDate, setExpectedEndDate] = useState(props.surveyData.expectedEndDate != null ? changeDate(props.surveyData.expectedEndDate) : getDate());
  const [time, setTime] = useState(720);
  const [pannel, setPannel] = useState('webLink');
  const [checkBoxstate, setCheckBoxstate] = useState(props.surveyData.getDemographic ?? {
    age: false,
    gender: false,
    city: false,
    name: false,
    email: false,
    phone: false
  });
  const { age, gender, city ,name, email, phone} = checkBoxstate;

  // let reposneUrl = "https://response.enumerate.ai"
  let reposneUrl = "https://dev.videoapp.enumerate.ai"

  let URL = encodeURI(reposneUrl + "/Survey?id=" + props.id );

  let AnomyousURL = encodeURI(reposneUrl + "/Survey?id=" + props.id +"&token=" + anomyousToken);

  const classesCheckBox = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      display: 'flex',
      margin: theme.spacing(1),
    },
  }));

  
  const handleCheckBoxChange = (event) => {
    setCheckBoxstate({ ...checkBoxstate, [event.target.name]: event.target.checked });
  };


  const handleDate = (event , value)=>{
    const unixTimeZero = Date.parse(value)
    const dateVal = Date.parse(new Date())
    let out = Math.floor(((unixTimeZero - dateVal) / (60*60*1000)) )
    setTime(out)
    setExpectedEndDate(value)
  }

  const handleSubmit = () => {
    setProgessBar(true)
    const data ={
      time: time,
      batchId :props.id,
      demogrophy: checkBoxstate
    }
    Survey.AnonymousToken(data).then((e) =>{
      console.log(e)
      if(e.success === true){
        setAnomyousToken(e.token)
      } 
    })
    setTimeout(function () {
      setProgessBar(false)
      }, 1000);

  }

  const showPannelObj = (e, id)=>{
    e.preventDefault()
    setPannel(id)
  }

  const emailLink = () =>{
    return(
      <Fragment>
        <Box className="d-flex w-100 p-4 justify-content-center" >
              <Card className="card-box-alt card-border-top border-primary m-2 p-4">
                <h3 className="font-size-lg font-weight-bold mt-5 mb-4">
                  Required Sign Up - Sign In 
                </h3>
                <p className="card-text px-4 mb-4">
                  Copy the link below and share it with users.
                </p>
                <FormControl className="w-100" variant="outlined">
                  <TextField
                    variant="outlined"
                    value={URL}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CopyToClipboard
                            text={URL}>
                            <Fab size="small" color="primary">
                              <Link />
                            </Fab>
                          </CopyToClipboard>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Card>
          </Box>
        </Fragment>
    )
  }

  const webLink = () =>{
    return (
      <Fragment>
      <Box className="d-flex w-100 p-4 justify-content-center" >
      <Card className="card-box-alt card-border-top border-primary m-2 p-4">
        <h3 className="font-size-lg font-weight-bold mt-5 mb-4">
          No Sign Up - Sign Is Required
        </h3>
        <p className="card-text px-2 mb-4">
          Copy the link below and share it with users.
        </p>
        <div className={classesCheckBox.root}>
        <FormControl component="fieldset" className={classesCheckBox.formControl}>
            <FormLabel component="legend">Demography Required?</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={checkBoxstate.age} onChange={handleCheckBoxChange} name="age" />}
                label="age"
              />
              <FormControlLabel
                control={<Checkbox checked={checkBoxstate.gender} onChange={handleCheckBoxChange} name="gender" />}
                label="gender"
              />
              <FormControlLabel
                control={<Checkbox checked={checkBoxstate.city} onChange={handleCheckBoxChange} name="city" />}
                label="city"
              />
            </FormGroup>
            <FormGroup row>
            <FormControlLabel
                control={<Checkbox checked={checkBoxstate.name} onChange={handleCheckBoxChange} name="name" />}
                label="name"
              />
              <FormControlLabel
                control={<Checkbox checked={checkBoxstate.email} onChange={handleCheckBoxChange} name="email" />}
                label="email"
              />
              <FormControlLabel
                control={<Checkbox checked={checkBoxstate.phone} onChange={handleCheckBoxChange} name="phone" />}
                label="phone"
              />
            </FormGroup>
            
          </FormControl>
          </div>
          <div className="mb-2">
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <KeyboardDatePicker
            inputVariant="outlined"
            id="date-picker-inline"
            className="MuiFormControl-fullWidth"
            variant="outlined"
            format="MM/dd/yyyy"
            label="Expected End Date"
            value={expectedEndDate}
            // onChange={(event, values) =>  setExpectedEndDate(values) }
            onChange={handleDate}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            />
      </MuiPickersUtilsProvider>
      </div>
      <div className="mb-2">
      {  anomyousToken != '' ?
      <FormControl className="MuiFormControl-fullWidth" variant="outlined">
      <TextField
        variant="outlined"
        value={AnomyousURL}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyToClipboard
                text={AnomyousURL}>
                <Fab size="small" color="primary">
                  <Link />
                </Fab>
              </CopyToClipboard>
            </InputAdornment>
          )
        }}
      />
    </FormControl> 
    : null}
    </div>
    <div className="mb-2">
      <Button
      color="primary"
      size="large"
      variant="outlined"
      className="mb-5" 
      onClick={ () => { handleSubmit() }}>
      Generate link
    </Button>
      { progessbar ? <CircularProgress style={{marginBottom:'5px', marginLeft:'3px'}}/> : null}
      </div>
      </Card>
    </Box>
    </Fragment>
    )
  }
  
  const website = () =>{
    return(
      <Fragment>
        <Box className="d-flex w-100 p-4 justify-content-center" >
              <Card className="card-box-alt card-border-top border-primary m-2 p-4">
                <h3 className="font-size-lg font-weight-bold mt-5 mb-4">
                Coming Soon
                </h3>
                
              </Card>
          </Box>
        </Fragment>
    )
  }
  const showPannel = () =>{
    if (pannel === 'Email'){
      return emailLink()
    }
    else if (pannel === 'webLink'){
      return webLink()
    }
    else if (pannel === 'pannel'){
      return <BuyPanel  surveyData={props.surveyData} editSurvey={props.editSurvey}/>
    }
    else if (pannel === 'webiste'){
      return website()
    }
  }
  return (
    <div className="card-body justify-content-between pannel-hegiht">
      <Fragment>
      <Grid container spacing={4} className="justify-content-center">
        <Grid item xs={12} sm={6} lg={4}>
        <div className=" pt-4 px-4 mb-2">
                        <Grid container spacing={4} className="justify-content-center">
                            <Grid item xs={12} sm={6} lg={6}>
                                <HoverDiv onClick={e => showPannelObj(e, 'Email')} className="mb-4 card card-box card-box-hover-rise-alt">
                                <CardContent className="p-3" >
                                    <div className="feature-box text-center mb-4">
                                        <Button variant="outlined" color="primary" className="d-60 ">
                                            <span className="btn-wrapper--icon">
                                                <FontAwesomeIcon icon={faEnvelope} className="font-size-lg" color="#5383ff"/>
                                            </span>
                                        </Button>
                                        <h3 className="font-size-lg font-weight-bold my-3">Email</h3>
                                        <p className="text-black-50 mb-3">
                                        Ideal for tracking your survey respondents
                                        </p>
                                    </div>
                                    </CardContent>
                                </HoverDiv>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <HoverDiv onClick={e => showPannelObj(e, 'webLink')} className="mb-4 card card-box card-box-hover-rise-alt">
                                    <CardContent className="p-3" >
                                    <div className="feature-box text-center mb-4">
                                        <Button variant="outlined" color="primary" className="d-60 ">
                                            <span className="btn-wrapper--icon">
                                                <FontAwesomeIcon icon={faLink} className="font-size-lg" color="#5383ff"/>
                                            </span>
                                        </Button>
                                        <h3 className="font-size-lg font-weight-bold my-3">web link</h3>
                                        <p className="text-black-50 mb-3">
                                        Ideal for sharing via email, social media, etc.
                                        </p>
                                    </div>
                                    </CardContent>
                                </HoverDiv>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <HoverDiv onClick={e => showPannelObj(e, 'webiste')} className="mb-4 card card-box card-box-hover-rise-alt">
                                <CardContent className="p-3" >
                                    <div className="feature-box text-center mb-4">
                                         <Button variant="outlined" color="primary" className="d-60 " onClick={e => showPannelObj(e, 'webiste')}>
                                            <span className="btn-wrapper--icon">
                                                <FontAwesomeIcon icon={faFile} className="font-size-lg" color="#5383ff"/>
                                            </span>
                                        </Button>
                                        <h3 className="font-size-lg font-weight-bold my-3">Website</h3>
                                        <p className="text-black-50 mb-3">
                                        Embed your survey on your website
                                        </p>
                                    </div>
                                    </CardContent>
                                </HoverDiv>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                            { (surveyType !=3 )?
                                <HoverDiv onClick={e => showPannelObj(e, 'pannel')} className="mb-4 card card-box card-box-hover-rise-alt" style={{maxHeight:'212px'}}>
                                <CardContent className="p-3" >
                                    <div className="feature-box text-center mb-4">
                                        <Button variant="outlined" color="primary" className="d-60 ">
                                            <span className="btn-wrapper--icon">
                                                <FontAwesomeIcon icon={faUsers} className="font-size-lg" color="#5383ff"/>
                                            </span>
                                        </Button>
                                        <h3 className="font-size-lg font-weight-bold my-3">Buy Targeted Responses</h3>
                                        <p className="text-black-50 mb-3">
                                        Find people who fit your criteria
                                        </p>
                                    </div>
                                    </CardContent>
                                </HoverDiv>:
                                null}
                            </Grid>
                        </Grid>
                    </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Grid container spacing={1} >
            {showPannel()}
          </Grid>
        </Grid>
      </Grid>
        </Fragment>

   
    </div>
  )
}