import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Checkbox,
  makeStyles,
  IconButton
} from '@material-ui/core';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import { IoReloadSharp ,IoReorderThreeSharp } from "react-icons/io5";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RegionSelector = ({
  options,
  filters,
  setFilter,
  title
}) => {
  const [selected, setSelected] = useState(filters);
  const [selOptions, setSelOptions] = useState(options);
  const classes = useStyles();

  const [hideShow, setHideShow] = useState(true);

  const handleHideShowChange = () => {
    setHideShow((prev) => !prev);
  };

  const handleChange = (event) => {
    let value = [...selected ]
    if (value.includes(event.target.name))
    {
      value = value.filter(function(item) {
        return item !== event.target.name
      })
    }else{
      value.push(event.target.name)
    }
    setSelected(value);
    setFilter(value);
  };

  const resetFilter = () => {
    setSelected([]);
    setFilter([]);
  };

  useEffect(() => {
    if (filters.length === 0) {
      setSelOptions(options);
    }
  }, [options]);

  return (
    <>
     <div className="m-2 mb-0 p-2">
     <div className={ clsx({"card-header pr-2 align-box-row w-100": true, "border-bottom":hideShow })} style={{padding:' 0rem 1.25rem'}}>
          <div className="card-header--title  font-size-md font-weight-bold align-box-row w-100" >
          {title}
          </div>
          <div className="ml-auto align-self-center">
              <IconButton
                color="secondary"
                aria-label="refresh"
                onClick={resetFilter}
                disabled={filters.length === 0}
              >
            <IoReloadSharp />
          </IconButton>
          </div>
          <div className="ml-auto align-self-center">
            <IconButton
              color="secondary"
              aria-label="refresh"
              onClick={handleHideShowChange}
            >
              <IoReorderThreeSharp />
            </IconButton>
          </div>
      </div>
    <Collapse in={hideShow}> 
      <FormControl className="d-flex opacity-9" >
      {selOptions.map((name ,key) => (
      <FormControlLabel key={key}
      className="ml-4 mb-0 mt-0"
          control={
            <Checkbox
              checked={selected.includes(name)}
              onChange={handleChange}
              name={name}
              color="secondary"
            />
          }
          label={name}
        />
        ))}
        </FormControl>
      </Collapse>
    </div> 
    
    </>
  );
};

export default RegionSelector;
