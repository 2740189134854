
export const scoreFormatter = (e) => {
  return parseFloat(5 * (e + 1)).toFixed(1);
};

export const percentageConevertor = (partialValue, totalValue) => {
  if (totalValue == 0){
    return 0
  }
  return  Math.round((100 * partialValue) / totalValue);
} 