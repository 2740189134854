import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import SetElement from './SetElement';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.danger,
  }
}));

const MediaSet = ({
  sets,
  mediaNameList,
  saveSets
}) => {
  const [expanded, setExpanded] = useState(false);
  const [mediaSetList, setMediaSetList] = useState(sets || []);
  const classes = useStyles();

  const toggleExpansion = () => {
    setExpanded((exp) => !exp);
  };

  const storeNewSets = (list) => {
    const filteredList = list.filter((e) => {
      return e.setName !== '' && e.mediaList.length > 0;
    });
    saveSets(filteredList);
  };

  const updateSet = (obj, index) => {
    const newSet = [...mediaSetList];
    newSet[index] = obj;
    setMediaSetList(newSet);
    storeNewSets(newSet);
  };

  const removeSet = (index) => {
    const newSet = [...mediaSetList];
    newSet.splice(index, 1);
    setMediaSetList(newSet);
    storeNewSets(newSet);
  };

  const createSet = () => {
    const newSet = [...mediaSetList];
    newSet.push({
      setName: '',
      mediaList: []
    });
    setMediaSetList(newSet);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={toggleExpansion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="mediaSet-content"
          id="mediaSet-header"
        >
          <Typography className={classes.heading}>Create Sets Of Uploaded Media</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ flexDirection: 'row', width: '100%' }}>
            {mediaSetList.map((eachSet, setIndex) => (
              <SetElement
                key={setIndex}
                name={eachSet.setName}
                mediaList={eachSet.mediaList}
                setIndex={setIndex}
                options={mediaNameList}
                updateSet={updateSet}
                removeSet={removeSet}
              />
            ))}
            <div style={{ paddingTop: '10px' }}>
              <Button
                variant="outlined"
                color="default"
                onClick={createSet}
                startIcon={<AddIcon />}
              >
                Create New Set
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MediaSet;
