
import {Questionaire} from '../../../Store/ApplicationStore'
const Validation = [
    {
      id: "contains",
      validate: (answer, value) => {
        let val = value.toLowerCase();
        for (let i = 0; i < answer.value.length; i++) {
          let lowerAns = answer.value[i]['name'].toLowerCase();
          if (lowerAns.indexOf(val) >= 0) {
            return true;
          }
        }
        return false;
      }
    },
    {
        id: "Not Contains",
        validate: (answer, value) => {
          let val = value.toLowerCase();
          for (let i = 0; i < answer.value.length; i++) {
            let lowerAns = answer.value[i]['name'].toLowerCase();
            if (lowerAns.indexOf(val) >= 0) {
              return false;
            }
          }
          return true;
        }
      },
    {
      id: "Not Contains in , seperated list",
      validate: (answer, value) => {
        let val = value.toLowerCase().split(",");
        let val2 = val.map(function(x){ return x.trim(); })
        for (let i = 0; i < answer.value.length; i++) {
          let lowerAns = answer.value[i]['name'].toLowerCase();
          if (val2.indexOf(lowerAns) >= 0) {
            return false;
          }
        }
        return true;
      }
    },
    {
      id: "Contains in , seperated list",
      validate: (answer, value) => {
        let val = value.toLowerCase().split(",") ;
        let val2 = val.map(function(x){ return x.trim(); })
        for (let i = 0; i < answer.value.length; i++) {
          let lowerAns = answer.value[i]['name'].toLowerCase();
          if (val2.indexOf(lowerAns) >= 0) {
            return true;
          }
        }
        return false;
      }
    },
    {
      id: "equals",
      validate: (answer, value) => {
        if (answer === undefined ){
          return false
        }
        if (answer.value.length != 0){
          let lowerAns = answer.value[0]['name'].toLowerCase();
          let val = value.toLowerCase();
          if (lowerAns == val && answer.value.length == 1) {
            return true;
          }
          return false;
        }else{
          return false
        }
      }
    },
    {
      id: "count",
      validateCount: async (answer, value, questionId) => {
        if (answer === undefined ){
          return false
        }
        if (value.indexOf("=") > -1)
        {
          let out = value.split("=")
          if (out.length == 2 ){
            let count = parseInt(out[1])
            let valValue = out[0].trim()
            let outobj = await Questionaire.getAnswerCount({
              "isOrder": false,
              "questionId": questionId
            })

            if (outobj.success == true){
              let outObjValue = outobj.value
              for (let i = 0; i < outObjValue.length; i++) {
                if (outObjValue[i].value == valValue  &&   answer.value.name.indexOf(valValue) > -1 && outObjValue[i].count >= count){
                  return true
                }
              }
            }
          
          }
        }
        return false
      }
    }
    
  ]
export default Validation;