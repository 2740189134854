import React, { Fragment, useState, useEffect } from 'react';
import {
  TextField,
  Grid, IconButton,
  InputLabel, FormControlLabel ,Switch, Typography, Button
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Authorization} from '../../../Store/ApplicationStore'
import { Clear, Image, Add } from '@material-ui/icons';

export default (props) => {

    const [city, setCity] = useState({})
    const [countryList, setCountryList] = React.useState([]);
    const [country, setCountry] = React.useState('');
    const [cityList, setCityList] = React.useState([]);
  
    const [row, setRow] = useState(props.questionObject.hasOwnProperty('row') ? props.questionObject.row:  []);
    const [orderBelow, setOrderBelow] = useState(props.questionObject.hasOwnProperty('orderBelow') ? props.questionObject.orderBelow : false );
    
  
    const submitChange = (obj = {}) => {
        props.updateQuestionObject({
        row,
        orderBelow,
        ...obj
        });
    }

    const updateRowText= (event, key)=>{
        const tempText = event.target.value;
        let tempRow = {...row}
        let tempObj = { ...tempRow[key] }
        tempObj.name = tempText
        tempRow[key] = {...tempObj}
        setRow(tempRow);
        submitChange({
          row: tempRow
        });

    }

    function updateOrderBelow(event) {
        const tempShow = event.target.checked;
        setOrderBelow(tempShow);
        submitChange({
        orderBelow: tempShow
        });
    }
  
    const handleCountryChange = (event, values) =>{
      if(values != null && values != undefined){
        setCountry(values.name)
        Authorization.getCity(values.name).then(response => {
            setCityList(response)
        })
      }
       
    }
    const addCity = (e)=>{
        e.preventDefault()
        if (Object.keys(city).length !== 0 ){
            let tempRow = {...row}
            tempRow[city.name] = {...city}
            setRow(tempRow) 
            setCity({})
            submitChange({
              row: tempRow
            });
        }
    }
    const removeItem = (e , key)=>{
      e.preventDefault()
      let tempRow = {...row}
      delete tempRow[key]
      setRow(tempRow) 
      setCity({})
      submitChange({
        row: tempRow
      });
    }
    
    
    const cityContainer =()=>{
        return (
        <Fragment>
            <div className="mb3">
                    <Autocomplete className="my-3"
                        inputProps={{
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        size="medium"
                        options={countryList}
                        getOptionLabel={option => option.name}
                        onChange={handleCountryChange}
                        renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Countries"
                            placeholder="Countries"
                            fullWidth
                            autoComplete='off'
                        />
                        )}
                        autoComplete='off'
                    />
            </div>
            <div className="mb3">
            <Autocomplete className="my-3"
                        inputProps={{
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        size="medium"
                        options={cityList}
                        getOptionLabel={option => option.name}
                        onChange={(event, values) => values? setCity(values) : null}
                        renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Cities"
                            placeholder="Cities"
                            fullWidth
                            autoComplete='off'
                        />
                        )}
                        autoComplete='off'
                    />
            </div>
            <div className="mb-2">
            <Button variant="outlined" size="medium"  color="primary"  onClick={(e)=>{addCity(e)}}>Add City</Button>
            </div>
        </Fragment>
        )
    }
 

  const rowObj = () => {
    const tifOptions = Object.keys(row).map(key => (
      <Fragment key={key}>
         <Grid container spacing={2}>
          <Grid item xs={11} md={11} l={11} >
              <TextField multiline value={row[key].name} onBlur={() => submitChange()} onChange={(e)=> updateRowText(e, key)} fullWidth variant="standard"  variant="outlined" size="small"/>
                <p value={key} style={{ whiteSpace :"pre-wrap"}}>{key}</p>
          </Grid>
         <Grid item xs={1} md={1} l={1} >
              <IconButton aria-label="delete" color="primary" onClick={(e) => removeItem( e, key)}>
                <Clear />
              </IconButton>
         </Grid>
         </Grid>
        
      </Fragment>
    ));
    return tifOptions;
  }
  

  const getCountry = ()=>{
    if (!countryList.length){
        Authorization.getCountry().then(response => {
          if (response != null &&  response != undefined)
          {
            setCountryList(response)
          }
        })
      }
      
  }

  useEffect(() => {
    getCountry()
    submitChange();
  }, []);


  return (
    <>
    <Grid container spacing={2} justify="center" >
    <Grid item xs={10} md={10} lg={10} >
          {cityContainer()}
        </Grid>
    </Grid>
      <Grid container spacing={2} justify="center" >

        <Grid item xs={10} md={10} lg={10} >
          {rowObj()}
        </Grid>
        <Grid item xs={2} md={2} lg={2} >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={orderBelow}
                          onChange={updateOrderBelow}
                          name="checkedC"
                          color="primary"
                        />
                      }
                      label="Order one below other"
                    />
          </Grid>
      </Grid> 
    </>
  )
};
