import React, { Fragment ,useState} from 'react';
import Chart from 'react-apexcharts';
import {Select, FormControl, Grid, MenuItem
} from '@material-ui/core';

export default (props) => {
    let data = props.values;
    const [displayType, setDisplayType] = useState(1)
    const test = (value, values)=>{
        if(Number.isInteger(value))
        {
          let out = parseInt(value) / values.reduce((a, b) => a + b, 0) * 100
          return Math.round(out) + " %"
        }
        else{
          return value
        }
      }
    const handleChange =(event)=>{
    setDisplayType(event.target.value)
    }

    let overAllData = []
    if (data != null){
        for (let [key, value] of Object.entries(data)) {
            let title = key
            let categories = [];
            let values = [];
            for (let [x, y] of Object.entries(value)) {
                    categories.push(x);
                    values.push(y);
                }

            const BarOptions = {
                chart: {
                    type: 'bar',
                    toolbar: {
                    show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                    horizontal: true
                    }
                },
                xaxis: {
                    categories: categories
                },
                yaxis: {
                    labels: {
                      formatter: function (value) {
                        return 'count - ' + value ;
                      }
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    formatter: function (val, opts) {
                        return test(val, values)
                    },
                    textAnchor: 'middle',
                    distributed: false,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: '',
                        colors: ['white']
                    }
                  }
                };
            const Barseries = [
            {
                name: '',
                data: values
            }
            ];
            const PieOption = {
    
                labels: categories,
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                      return  Math.round(val) + "%"
                    }
                },
                theme: {
                    mode: 'light', 
                    palette: 'palette2', 
                    monochrome: {
                        enabled: false,
                        color: '#255aee',
                        shadeTo: 'light',
                        shadeIntensity: 0.65
                    },
                }
              }
    
            overAllData.push({
                title : title,
                barOptions: BarOptions,
                barseries: Barseries,
                pieOption:PieOption,
                pieSeries: values
            })
            
          }
    }
    
    
      const TableContent = (props) => {
        return (
            
            <Fragment>
            { 
            props.displayType == 1?
                overAllData.map((data,idx) => (
                    
                <Fragment key={idx}>
                    <div className="mb-1 mt-1">{ data.title }</div>
                    <div style={{height: 350}}>
                    <Chart options={data.barOptions} series={data.barseries} height='100%' type="bar" />
                    </div>
                </Fragment>
                ))
                : null }
            {props.displayType == 2?
                overAllData.map((data,idx) => (
                    
                    <Fragment key={idx}>
                        <div className="mb-1 mt-1  d-flex justify-content-center opacity-9">{ data.title }</div>
                        <div style={{height: 350}} className="d-flex justify-content-center">
                        <Chart options={data.pieOption} series={data.pieSeries} type="pie" width="450" />
                        </div>
                    </Fragment>
                    ))
            : null  
            }
             {props.displayType == 3?
                overAllData.map((data,idx) => (
                    
                    <Fragment key={idx}>
                        <div className="mb-1 mt-1  d-flex justify-content-center opacity-9">{ data.title }</div>
                        <div style={{height: 350}} className="d-flex justify-content-center">
                        <Chart options={data.pieOption} series={data.pieSeries} type="donut" width="450" />
                        </div>
                    </Fragment>
                    ))
            : null  
            }
            </Fragment>
        )
      }


  return (
    <Fragment> 
        <Grid container spacing={2} >
            <Grid item xs={7} lg={9} md={8}>
                </Grid>
                <Grid item xs={5} lg={3} md={4}>
                    <FormControl className="">
                        
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={displayType}
                        variant="outlined"
                        onChange={handleChange}

                        >
                        <MenuItem value={1}>Bar Graph</MenuItem>
                        <MenuItem value={2}>Pie Graph</MenuItem>
                        <MenuItem value={3}>Donut Graph</MenuItem>
                        {/* <MenuItem value={3}>Table Percent</MenuItem>
                        <MenuItem value={4}>Table number</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
        </Grid>
        <TableContent displayType={displayType}/>
    
    </Fragment>
    
  );
}