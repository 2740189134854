import React, { Fragment, useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  Card,DialogContent, DialogActions,
  Button,
  TextField, LinearProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';

import DialogTitle from '@material-ui/core/DialogTitle';
import { Survey , Authorization} from '../../../Store/ApplicationStore';
import TaxonomyDisplay from './taxonomyDisplay'



function SentimnetDialog(props) {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleBreadcrumb,
    pageTitleIconBox,
    pageTitleDescription
  } = props;

  const history = useHistory();
  const surveyId = props.surveyId
  const questionId = props.questionId

  const [industryList, setIndustryList] = React.useState([]);
  const [myTaxonomyList, setMyTaxonomyList] = React.useState([]);
  const [taxonomyName, setTaxonomyName] = useState('')
  const [industry, setIndustry] = React.useState({});
  const [category, setCategory] = React.useState({});
  const [myTaxonomy, setMyTaxonomy] = React.useState([]);
  const [allCategoryList, setAllCategoryList] = React.useState([]);
  const [modal1, setModal1] = useState(false);
  const [categoryList, setCategoryList] = React.useState([]);
  const toggle1 = () => setModal1(!modal1);
  const [open, setOpen] = React.useState(false);
  const [taxonomy, setTaxonomy] = useState({})
  const [taxonomyId, setTaxonomyId] = useState(0)
  const [progress, setProgress] = useState(false)
  const [scroll, setScroll] = React.useState('paper'); 

  useEffect(() => {
    if (industryList != undefined)
    {
      if(industryList.length == 0){
        getData()
      }
    }
    
  },[industryList, allCategoryList]);

  const getData = async (id) =>{
    let tempCategory = []
    let tempIndustry = []
    let tempSaveTaxonomy = null
    let tempTaxonomyId = null
    await Survey.getIndustryCategoryProduct().then(response => {
      if(response.status === true){
        setIndustryList(response.industry)
        setAllCategoryList(response.category)
        tempCategory = response.category
        tempIndustry = response.industry
      } 
    })

    await Survey.getBatchIndustryCategoryTaxonomy({'batchId': parseInt(props.surveyId)}).then((e)=>{
      if (e.success == true){
        if (e.value.categoryId != null){
          if (tempCategory != undefined && tempCategory != null)
            {
              setCategory(tempCategory.find(o => o.id === e.value.categoryId))
            }
        }

        if (e.value.industryId != null){
          if (tempIndustry != undefined && tempIndustry != null)
          {
            setIndustry(tempIndustry.find(o => o.id === e.value.industryId))
            let arr = []
            tempCategory.map((x ,id) => {
                if(e.value.industryId === x.industryId){
                    arr.push(x)
                }
            })
            setCategoryList(arr)
          }
        }
        
        if (e.value.taxonomyId != null){
          tempSaveTaxonomy = e.value.taxonomyId
          setTaxonomyId(e.value.taxonomyId)
          if (e.value.taxonomy != null){
            setTaxonomy(e.value.taxonomy)
          }else{
            setTaxonomy({})
          }
          
        }else if (e.value.taxonomyId == null && e.value.categoryId != null){
          let selectedTaxonomy =  tempCategory.find(o => o.id === e.value.categoryId);
          let outTaxonomyId = selectedTaxonomy['taxonomyId']
          setTaxonomyId(outTaxonomyId)
          getTaxonomy(outTaxonomyId)
          tempSaveTaxonomy = outTaxonomyId
        }
      }
    })

    await Survey.getMySavedTaxonomy().then((e)=>{
      if (e.success == true){
        if (e.output != undefined && e.output != null){
          let out = e.output.find(o => o.id === tempSaveTaxonomy)
          setMyTaxonomyList(e.output)
          if (out != undefined)
          {
            setMyTaxonomy(out)
          }
        }
      }
    })
  }


  const getMySavedTaxnomy = async ()=>{
    await Survey.getMySavedTaxonomy().then((e)=>{
      if (e.success == true){
        if (e.output != undefined && e.output != null){
          let out = e.output.find(o => o.id === taxonomyId)
          setMyTaxonomyList(e.output)
          if (out != undefined)
          {
            setMyTaxonomy(out)
          }
        }
      }
    })
  }


  const addTaxnomy = async (name ,taxonomy)=>{
    let data ={
      "batchId": surveyId,
      "name": name,
      "taxonomy": taxonomy
    }

    await Survey.addMyTaxonomy(data).then((e)=>{
      if (e.status == 'success'){
        getMySavedTaxnomy()
      }
    })
  }

  const updateTaxnomy = async (name ,taxonomy)=>{
    let out = myTaxonomyList.find(o => o.id === taxonomyId)
    if (out.length != 0)
    {
      let data ={
        "name": name,
        "taxonomy": taxonomy,
        "taxonomyId": taxonomyId
      }
      await Survey.editMyTaxonomy(data).then((e)=>{
        if (e.status == 'success'){
          getMySavedTaxnomy()
        }
      })
    }
  }

  const getTaxonomy = async(taxonomyId)=>{
    await Survey.getTaxonomyById({'taxonomyId': taxonomyId}).then((e)=>{
      if (e.success == true){
       
        setTaxonomy(e.output)
      }
    })
  }

  const handleIndustryChange = (event, values) => {
    setIndustry(values)
      if (values!= null){
        let arr = []
        allCategoryList.map((e ,id) => {
            if(values.id === e.industryId){
                arr.push(e)
            }
        })
        setCategory(null)
        setCategoryList(arr)
      }
  };

  const handleCategoryChange = (event, values)=>{
    setCategory(values)
    let tempTaxonomy = values['taxonomyId']
    setTaxonomyId(tempTaxonomy)
    if (tempTaxonomy != null){
      getTaxonomy(tempTaxonomy)
    }else{
      setTaxonomy({})
    }
    

  }

  const handleMyTaxonomyChange = (event, values)=>{
    setMyTaxonomy(values)
    let tempTaxonomy = values['id']
    setTaxonomyId(tempTaxonomy)
    getTaxonomy(tempTaxonomy)
    setTaxonomyName(values['name'])    
  }

  const save = async (e)=>{
    setProgress(true)
    let data = {
      "batchId": surveyId,
      "categoryId": category.length != 0 ? category.hasOwnProperty('id') ? category.id : 0 : 0,
      "industryId": category.length !=0 ? industry.hasOwnProperty('id') ? industry.id: 0 : 0,
      "taxonomyId": taxonomyId
    }
    await Survey.updateBatchIndustryCategoryTaxonomy(data).
    setProgress(false)

  }

  const analysis = async (e) =>{
    setProgress(true)
    let data = {
      "batchId": surveyId,
      "questionId": questionId
    }
    await Survey.addVideosForSentimentAnalysis(data)
    setProgress(false)
  }
  



  return (
    <Fragment>   
        {!open && (
      <div className="d-flex align-items-center">
          <Button 
            onClick={toggle1} 
            color="primary"
            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary">
             Add / Edit Taxonomy
        </Button>
      
      <Card className="card-box mb-4">
      <Dialog maxWidth="lg" open={modal1} onClose={toggle1}>
      <DialogTitle id="scroll-dialog-title">Set Taxonomy</DialogTitle>
      <DialogContent dividers={scroll === 'paper'} style={{minWidth: '140vh'}}>
      <Grid container spacing={2} justify="center" >
          <Grid item xs={4} lg={4} md={4}>
            <Autocomplete className="my-3"
                id="industry-select"
                size="medium"
                options={industryList}
                value={industry}
                getOptionLabel={option => option != null ? option.name : ''}
                onChange={handleIndustryChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Industry"
                    placeholder="select a industry"
                    fullWidth
                  />
                )}
              />
          </Grid>
          <Grid item xs={4} lg={4} md={4}>
              <Autocomplete className="my-3"
                id="category-select"
                size="medium"
                value={category}
                options={categoryList}
                getOptionLabel={option => option != null ? option.name : ''}
                onChange={handleCategoryChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="category"
                    placeholder="select a category"
                    fullWidth
                  />
                )}
              />
          </Grid>
          <Grid item xs={4} lg={4} md={4}>
              <Autocomplete className="my-3"
                id="category-select"
                size="medium"
                value={myTaxonomy}
                options={myTaxonomyList}
                getOptionLabel={option => option != null ? option.name : ''}
                onChange={handleMyTaxonomyChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Saved Taxonomy"
                    placeholder="My Saved Taxonomy"
                    fullWidth
                  />
                )}
              />
          </Grid>
          
        </Grid>
        
        <div>
        <TaxonomyDisplay taxonomy={taxonomy} addTaxnomy={addTaxnomy} taxonomyName={taxonomyName} updateTaxnomy={updateTaxnomy}/>
        </div>
        
      </DialogContent>
         
         
      { progress ? <LinearProgress/>: null}
          <DialogActions>
           
          <Button color="secondary" className="m-1"  variant="outlined"  size="large" onClick={save}>
                    save
                </Button>
                <Button color="secondary" className="m-1"  variant="outlined"  size="large" onClick={analysis}>
                  Analysis
                </Button>
          </DialogActions>


            </Dialog>
        </Card>
    </div>
  )}
  </Fragment>
  );
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(SentimnetDialog);
