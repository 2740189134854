import React, { useState } from 'react';
import { 
  Grid,
  Paper,
  Button, Typography ,TextField, IconButton
 } from '@material-ui/core'; 
 import { makeStyles } from '@material-ui/core/styles';
 import Accordion from '@material-ui/core/Accordion';
 import AccordionDetails from '@material-ui/core/AccordionDetails';
 import AccordionSummary from '@material-ui/core/AccordionSummary';
 import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 import IosTrashOutline from 'react-ionicons/lib/IosTrashOutline';
import IosCloseCircleOutline from 'react-ionicons/lib/IosCloseCircleOutline';
const useStyles = makeStyles((theme) => ({
  root: {

  },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.primary,
  },
  grid:{
      margin:2
  }
}));
const AttributeBundle = ({
  feature,
  expanded,
  expandPanel,
  attributes,
  updateAttributeList,
  deleteFeature
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [attributeList, setAttributeList] = useState(attributes?.length > 0 ? attributes : []);

  const updateAttributeTempList = (list) => {
    updateAttributeList(feature, list);
  };

  const addToList = (type) => {
    if (value) {
      let newAttrList = [];
      if (type) {
        newAttrList = [...attributeList, value];
      } else {
        const newArr = value.split(',');
        newAttrList = [...attributeList, ...newArr];
      }
      setAttributeList(newAttrList);
      setValue('');
      updateAttributeTempList(newAttrList);
    }
  };

  const removeFromList = (attr) => {
    const index = attributeList.indexOf(attr);
    if (index >= 0) {
      const newAttrList = [...attributeList];
      newAttrList.splice(index, 1);
      setAttributeList(newAttrList);
      updateAttributeTempList(newAttrList);
    }
  };

  return (
    <Grid container spacing={2} justify="center" className="mb-2">
      <Grid item xs={11} lg={11} md={11}>
        <Accordion expanded={expanded === feature} onChange={expandPanel(feature)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>{feature}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {attributeList.map((e, x) => (
                <Grid item  className={classes.grid} >
                  <Paper className={classes.paper}>
                    {e}
                    <IconButton
                      variant="outlined"
                      color="inherit"
                      size="small"
                      onClick={(y) => {
                        removeFromList(e)
                      }}
                    >
                      <span className="btn-wrapper--icon">
                        <IosCloseCircleOutline color="var(--danger)"/>
                      </span>
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
          <AccordionDetails>
            <Grid container spacing={2} justify="center" className="mb-2">
              <Grid item xs={4} lg={4} md={4}>
                <TextField
                  multiline
                  value={value}
                  variant="outlined"
                  label="add features"
                  placeholder=""
                  fullWidth
                  size="small"
                  onChange={(e)=> setValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={4} lg={1} md={2}>
                <Button color="secondary" variant="outlined" size="medium" onClick={(e)=>addToList(true)}>
                  Add
                </Button>                     
              </Grid>
              <Grid item xs={4} lg={3} md={4}>
                <Button color="secondary" variant="outlined" size="medium" onClick={(e)=>addToList(false)}>
                  Add multiple with , sperated
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={1} lg={1} md={1}>
        <Button
          variant="outlined"
          color="secondary" 
          className="m-2"
          size="small"
          onClick={(e)=>deleteFeature(feature)}
        >
          <span className="btn-wrapper--icon">
            <IosTrashOutline color="var(--danger)"/>
          </span>
        </Button> 
      </Grid>
    </Grid>
  );
};

export default AttributeBundle;
