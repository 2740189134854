import React, { Fragment, useState } from 'react';
import { FormGroup, FormControlLabel, Radio, RadioGroup, Checkbox, Button, TextField , Grid} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
export default (props) => {
  const [answer, setAnswer] = useState([]);
  const showTextBox = props.questionObject.hasOwnProperty('showTextBox') ? props.questionObject.showTextBox : false ;
  const textBoxNumber = props.questionObject.hasOwnProperty('textBoxNumber') ? [...Array(parseInt(props.questionObject.textBoxNumber)).keys() ]: [] ;
  const textTitle = props.questionObject.hasOwnProperty('textTitle') ? props.questionObject.textTitle: "Enter value for others";
  const textBelow = props.questionObject.hasOwnProperty('textBelow') ? props.questionObject.textBelow : false ;
  const orderBelow = props.questionObject.hasOwnProperty('orderBelow') ? props.questionObject.orderBelow : false ;
  const imageSize = props.questionObject.hasOwnProperty('imageSizeBig') ? props.questionObject.imageSizeBig ? "15rem" : "10rem": "10rem" ;
  const [textAnswer, setTextAnswer]= useState(props.questionObject.hasOwnProperty('textBoxNumber') ? [...Array(parseInt(props.questionObject.textBoxNumber)).fill('') ]: []);
  const [isAnswered,setIsAnswered] = useState(false);
  const [progressbar, setProgressbar] = useState(false);
  let Sel = (props) => {
    if (props.type == 'single') {
      return <Radio color={props.color} />
    }
    else {
      return <Checkbox color={props.color} checked={props.checked} onChange={props.onChange} name={props.name} />
    }
  };
  let Group = (props) => {
    if (props.type == 'single') {
      return (
        <RadioGroup value={props.value} onChange={props.onChange} row>
          {props.children}
        </RadioGroup>
      )
    }
    else {
      return (
        <FormGroup row>
          {props.children}
        </FormGroup>
      )
    }
  };
  let values = props.questionObject.options || [];

  const handleChange = (event) => {
    let newArr = [ ...answer ];
    if (props.type == 'single') {
      newArr = [event.target.name];
    }
    else {
      let name = event.target.name;
      let index = newArr.indexOf(name);
      if (index >= 0) {
        newArr.splice(index, 1);
      }
      else {
        newArr.push(name);
      }
    }
    setAnswer(newArr);
    if (!props.preview) {
      props.save({
        value: newArr
      });
    }
  };

  const selectImage = (url) => {
    let newArr = [ ...answer ];
    if (props.type == 'single') {
      newArr = [url];
    }
    else {
      let index = newArr.indexOf(url);
      if (index >= 0) {
        newArr.splice(index, 1);
      }
      else {
        newArr.push(url);
      }
    }
    setAnswer(newArr);
    if (!props.preview) {
      props.save({
        value: newArr
      });
    }
  }

  const submitAnswer = () => {
    let newArr = [ ...answer ];
    if (showTextBox){
      for (let i =0 ; i < textAnswer.length; i ++)
      {
        if (textAnswer[i] != ''){
          newArr.push(textAnswer[i])
        }
      }
    }
    if (newArr.length === 0 )
    {
        setIsAnswered(true)
    }
    else{
        setIsAnswered(false)
        setProgressbar(true)
        props.submit({
          value: newArr
        });
    }

  }

  const updateAnserText = (i, answerText)=>{
    let out = [ ...textAnswer]
    out[i] = answerText
    setTextAnswer(out)
  }



  let textDisplayOBj = () =>{
    return (
      <>
      <Group value={answer.length > 0 ? answer[0] : ""} onChange={handleChange}>
          {values.map((e, i) => {
            return (
              orderBelow?
              <Grid container  key={i}>
                <FormControlLabel key={i}
                  control={
                    <Sel checked={answer.indexOf(e) >= 0} onChange={handleChange} name={e} color={props.theme.color}/> 
                  }
                  label={<span style={{fontSize:props.theme.componentFontSize, color:props.theme.fontColor, fontFamily:props.theme.fontFamily, whiteSpace :"pre-wrap"
                    }}>{e}</span>}
                  value={e}
                />
              </Grid>
              :
              <FormControlLabel key={i}
                  control={
                    <Sel checked={answer.indexOf(e) >= 0} onChange={handleChange} name={e} color={props.theme.color}/> 
                  }
                  label={<span style={{fontSize:props.theme.componentFontSize, color:props.theme.fontColor, fontFamily:props.theme.fontFamily, whiteSpace :"pre-wrap"
                    }}>{e}</span>}
                  value={e}
                />
            )
          })}
        </Group>
      </>
    )
  }

  let textBoxDisplayObj = ()=>{
    return (
      <>
      {showTextBox === true ? 
      textBoxNumber.map((x,i)=>{
          return (
            <Grid container spacing={4} className="mb-1" key={i}>
            <Grid item xs={12} lg={6} md={6}>
              <TextField
              variant={props.theme.conponentVarient}
              color={props.theme.color}
              label={textTitle}
              placeholder={textTitle}
              type="text"
              width="50%"
              fullWidth
              value={textAnswer[i] != undefined ? textAnswer[i] : ''}
              onChange={(event) => {updateAnserText( i, event.target.value)}}
              />
            </Grid>
            </Grid>
          )
        })
      : 
      null
      }
      </>
    )
  }
  
  return (
    <Fragment>
      <ThemeProvider theme={props.theme.pallet}>
      {props.content == "text" ? (
        <>
       {
         textBelow ?
         <>
         <div>{textBoxDisplayObj()}</div>
         <div>{textDisplayOBj()}</div>
         </>

         :
         <>
         <div>{textDisplayOBj()}</div>
         <div>{textBoxDisplayObj()}</div>
         </>
       }

        </>
      ) : (
        <div className="align-content-center justify-content-around" style={{whiteSpace: "initial"}}>
          {values.map((e, i) => {
            return (
              orderBelow ?
              <Grid container  key={i}>
                <Button key={e.finUrl} className="m-3" color={props.theme.color} variant={answer.indexOf(e.finUrl) >= 0 ? "contained" : "outlined"} onClick={selectImage.bind(null, e.finUrl)}>
                  <div className="d-flex flex-column align-item-center">
                    <img className="p-2" src={e.finUrl} style={{width: imageSize, height: imageSize , borderRadius: '0.25rem'}} />
                  </div>
                </Button>
              </Grid>
              :
              <Button key={e.finUrl} className="m-3" color={props.theme.color} variant={answer.indexOf(e.finUrl) >= 0 ? "contained" : "outlined"} onClick={selectImage.bind(null, e.finUrl)}>
              <div className="d-flex flex-column align-item-center">
                <img className="p-2" src={e.finUrl} style={{width: imageSize, height: imageSize , borderRadius: '0.25rem'}} />
              </div>
            </Button>
            )
          })}
        </div>
      )}
      {isAnswered ? <div>
                    <Alert severity="error">Please Answer </Alert>
                </div> : null}
      <div className="d-block">
                {props.preview ? null : (<Button className="mt-3" color={props.theme.color} variant={props.theme.buttonVariant} onClick={submitAnswer}>Submit</Button>)}
                { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
      </div>
      </ThemeProvider>
    </Fragment>
  )
}