import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Card,
  CardContent,
  TextField, FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';

import { Authorization,  Survey } from '../../../../Store/ApplicationStore';
export default (props) => {

const surveyId = props.surveyId

const surveyTypes = {
  1:"Simple Survey",
  2:"Ad Testing",
  3:"Video Conference"
}

const [name, setName] = useState(props.surveyData.title);
const [description, setDescription] = useState(props.surveyData.notes ?? '');
const [shareList, setShareList] = useState([])
const [email, setEmail] = useState('')
const [emailDoesnNotExists, setEmailDoesnNotExists] = useState(false);
const [checked, setChecked] = useState(false)
  const handleNameChange = (name) => {
    setName(name)
    let survey = props.surveyData
    survey.title = name
    survey.edited = true
    props.editSurvey(survey)

  };
  const handleDescription = (notes) => {
    setDescription(notes)
    let survey = props.surveyData
    survey.notes = notes
    survey.edited = true
    props.editSurvey(survey)
  };
  
  const validateEmail = (email) =>{
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleEmailChange = (value) =>{
    setEmailDoesnNotExists(true)
    if (value.length > 8)
    {
      if (validateEmail(value))
      {
        Authorization.checkEmailExists(value).then(response => {
            setEmailDoesnNotExists(response)
        })
      }
      else{
        setEmailDoesnNotExists(false)
      }
    }
    setEmail(value)
  }


  useEffect(()=>{
    if (shareList.length == 0)
    {
      getShareToUsers()
    }
  },[shareList])

  const getShareToUsers = async ()=>{
    await Survey.getShareToUsers({'batchId': surveyId}).then((e)=>{
      if (e.success == true){
        setShareList(e.output)
      }
    })
  
  
  }
  const handelShareCheckChange = async (x, id)=>{
    x.preventDefault()
    let subData = [...shareList]
    for (let i = 0; i < subData.length; i += 1) {
      if (subData[i].id == id){
          subData[i].isEdit = x.target.checked
      }
    }
    var result = subData.find(obj => {
      return obj.id === id
    })
    await Survey.updateShareToUsers({'batchId': surveyId , 'shareId': id, 'isEdit': result.isEdit}).then((e)=>{
      if (e.success == true){
        getShareToUsers()
      }
    })
  }
  

  const handelDeleteChange = async (x, id)=>{
    x.preventDefault()
    await Survey.deleteShareToUsers({'batchId': surveyId , 'shareId': id}).then((e)=>{
      if (e.success == true){
         getShareToUsers()
      }
    })
  }

  const handelAddShareToUser = async (x)=>{
    x.preventDefault()
    await Survey.addShareToUsers({'batchId': surveyId , 'email': email, 'isEdit': checked}).then((e)=>{
      if (e.success == true){
        getShareToUsers()
      }
    })
    setEmail('')
    setChecked(false)
  }

  return (
    <Fragment>
      <div className="card-body justify-content-between " style={{height:'80vh', overflowY: 'scroll'}}>
        <Grid container spacing={4} alignItems="center" justify="center" >
            <Grid item xs={8} lg={8}>
                <Card className="mt-4">
                  <CardContent className="p-3">
                  <div className="justify-content-between mb-4">
                              <div className="mb-3">
                              <TextField
                                  variant="outlined"
                                  label="Name"
                                  fullWidth
                                  placeholder="Enter a Name"
                                  type="text"
                                  value={name}
                                  onChange={(event) => {handleNameChange(event.target.value)}}
                                  />
                                
                              </div>
                              <div className="mb-3">
                              <TextField
                                  variant="outlined"
                                  label="Description"
                                  fullWidth
                                  placeholder="Description"
                                  type="text"
                                  value={description}
                                  onChange={(event) => {handleDescription(event.target.value)}}
                                  />
                                
                              </div>
  
                  </div>
                  </CardContent>
            </Card>
            </Grid>

            <Grid item xs={8} lg={8}>
            <Card className="card-box mb-4">
                        <div className="card-header pr-2">
                            <h5>
                                Share survey with
                                </h5>
                        </div>
                        <CardContent className="p-3">
                            <div className="table-responsive">
                                <table className="text-nowrap mb-0 table table-borderless table-hover">
                                    <thead>
                                        <tr>
                                            
                                            <th className="text-left">email</th>
                                            <th className="text-center">Edit permision</th>
                                            <th className="text-center">delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {shareList.map((e, i) => (
                                      <>
                                       <tr key={i}>
                                            <td  className="text-center">
                                            <span className="text-black-50 d-block">{e.email}</span> 
                                            </td>
                                            <td  className="text-center">
                                            <FormControlLabel control={<Checkbox checked={e.isEdit} onChange={(x) => handelShareCheckChange(x, e.id)} />} />
                                            </td>
          
                                            <td  className="text-center">
                                            <Button  variant="outlined" color="inherit" className="text-google"  size="medium" onClick={(x) => handelDeleteChange(x, e.id)}>
                                                    delete
                                              </Button> 
                                            </td>
                                        </tr>
                                      </>
                                     ))}
                                       
                                       
                                    </tbody>
                                </table>
                            </div>
                        </CardContent>
                        <div className="card-footer d-flex justify-content-between">

                        <Grid container spacing={4} alignItems="center" justify="center" >
                            <Grid item xs={5} lg={5} md={5}>
                            <TextField
                                  variant="outlined"
                                  label="Email"
                                  className="p-2"
                                  fullWidth
                                  placeholder="Enter email address"
                                  type="email"
                                  value={email}
                                  {...(emailDoesnNotExists ? {error:true} : {})}
                                  {...(emailDoesnNotExists ? { helperText:"email doesnt Exist"} : {})}
                                  onChange={(event) => {handleEmailChange(event.target.value)}}
                                  />
                            </Grid>
                            <Grid item xs={3} lg={2} md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckedChange} />} label="Edit permision"  labelPlacement="bottom"/>
                              </FormGroup>
                            </Grid>
                            <Grid item xs={2} lg={2} md={2}>
                            <Button color="secondary"  variant="contained"  size="medium" onClick={(e)=>handelAddShareToUser(e)}>
                                add
                          </Button>
 
                            </Grid>
                        </Grid>
                              
                              
                           
                        </div>
                    </Card>
            </Grid>
          </Grid>
        </div>
      </Fragment>
      
  )
}