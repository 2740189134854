import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid ,Divider
} from '@material-ui/core';
import RegionSelector from './RegionSelector';

const Location = ({
  cityOptions,
  stateOptions,
  countryOptions,
  cityFilter,
  stateFilter,
  countryFilter,
  setFilter
}) => {
  return (
    <>

            <RegionSelector
              options={countryOptions}
              filters={countryFilter}
              setFilter={(country) => setFilter({
                country
              })}
              title="Countries"
            />
            <Divider />
            <RegionSelector
              options={stateOptions}
              filters={stateFilter}
              setFilter={(state) => setFilter({
                state
              })}
              title="States"
            />
            <Divider />
            <RegionSelector
              options={cityOptions}
              filters={cityFilter}
              setFilter={(city) => setFilter({
                city
              })}
              title="Cities"
            />

        </>
  );
};

export default Location;
