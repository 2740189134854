import React, { Fragment, useEffect, useState } from 'react';
import { Survey ,Authorization } from 'Store/ApplicationStore';
import {  Input, InputNumber, Popconfirm, Form, Typography } from 'antd';
import { Table } from "ant-table-extensions";
import {TextField, Button, Grid, Card
} from '@material-ui/core';
import IosConstructOutline from 'react-ionicons/lib/IosConstructOutline';
import IosTrashOutline from 'react-ionicons/lib/IosTrashOutline';
import IosCloseCircleOutline from 'react-ionicons/lib/IosCloseCircleOutline';
import IosDoneAll from 'react-ionicons/lib/IosDoneAll';
import IosCheckmark from 'react-ionicons/lib/IosCheckmark';
import { Save} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SentimnetDialog from './dialog'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <TextField  fullWidth multiline variant="outlined" />
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TranscriptDashboard = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const isOperation = props.isOperation
  const [fullData, setFullData] = useState([])
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const surveyId = urlParams.get('id');
  const questionId = urlParams.get('questionId')
  const [searchUserId, setSearchUserId] = useState('');
  const [searchUserName, setSearchUserName] = useState('');
  const [updateFilter, setUpdateFilter] = useState('');
  const [sentimentFilter, setSentimentFilter] = useState('');
  const [proofRead, setProofRead] = useState(0);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [notUpdatedCount, setNotUpdatedCount ] = useState(0);
  const [notApprovedCount, setNotApprovedCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalVideoTime, setTotalVideoTime] = useState(0);


  const isEditing = (record) => record.id === editingKey;
  
  const FilterByUserId = (
    <>
    <Input
      placeholder="Id"
      value={searchUserId}
      style={{minWidth: '80px'}}
      onChange={e => {
        const currValue = e.target.value;
        let intcurrValue =  parseInt(currValue)
        setSearchUserId(currValue);
        const filteredData = fullData.filter(entry =>
          entry.id == intcurrValue
        );
        setData(filteredData);
      }}
    />
    </>
    
  );

  const handleChangeUpdateFilterChange = (event)=>{
    let updateValue = event.target.value
    let updateValueObj = updateValue == 'Updated' ? true : false
    setUpdateFilter(updateValue)
    if (updateValue != ''){
      const filteredData = fullData.filter(entry =>
        entry.isUpdate == updateValueObj
      );

      setData(filteredData)
    }else{
      setData(fullData)
    }
    
  }
  const handleChangeSentimentFilterChange = (event) =>{

  let sentValue = event.target.value
  let sentvalueObj = sentValue == 'Approved' ? true : false
  setSentimentFilter(sentValue)
    if (sentValue != ''){
      const filteredData = fullData.filter(entry =>
        entry.isAnalysisApproved == sentvalueObj
      );
      setData(filteredData)
    }else{
      setData(fullData)
    }
  }

  const FilterByIsUpdated = (
    <>
    <FormControl style={{minWidth: 100}}>
        <InputLabel id="demo-customized-select-label">update </InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={updateFilter}
          fullWidth
          variant="standard"
          onChange={handleChangeUpdateFilterChange}
        >
          <MenuItem value={''}>None</MenuItem>
          <MenuItem value={'Updated'}>Updated</MenuItem>
          <MenuItem value={'Not updated'}>Not updated</MenuItem>
        </Select>
      </FormControl>
    </>
    
  );

  const FilterByIsSentimentApproved = (
    <>
    <FormControl style={{minWidth: 100, alignItems:"center", textAlign:"center"}}>
        <InputLabel id="demo-customized-select-label">sentimnet </InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={sentimentFilter}
          fullWidth
          variant="standard"
          onChange={handleChangeSentimentFilterChange}
        >
           <MenuItem value={''}>None</MenuItem>
          <MenuItem value={'Approved'}>Approved</MenuItem>
          <MenuItem value={'Not Approved'}>Not Approved</MenuItem>
        </Select>
      </FormControl>
    </>
  )

  const FilterByName = (
    <Input
      placeholder="Name"
      value={searchUserName}
      style={{minWidth: '80px'}}
      onChange={e => {
        const currValue = e.target.value;
        setSearchUserName(currValue);
        if (currValue.length > 3)
        {
        const filteredData = fullData.filter(entry =>
          entry.name.toLowerCase() == currValue.toLowerCase()
        );
        setData(filteredData);
        }
      }}
    />
    
  );

  const columnsWithSub = [

    {
      title: 'master id',
      dataIndex: 'userId',
      width: '7%',
      editable: false,
    },
    {
      title: 'video id',
      dataIndex: 'id',
      width: '5%',
      editable: false,
    },
    {
        title: 'url',
        dataIndex: 'url',
        width: '18%',
        editable: false,
        render: (_, record) => {
            if (record.url[1] == 'video'){
                return <video width="300" height="300" controls src={record.url[0]} />
            }else if(record.url[1] == 'audio') {
                return <audio width="200" height="200" controls src={record.url[0]} />
            }
        }
      },
    {
        title: 'transcript',
        dataIndex: 'transcript',
        width: '32%',
        editable: true,
    },
    {
      title: FilterByIsUpdated,
      dataIndex: 'updated',
      width: '3%',
      editable: false,
      render: (_, record) => {
        return (
        <div style={{textAlign:"center"}}>{ record.isUpdate == true ? 
        <span className="m-1 badge badge-info">update</span> : 
        <span className="m-1 badge badge-warning">Not updated</span> }</div>
        )
        
      }
    },
    
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '8%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
            <>
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => save(record.id)}>
                <span className="font-size-xxl d-block mb-1"><IosDoneAll fontSize="28px" color="var(--primary)"/></span>
                <span className="pb-1">Save</span>
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" >
                <span className="font-size-xxl d-block mb-1">< IosCloseCircleOutline fontSize="28px" color="var(--danger)"/></span>
                <span className="pb-1">Cancel</span>
            </Button>
            </Popconfirm>
            </>
        ) : (
            <>
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => edit(record)}>
                    <span className="font-size-xxl d-block mb-1"><IosConstructOutline fontSize="28px" color="var(--primary)"/></span>
                    <span className="pb-1">Edit</span>
            </Button>

            {record.isProofRead ?  <div style={{textAlign:"center"}}> <span className="m-1 badge badge-success">proof Read</span> </div> 
            :
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => updateProofRead(record)}>
                  <span className="font-size-xxl d-block mb-1"><IosCheckmark fontSize="28px" color="var(--primary)"/></span>
                  <span className="pb-1">Proof read</span>
              </Button>
          }
            </>
        );
      },
    },
    {
      title: FilterByIsSentimentApproved,
      dataIndex: 'sentimentAppreoved',
      width: '3%',
      editable: false,
      render: (_, record) => {
        return (
          <>
          { 
            !record.isAnalysisApproved ?
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => approve(record)}>
            <span className="font-size-xxl d-block mb-1"><IosCheckmark fontSize="28px" color="var(--primary)"/></span>
            <span className="pb-1">approve</span>
           </Button>
           : 
            <div style={{textAlign:"center"}}> <span className="m-1 badge badge-success">approved</span> </div>
          }
          </>
        )
        
      }
    },
  ];


    
  const columnsOutSub = [
    
    {
      title: 'master Id',
      dataIndex: 'userId',
      width: '7%',
      editable: false,
    },
    {
      title: 'video id',
      dataIndex: 'id',
      width: '5%',
      editable: false,
    },
    {
        title: 'url',
        dataIndex: 'url',
        width: '18%',
        editable: false,
        render: (_, record) => {
            if (record.url[1] == 'video'){
                return <video width="300" height="300" controls src={record.url[0]} />
            }else if(record.url[1] == 'audio') {
                return <audio width="200" height="200" controls src={record.url[0]} />
            }
        }
      },
    {
        title: 'Transcript',
        dataIndex: 'transcript',
        width: '32%',
        editable: true,
    },
    {
      title: 'Transcriber Name',
      dataIndex: 'transcriberName',
      width: '5%',
      editable: true,
  },
    {
      title: FilterByIsUpdated,
      dataIndex: 'updated',
      width: '3%',
      editable: false,
      render: (_, record) => {
        return (
        <div style={{textAlign:"center"}}>{ record.isUpdate == true ? 
        <span className="m-1 badge badge-info">update</span> : 
        <span className="m-1 badge badge-warning">Not updated</span> }</div>
        )
        
      }
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '8%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
            <>
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => save(record.id)}>
                <span className="font-size-xxl d-block mb-1"><IosDoneAll fontSize="28px" color="var(--primary)"/></span>
                <span className="pb-1">Save</span>
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" >
                <span className="font-size-xxl d-block mb-1">< IosCloseCircleOutline fontSize="28px" color="var(--danger)"/></span>
                <span className="pb-1">Cancel</span>
            </Button>
            </Popconfirm>
            </>
        ) : (
            <>
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => edit(record)}>
                    <span className="font-size-xxl d-block mb-1"><IosConstructOutline fontSize="28px" color="var(--primary)"/></span>
                    <span className="pb-1">Edit</span>
              </Button>
            { record.isProofRead ?  <div style={{textAlign:"center"}}> <span className="m-1 badge badge-success">proof Read</span> </div> 
            :
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => updateProofRead(record)}>
                  <span className="font-size-xxl d-block mb-1"><IosCheckmark fontSize="28px" color="var(--primary)"/></span>
                  <span className="pb-1">Proof read</span>
              </Button>
            }
            </>
        );
      },
    },
    {
      title: FilterByIsSentimentApproved,
      dataIndex: 'sentimentAppreoved',
      width: '3%',
      editable: false,
      render: (_, record) => {
        return (
          <>
          { 
            !record.isAnalysisApproved ?
            <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => approve(record)}>
            <span className="font-size-xxl d-block mb-1"><IosCheckmark fontSize="28px" color="var(--primary)"/></span>
            <span className="pb-1">approve</span>
           </Button>
           : 
            <div style={{textAlign:"center"}}> <span className="m-1 badge badge-success">approved</span> </div>
          }
          </>
        )
        
      }
    },
  ];


  const [columnType, setColumnType] = useState('withOutSub')
  const [question ,setQuestion] = useState({})
  const [permision, setPermisison] = useState({isDownload: true,
    isForm: true,
    isAiAnalysis: true,
    isBrand: true,
    isAgnecy: false})
  
  useEffect(()=>{
    if (data.length == 0)
    {
      getData()
    }
    },[data ,question])


  const getData = async ()=>{
    await Survey.getTranscriptDashboard(
      {
          "batchId": surveyId,
          "questionId": questionId
      }
      ).then((e)=>{
          if (e.status == 'success') {

            setUpdatedCount(e.count.updated)
            setNotUpdatedCount(e.count.notUpdated)
            setNotApprovedCount(e.count.notApproved)
            setApprovedCount(e.count.approved)
            setTotal(e.count.total)
            setTotalVideoTime(e.count.videoTime)
            setProofRead(e.count.proofRead)
            setFullData(e.value)
            setData(e.value)
          }
      })

  await Survey.getAIquestions({"batchId": surveyId, "questionId":questionId}).then((e)=>{
    if (e.status == 'success'){
      let data = e.value[0]
      setQuestion(data)
      if (data != null)
      {
        if (data.analysis != null || undefined ){
          if (data.analysis?.hasOwnProperty('transcriptValue'))
          {
            if (data.analysis.transcriptValue == 'transcript_translate'){
              setColumnType('withSub')
            }
          }
        }
      }
      
    }
  })
  const per = await Authorization.getPermission();
  setPermisison(per);

  }

 


  const edit = (record) => {
    form.setFieldsValue({
      transcript: '',
      subTranscript: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const deleteRecord = async (record) => {
    let dataObj = {
      "videoId": record.id
    }
    let tempData = [...data]
    const finalData = tempData.filter((item) => item.id !== record.id);
    setData(finalData)
    setFullData(finalData)
    await Survey.deleteVideoTranscript(dataObj)
  };

  const approve= async (record) =>{
    let dataObj = {
      "videoId": record.id,
      "isApproved":true
    }
    let tempData = [...data]
    const  objIndex = tempData.findIndex((obj => obj.id == record.id));
    tempData[objIndex].isAnalysisApproved = true
    setData(tempData)
    setFullData(tempData)
    await Survey.approveVideoTranscript(dataObj)
  }

  const updateProofRead= async(record) =>{
    let dataObj = {
      "videoId": record.id,
      "subTranscript":'',
      "proofRead" : true,
      "transcript": ''

    }
    let tempData = [...data]
    const  objIndex = tempData.findIndex((obj => obj.id == record.id));
    tempData[objIndex].isProofRead = true
    setData(tempData)
    setFullData(tempData)
    await Survey.updateVideoTranscript(dataObj)
  }

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      if (row.hasOwnProperty('subTranscript'))
      {
        Survey.updateVideoTranscript({
          videoId: key,
            ...row, 
            proofRead : false
        }).then((e)=>{
            console.log(e)
        })
      }
      else{
        Survey.updateVideoTranscript({
          videoId: key,
            ...row,
            subTranscript:'',
            proofRead : false
        }).then((e)=>{
            console.log(e)
        })
        
      }
      
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row , isUpdate: true});
        setData(newData);
        setFullData(newData)
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setFullData(newData)
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleAnalysis= (e)=>{
    e.preventDefault()
    history.push({
        pathname: '/Dashboard',
        search: '?id='+surveyId 
     });
}

  const handleForm = (e)=>{
    e.preventDefault()
    history.push({
        pathname: '/FormDashboard',
        search: '?id='+surveyId 
    });
  }


  const colObj =  isOperation ? columnsOutSub : permision.isBrand ? columnsWithSub : columnsOutSub
  const mergedColumns =  colObj.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
<>
      <Grid container spacing={2} >
      <Grid item xs={1} lg={1} >
        </Grid>
        <Grid item xs={1} lg={5} md={4} >

        { questionId == 0 ?
          <Card className="card-box mb-1 mt-1 d-flex flex-row flex-wrap justify-content-center">
                  <div className="py-2 px-2 d-flex align-items-center" >
                      <div>
                          <span className="d-block opacity-7">Total</span>
                          <span className="font-weight-bold font-size-lg ">{total}</span>
                      </div>
                  </div>
                  <div className="py-2 px-2 d-flex align-items-center" >
                    <div>
                        <span className="d-block opacity-7">Video time</span>
                        <span className="font-weight-bold font-size-lg ">{totalVideoTime} min</span>
                    </div>
                  </div>
                  <div className="py-2 px-2 d-flex align-items-center" >
                    <div>
                        <span className="d-block opacity-7">Updated</span>
                        <span className="font-weight-bold font-size-lg ">{updatedCount}</span>
                    </div>
                  </div>
                  <div className="py-2 px-2 d-flex align-items-center" >
                    <div>
                        <span className="d-block opacity-7">Not Updated</span>
                        <span className="font-weight-bold font-size-lg ">{notUpdatedCount}</span>
                    </div>
                  </div>
                  <div className="py-2 px-2 d-flex align-items-center" >
                    <div>
                        <span className="d-block opacity-7">Approved</span>
                        <span className="font-weight-bold font-size-lg ">{approvedCount}</span>
                    </div>
                  </div>
                  <div className="py-2 px-2 d-flex align-items-center" >
                    <div>
                        <span className="d-block opacity-7">Not Approved</span>
                        <span className="font-weight-bold font-size-lg ">{notApprovedCount}</span>
                    </div>
                  </div>
                  <div className="py-2 px-2 d-flex align-items-center" >
                    <div>
                        <span className="d-block opacity-7">proof Read</span>
                        <span className="font-weight-bold font-size-lg ">{proofRead}</span>
                    </div>
                  </div>
          </Card> : null}
      </Grid>
                
        <Grid item xs={3} lg={2} md={3}>
        {isOperation ? null :  <SentimnetDialog surveyId={surveyId} questionId={questionId}/> }
          </Grid>
          <Grid item xs={4} lg={2} md={3}>
           {isOperation ? null :
            <Button color="secondary" className="m-1"  variant="contained"
                style={{float:"right" }} onClick={(e) => handleAnalysis(e)}>
                Analysis Dashboard
            </Button>
          }
          </Grid>
          <Grid item xs={4} lg={2} md={3}>
          {isOperation ?  <Button color="secondary" className="m-1"  variant="contained"
                style={{float:"right" }} onClick={(e) => handleForm(e)}>
                Form Dashboard
            </Button> : null }
          </Grid>
           
          </Grid>
      
      
    <Form form={form} component={false} style={{padding:'10px'}}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
        position:['topRight'],
          onChange: cancel,
        }}
        searchable
      />
    </Form>
    </>
  );
};

export default TranscriptDashboard 
