import React from 'react';
import styled from 'styled-components';
import { IoManSharp, IoWomanSharp } from "react-icons/io5";
import {
  Typography
} from '@material-ui/core';

const UserList = ({
  list,
  selected,
  selectUser
}) => {
  return (
    <List>
      {list.map((user) => (
        <User
          key={user.id}
          className={selected === user.videoId ? 'active' : ''}
          onClick={() => selectUser(user.videoId)}
        >
          <div className="content">
            <div>
              <Typography variant="h5" color="inherit">{user.name}</Typography>
            </div>
            <div className="caption">
              {user.gender === 1 ? (
                <IoManSharp
                  color={selected === user.videoId ? '#fff' : '#3f5efb'}
                  fontSize="16px"
                />
              ) : (
                <IoWomanSharp
                  color={selected === user.videoId ? '#fff' : '#3f5efb'}
                  fontSize="16px"
                />
              )}
              <span>{`, ${user.age} years`}</span>
            </div>
          </div>
        </User>
      ))}
    </List>
  );
};

export default UserList;

const List = styled.div`
  width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow-y: auto;
  background-color: #fafafa;
`;

const User = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 2px solid #3f5efb;
  background-color: #fff;
  color: #3f5efb;
  margin: 10px;
  position: relative;
  box-shadow: 0px 0px 5px 5px #eaeaea;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 5px 5px #3f5efb50;
  }
  .content {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .caption {
    display: flex;
    font-size: 16px;
  }
  &.active {
    background-color: #3f5efb;
    color: #fff;
    box-shadow: 0px 0px 5px 4px #fafafa;
    color: #fff;
    border-color: transparent;
  }
`;
