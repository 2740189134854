import React, { Fragment, useReducer, useEffect, useState, Link } from 'react';
import { Survey, Authorization } from 'Store/ApplicationStore';
import { PageTitle } from '../../../components';
import { getParentData, getFilteredData } from './DemoGraphyController';
import Filter from './Filters';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CircularProgress ,TextField ,Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import QuestionDashboard from './Types';
import { ContactlessOutlined } from '@material-ui/icons';
import ExcelExport from './ExcelExport'
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
const hash = require('object-hash');
const useStyles = makeStyles({
  root: { width: '100%' }
}, { name: 'MuiFormControl' });

const intialState = {
  filters: {
    gender: [],
    age: {
      min: 0,
      max: 100
    },
    city : [],
    state:[],
    country:[],
    outputType:"graph"

  },
  demography: {
    parent: {
      gender: ['Male', 'Female', "others", "prefer not to answer"],
      age: [ 15, 30, 40, 50, 60],
      city: [],
      state:[],
      country:[],
      outputType:"graph"

    },
    data: {
      gender: {
        Male: 0,
        Female: 0
      },
      age: []
    }
  },
  filterData: {}, // "hash of filters": { "questionId" : <data> }
  hashCode: '',
  showDashboard: [],
  displayType: 'list',
  questions: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_filter':
      return {
        ...state, filters: { ...action.filters }, demography: { ...state.demography, data: { ...action.data } }
      }
    case 'intiate_demography':
      return {
        ...state, demography: { ...action.data  }
      }
    case 'show_dashboard':
      return {
        ...state, showDashboard: [ ...action.data ]
      }
    case 'change_display':
      return {
        ...state, displayType: action.data
      }
    case 'filtered_data':
      return {
        ...state, filterData: action.filterData, hashCode: action.code
      }
    case 'add_questions':
      return {
        ...state, questions: action.data
      }
  }
}

export default (props) => {
  const history = useHistory();
  const classes = useStyles();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let isBrand = props.permission.isBrand
  const surveyId = urlParams.get('id');
  // const surveyName = urlParams.get('name');
  const [surveyType,setSurveyType] = useState(1);
  const [surveyName,setSurveyName] = useState('');
  const [ surveyData, setSurveyData] = useState({});
  const [state, dispatch] = useReducer(reducer, intialState);
  const [analyseStatus, setAnalyseStatus] = useState(false);
  const [summary, setSummary] = useState(null);
  let demoGraphyValue = {};


  useEffect(() => {

    if (surveyId) {
       Survey.getDemographyInformation(surveyId).then(data => {
        if (data.status == 'success') {
          demoGraphyValue = data.value;
          let values = getParentData(data.value);
          dispatch({ type: 'intiate_demography', data: values  });
        }
      });
       Survey.getAllQuestions(surveyId).then((data) => {
        if (isBrand == true){
          data = data.filter(function (el) {
            return el.questionText.toLowerCase().indexOf("name of interviewer") == -1 && el.questionText.toLowerCase().indexOf("name of agency") == -1
          })
        }
        dispatch({ type: 'add_questions', data: data })
      });

      Survey.getAllSurveys(surveyId).then((data) => {
        if (data.length != 0){
          let dataOut = data[0]
            setSurveyData(dataOut)
            setSurveyName(dataOut.title)
            setSurveyType(dataOut.surveyTypeId)
            if (dataOut.summary != null || dataOut.summary != undefined){
              let out = dataOut.summary.split('**')
              setSummary(out)
            }
            
          }
        })
    }
  }, [isBrand]);



  const updateSurveyData = async()=>{
    await Survey.getAllSurveys(surveyId).then((data) => {
      if (data.length != 0){
        let dataOut = data[0]
          setSurveyData(dataOut)
          setSurveyName(dataOut.title)
          setSurveyType(dataOut.surveyTypeId)
          if (dataOut.summary != null || dataOut.summary != undefined){
            let out = dataOut.summary.split('**')
            setSummary(out)
          }
          
        }
      })
  }

  useEffect(() => {
    if (state.showDashboard.length > 0) {
      const hashCode = getHashCodeFromFilter();
      state.showDashboard.forEach((e) => {
        let id = e.questionId;
        let fetch = true;
        if (state.filterData.hasOwnProperty(hashCode) && state.filterData[hashCode].hasOwnProperty(id)) {
          fetch = false;
        }
        if (fetch) {
          let filterObj = getFilterQueryObject(id);
           Survey.getDashboardData(filterObj).then((data) => {

            if (data.status == 'success') {
              let newFilteredData = { ...state.filterData };
              if(!newFilteredData.hasOwnProperty(hashCode)) {
                newFilteredData[hashCode] = {
                  [e.questionId]: {}
                }
              }
             
              newFilteredData[hashCode][e.questionId] = {
                stats: data.value,
                media: e.questionUrl
              };
              dispatch({ type: 'filtered_data', filterData: newFilteredData, code: hashCode })
            }
          })
        }
      });
    }
  }, [state.showDashboard, state.filters]);

  const getHashCodeFromFilter = () => {
    let obj = { ...state.filters };
    return hash(obj);
  }

  const updateFilters = (data) => {
    let demographyData = getFilteredData(demoGraphyValue, data);
    dispatch({ type: 'update_filter', filters: data, data: demographyData });
  }
  const analysisVideos = () => {
    let Data = {
      'batchId': surveyId
    }
    setAnalyseStatus(true);
    Survey.addVideosForAnalysis(Data).then((e)=>{
      console.log(e)
    })
  };
  const getFilterQueryObject = (questionId) => {
    let filter = {
      "age": 25,
      "ageOffset": 10,
      "batchId": surveyId,
      "city": [],
      "count": 0,
      "country": [],
      "gender": 1,
      "offset": 0,
      "questionId": questionId,
      "state": [],
      "outputType":"graph"
    };
    filter.age = state.filters.age.min;
    filter.ageOffset = state.filters.age.max - state.filters.age.min;
    filter.gender = state.filters.gender ?? [0,1];
    filter.city = state.filters.cities ?? [];
    filter.state = state.filters.states ?? [];
    filter.country = state.filters.countries ?? [];
    filter.outputType = state.filters.outputType
    return filter;
  }

  const updateQuestionList = (value) => {
    dispatch({ type: 'show_dashboard', data: value })
  }

  // const getLink = (e ,questionId, question) => {
  //   let AnomyousURL = encodeURI("/AiAnalysisDashboard?batchId="+surveyId+ "&questionId="+ questionId+ "&question=" + question);
  //   window.open(AnomyousURL, "_blank")
  // }
  // const handleAiDashbaord = (e, questionId, question) => {
  //   let AnomyousURL = encodeURI("/AiAnalysisDashboard?batchId="+surveyId+ "&questionId="+ questionId+ "&question=" + question);
  //   window.open(AnomyousURL, "_blank")
  // }

  const handleAiDashbaord = (e, questionId) => {
    let AnomyousURL = encodeURI("/AiAnalysisDashboard?batchId="+surveyId+ "&questionId="+ questionId);
    window.open(AnomyousURL, "_blank")
  }
  
  
  
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleTanscript = (e, questionId)=>{
    e.preventDefault()
    history.push({
        pathname: '/TranscriptDashboard',
        search: '?id='+surveyId + '&questionId=' + questionId
     });
}

  return (
    <Fragment>
      <div style={{marginTop:'-30px'}}>
      <Filter data={state.demography.parent} filters={state.filters}  
      updateFilters={updateFilters} surveyName={surveyName} surveyData={surveyData} 
      surveyId={surveyId} analysisVideos={analysisVideos} analyseStatus={analyseStatus} surveyType={surveyType} updateSurveyData={updateSurveyData}/>
      <div className="d-block m-3">
      <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            // options={[{feqid:0,questionText:"ALL"},...state.questions]}
            options={state.questions}
            disableCloseOnSelect
            className={classes.root}
            onChange={(e,value) =>{
              updateQuestionList(value)}}
            getOptionLabel={option => option.questionText}
            renderOption={(option, state) => {
              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 3 }}
                    checked={state.selected}
                  />
                  {  option.orderId +") "+option.questionText}
                </React.Fragment>
              );
            }}
            // style={{ width: 500 }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Select Questions"
                placeholder="select Questions"
              />
            )}
          />
          <Fragment>
          {summary != null?
          <Card className="card-box mt-4 mb-4-spacing overflow-visible" >
                <div className="card-header">
                  <div className="card-header--title font-size-md font-weight-bold py-2">
                    Summary
                    </div>
                </div>
                <CardContent className="p-3">
                { summary.map(notification => <p>{ notification }</p>) }
                  
                </CardContent>
              </Card> :
               null}
          </Fragment>
        {state.showDashboard.map((e, i) => {
          
          const hashCode = getHashCodeFromFilter();
          if (state.filterData.hasOwnProperty(hashCode) && state.filterData[hashCode].hasOwnProperty(e.questionId)) {
            return (
              <Fragment>
               { state.filters.outputType === "graph" ?
              <Card className="card-box mt-4 mb-4-spacing overflow-visible" key={e.questionId}>
                <div className="card-header">
                  <div className="card-header--title font-size-md font-weight-bold py-2">
                    {`#Q ${e.orderId} - ${e.questionText}`}
                    
                  </div>
                  {e.answerType === 'video' ? 
                  <>
                  <Button variant="contained" color="secondary" style={{marginRight:"2px"}} size="small" onClick={(x)=>handleTanscript(x, e.questionId)}>Transcript Dashboard</Button>
                  <Button color="secondary" variant="contained" className="m-1" size="small" onClick={(x)=>{handleAiDashbaord(x, e.questionId)}}>AI Dashboard</Button>
                  </>: 
                  null}
                </div>
                <CardContent className="p-3">
                  <QuestionDashboard id={e.answerType} analyseStatus={analyseStatus} values={state.filterData[hashCode][e.questionId]['stats']}  media={state.filterData[hashCode][e.questionId]['media']}/> 
                </CardContent>
              </Card>
              : 
              <Card className="card-box mt-4 mb-4-spacing overflow-visible" key={e.questionId}>
                <div className="card-header">
                  <div className="card-header--title font-size-md font-weight-bold py-2">
                    {`#Q ${e.questionText}`}
                  </div>
                </div>
                <CardContent className="p-3">
                  <ExcelExport values={state.filterData[hashCode][e.questionId]['stats'] }  name={e.questionText} id={e.questionId} />
                </CardContent>
              </Card>
                }
              </Fragment>
            )
          }
          else {
            return (
              <Card className="card-box mt-4 mb-4-spacing overflow-visible" key={e.questionId}>
                <div className="card-header">
                  <div className="card-header--title font-size-md font-weight-bold py-2">
                    {`#Q ${e.questionText}`}
                  </div>
                </div>
                <CardContent className="p-3">
                  <CircularProgress />
                </CardContent>
              </Card>
            )
          }
        })}
      </div>
      </div>
    </Fragment>
  )
}