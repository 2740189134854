
import { createTheme } from '@material-ui/core/styles';
import { red , green, blue, yellow ,pink ,purple } from '@material-ui/core/colors';
import typography from './typography';

/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

const theme = (color)=> createTheme({
  palette: {
    primary: {main:color},
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  shape: {
    borderRadius: '0.5rem'
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: vars.second
      }
    }
  },
  typography
});

const getFontFamily = (id)=>{
  switch(id) {
    case "1":
      return "'Roboto', 'Helvetica', 'Arial', sans-serif"
    case "2":
      return "Arial, sans-serif"
    case "3":
      return "Verdana, sans-serif"
    case "4":
      return "Helvetica, sans-serif"
    case "5":
      return "Tahoma, sans-serif"
    case "6":
      return "'Trebuchet MS', sans-serif"
    case "7":
      return "'Times New Roman', serif"
    case "8":
      return "Georgia, serif"
    case "9":
      return "Garamond, serif"
    case "10":
      return "'Courier New', monospace"
    case "11":
      return "'Brush Script MT', cursive"
    default:
      return "'Roboto', 'Helvetica', 'Arial', sans-serif"
  }
}
const PreviewTheme = (color ="#263055", buttonVariant ="contained", conponentVarient= "outlined", 
backgroundColor = "#FFF", componentBgColor="#ffffff70",componentOpacity=0.8, questionFontSize="18px" ,
backgroundImage="none",componentFontSize="14px", fontColor="#000000" ,fontFamily=1, questionSubFontSize="16px")=> {
  let compRgba= null
  try{
    compRgba = 'rgba('+componentBgColor.r +','+componentBgColor.g+','+componentBgColor.b +','+componentBgColor.a+')'
  }
  catch{
    compRgba = componentBgColor
  }
  let _fontFamily = getFontFamily(fontFamily)
    return {
        pallet: theme(color),
        color : "primary",
        colorValue:color,
        buttonVariant:buttonVariant,
        backgroundColor : backgroundColor,
        conponentVarient : conponentVarient,
        componentBgColor:componentBgColor,
        componentBgColorRgba:compRgba,
        componentOpacity:componentOpacity,
        questionFontSize:questionFontSize,
        questionSubFontSize:questionSubFontSize,
        backgroundImage:backgroundImage,
        componentFontSize:componentFontSize,
        fontColor:fontColor,
        fontFamily:_fontFamily
    }
}

export { PreviewTheme}