
import React, { Fragment, useState, useEffect } from 'react';
import {
    Button,
    Menu,
    Grid
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IosConstructOutline from 'react-ionicons/lib/IosConstructOutline';
import IosTrashOutline from 'react-ionicons/lib/IosTrashOutline';
import IosCopyOutline from 'react-ionicons/lib/IosCopyOutline';
import IosMoreOutline from 'react-ionicons/lib/IosMoreOutline';
import IosDocumentOutline from 'react-ionicons/lib/IosDocumentOutline';
import IosListBoxOutline from 'react-ionicons/lib/IosListBoxOutline';
import IosInformationCircleOutline from 'react-ionicons/lib/IosInformationCircleOutline'
import IosLink from 'react-ionicons/lib/IosLink'
import MdPodium from 'react-ionicons/lib/MdPodium'
import { Survey, Authorization } from '../../Store/ApplicationStore'
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles({
    root: {
        borderRight: '#eeeff8 solid 1px',
        borderBottom: '#eeeff8 solid 1px',
        padding: '1px'
    },
  });

export default function DropDown(props) {
    const classes = useStyles();
    const [anchorElMenu1, setAnchorElMenu1] = React.useState(null);
    const handleClickMenu1 = event => {setAnchorElMenu1(event.currentTarget);};
    const handleCloseMenu1 = () => {setAnchorElMenu1(null);};
    const history = useHistory();
    const [permission, setPermission] = useState({
        isDownload: true,
        isForm: true,
        isAiAnalysis: true
      });

    const getPermission = async () => {
        const per = await Authorization.getPermission();
        setPermission(per);
    }; 
    
    useEffect(() => {
        getPermission();
    }, []);

    const handleEdit = (id)=>{
        history.push({
            pathname: '/Manage',
            search: '?id='+ props.id 
         });
    }

    const handleAnalytics = (id)=>{
        history.push({
            pathname: '/Dashboard',
            search: '?id='+ props.id 
            });
    }

    const handleForm = (id)=>{
        history.push({
            pathname: '/FormDashboard',
            search: '?id='+ props.id 
         });
    }
    
    const handleTranscript = (id) =>{
        history.push({
            pathname: '/TranscriptDashboard',
            search: '?id='+props.id + '&questionId=' + 0
        });
    }

    const handleQuota = (id) =>{
        history.push({
            pathname: '/QuotaDashboard',
            search: '?id='+props.id 
        });
    }

    const handleOperationLink = (id) =>{
        history.push({
            pathname: '/operationLink',
            search: '?id='+props.id 
        });
    }

    const handleDuplicate = async (id)=>{
        const data ={
            batchId:id
        }
        await Survey.copySurvey(data).then(function(res){
            history.push({
                pathname: '/Manage',
                search: '?id='+ res.survey_id 
                });
            }
        )
       }
    
    const handleDelete = (id)=>{
        const data ={
            batchId:id
        }
        Survey.deleteExistingSurvey(data).then(function(e){
            if (e.success == true){
                props.deleteId(id)
            }
        })
        
    }
    return (
        <Fragment>
            <div className="text-center">
                <Button variant="outlined" color="primary" onClick={handleClickMenu1}>
               < IosMoreOutline fontSize="28px" color="var(--primary)"/>
            </Button>
            </div>
            <Menu
            anchorEl={anchorElMenu1}
            keepMounted
            open={Boolean(anchorElMenu1)}
            onClose={handleCloseMenu1} classes={{ list: 'p-0' }}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
                <div className="overflow-hidden dropdown-menu-lg p-0">
                {props.isOperation ?
                        <Grid container spacing={0}>
                            <Grid item sm={6} className={classes.root}>
                                    <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleForm(props.id)}}>
                                        <span className="font-size-xxl d-block mb-1"><IosListBoxOutline fontSize="28px" color="var(--info)"/></span>
                                        <span className="pb-1">Form </span>
                                    </Button>
                            </Grid>

                            <Grid item sm={6} className={classes.root}>
                                    <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleTranscript(props.id)}}>
                                        <span className="font-size-xxl d-block mb-1"><IosDocumentOutline fontSize="28px" color="var(--info)"/></span>
                                        <span className="pb-1">Transcript </span>
                                    </Button>
                            </Grid>
                            <Grid item sm={6} className={classes.root}>
                                    <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleQuota(props.id)}}>
                                        <span className="font-size-xxl d-block mb-1"><IosInformationCircleOutline fontSize="28px" color="var(--info)"/></span>
                                        <span className="pb-1">Quota </span>
                                    </Button>
                            </Grid>
                            <Grid item sm={6} className={classes.root}>
                                    <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleOperationLink(props.id)}}>
                                        <span className="font-size-xxl d-block mb-1"><IosLink fontSize="28px" color="var(--info)"/></span>
                                        <span className="pb-1">Link </span>
                                    </Button>
                            </Grid>
                        </Grid>
                    :
                        <Grid container spacing={0} justify="center">
                            <Grid item sm={4}  className={classes.root}>
                                <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => { handleEdit(props.id)}}>
                                    <span className="font-size-xxl d-block mb-1"><IosConstructOutline fontSize="28px" color="var(--primary)"/></span>
                                    <span className="pb-1">Edit</span>
                                </Button>
                            </Grid>
                            <Grid item sm={4} className={classes.root}>
                                <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleDuplicate(props.id)}}>
                                    <span className="font-size-xxl d-block mb-1"><IosCopyOutline fontSize="28px" color="var(--success)" /></span>
                                    <span className="pb-1">Duplicate</span>
                                </Button>
                            </Grid>
                            <Grid item sm={4} className={classes.root}>
                                <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleDelete(props.id)}}>
                                    <span className="font-size-xxl d-block mb-1">< IosTrashOutline fontSize="28px" color="var(--danger)"/></span>
                                    <span className="pb-1">Delete</span>
                                </Button>
                            </Grid>

                            <Grid item sm={4} className={classes.root}>
                                <Button variant="outlined" color="primary" className="border-0 w-100 d-block"  onClick={(event) => {handleAnalytics(props.id)}}>
                                    <span className="font-size-xxl d-block mb-1"><MdPodium fontSize="28px" color="var(--info)"/></span>
                                    <span className="pb-1">Analytics </span>
                                </Button>
                            </Grid>
                            {permission.isForm && (
                                <Grid item sm={4} className={classes.root}>
                                    <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleForm(props.id)}}>
                                        <span className="font-size-xxl d-block mb-1"><IosListBoxOutline fontSize="28px" color="var(--info)"/></span>
                                        <span className="pb-1">Form </span>
                                    </Button>
                                </Grid>
                            )}
                            <Grid item sm={4} className={classes.root}>
                                    <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleQuota(props.id)}}>
                                        <span className="font-size-xxl d-block mb-1"><IosInformationCircleOutline fontSize="28px" color="var(--info)"/></span>
                                        <span className="pb-1">Quota </span>
                                    </Button>
                            </Grid>
                        </Grid>
                }
                </div>
            </Menu>
        </Fragment>
    )


}