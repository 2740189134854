import React, { useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import VideoElement from './VideoElement';
import VideoElementFull from './VideoElementFull';
import { IconButton, Typography , Paper ,Box} from '@material-ui/core';
import { MenuOpen, Close } from '@material-ui/icons';
import Fade from '@material-ui/core/Fade';
import '../../Manage/Manage.css'
const VideoList = ({
  list,
  openVideo,
  expand, 
  toggleExpansion
}) => {


  return (

    <Paper
      className={clsx('app-sidebar-wrapper', {
        'app-sidebar-wrapper-close': false,
        'app-sidebar-wrapper-open': true,
        'app-sidebar-wrapper-fixed': false
      })}
      square
      open={true}
      elevation={3}>
      <div
        className={clsx({
          'app-sidebar-menu': true,
          'app-sidebar-collapsed': false
        })}>
       <div className="side-overflow">
          <Box bgcolor="#fof6fc" p={0} m={0}>
           <IconButton
          style={{ float: 'left' }}
          aria-label="toggle expansion"
          onClick={toggleExpansion}
        >
          {expand ? (
            <Close />
          ) : (
            <MenuOpen />
          )}
        </IconButton>
        {!expand ?
        <>
              {list.map((e, i) => (
              <VideoElement
                key={i}
                expand={expand}
                openVideo={openVideo}
                data={e}
              />
            ))}
            </> :
            <>
            {list.map((e, i) => (
            <VideoElementFull
              key={i}
              expand={expand}
              openVideo={openVideo}
              data={e}
            />
            ))}
            </>
        }
          </Box>
        </div>
      </div>
    </Paper>
  );
};

export default VideoList;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: calc(125vh - 20px);
  .list {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    overflow-y: auto;
    padding: 10px;
    z-index: 10;
    border-radius: 5px;

    background-color: #fafafa;
    box-shadow: 0px 0px 5px 2px #eee;
    text-align: right;
    transition: all .5s ease;
    &.contract {
      right: 0px;
    }
    &.expand {
      width: calc(100vw - 200px);

    }
  }
`;

