import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import {
  withStyles,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import { SENTIMENTS } from './constant';
import UserList from './userList';
import RespondentData from './respondentData';
import { Survey } from './../../../Store/ApplicationStore';

const CONTENT_HEIGHT = 240;

const COLORS = Object.keys(SENTIMENTS).sort().map((e) => SENTIMENTS[e].color);

const CumulativeChartOptions = {
  chart: {
    id: 'cumulativeEmotion',
    type: 'area',
    height: CONTENT_HEIGHT,
    zoom: {
      enabled: false
    },
    stacked: true,
    toolbar: {
      show: false
    }
  },
  colors: COLORS,
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  fill: {
    type: 'gradient',
    gradient: {
      opacityFrom: 0.6,
      opacityTo: 0.8,
    }
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left'
  },
  xaxis: {
    type: 'numeric',
    labels: {
      formatter: function(e) {
          return parseInt(e / 60) + ":" + parseInt(e % 60)
      }
    }
  },
  grid: {
    borderColor: '#f1f1f1',
  }
};

const StyledCard = withStyles({
  root: {
    backgroundColor: '#eaeaea'
  }
})(Card);

const structureCumulativeChartInfo = (emotionArr) => {
  const baseData = {};
  const baseEntry = {};
  Object.keys(SENTIMENTS).forEach((e) => {
    baseData[e] = {
      name: SENTIMENTS[e].text,
      data: []
    };
    baseEntry[e] = 0;
  });

  Object.keys(emotionArr).forEach((time) => {
    const base = {
      ...baseEntry
    };
    Object.keys(emotionArr[time]).forEach((sentiment) => {
      base[sentiment.toLowerCase()] = emotionArr[time][sentiment];
    });
    Object.keys(base).forEach((entry) => {
      baseData[entry].data.push({
        x: parseInt(time, 10) - 1,
        y: base[entry]
      });
    });
  });

  const arrayConvert = [];
  Object.keys(baseData).sort().forEach((e) => {
    arrayConvert.push(baseData[e]);
  });
  return arrayConvert;
};

const FacialDashboard = ({
  surveyId,
  questionId,
  questionText
}) => {
  const [facialInfo, setFacialInfo] = useState({
    url: '',
    responseList: [],
    cumulativeData: [],
    loading: true
  });
  const [selected, setSelected] = useState('');

  const fetchFacialData = async (payload) => {
    try {
      const response = await Survey.getAdSurveyDashboard(payload);
      if (response.status === 'success') {
        const cumulativeData = structureCumulativeChartInfo(response.videoExpression);
        setFacialInfo({
          url: response.questionUrl,
          responseList: response.userList,
          cumulativeData,
          loading: false
        });
      }
    } catch (err) {

    }
  };

  useEffect(() => {
    if (surveyId && questionId) {
      fetchFacialData({
        batchId: surveyId,
        questionId,
        videoId: 0
      });
    }
  }, [surveyId, questionId]);
  
  return (
    <>
      <Grid container spacing={2}>
        {questionText && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {questionText}
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <StyledCard>
            <CardHeader
              title="Main Video"
              titleTypographyProps={{
                variant: 'h4'
              }}
            />
            <CardContent>
              <VideoNode
                src={facialInfo.url}
                controls
                controlsList="no-download"
              />
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledCard>
            <CardHeader
              title="Sentiment Count"
              titleTypographyProps={{
                variant: 'h4'
              }}
            />
            <CardContent>
              <Chart
                options={CumulativeChartOptions}
                series={facialInfo.cumulativeData}
                height={CONTENT_HEIGHT}
                type="area"
              />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Response>
        <div className="title">
          <Typography variant="h5" color="inherit">
            Respondent List
          </Typography>
        </div>
        <div className="content">
          <UserList
            list={facialInfo.responseList}
            selected={selected}
            selectUser={setSelected}
          />
          <RespondentData
            batchId={surveyId}
            questionId={questionId}
            videoId={selected}
          />
        </div>
      </Response>
    </>
  );
};

export default FacialDashboard;

const VideoNode = styled.video`
  width: 100%;
  height: ${CONTENT_HEIGHT + 10}px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #000;
`;

const Response = styled.div`
  display: block;
  margin: 20px 0px;
  border-top: 2px solid #888;
  max-height: calc(100vh - 80px);
  .title {
    border-bottom: 1px solid #ddd;
    padding: 10px 20px;
    background-color: #3d497790;
  }
  .content {
    display: flex;
  }
`;