import React, { Component, Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tab, Box, Typography, Tabs, AppBar, List, ListItem, ListItemAvatar, Avatar,
    CircularProgress,Grid, ListItemText, ListItemSecondaryAction, IconButton, ButtonGroup, Divider } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import FlipCameraIos from '@material-ui/icons/FlipCameraIos';
import Camera from '@material-ui/icons/Camera';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ThemeProvider } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import supportedMimeTypes from './util';
import Alert from '@material-ui/lab/Alert';
import './App.css';

function makeid() {
    return Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000
}

class VideoCreate extends Component {
    render() {
        return (
            <Typography>User can answer through a Camera or can upload a File</Typography>
        )
    }
}

const VideoRender = (props) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }
      
    function hasUserMedia() { 
        //check if the browser supports the WebRTC 
        return !!(navigator.getUserMedia || navigator.webkitGetUserMedia || 
           navigator.mozGetUserMedia); 
     }
       
    return (
        (
            (iOS()) ? 
                <div>
                    <Typography> Use Chrome / Firefox or Please Share a Video Recording  </Typography>
                    <FileUpload {...props} />
                </div>
                :
                (!hasUserMedia() ? 
                <div>
                    <Typography>Use Chrome / Firefox or Please Share a Video Recording </Typography>
                    <FileUpload {...props} />
                </div>
                :
                <div >
                    <ThemeProvider theme={props.theme.pallet}>
                    <AppBar position="static" color={props.theme.color} >
                        <Tabs value={value} onChange={handleChange} aria-label="Video tabs">
                            <Tab label="Record Video" {...a11yProps(0)} />
                            <Tab label="Share video Recording" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <VideoRecorderWidget {...props} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <FileUpload {...props} />
                    </TabPanel>
                    </ThemeProvider>
                </div>
            )
        )
    );
}

class VideoRecorderWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stream: "",
            source: "",
            constraint: {
                video: {
                    width: { min: 640, ideal: 1280 },
                    height: { min: 400, ideal: 720 },
                    facingMode: 'user'
                },
                audio: true
            },
            recording: false,
            review: false,
            previewDisable:false,
            webcamReview: false,
            videoInputs: [],
            audioInputs: [],
            started:false,
            progressbar: false,
            isAnswered :false,
            videoSource: false,
            error: false
        }
        this.selectSource = this.selectSource.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.startRecording = this.startRecording.bind(this);
        this.selectFiles = this.selectFiles.bind(this);
        this.shiftCamera = this.shiftCamera(this);
        this.submitAnswer = this.submitAnswer.bind(this);
        this.resetSubmission = this.resetSubmission.bind(this);
        this.previewVideo = this.previewVideo.bind(this);
        this.webcam = React.createRef(null);
        this.uploadFile = React.createRef(null);
        this.stream = null;
        this.recording = null;
        this.data = [];
        this.file = null;
      
    }

    async componentDidMount() {
        let inputDevices = [];
        try {
            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                inputDevices = await navigator.mediaDevices.enumerateDevices();
            }
        }
        catch (err) { }
        let videoDevices = [], audioDevices = [];
        inputDevices.forEach((e) => {
            if (e.kind == "videoinput") {
                videoDevices.push(e);
            }
            else if (e.kind == "audioinput") {
                audioDevices.push(e);
            }
        })
        this.setState({
            videoInputs: videoDevices,
            audioInputs: audioDevices
        })

        this.selectSource('webcam')
    }

    componentWillUnmount() {
        try {
            if (this.stream) {
                let tracks = this.stream.getTracks();
                tracks.forEach(function (track) {
                    track.stop();
                });
            }
            if (this.webcam?.current?.srcObject) {
                this.webcam.current.srcObject = null;
            }
            this.stream = null;
        } catch (err) {}
    }

    shiftCamera() {
        if (this.state.recording || this.state.videoInputs.length <= 1) {
        }
        else {
            this.setState((state, props) => {
                let cons = Object.assign({}, state.constraint);
                cons.video.facingMode = cons.video.facingMode == "user" ? "environment" : "user";
                return {
                    constraint: cons
                }
            })
        }
    }

    async selectSource(source) {
        if (source == "webcam") {
            if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                try {
                    let stream = await navigator.mediaDevices.getUserMedia(this.state.constraint);
                    this.webcam.current.srcObject = stream;
                    this.stream = stream;
                    this.setState({
                        videoSource: true,
                        error: false
                    });
                }
                catch (err) {
                    let error = 'Permission denied to capture video/audio from browser. Please allow the permission to submit the answer.';
                    this.props.logError({
                        error,
                        errorObject: err
                    });
                    this.setState({
                        videoSource: false,
                        error
                    });
                }
            } else {
                let error = 'Your browser does not support the latest features. Please use Chrome/Firefox to fill the survey. In case you are using Chrome/Firefox, please update your browser.';
                this.props.logError({
                    error,
                    errorObject: {}
                });
                this.setState({
                    error
                });
            }
        }
        else {
            if (this.stream) {
                let tracks = this.stream.getTracks();
                tracks.forEach(function (track) {
                    track.stop();
                });
                this.webcam.current.srcObject = null;
                this.stream = null;
            }
            this.setState({
                videoSource: false
            });
            this.uploadFile.current.click();
        }
        this.setState({
            source: source == "webcam" ? "webcam" : this.state.source,
            review: false
        })
    }

    async startRecording() {
        if (this.webcam.current.srcObject === null) {
            try {
                await this.selectSource('webcam');
            }
            catch (err) { }
        }
        this.file = null;
        // this.recording = new MediaRecorder(this.stream, {
        //     mimeType: this.state.mimeType.mimeType
        // });
        try {
            if (isMobile) {
                this.recording = new MediaRecorder(this.stream);
            } else {
                this.recording = new MediaRecorder(this.stream, {
                    mimeType: supportedMimeTypes[0]
                });
            }
            this.data = [];
            this.recording.ondataavailable = (event) => {
                this.data.push(event.data);
            };
            this.recording.start();
            this.setState({
                recording: true,
                review: false,
                webcamReview: false,
                previewDisable:false,
                error: false
            })
        } catch (err) {
            let error = 'Browser does not support recording media.';
            this.props.logError({
                error,
                errorObject: {}
            });
            this.setState({
                error
            });
        }
    }

    stopRecording() {
        this.recording.onstop = (event) => {
            let fileType = supportedMimeTypes[0];
            if (isMobile) {
                fileType = this.recording.mimeType;
            }
            if (fileType.indexOf(';') >= 0) {
                fileType = fileType.split(';')[0];
            }
            this.file = new Blob(this.data, { type: fileType });
            // if (this.state.mimeType == 'video/mp4'){
            //     this.file = new Blob(this.data, { type: 'video/mp4' });
            // }
            // else{
            //     this.file = new Blob(this.data, { type: 'video/webm' })
            // }
        }
        this.recording.stop();
        this.setState({
            recording: false,
            webcamReview: true,
            previewDisable:true
        })
        this.state.started = true
    }

    previewVideo() {
        if (this.stream) {
            let tracks = this.stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            this.webcam.current.srcObject = null;
            this.stream = null;
            if (this.file !== null){
                this.webcam.current.children[0].src = URL.createObjectURL(this.file);
                this.webcam.current.load();
            }  
        }
        this.setState({
            review: true,
            webcamReview: false
        })
    }

    selectFiles() {
        if (this.uploadFile.current.files.length > 0 && this.uploadFile.current.files[0].name) {
            this.file = new File([this.uploadFile.current.files[0]], this.uploadFile.current.files[0].name, { type: this.uploadFile.current.files[0].type });
            this.webcam.current.children[0].src = URL.createObjectURL(this.file);
            this.webcam.current.load();
            this.setState({
                source: "file",
                review: true
            });
        }
        else {
            this.resetSubmission();
        }
    }

    submitAnswer() {
        if (this.file == null){
            this.setState({
                isAnswered: true
            })
        }
        else{
            this.setState({
                review: false,
                source: "",
                progressbar: true,
                isAnswered: false
            });
            this.file.lastModifiedDate = new Date();
            this.file.name = makeid() + '.webm';
            this.props.submit({
                files: [this.file]
            });
        }
    }

    resetSubmission() {
        if (this.stream) {
            let tracks = this.stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            this.webcam.current.srcObject = null;
            this.stream = null;
        }
        this.setState({
            recording: false,
            review: false,
            source: ""
        });
        this.file = null;
    }

    render() {
        return (
            <Fragment>
                {/* <div className="d-block w-100"> */}
                {this.state.error ? (
                    <div className="card-body justify-content-between">
                        {this.state.error}
                    </div>
                ) : (
                    <div className="card-body justify-content-between">
                        <Grid container spacing={1} justify="center">
                            <Grid item xs={12} lg={12} >
                                <Grid container justify="center">
                                    <Grid item>
                                        <video className="videoContainer" autoPlay={!this.state.review} 
                                            ref={this.webcam} muted={!this.state.review} 
                                            controls={this.state.review} controlsList="nodownload" 
                                            style={{maxWidth: '800px', maxHeight: '700px'}}>
                                            <source />
                                        </video>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {this.state.videoSource && (
                                <Grid item xs={12} lg={12} >
                                    <Grid container justify="center">
                                        <Grid item>
                                            <div className="d-flex align-content-center align-item-center">
                                                <ButtonGroup variant={this.props.theme.buttonVariant} color={this.props.previewColor} className="d-none d-md-block">
                                                    {this.state.recording ? (
                                                        <Button startIcon={<StopIcon color="error"/>} onClick={this.stopRecording}>Stop Recording</Button>
                                                    ) : (
                                                        <Button startIcon={<Camera  color="error"/>} onClick={this.startRecording}>Start Recording</Button>
                                                    )}
                                                    <Button color={this.state.started?  "default" : this.props.theme.color} startIcon={<VisibilityIcon />} onClick={this.previewVideo}>Preview</Button>
                                                    <Button startIcon={<FlipCameraIos />} onClick={this.shiftCamera}  color={ this.props.theme.color}>Camera</Button>
                                                </ButtonGroup>
                                                <div className="d-block mt-1 mb-1 ml-4 mr-4 pt-1" >
                                                    <Divider style={this.state.recording ? {backgroundColor: 'var(--danger)'} : {}} />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {this.state.videoSource && (
                            <div className="d-flex d-md-none justify-content-around w-100">
                                {this.state.recording ? (
                                    <Button color={this.props.previewColor} onClick={this.stopRecording} variant="contained">
                                        <StopIcon fontSize="large" color="error"/>
                                            Stop Recording
                                    </Button>
                                ) : (
                                    <Button onClick={this.startRecording} color={this.props.theme.color} variant="contained">
                                        <Camera fontSize="large" color="error" />
                                        Start Recording
                                    </Button>
                                )}
                                <IconButton color={this.state.started?  "default" : this.props.theme.color} onClick={this.previewVideo} disabled={!this.state.previewDisable}>
                                    <VisibilityIcon fontSize="large" />
                                </IconButton>
                               
                                {/* <IconButton color={this.props.previewColor} onClick={this.shiftCamera}>
                                    <FlipCameraIos fontSize="large" />
                                        Switch
                                </IconButton>  */}
                            </div>
                        )}
                    </div>
                )}
                <Grid container spacing={1} justify="center">
                    <Grid item xs={12} lg={12} md={12} >
                        <p  style={{ textAlign:"center", fontSize: "12px", color: this.props.theme.fontColor, fontFamily: this.props.theme.fontFamily}}>
                        Select a quiet location. Position the camera in a way that your face is entirely visible within the frame.<br/> 
                        Make sure there is proper/sufficient lighting and avoid backlighting. <br/>
                        </p>
                    </Grid>
                </Grid>
                {this.state.isAnswered ? <div>
                    <Alert severity="error">Please Answer </Alert>
                </div> : null}
                <div className="d-block mt-2">
                    {this.props.preview ? null : (
                        <Button className={this.file ? "button save m-1" : "button save Disable m -1"} disabled={this.state.progressbar || this.state.recording} variant={this.props.theme.buttonVariant} color={this.props.theme.color}  onClick={this.submitAnswer}>Submit</Button>
                    )}
                        {this.props.preview ? null : (<Button variant={this.props.theme.buttonVariant} color={this.props.theme.color}  className="button cancel m-1" onClick={this.resetSubmission}>Cancel</Button> 
                    )}
                     { this.state.progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
                </div>
            </Fragment>
        )
    }
}

const FileUpload = (props) => {
    let fileUpload = useRef();
    const [file, setFile] = useState([]);
    const [progressbar, setProgressbar] = useState(false)
    const [isAnswered,setIsAnswered] = useState(false)
    const openFileLoader = () => {
        fileUpload.current.click();
    }
    const uploadFiles = (event) => {
        if (fileUpload.current.files.length > 0 && fileUpload.current.files[0].name) {
            let e = fileUpload.current.files[0];
            let substr = (Math.random() + 1).toString(36).substring(7);
            let fileName = substr + e.name.replace(/\s+/g,"_");
            let newFile = new File([e], fileName, { type: e.type });
            setFile([newFile]);
        }
    }
    const removeFile = () => {
        setFile([]);
    }

    const submitFile = () => {
        if (file.length === 0 )
        {
            setIsAnswered(true)
        }
        else{
            setIsAnswered(false)
            setProgressbar(true)

            props.submit({
                files: file
            });
        } 
    }
    function detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) );
      }

    return (
        <Fragment>
            <List>
                {file.map((e, i) => (
                    <ListItem key={i}>
                    <ListItemAvatar>
                        <Avatar>
                            <DescriptionIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={e.name}
                    />
                    <ListItemSecondaryAction>
                        <IconButton color={props.theme.color} edge="end" aria-label="delete" onClick={removeFile}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            {detectMob() ? 
            <Fragment>
            <p>Click to open Camera</p>
            <Button size="large" variant={props.theme.buttonVariant} color={props.theme.color} component="label" endIcon={<Camera />} onClick={openFileLoader} >
            camera
            </Button>
            </Fragment>
            :
            <Button size="large" variant={props.theme.buttonVariant} color={props.theme.color} component="label" endIcon={<CloudUploadIcon />} onClick={openFileLoader} >
            Share Video
            </Button>
            }   
            <input ref={fileUpload} type="file" accept="video/mp4,video/x-m4v,video/*" onChange={uploadFiles} style={{display:"none"}}/>
            {isAnswered ? <div>
                <Alert severity="error">Please Answer </Alert>
            </div> : null}
            <div className="d-block pt-4">
                <Button color={props.theme.color} variant={props.theme.buttonVariant} onClick={submitFile} disabled={file.length == 0 || progressbar} >Submit</Button>
                { progressbar ? <CircularProgress  style={{marginBottom:'-15px', marginLeft:'15px'}}/>: null}
            </div>
            
        </Fragment>
    )
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const VideoValidate = [];


export { VideoCreate, VideoRender, VideoValidate }