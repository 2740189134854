import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  FormGroup,
  Switch,
  withStyles,
  FormControlLabel,
  IconButton, Button
} from '@material-ui/core';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';

import { IoReloadSharp ,IoReorderThreeSharp } from "react-icons/io5";
import {
  POSITIVE_COLOR,
  NEGATIVE_COLOR,
  NEUTRAL_COLOR
} from './constants';


const PositiveSwitch = withStyles({
  switchBase: {
    color: POSITIVE_COLOR,
    '&$checked': {
      color: POSITIVE_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: POSITIVE_COLOR,
    },
  },
  checked: {},
  track: {},
})(Switch);

const NegativeSwitch = withStyles({
  switchBase: {
    color: NEGATIVE_COLOR,
    '&$checked': {
      color: NEGATIVE_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: NEGATIVE_COLOR,
    },
  },
  checked: {},
  track: {},
})(Switch);

const NeutralSwitch = withStyles({
  switchBase: {
    color: NEUTRAL_COLOR,
    '&$checked': {
      color: NEUTRAL_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: NEUTRAL_COLOR,
    },
  },
  checked: {},
  track: {},
})(Switch);

const Aspect = ({
  positive,
  negative,
  neutral,
  setFilter
}) => {
  const [types, setTypes] = useState({
    positive,
    negative,
    neutral
  });
  const [hideShow, setHideShow] = useState(true);

  const handleHideShowChange = () => {
    setHideShow((prev) => !prev);
  };


  const resetFilter = () => {
    setTypes({
      positive: true,
      negative: true,
      neutral: true
    });
    setFilter([]);
  };

  const updateFilter = (event) => {
    const newTypes = {
      ...types,
      [event.target.name]: event.target.checked
    };
    setTypes(newTypes);
    let filter = [];
    Object.keys(newTypes).forEach((e) => {
      if (newTypes[e]) {
        filter.push(e);
      }
    });
    if (filter.length === 3) {
      filter = [];
    }
    setFilter(filter);
  };

  return (
<>
<div className="m-2 p-2">
    <div className={ clsx({"card-header pr-2 align-box-row w-100": true, "border-bottom":hideShow })} style={{padding:' 0rem 1.25rem'}}>
          <div className="card-header--title  font-size-md font-weight-bold align-box-row w-100" >
          Aspect
          </div>

          <div className="ml-auto align-self-center">
          <IconButton
          
            color="secondary"
            aria-label="refresh"
            onClick={resetFilter}
            disabled={positive && negative && neutral}
          >
            <IoReloadSharp />
          </IconButton>
          </div>
          <div className="ml-auto align-self-center">
            <IconButton
              color="secondary"
              aria-label="refresh"
              onClick={handleHideShowChange}
            >
              <IoReorderThreeSharp />
            </IconButton>
          </div>
         
      </div>
      <Collapse in={hideShow}>
        <div className="d-flex opacity-9" style={{maxHeight: '250px'}}>
            <FormGroup row={false}>
                    <FormControlLabel
                    className="ml-4 mb-0 mt-0"
                      control={
                        <PositiveSwitch
                          checked={types.positive}
                          onChange={updateFilter}
                          name="positive"
                        />
                      }
                      label="Positive"
                    />
                    <FormControlLabel
                    className="ml-4 mb-0 mt-0"
                      control={
                        <NegativeSwitch
                          checked={types.negative}
                          onChange={updateFilter}
                          name="negative"
                        />
                      }
                      label="Negative"
                    />
                    <FormControlLabel
                    className="ml-4 mb-0 mt-0"
                      control={
                        <NeutralSwitch
                          checked={types.neutral}
                          onChange={updateFilter}
                          name="neutral"
                        />
                      }
                      label="Neutral"
                    />
            </FormGroup>
          </div>
      </Collapse>
      </div>
      </>
  );
};

export default Aspect;
