import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  IconButton, Button
} from '@material-ui/core';
import {
  Favorite,
  FavoriteOutlined,
  OpenInBrowser
} from '@material-ui/icons';
import {
  Survey
} from '../../../../Store/ApplicationStore';
import { NEGATIVE_COLOR} from '../constants';
import { IoHeartOutline, IoHeartSharp } from "react-icons/io5";

const useStyles = makeStyles({
  root: {
    height: 244,
    overflowY: 'auto'
  },
  media: {
    height: 180,
   
  },
});

const VideoElementFull = ({
  data,
  openVideo,
  expand
}) => {
  const [showTranscript, setShowTranscript] = useState(expand);
  const [isFavorite, setFavorite] = useState(data.isFavorite);
  const classes = useStyles();

  const toggleFavorite = () => {
    setFavorite((fav) => {
      Survey.toggleFavoriteVideo({
        isFavorite: !fav,
        id: data.id
      });
      return !fav;
    });
  };

  useEffect(() => {
    setShowTranscript(expand);
  }, [expand]);

  return (



    <Grid container spacing={1} justifyContent="center" className="mb-2">
      <Grid item xs={ 3 } md={5 } lg={ 5}>
            <div className="card card-img-wrapper rounded card-box-hover-rise p-4 m-2 align-item-center" style={{ cursor:"pointer"}}>
                <div className="card-badges card-badges-bottom">

                <IconButton
                  aria-label="add to favorites"
                  onClick={toggleFavorite}
                  style={{marginRight: '15px', marginBottom: '10px'}}
                 >
                {isFavorite ? (
                        <IoHeartSharp  style={{color: NEGATIVE_COLOR}}  size="24" />
                      ) : (
                        <IoHeartOutline  style={{color:'white'}} size="24" />
                      )}</IconButton>

                </div> 
                <img alt="..." className="card-img-top rounded" src={data.thumbnail} onClick={(e) => openVideo(data.videoId) } style={{minWidth: '120px', minHeight:'120px', margin:'0 auto'}} />
            </div>
      </Grid>
        <Grid item xs={9} md={7} lg={7}>
          <Card className={classes.root}>
            <CardHeader
              title={`${data.gender === 0 ? 'Female' : 'Male'}, ${data.age} Years`}
              subheader={`${data.city}, ${data.state}, ${data.country}`}
            />
            <CardContent style={{ textAlign: 'left' }}>
              {data.transcript}
            </CardContent>
          </Card>
        </Grid>
    </Grid>
  )
};

export default VideoElementFull;
