import React, { useEffect, useState } from 'react';
import ReactWordCloud from 'react-wordcloud';
import {
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import {
  POSITIVE_COLOR,
  NEGATIVE_COLOR,
  NEUTRAL_COLOR
} from './constants';

const Keywords = ({
  words
}) => {
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    const newWords = words.map(e => ({
      text: e.text,
      value: e.type === 'positive' ? 3 : (e.type === 'negative' ? 2 : 1)
    }));
    setKeywords(newWords);
  }, [words]);

  return (
    <Card className="card-box mb-4">
      <div className="card-header pr-2" style={{padding:' 0.10rem 1.25rem'}}>
          <div className="card-header--title mb-2 font-size-md font-weight-bold pt-3 pb-2" >
          Characteristic
          </div>
      </div>
      <div className="d-flex justify-content-center" style={{maxHeight: '250px'}}>
      <ReactWordCloud
          words={keywords}
          options={{
            fontSizes: [18, 24]
          }}
          callbacks={{
            getWordColor: word => word.value === 3 ? POSITIVE_COLOR : (word.value === 2 ? NEGATIVE_COLOR : NEUTRAL_COLOR),
          }}
        />
      </div>
  </Card>

  );
};

export default Keywords;
