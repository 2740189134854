import React, { useCallback, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton, Box, Tooltip ,FormControl, Checkbox, FormControlLabel
} from '@material-ui/core';
import {percentageConevertor} from './util'
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import { IoReloadSharp ,IoReorderThreeSharp } from "react-icons/io5";
import { BAR_COLOR, NEUTRAL_COLOR , WOMAN_COLOR ,MAN_COLOR} from './constants';

const AgeChart = ({
  ageData,
  filters,
  setFilter,
  isChart, totalCount
}) => {
  const categories = [];
  const values = [];
  const colors = [];

  const [hideShow, setHideShow] = useState(true);

  const handleHideShowChange = () => {
    setHideShow((prev) => !prev);
  };

  ageData.forEach((e) => {
    categories.push(e.label);
    values.push(percentageConevertor(e.count, totalCount));
    if (filters.length === 0 || filters.indexOf(e.label) >= 0) {
      colors.push(BAR_COLOR);
    } else {
      colors.push(NEUTRAL_COLOR);
    }
  });

  const clickEvent = (event, chartContext, config) => {
    if (!(config.dataPointIndex < 0 || config.dataPointIndex >= categories.length)) {
      const clickedLabel = categories[config.dataPointIndex];
      const index = filters.indexOf(clickedLabel);
      let newFilters = [...filters];
      if (index < 0) {
        if (newFilters.length === categories.length - 1) {
          newFilters = [];
        } else {
          newFilters.push(clickedLabel);
        }
      } else {
        newFilters.splice(index, 1);
      }
      setFilter(newFilters);  
    }
  };

  const handleChange = (event) => {
    let value = [...filters ]
    if (value.includes(event.target.name))
    {
      value = value.filter(function(item) {
        return item !== event.target.name
      })
    }else{
      value.push(event.target.name)
    }
    setFilter(value);
  };

  const options = useMemo(() => ({
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      },
      events: {
        click: clickEvent
      }
    },
   
    plotOptions: {
      bar: {
        vertical: true
      }
    },

    fill: {
      colors: [WOMAN_COLOR]
    },

    xaxis: {
      categories: categories,
      labels: {
        rotate: -20,
        rotateAlways: false,
        offsetX: 5
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
          return val + ' %'
      },
      textAnchor: 'middle',
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: '',
          colors: ['white']
      }
    }
  }), [filters]);

  const series = [
    {
      name: '',
      data: values
    }
  ];

  return (
    <>
    { !isChart ?
    <div className="m-2 p-2">
   <div className={ clsx({"card-header pr-2 align-box-row w-100": true, "border-bottom":hideShow })} style={{padding:' 0rem 1.25rem'}}>
          <div className="card-header--title  font-size-md font-weight-bold align-box-row w-100" >
          Age
          </div>
          <div className="ml-auto align-self-center">
              <IconButton
                color="primary"
                aria-label="refresh"
                onClick={() => setFilter([])}
                disabled={filters.length === 0}
              >
                <IoReloadSharp />
            </IconButton>
          </div>
          <div className="ml-auto align-self-center">
            <IconButton
              color="secondary"
              aria-label="refresh"
              onClick={handleHideShowChange}
            >
              <IoReorderThreeSharp />
            </IconButton>
          </div>
      </div>
      <Collapse in={hideShow}>
        <div className="d-flex opacity-9" >
            <FormControl className="d-flex" >
              {Object.entries(ageData).map((key ,index) => (
              <FormControlLabel key={key[0]}
                className="ml-4 mb-0 mt-0"
                  control={
                    <Checkbox
                      checked={filters.includes(key[1]['label'])}
                      onChange={handleChange}
                      name={key[1]['label']}
                      color="secondary"
                    />
                  }
                  label={key[1]['label'] +' years'}
                />
                ))}
              </FormControl>
          </div>
      </Collapse>
      </div>
      :
    <Card className="card-box mb-4">
      <div className="card-header pr-2" style={{padding:' 0.25rem 1.25rem'}}>
          <div className="card-header--title mb-0 font-size-md font-weight-bold">
              Age
          </div>
          <Box className="card-header--actions">
              <IconButton
                color="primary"
                aria-label="refresh"
                onClick={() => setFilter([])}
                disabled={filters.length === 0}
              >
                <IoReloadSharp />
            </IconButton>
          </Box>
      </div>

    <Chart options={options} series={series} height='200px' type="bar"  />

  </Card>
    
}
</>
  );
};

export default AgeChart;
