import React, { lazy, Suspense, Fragment, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation , useHistory} from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { ClimbingBoxLoader } from 'react-spinners';

import MuiTheme from './theme';
import { Authorization } from './Store/ApplicationStore';
// Layout Blueprints

import {
  PageWrapper,
  MinimalLayout
} from './layout-blueprints';

import LandingPage from './containers/LandingPage/LandingPage';
import ManageSurvey from './containers/Survey/Manage/Manage';
import RegisterLogin from './containers/Authentication/Register/index';
import Dashboard from './containers/Survey/Dashboard';
import FormDashboard from './containers/Survey/FormDashboard';
import TranscriptDashboard from './containers/Survey/TranscriptDashboard';
import FaceAnalysisDashboard from './containers/Survey/FaceAnalysisDashboard';
import AiAnalysisDashboard from './containers/Survey/VideoAiDashboard';
import QuotaDashboard from './containers/Survey/QuotaDashboard'

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Routes = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [pathname, setRedirect] = useState('');
  const [userName, setUserName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [permission, setPermission] = useState({
    isDownload: true,
    isForm: true,
    isAiAnalysis: true,
    isBrand: false,
    isAgnecy: false
    
  });
  const urlQuery = useQuery();

  const getUserPermission = async () => {
    const per = await Authorization.getPermission();
    setPermission(per);
  };

  useEffect(() => {
    const redirect = location.pathname.indexOf('Home') >= 0 || location.pathname.indexOf('Manage') >= 0 || location.pathname.indexOf('Dashboard') >= 0 ? location.pathname + location.search : '/Home';
    setRedirect(redirect);
    if (!isAuthenticated){
      Authorization.isAuthenticated().then(async res =>{
        if(res){
          setIsAuthenticated(res.auth);
          setUserName(res.userName);
          await getUserPermission();
          history.replace(redirect);
        }
      })
    }
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };
  
  const login = async (email, password) =>{
    try {
      let result = await Authorization.login(email, password);
      if (result.success) {
        setIsAuthenticated(true);
        setIsValidLogin(true)
        let out = await Authorization.getUserDetails();
        await getUserPermission();
        setUserName(out.userName)
        history.replace(pathname);
      }else{
        setIsValidLogin(false)
      }
      
    }
    catch(err) {
      console.log(err);
    }
  }
  const logout= async () =>{
    try {
      await Authorization.logout();
    }
    catch(err) {}
    setIsAuthenticated(false)
    history.replace('/Login');
  } 

  const signup = async (name,  email, password) =>{
    try {
      await Authorization.register(name, null, null, email, password, null, 0, 0); 
      history.push('/Login');
    }
    catch(err) {
    }
  }

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait 
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
          {!isAuthenticated ? (
            <Fragment>
            {/* <Redirect exact from="/Login" to="/Login" /> */}
              <Route exact path="/">
                <Redirect to="/Login" />
              </Route>
              <Route path={['/Login', '/Register']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Login" >
                        <RegisterLogin  login={login} register={signup} tab={0} isValidLogin={isValidLogin} showSignUp={true}/>
                      </Route>
                      <Route path="/Register" >
                        <RegisterLogin login={login} register={signup} tab={1} isValidLogin={isValidLogin} showSignUp={true}/>
                      </Route>
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>
          </Fragment>
          ):(
            <Fragment>
              <Route path={['/Home', '/Manage', '/Dashboard',
              '/FormDashboard','/TranscriptDashboard','/FaceAnalysisDashboard', '/AiAnalysisDashboard','/QuotaDashboard']}>
                <PageWrapper userName={userName} logout={logout}>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      style={{ height: '100%' }}
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Home" >
                        <LandingPage logout={logout} isOperation={false}/>
                        </Route>
                      <Route path="/Manage"  >
                        <ManageSurvey logout={logout} isOperation={false} permission={permission}/>
                      </Route>
                      <Route path="/Dashboard"  >
                        <Dashboard  permission={permission}/>
                      </Route>
                      <Route path="/FormDashboard" >
                        {permission.isForm ? <FormDashboard isOperation={false}/> : <></>}
                      </Route>
                      <Route path="/TranscriptDashboard" >
                        <TranscriptDashboard isOperation={false} />
                      </Route>
                      <Route path="/FaceAnalysisDashboard" >
                        <FaceAnalysisDashboard surveyId={parseInt(urlQuery.get('batchId'))} questionId={parseInt(urlQuery.get('questionId'))} questionText={decodeURI(urlQuery.get('question') || '')} />
                      </Route>
                      <Route path="/AiAnalysisDashboard" >
                        <AiAnalysisDashboard surveyId={urlQuery.get('batchId')} questionId={urlQuery.get('questionId')} questionText={decodeURI(urlQuery.get('question') || '')} />
                      </Route>
                      <Route path="/QuotaDashboard"  >
                        <QuotaDashboard isOperation={false} permission={permission}/>
                      </Route>
                    </motion.div>
                  </Switch>
                </PageWrapper>
              </Route>
            </Fragment>
          )}
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;