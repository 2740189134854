import React, { Fragment } from 'react';
import Highlighter from 'react-highlight-words';
import { TextField, MenuItem, Card, Grid } from '@material-ui/core';
import { Button as MTButton} from '@material-ui/core';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Survey ,Authorization} from 'Store/ApplicationStore';
import CreateDialog from './dialog'
import AIAnalysisDialog from '../Dashboard/Filters/dialogAi';
import {Route} from 'react-router-dom'
import { Table } from "ant-table-extensions";

class FormDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.isOperation = props.isOperation
        const queryString = window.location.search;
        this.urlParams = new URLSearchParams(queryString);
        this.id = this.urlParams.get('id');
        this.state = {
            searchText: '',
            searchedColumn: '',
            data :[],
            title:'',
            columns:[ {
                title: 'id',
                dataIndex: 'id',
                width: 150,
                height: 200,
              }],
            dropDownData:[ "Reject", "Done"],
            operationDropDown:["Link Only", "Incomplete", "QC-Verify", "OP-Reject", "OP-Done"],
            rejectReasonData:["irrelevant answers", "answer is scripted", "no voice", "bad video quality", 
            "bad audio quality", "reponse is repeated", "same video repeated" ,"respondent is under age" ,
            "bad answer", "Age mismatch", 
            "SEC Education wrong","SEC Occupation wrong",
            "SEC Durable ownership wrong","Panel profile not correct",
            "Panel Age not correct","location wrong"],
            languages :[
                'English (United States)',
                'Hindi (India)',
                'Telugu (India)',
                'Kannada (India)',
                'Malayalam (India)',
                'Marathi (India)',
                'Tamil (India)',
                'German (Germany)',
                'Chinese, Mandarin',
                'Czech (Czech Republic)',
                'Danish (Denmark)',
                'French (France)',
                'Italian (Italy)',
                'Japanese (Japan)',
                'Portuguese (Brazil)',
                'Spanish (Spain)',
                'Turkish (Turkey)',
                'Malay (Malaysia)',
                'Manual Transcript'
              ],
            overAllStatus:{},
            modal:false,
            isDelete:false,
            permission: {
                isDownload: true,
                isForm: true,
                isAiAnalysis: true,
                isBrand: false,
                isAgnecy: false,
                isVendor: false,
              }
        };
        }
    
    
    handleModelClose=(e , value)=>{
        e.preventDefault()
        this.setState({
            modal : false,
            isDelete: value
        })
        
    }

    
    
    handleComponent=(event,name)=>{
        let id = name.props.id
        let value = event.target.value
        let data = this.state.data
        let dataLen = data.length
        for (let i = 0; i < dataLen; i++) {
            if (data[i].hasOwnProperty('status') && data[i]['id'] == id)
            {
                data[i]['status'] = [value, id]
            }
        }
        let obj ={
            "anonymousUserId": id,
            "batchId": this.id,
            "status": value,
            "updateType": 'final'
          }

        if (['delete', 'remove'].includes(value.toLowerCase())){
            let out = window.confirm("Do you really want to delete?")
                if (out === true){
                Survey.UpdateFormDashboardData(obj).then((output) => {
                if (output.status === "success"){
                        let dataOut = data.filter((item) => item.survey_id !== id)
                        this.setState({
                            data : dataOut
                        })
                }
            })
            }
        }
        else{
            this.setState({
                data : data
            })
            Survey.UpdateFormDashboardData(obj).then((output) => {
               
            })
        }
        
    }



    handleComponentOperation=(event,name)=>{
        let id = name.props.id
        let value = event.target.value
        let data = this.state.data
        let dataLen = data.length
        for (let i = 0; i < dataLen; i++) {
            if (data[i].hasOwnProperty('operationStatus') && data[i]['id'] == id)
            {
                data[i]['operationStatus'] = [value, id]
            }
        }
        let obj ={
            "anonymousUserId": id,
            "batchId": this.id,
            "status": value,
            "updateType": 'operation'
          }
        this.setState({
            data : data
        })
        Survey.UpdateFormDashboardData(obj).then((output) => {
        }) 
    }

    handleComponentRejcetReason=(event,name)=>{
        let id = name.props.id
        let value = event.target.value
        let data = this.state.data
        let dataLen = data.length
        for (let i = 0; i < dataLen; i++) {
            if (data[i].hasOwnProperty('rejectReason') && data[i]['id'] == id)
            {
                data[i]['rejectReason'] = [value, id]
            }
        }
        let obj ={
            "anonymousUserId": id,
            "batchId": this.id,
            "status": value,
            "updateType": 'rejectReason'
          }
        this.setState({
            data : data
        })
        Survey.UpdateFormDashboardData(obj).then((output) => {
        }) 
    }

    handleComponenLangague=(event,name)=>{
        let id = name.props.id
        let value = event.target.value
        let data = this.state.data
        let dataLen = data.length
        for (let i = 0; i < dataLen; i++) {
            if (data[i].hasOwnProperty('language') && data[i]['id'] == id)
            {
                data[i]['language'] = [value, id]
            }
        }
        let obj ={
            "anonymousUserId": id,
            "batchId": this.id,
            "status": value,
            "updateType": 'language'
          }
        this.setState({
            data : data
        })
        Survey.UpdateFormDashboardData(obj).then((output) => {
        }) 
    }


    fetchPermissions = async () => {
        const per = await Authorization.getPermission();
        this.setState({
          permission: per
        });
      }

    anlysisRoute = (e ,history)=>{
        e.preventDefault()
        history.push("/Dashboard?id="+ this.id) 
      }
    
    transcriptRouter = (e ,history)=>{
        e.preventDefault()
        history.push({
            pathname: '/TranscriptDashboard',
            search: '?id='+this.id + '&questionId=' + 0
        });
    }

    quotaRouter = (e ,history)=>{
        e.preventDefault()
        history.push({
            pathname: '/QuotaDashboard',
            search: '?id='+this.id 
        });
    }
    

    statusBar (){
        return (
            <Fragment>
 
                <Grid container spacing={0} justifyContent="center" >
                <Grid item xs={1} lg={2} md={3} >
                <h5>{this.state.title}</h5>
                </Grid>
                <Grid item xs={6} lg={5} md={4} >
                        <Card className="card-box mb-1 mt-1 d-flex flex-row flex-wrap justify-content-center">
                                
                            {
                                Object.keys(this.state.overAllStatus).map((key, index) => (
                                <div className="py-2 px-2 d-flex align-items-center" key={index}>
                                    <div>
                                        <span className="d-block opacity-7">{key}</span>
                                        <span className="font-weight-bold font-size-lg ">{this.state.overAllStatus[key]}</span>
                                    </div>
                                </div>
                                ))
                            }
                        </Card>
                    </Grid>
                
                
                     
                    <Grid item xs={2} lg={1} md={1} >
                    {this.isOperation ? 
                    null
                    :
                        <Route render={({ history}) => (
                            <MTButton color="secondary"  variant="contained"
                                style={{marginLeft:'10px'}} onClick={(e) => this.anlysisRoute(e, history)}>
                                Analysis 
                            </MTButton>
                        )} />
                    }
                    </Grid>
                    <Grid item xs={2} lg={1} md={1} >
                    <Route render={({ history}) => (
                        <MTButton color="secondary"  variant="contained"
                            style={{marginLeft:'10px'}} onClick={(e) => this.quotaRouter(e, history)}>
                            Quota 
                        </MTButton>
                    )} /> 
                    </Grid>
                    <Grid item xs={2} lg={1} md={2} >
                       { this.isOperation  ? <Route render={({ history}) => (
                            <MTButton color="secondary"  variant="contained"
                                style={{marginLeft:'10px'}} onClick={(e) => this.transcriptRouter(e, history)}>
                                Transcript 
                            </MTButton>
                        )} /> : this.state.permission.isAiAnalysis ? <AIAnalysisDialog  surveyId={this.id}  /> : null }
                    </Grid>
                    <Grid item xs={2} lg={1} md={1} >
                    {this.isOperation ? <CreateDialog  surveyId={this.id} isOperation={this.isOperation} /> : !this.state.permission.isBrand ?  <CreateDialog  surveyId={this.id} isOperation={this.isOperation} /> : null }
                    </Grid>
                 </Grid>
                
               
            </Fragment>
        )
    
    }
    dropDown(value){
        return(
                <TextField
                    select
                    size="small"
                    value={value[0]}
                    onChange={this.handleComponent}
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                {this.state.dropDownData.map((option ,key) => (
                    <MenuItem  key={value[1]+"_"+key} value={option} id={value[1]}>
                        {option}
                    </MenuItem>
                ))}
                  </TextField>
        )
    }
    dropDownOperation(value){
        return(
                <TextField
                    select
                    size="small"
                    value={value[0]}
                    onChange={this.handleComponentOperation}
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                {this.state.operationDropDown.map((option ,key) => (
                    <MenuItem  key={value[1]+"_"+key} value={option} id={value[1]}>
                        {option}
                    </MenuItem>
                ))}
                  </TextField>
        )
    }
    rejectReasonDropDown(value){
        return(
                <TextField
                    select
                    size="small"
                    value={value[0]}
                    onChange={this.handleComponentRejcetReason}
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                {this.state.rejectReasonData.map((option ,key) => (
                    <MenuItem  key={value[1]+"_"+key} value={option} id={value[1]}>
                        {option}
                    </MenuItem>
                ))}
                  </TextField>
        )
    }
    dropDownLangauge(value){
        return(
                <TextField
                    select
                    size="small"
                    value={value[0]}
                    onChange={this.handleComponenLangague}
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                {this.state.languages.map((option ,key) => (
                    <MenuItem  key={value[1]+"_"+key} value={option} id={value[1]}>
                        {option}
                    </MenuItem>
                ))}
                  </TextField>
        )
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    updateColumnData = (data) =>{
        let dataOut = data
        let dataLen = data.length
        for (let i = 0; i < dataLen; i++) {
            if (data[i].hasOwnProperty('type'))
            {
                
               if(data[i]['type'] == 'audio'){
                    dataOut[i]['render'] = text => text != undefined ? <audio key={text} controls src={text} width={'100%'}/> : '' 
               }
               else if(data[i]['type'] == 'video'){
                    dataOut[i]['render'] = text => text != undefined ? 
                        <video width="200" height="200" controls src={text} /> :'' 
               }
               else if(data[i]['type'] == 'faceEmotion'){
                dataOut[i]['render'] = text => text != undefined ? 
                    <video width="200" height="200" controls src={text} /> :'' 
                }
               else if(data[i]['type'] == 'file'){
                dataOut[i]['render'] = text => text != undefined ? 
                    <Button type="primary" ><a href={text} target="_blank" >view </a></Button>  : ''
               }
               else if (data[i]['type'] == 'matrix'){
                dataOut[i]['render'] = text => text != undefined ? <div style={{maxHeight:'150px', overflowY:'scroll'}}> <span> {text}</span></div> : '' 
                }
                else if (data[i]['type'] == 'randomizedMediaDisplay'){
                    dataOut[i]['render'] = text => text != undefined ? <div style={{maxHeight:'150px', overflowY:'scroll'}}> <span> {text}</span></div> : '' 
                }
               else{
                dataOut[i] = {...data[i] , ...this.getColumnSearchProps(data[i]['key'] )}
               }
            }
            if (data[i]['key'] === 'language'){
                dataOut[i] = { ...data[i], editable: true, ...this.getColumnSearchProps(data[i]['key'] )}
                dataOut[i]['render'] = text => text ?  this.dropDownLangauge(text) : null
            }
            if (data[i]['key'] === 'status'){
                dataOut[i] = { ...data[i], editable: true, ...this.getColumnSearchProps(data[i]['key'] )}
                dataOut[i]['render'] = text => text ?  this.dropDown(text) : null
            }
            if (data[i]['key'] === 'operationStatus'){
                dataOut[i] = { ...data[i], editable: true, ...this.getColumnSearchProps(data[i]['key'] )}
                dataOut[i]['render'] = text => text ?  this.dropDownOperation(text) : null
            }
            if (data[i]['key'] === 'rejectReason'){
                dataOut[i] = { ...data[i], editable: true, ...this.getColumnSearchProps(data[i]['key'] )}
                dataOut[i]['render'] = text => text ?  this.rejectReasonDropDown(text) : null
            }
            
        }
        return dataOut
    }
    async componentDidMount() {
        let input = {
            batchId: this.id,
            isOperation: this.isOperation 
        }
        Survey.getAllSurveys(this.id).then((data) => {
            if (data != null){
                if (data[0] != null){
                    this.setState({
                        title :data[0].title.substring(0, 50)
                    })
                    if (data[0].statusText != null)
                    {
                        this.setState({
                            dropDownData :data[0].statusText
                        })
                    }
                    
                }
            }
                
            
        })
        Survey.getFormDashboardData(input).then((output) => {
            if (output.status === "success"){
                let datOut = output.data
                if (datOut.slength !=0){
                    datOut = datOut.sort((a, b) => b.id - a.id)
                }
                
                let columObj = output.columns
                this.setState({
                    data : datOut,
                    columns: this.updateColumnData(columObj),
                    overAllStatus: output.overAllStatus
                })
            }
        });
        this.fetchPermissions();
    }

    render() {
        return (
        <Fragment>
            
            <div style={{marginTop:'-30px' , padding:'10px'}}>

            {this.statusBar()}
                <Table  columns={this.state.columns} dataSource={this.state.data}  scroll={ this.state.data.length!=0 ? { x:true ,  y: 'calc(55vh)' } : { x:true }} pagination={{ position:['topRight'] }} bordered={true} searchable/>
            </div>
          <style>
            {`
            .ant-table-cell {
                min-width: 200px;
                max-height: 200px;
                overflow-y: scroll;
            }
            .ant-table-filter-column-title {
                height: 150px;
                overflow-y: scroll;
                text-align: center;
              }
            `}
          </style>
    </Fragment>);
    }
}

export default FormDashboard

