import React, { useEffect, useState } from 'react';
import { 
  Grid,
  Button,TextField,
 } from '@material-ui/core'; 
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AttributeBundle from './attributeBundle';

const useStyles = makeStyles((theme) => ({
    root: {
 
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.primary,
    },
    grid:{
        margin:2
    }
}));
export default function TaxonomyDisplay(props) {
  
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [taxonomy, setTaxonomy] = useState({})
    const [isEdited, setIsEdit] = useState(false)
    const [taxonomyName, setTaxonomyName] = useState('')
    const [featuretext, setFeatureText] = useState('')
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(()=>{
        setTaxonomy(props.taxonomy)
    },[props.taxonomy])
    
    useEffect(()=>{
        setTaxonomyName(props.taxonomyName)
    },[props.taxonomyName])


    const addTaxnomy = (e)=>{
        e.preventDefault()
        props.addTaxnomy(taxonomyName,taxonomy)
    }

    const updateTaxnomy = (e)=>{
        e.preventDefault()
        props.updateTaxnomy(taxonomyName,taxonomy)
    }
    
    
    const addFeature = (e)=>{
        e.preventDefault()
        if (featuretext != ''){
        let tempTaxonomy = {...taxonomy}
            tempTaxonomy[featuretext] = []
            setFeatureText('')
            setTaxonomy(tempTaxonomy)
        }
        setIsEdit(true)
        setFeatureText('')
    }

    const deleteFeature = (key)=>{
        let tempTaxonomy = {...taxonomy}
        delete tempTaxonomy[key]
        setTaxonomy(tempTaxonomy)
        setIsEdit(true)
    }

    const updateAttributeList = (key, list)=>{
        let tempTaxonomy = {...taxonomy}
        tempTaxonomy[key] = list;
        setTaxonomy(tempTaxonomy)
        setIsEdit(true)
    }

        return (
            <Fragment>
                <Grid container spacing={2} justify="center" className="mb-2">
                {isEdited ?
                        <>
                        <Grid item xs={4} lg={4} md={4}>
                        <TextField
                            variant="outlined"
                            label="name of taxonomy"
                            placeholder="name of taxonomy"
                            fullWidth
                            value={taxonomyName}
                            size="small"
                            onChange={(e)=>setTaxonomyName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} lg={2} md={3}>
                            <Button color="secondary" variant="outlined" size="medium" 
                            onClick={(e)=> addTaxnomy(e)} >
                                save as new taxonomy
                            </Button>
                        </Grid>
                        <Grid item xs={4} lg={2} md={3}>
                            <Button color="secondary" variant="outlined" size="medium" 
                            onClick={(e)=> updateTaxnomy(e)} >
                                update existing taxonomy
                            </Button>
                        </Grid>
                        </>
                        : null}
                </Grid>
                {
                Object.keys(taxonomy).map((key, i) => (
                    <AttributeBundle
                        key={key}
                        feature={key}
                        expanded={expanded}
                        expandPanel={handleChange}
                        updateAttributeList={updateAttributeList}
                        deleteFeature={deleteFeature}
                        attributes={taxonomy[key]}
                    />
                ))}
                <Grid container spacing={2} justify="center" className="mb-2">
                                <Grid item xs={4} lg={4} md={4}>
                                <TextField
                                    multiline
                                    variant="outlined"
                                    label="add features"
                                    placeholder=""
                                    fullWidth
                                    size="small"
                                    onBlur={(e)=>setFeatureText( e.target.value)}
                                    />
                                </Grid>
                    <Grid item xs={4} lg={4} md={4}>
                        <Button color="secondary" variant="outlined" size="medium" onClick={(e)=>addFeature(e)} >
                            Add Feature
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
}