const questionTemplate = {
    // SecGridNew: {
    //     name: "SEC Grid India New",
    //     id: "secGridNew",
    //     object : {
    //         answerType: "matrix",
    //         condition: {
    //             list: [{
    //                 jump: "end", 
    //                 list: [
    //                     {"value": "Unskilled Worker = Illiterate, Literate but no formal schooling, School Upto 4 yrs, School 5-9 yrs, SSC / HSC, Some college but not graduate, Graduate / Post-Graduate: General, Graduate / Post-Graduate: Professional", "question": 0, "condition": "contains"}, 
    //                     {"logic": "or"}, {"value": "Skilled Worker = Illiterate, Literate but no formal schooling, School Upto 4 yrs, School 5-9 yrs,\nSSC / HSC, Some college but not graduate ", "question": 0, "condition": "contains"},
    //                      {"logic": "or"}, {"value": "Petty Trader = Illiterate, Literate but no formal schooling, School Upto 4 yrs, School 5-9 yrs,\nSSC / HSC, Some college but not graduate", "question": 0, "condition": "contains"},
    //                       {"logic": "or"}, {"value": "Shop Owner = Illiterate, Literate but no formal schooling, School Upto 4 yrs, School 5-9 yrs", "question": 0, "condition": "contains"}, 
    //                       {"logic": "or"}, {"value": "Businessman /Industrialist – No. of Employees None = Illiterate, Literate but no formal schooling, School Upto 4 yrs", "question": 0, "condition": "contains"}, 
    //                       {"logic": "or"}, {"value": "Businessman /Industrialist – No. of Employees 1-9 = Illiterate", "question": 0, "condition": "equals"}, {"logic": "or"}, 
    //                       {"value": "Self Employed Professional = Literate but no formal schooling, School Upto 4 yrs, School 5-9 yrs", "question": 0, "condition": "contains"}, 
    //                       {"logic": "or"}, {"value": "Clerical / Salesmen = Literate but no formal schooling, School Upto 4 yrs, School 5-9 yrs, SSC / HSC", "question": 0, "condition": "contains"}, 
    //                       {"logic": "or"}, {"value": "Supervisory Level = Literate but no formal schooling, School Upto 4 yrs, School 5-9 yrs, SSC / HSC", "question": 0, "condition": "contains"}, 
    //                       {"logic": "or"}, {"value": "Officer / Executive – Junior =  Literate but no formal schooling, School Upto 4 yrs, School 5-9 yrs", "question": 0, "condition": "contains"}
    //                     ]
    //                 }],
    //             status: true},
    //         feQId: 0,
    //         orderId: 0,
    //         questionFile: false,
    //         questionId: false,
    //         questionObject: {
    //             options: {
    //             column: ["Unskilled Worker", "Skilled Worker", "Petty Trader" ,"Shop Owner" ,
    //                     "Businessman /Industrialist – No. of Employees None",
    //                     "Businessman /Industrialist – No. of Employees 1-9",
    //                     "Businessman /Industrialist – No. of Employees 10 +",
    //                     "Self Employed Professional",
    //                     "Clerical / Salesmen", "Supervisory Level" , "Officer / Executive – Junior" , "Officer / Executive – Middle / Senior"],
    //             row: ["Illiterate", "Literate but no formal schooling", "School Upto 4 yrs" , "School 5-9 yrs",
    //             "SSC / HSC", "Some college but not graduate" , "Graduate / Post-Graduate: General" ,"Graduate / Post-Graduate: Professional"],
    //             title: "OCCUPATION"
    //         }},
    //         questionText: "Please select Your relevant OCCUPATION and Education",
    //         questionType: 1,
    //         questionTypeName: "text",
    //         questionUrl: ""
    //     }
        
    // }
    // Nps:{
    //     name: "NPS",
    //     id: "nps",
    //     object : {

    //     }
    // }
}
export default questionTemplate

