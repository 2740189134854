import React, { Fragment, useRef ,useState} from 'react';
import { FormControl, FormControlLabel, Radio, Checkbox,
   TextField, IconButton, Button, Switch,
    Tooltip, Grid } from '@material-ui/core';
import { Clear, Image, Add } from '@material-ui/icons';
import IosImagesOutline from 'react-ionicons/lib/IosImage'
export default (props) => {

  const [values , setValues] = useState(props.questionObject.hasOwnProperty('options') ? props.questionObject.options: []);
  const [showTextBox, setShowTextBox] = useState(props.questionObject.hasOwnProperty('showTextBox') ? props.questionObject.showTextBox : false );
  const [textBelow, setTextBelow] = useState(props.questionObject.hasOwnProperty('textBelow') ? props.questionObject.textBelow : false );
  const [orderBelow, setOrderBelow] = useState(props.questionObject.hasOwnProperty('orderBelow') ? props.questionObject.orderBelow : false );
  const [imageSizeBig, setImageSizeBig] = useState(props.questionObject.hasOwnProperty('imageSizeBig') ? props.questionObject.imageSizeBig : false );
  const [textBoxNumber, setTextBoxNumber]= useState(props.questionObject.hasOwnProperty('textBoxNumber') ? props.questionObject.textBoxNumber : 1 );
  const [textTitle, setTextTitle]= useState(props.questionObject.hasOwnProperty('textTitle') ? props.questionObject.textTitle : "Enter value for others" );

  var fileRef = useRef();
  var selectedOption = null;

  const submitChange = (obj = {}) => {
    const newObj = {
      options: values,
      showTextBox: showTextBox,
      textBoxNumber:textBoxNumber,
      textTitle:textTitle,
      orderBelow: orderBelow,
      textBelow: textBelow,
      imageSizeBig: imageSizeBig,
      ...obj
    };
    props.updateQuestionObject(newObj);
  }

  function updateShowTextBox(event) {
    const tempShow = event.target.checked;
    setShowTextBox(tempShow);
    submitChange({
      showTextBox: tempShow
    });
  }

  function updateImageSize(event) {
    const tempShow = event.target.checked;
    setImageSizeBig(tempShow);
    submitChange({
      imageSizeBig: tempShow
    });
  }

  function updateShowTextBox(event) {
    const tempShow = event.target.checked;
    setShowTextBox(tempShow);
    submitChange({
      showTextBox: tempShow
    });
  }
  function updateOrderBelow(event) {
    const tempShow = event.target.checked;
    setOrderBelow(tempShow);
    submitChange({
      orderBelow: tempShow
    });
  }
  function updateTextBelow(event) {
    const tempShow = event.target.checked;
    setTextBelow(tempShow);
    submitChange({
      textBelow: tempShow
    });
  }
  function updateShowTextBox(event) {
    const tempShow = event.target.checked;
    setShowTextBox(tempShow);
    submitChange({
      showTextBox: tempShow
    });
  }

  function updateTextTitle(event) {
    const tempTitle = event.target.value;
    setTextTitle(tempTitle);
  };

  function updateTextBoxNumber(event) {
    const tempNumber = event.target.value;
    setTextBoxNumber(tempNumber);
  };

  function updateText(i, val) {
    let _values = [ ...values ]
    _values[i] = val;
    setValues(_values);
  }

  function removeItem(i) {
    let _values = [ ...values ];
    _values.splice(i, 1);
    setValues(_values)
    submitChange({
      options: _values
    });
  }

  function openFileLoader(i) {
    selectedOption = i;
    fileRef.current.click();
  }

  function imageLoad() {
    let _values = [...values]
    if (fileRef.current.files.length > 0 && fileRef.current.files[0].name) {
      _values[selectedOption] = {
        file: null,
        url: "",
        urlError: false,
        finUrl: ""
      }
      let fileName = fileRef.current.files[0].name.replace(/\s+/g,"_");
      _values[selectedOption]["file"] = new File([fileRef.current.files[0]] , fileName, { type: fileRef.current.files[0].type });
      _values[selectedOption]["finUrl"] = URL.createObjectURL(_values[selectedOption]["file"]);
      setValues(_values)
      submitChange({
        options: _values
      });
    }
  }
  
  function updateURL(i, event) {
    let _values = [...values]
    _values[i] = {
      file: null,
      url: event.target.value,
      urlError: false,
      finUrl: event.target.value
    }
    setValues(_values)
    submitChange({
      options: _values
    });
  }

  function addNewItem() {
    let _values = [...values]
    if (props.content == "text") {
      _values.push("");
    }
    else {
      _values.push({
        file: null,
        url: "",
        urlError: false,
        finUrl: "",

      });
    }
    setValues(_values)
  }

  let sel = null;
  if (props.type == 'single') {
    sel = <Radio checked={false} />
  }
  else {
    sel = <Checkbox checked={false} />
  }

  return (
    <div>
      <FormControl className="w-100">
      {props.content == "text" ? 
      <Grid container spacing={2}>
          <Grid item xs={8} md={8} lg={8} >
              {showTextBox ? 
              <>
              <Grid container spacing={2} className="mb-3"> 
                  <Grid item xs={3} md={3} l={3} >
                        <TextField value={textBoxNumber} 
                        label="Number"
                        placeholder="Number of text box"
                        type="number"
                        fullWidth onChange={updateTextBoxNumber}
                        onBlur={() => submitChange({})}
                        variant="standard"  variant="outlined" size="small"/>
                   </Grid>
                   <Grid item xs={6} md={6} l={6} >
         
                        <TextField multiline value={textTitle} 
                        label="Text box title"
                        placeholder="Text box title"
                        fullWidth onChange={updateTextTitle} onBlur={() => submitChange({})} variant="standard"  variant="outlined" size="small"/>
                   </Grid> 
              </Grid>
                   </> : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {values.map((e, i) => {
            return (
              <Fragment key={i}>
                <FormControlLabel className="d-flex align-items-center" key={i} control={sel} label={
                  <Grid container spacing={2}>
                    <Grid item xs={11} md={11} l={11} >
                      <TextField multiline value={e} fullWidth onChange={(event) => updateText(i, event.target.value)} onBlur={() => submitChange({})} variant="standard"  variant="outlined" size="small"/>
                    </Grid>
                    <Grid item xs={1} md={1} l={1} >
                      <IconButton aria-label="delete" color="primary" onClick={removeItem.bind(null, i)}>
                        <Clear />
                      </IconButton>
                    </Grid>
                  </Grid>
                } />
              </Fragment>
            )
          })}
          </Grid>
          <Grid item xs={12}>
            <Tooltip title="Add Item">
              <IconButton aria-label="add" onClick={addNewItem}>
                <Add />
              </IconButton>
            </Tooltip>
          </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} md={4} lg={4} >
          <Grid container spacing={2}>
              <Grid item xs={12} md={12} l={12} >
              <FormControlLabel
                      control={
                        <Switch
                          checked={showTextBox}
                          onChange={updateShowTextBox}
                          name="checkedA"
                          color="primary"
                        />
                      }
                      label="Add text box for others"
                    />

                  </Grid>
                  <Grid item xs={12} md={12} l={12} >
                  <FormControlLabel
                      control={
                        <Switch
                          checked={textBelow}
                          onChange={updateTextBelow}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Show Text box above"
                    />
                    </Grid>
                    <Grid item xs={12} md={12} l={12} >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={orderBelow}
                          onChange={updateOrderBelow}
                          name="checkedC"
                          color="primary"
                        />
                      }
                      label="Order one below other"
                    />
                    </Grid>
              </Grid>
          </Grid>

        </Grid>
        :
        <Grid container spacing={2}> 
        <Grid item xs={9} md={9} lg={9} >
        {values.map((e, i) => {
          return (
            <FormControlLabel className="mt-2 mb-2" key={i} control={sel} label={
              <>
                <input type="file" ref={fileRef} accept="image/*" style={{display: 'none'}} onChange={imageLoad} />
                <div className="d-flex justify-content-between">
                  {e.finUrl ? <img src={e.finUrl} className="img-fluid rounded mr-3 shadow-sm" style={{width: '5rem', height: '5rem'}} /> : <IosImagesOutline style={{width: '5rem', height: '5rem'}} color="var(--primary)"/> }
                    <Grid container spacing={2}>
                      <Grid item xs={11} md={10} l={11} >
                      <Button variant="outlined" color="primary" className="mb-1" onClick={openFileLoader.bind(null, i)}>Image Upload</Button>
                    <TextField variant="outlined" className="mb-1" multiline fullWidth size="small" type="url" placeholder="Image URL" value={e.url || ""} onChange={updateURL.bind(null, i)} />
                    </Grid> 
                      <Grid item xs={1} md={1} l={1} >
                        <IconButton aria-label="delete" color="primary" onClick={removeItem.bind(null, i)}>
                          <Clear />
                        </IconButton>
                      </Grid> 
                    </Grid>
                </div>
              </>
            } />
          )
          })}
          </Grid>
          <Grid item xs={3} md={3} lg={3} >
          <FormControlLabel
            control={
              <Switch
                checked={orderBelow}
                onChange={updateOrderBelow}
                name="checkedC"
                color="primary"
              />
            }
            label="Order one below other"
          />
          <FormControlLabel
            control={
              <Switch
                checked={imageSizeBig}
                onChange={updateImageSize}
                name="checkedC"
                color="primary"
              />
            }
            label="Image size Big"
          />
        </Grid>
        <Grid item xs={12}>
          <Tooltip title="Add Item">
            <IconButton aria-label="add" onClick={addNewItem}>
              <Add />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    }
        
      </FormControl>

    </div>
  )
}