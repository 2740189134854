const axios = require('axios').default;

const BE_URL = "https://dev.survey.api.enumerate.ai/";
// const AUTH_URL = "https://dev.auth.enumerate.ai/";


// const BE_URL = "http://localhost:3001/";

// const BE_URL = "https://prod.survey.api.enumerate.ai/";
const AUTH_URL = "https://prod.auth.api.enumerate.ai/";



function uploadFile(header, url, file) {
  return axios({
    url: url,
    method: 'put',
    headers: header,
    data: file
  });
}
function makeFilePublic(header, payload) {
  return axios({
    url: BE_URL + "file/makepublic",
    method: 'post',
    headers: header,
    data: payload
  });
}

function loginAPI(email, password) {
  let URL = AUTH_URL + "login/";
  return axios({
    url: URL,
    method: 'post',
    data: {
      email: email,
      password: password
    }
  })
}


function getAnonymousToken(header, payload) {
  let URL = BE_URL + "anonymous/getToken/";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  })
}

function authAPI(header) {
  let URL = AUTH_URL + "auth/";
  return axios({
    url: URL,
    method: 'post',
    headers: header
  })
}

function getPermissionAPI(header) {
  let URL = BE_URL + "permission/";
  return axios({
    url: URL,
    method: 'post',
    headers: header
  })
}

function logoutAPI(header) {
  let URL = AUTH_URL + "logout/";
  return axios({
    url: URL,
    method: 'post',
    headers: header
  })
}

function registerAPI(name, dob, gender, email, password, city, lat, lon) {
  let URL = AUTH_URL + "register/";
  return axios({
    url: URL,
    method: 'post',
    data: {
      city: city,
      dob: dob,
      email: email,
      gender: gender,
      latitude: lat,
      longitude: lon,
      userType: 1,
      password: password,
      userName: name
    }
  })
}

function getAllProject(header, payload) {
  let URL = BE_URL + "project/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function createProject(header, payload) {
  let URL = BE_URL + "project/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAllTrack(header, payload) {
  let URL = BE_URL + "track/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function createTrack(header, payload) {
  let URL = BE_URL + "track/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAllSurvey(header, payload) {
  let URL = BE_URL + "batch/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function createSurvey(header, payload) {
  let URL = BE_URL + "batch/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}


function copySurvey(header, payload) {
  let URL = BE_URL + "batch/copy";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}


function getShareToUsers(header, payload) {
  let URL = BE_URL + "batch/shareToUsers/getAll";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}
function updateShareToUsers(header, payload) {
  let URL = BE_URL + "batch/shareToUsers";
  return axios({
    url: URL,
    headers: header,
    method: 'put',
    data: payload
  })
}
function deleteShareToUsers(header, payload) {
  let URL = BE_URL + "batch/shareToUsers";
  return axios({
    url: URL,
    headers: header,
    method: 'delete',
    data: payload
  })
}

function addShareToUsers(header, payload) {
  let URL = BE_URL + "batch/shareToUsers";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAllQuestion(header, payload) {
  let URL = BE_URL + "questions/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function createQuestion(header, payload) {
  let URL = BE_URL + "questions/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function modifyQuestion(header, payload) {
  let URL = BE_URL + "questions/";
  return axios({
    url: URL,
    headers: header,
    method: 'put',
    data: payload
  })
}
function updateQuestionAiAnalysis(header, payload) {
  let URL = BE_URL + "questions/updateanalysis";
  return axios({
    url: URL,
    headers: header,
    method: 'put',
    data: payload
  })
}

function deleteQuestion(header, payload) {
  let URL = BE_URL + "questions/";
  return axios({
    url: URL,
    headers: header,
    method: 'delete',
    data: payload
  })
}

function getAIquestions(header, payload) {
  let URL = BE_URL + "questions/aiquestion";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function getAllVideo(header, payload) {
  let URL = BE_URL + "video/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getSignedURL(header, payload) {
  let URL = BE_URL + "video/upload";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  })
}

function addVideo(header, payload) {
  let URL = BE_URL + "video/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function updateVideoStatus(header, payload) {
  let URL = BE_URL + "video/";
  return axios({
    url: URL,
    method: 'put',
    headers: header,
    data: payload
  });
}

function getTranscriptDashboard(header, payload) {
  let URL = BE_URL + "survey/transcriptdashboard/";
  return axios({
    url: URL,
    method: 'Post',
    headers: header,
    data: payload
  });
}

function updateVideoTranscript(header, payload) {
  let URL = BE_URL + "survey/transcriptdashboard/";
  return axios({
    url: URL,
    method: 'put',
    headers: header,
    data: payload
  });
}

function deleteVideoTranscript(header, payload) {
  let URL = BE_URL + "survey/transcriptdashboard/";
  return axios({
    url: URL,
    method: 'delete',
    headers: header,
    data: payload
  });
}
function approveVideoTranscript(header, payload) {
  let URL = BE_URL + "survey/transcriptIsSentimentApproved/";
  return axios({
    url: URL,
    method: 'put',
    headers: header,
    data: payload
  });
}


function getIndustryCategoryProduct(header, payload) {
  let URL = BE_URL + "industry/getall";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function getBatchIndustryCategoryTaxonomy(header, payload) {
  let URL = BE_URL + "batch/taxonomy";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function getMySavedTaxonomy(header, payload){
  let URL = BE_URL + "taxonomy/";
  return axios({
    url: URL,
    method: 'get',
    headers: header
  });
}
function getTaxonomyById(header, payload){
  let URL = BE_URL + "taxonomy/getbyid";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function addMyTaxonomy(header, payload){
  let URL = BE_URL + "taxonomy/";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function editMyTaxonomy(header, payload){
  let URL = BE_URL + "taxonomy/";
  return axios({
    url: URL,
    method: 'put',
    headers: header,
    data: payload
  });
}
function deleteMyTaxonomy(header, payload){
  let URL = BE_URL + "taxonomy/";
  return axios({
    url: URL,
    method: 'delete',
    headers: header,
    data: payload
  });
}

function updateBatchIndustryCategoryTaxonomy(header, payload) {
  let URL = BE_URL + "batch/taxonomy";
  return axios({
    url: URL,
    method: 'put',
    headers: header,
    data: payload
  });
}

function getCountryList(header) {
  let URL = BE_URL + "country/";
  return axios({
    url: URL,
    headers: header,
    method: 'get'
  });
}

function getStateList(country) {
  let URL = BE_URL + "state/?country=" + country +"/";
  return axios({
    url: URL,
    method: 'get'
  });
}

function getCityList(header, country, state) {
  let urlVal = ""
  if (state != '' && country === ''){
    urlVal = "city/?state=" + state
  }else if (country != '' && state === ''){
    urlVal = "city/?country=" + country
  }
  else{
    urlVal = "city/?country="+ country +"&state=" + state
  }
  let URL = BE_URL + urlVal;
  return axios({
    url: URL,
    method: 'get',
    headers: header,
  });
}

function emailExists(email) {
  var encodeEmail = encodeURIComponent(email)
  let URL = AUTH_URL + "register/?email=" + encodeEmail;
  return axios({
    url: URL,
    method: 'get'
  });
}

function surveyAnswer(header, payload) {
  let URL = BE_URL + "answer/";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function surveyAnswerErrorLogging(header, payload) {
  let URL = BE_URL + "answer/errorlogging";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}
function answerStatus(header, payload) {
  let URL = BE_URL + "answer/status";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}
function getAnswerUser(header, payload) {
  let URL = BE_URL + "answer/adduser";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function getAnswerCount(header, payload) {
  let URL = BE_URL + "answer/getcount";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function getQuotaCount(header, payload) {
  let URL = BE_URL + "answer/getquotacount";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}


function updateSurvey(header,payload){
  let URL = BE_URL + "batch/";
  return axios({
    url: URL,
    method: 'put',
    headers: header,
    data: payload
  });
}

function deleteSurvey(header,payload){
  let URL = BE_URL + "batch/";
  return axios({
    url: URL,
    method: 'DELETE',
    headers: header,
    data: payload
  });
}

function getQuestionIdsList(header) {
  let URL = BE_URL + "questions/questiontypelist";
  return axios({
    url: URL,
    method: 'get',
    headers: header
  });
}

function getAnswerIdsList(header) {
  let URL = BE_URL + "questions/answertypelist";
  return axios({
    url: URL,
    method: 'get',
    headers: header
  });
}

function getDemographyInformation(header, payload) {
  let URL = BE_URL + "surveydashboard/getFilter/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getDashboardData(header, payload) {
  let URL = BE_URL + "surveydashboard/get/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}


function getPPTData(header, payload) {
  let URL = BE_URL + "surveydashboard/getPPT/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getRawData(header, payload) {
  let URL = BE_URL + "surveydashboard/getRawData/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getFormDashboardData(header, payload) {
  let URL = BE_URL + "surveydashboard/getFormData/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function UpdateFormDashboardData(header, payload) {
  let URL = BE_URL + "surveydashboard/getFormData/";
  return axios({
    url: URL,
    headers: header,
    method: 'PUT',
    data: payload
  })
}

function addVideoForAi(header, payload) {
  let URL = BE_URL + "surveydashboard/addForAnalysis/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function addVideoForSentimentAnalysis(header, payload) {
  let URL = BE_URL + "surveydashboard/addForAnalysis/";
  return axios({
    url: URL,
    headers: header,
    method: 'put',
    data: payload
  })
}
function getAdSurveyDashboard(header, payload) {
  let URL = BE_URL + "adsurvey/getAll/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAllVideosForQuestion(header, payload) {
  let URL = BE_URL + "survey/getAllvideo/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAttributesForQuestion(header, payload) {
  let URL = BE_URL + "survey/getAttributes/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAnalysisScoresForQuestion(header, payload) {
  let URL = BE_URL + "survey/getSentAndTopFive/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function toggleFavoriteVideo(header, payload) {
  let URL = BE_URL + "survey/isfavoritevideo/";
  return axios({
    url: URL,
    headers: header,
    method: 'put',
    data: payload
  })
};

function getVideoDetail(header, payload) {
  let URL = BE_URL + "survey/getVideo/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
};

function getQuotaDashboard(header, payload) {
  let URL = BE_URL + "surveydashboard/getQuotaDashboard/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
};

export {
  getVideoDetail,
  toggleFavoriteVideo,
  getAnalysisScoresForQuestion,
  getAttributesForQuestion,
  getAllVideosForQuestion,
  UpdateFormDashboardData,
  getFormDashboardData,
  addVideoForAi,
  getAnswerUser,
  getPPTData,
  getRawData,
  getDashboardData,
  getDemographyInformation,
  getQuestionIdsList,
  getAnswerIdsList,
  loginAPI, 
  registerAPI, 
  authAPI, 
  logoutAPI, 
  getAllProject, 
  getAllQuestion, 
  getAllSurvey, 
  getAllTrack, 
  createProject, 
  createQuestion, 
  createSurvey, 
  createTrack, 
  getAllVideo, 
  addVideo,
  getSignedURL,
  uploadFile,
  updateVideoStatus,
  getIndustryCategoryProduct,
  getCountryList,
  getCityList,
  emailExists,
  surveyAnswer,
  updateSurvey,
  modifyQuestion,
  deleteQuestion,
  deleteSurvey,
  copySurvey,
  makeFilePublic,
  getAnonymousToken,
  answerStatus,
  getAnswerCount,
  getQuotaCount,
  surveyAnswerErrorLogging,
  getTranscriptDashboard, 
  updateVideoTranscript,
  deleteVideoTranscript,
  getAIquestions,
  updateQuestionAiAnalysis,
  getBatchIndustryCategoryTaxonomy, 
  updateBatchIndustryCategoryTaxonomy,
  getMySavedTaxonomy ,getTaxonomyById, 
  addMyTaxonomy, editMyTaxonomy, 
  addVideoForSentimentAnalysis,
  getAdSurveyDashboard,
  approveVideoTranscript,
  getPermissionAPI,
  getQuotaDashboard,
  getShareToUsers, 
  updateShareToUsers ,
  deleteShareToUsers,
   addShareToUsers
};