import React, { Fragment } from 'react';
import Dialog from './dialog'

import {
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';


export default function Projects(props) {
  
  const handleChangePage = (event, page) => {
    props.handlePageValue(page)
  };
  
  return (
    <Fragment>
      <Grid container justify='center'>
                <Grid item xs={12} md={11} lg={10} >
      <div className="d-block p-3 d-md-flex justify-content-between align-items-center text-center text-md-left">
            <div className="d-flex flex-md-row flex-column align-items-center">
              <div>
                <TextField
                  onChange={(event) => {props.filter(event.target.value)}}
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="my-3">
              <Pagination count={props.pageLength} variant="outlined" shape="rounded"  color="primary"  onChange={handleChangePage}
                /></div>
            <div className="d-block d-md-flex align-items-center">
            { props.isOperation ? null : <Dialog/> }
            </div>
          </div>
          </Grid>
          </Grid>
    </Fragment>
  );
}
