import { Table } from "ant-table-extensions";
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { Survey } from 'Store/ApplicationStore';
import { Grid ,Card , TextField, Button, InputLabel, MenuItem, FormControl, Select} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';

const QuotaDashboard = (props) => {

    const permission = props.isOperation ? { isBrand: false } : props.permission
    const history = useHistory();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const surveyId = urlParams.get('id');
    const [questionList , setQuestionList] = useState([])
    const [ questionId, setQuestionId ] = useState(0)
    const [ columns , setColumns] = useState([])
    const [ dataRow, setDataRow ] = useState([])
    const [ maleCount, setMaleCount ] = useState(0)
    const [ femaleCount, setFemaleCount ] = useState(0)
    const [ totalCount, setTotalCount ] = useState(0)
    const [ title, setTitle ] = useState(0)
    const [quotaType, setQuotaType] = useState('Done')
    

    useEffect(() => {
        getData(surveyId , 0, quotaType)
        getQuestion()
        getTitle()
      }, []);


    const handleForm = (e)=>{
    e.preventDefault()
    history.push({
        pathname: '/FormDashboard',
        search: '?id='+surveyId 
    });
    }
    
    
    const getTitle = async() =>{
        Survey.getAllSurveys(surveyId).then((data) => {
            if (data != null){
                if (data[0] != null){
                    setTitle(data[0].title.substring(0, 50))

                }
            }
                
            
        })
    }

    
    
    const getData = async( surveyId , questionId, quotaType )=>{
        
        let dataObj = {'batchId': surveyId , 'questionId': questionId, 'quotaType':quotaType}
        await Survey.getQuotaDashboard(dataObj).then((data) => {
            if (data.status == 'success'){
                setMaleCount(data.maleCount)
                setFemaleCount(data.femaleCount)
                setTotalCount(data.totalCount)
                setColumns(data.columns)
                setDataRow(data.data)
            }
        });
    }

    const getQuestion = async ()=>{
        await Survey.getAllQuestions(surveyId).then((data) => {
            if(data != 'unauthorised')
            {
                if  (data != null && data != undefined)
                {   
                    let questionObj = data?.filter(q =>  ['singleTextSelect' , 'secGrid', 'text', 'nccsGrid'].includes(q.answerType))
                    setQuestionList(questionObj)
                }
            }
           
          });
    }

    const handleValueChange = async (event, value)=>{
        if (value != null && value != undefined){
            setQuestionId(value.questionId)
            await getData(surveyId, value.questionId , quotaType )
        }else{
            setQuestionId(0)
            await getData(surveyId, 0 , quotaType )
        }
        
    }

    const handleQuotaTypeChange = async (event)=> {
        setQuotaType(event.target.value)
        await getData(surveyId, questionId, event.target.value)
    }


    return (
        <>
          <>

        <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6} md={3} lg={4} >
            <h5>{title}</h5>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1} >
                <Card className="p-3 mb-3">
                    <div className="text-black pb-2">
                        Total 
                    </div>
                    <div className="d-flex justify-content-between ">
                        <h3 className="display-4 mb-0 text-black">
                            <span className="text-primary">
                                <CountUp
                                start={0}
                                end={totalCount}
                                duration={3}
                                deplay={1}
                                separator=""

                            /></span>
                        </h3>
                        
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1}>
                <Card className="p-3 mb-3">
                    <div className="text-black pb-2">
                        Male 
                    </div>
                    <div className="d-flex justify-content-between ">
                        <h3 className="display-4 mb-0 text-black">
                            <span className="text-primary">
                                <CountUp
                                start={0}
                                end={maleCount}
                                duration={3}
                                deplay={1}
                                separator=""

                            /></span>
                        </h3>
                        
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1}>
                <Card className="p-3 mb-3">
                    <div className="text-black pb-2">
                        Female 
                    </div>
                    <div className="d-flex justify-content-between ">
                        <h3 className="display-4 mb-0 text-black">
                            <span className="text-primary">
                                <CountUp
                                start={0}
                                end={femaleCount}
                                duration={3}
                                deplay={1}
                                separator=""

                            /></span>
                        </h3>
                        
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4} >
            <Button color="secondary" className="m-1"  variant="contained"
                style={{float:"right" }} onClick={(e) => handleForm(e)}>
                Form Dashboard
            </Button>
            </Grid>
        
        </Grid>
        <Grid container spacing={4} justifyContent="center">
        {permission.isBrand?
  
        <Grid item xs={12} sm={6} md={6} lg={6} className="mb-3">
        <Autocomplete
        id="combo-box-demo"
        options={questionList}
        getOptionLabel={(option) => option.orderId + ') '+ option.questionText}
        onChange={handleValueChange}
        renderInput={(params) => <TextField {...params} label="Select question for quota" variant="outlined"  fullWidth/>}
        />
        </Grid>
              : 
        <>
            <Grid item xs={12} sm={6} md={3} lg={3} className="mb-3">

            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} className="mb-3">
            <Autocomplete
            id="combo-box-demo"
            options={questionList}
            getOptionLabel={(option) => option.orderId + ') '+ option.questionText}
            onChange={handleValueChange}
            renderInput={(params) => <TextField {...params} label="Select question for quota" variant="outlined"  fullWidth/>}
            />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} className="mb-3">
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Quota Type</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={quotaType}
                label="quotaType"
                onChange={handleQuotaTypeChange}
                variant="outlined" 
                >
                <MenuItem value={'OP-Done'}>OP-Done</MenuItem>
                <MenuItem value={'Done'}>Done</MenuItem>
                <MenuItem value={'Full-Data'}>Full-Data</MenuItem>
                </Select>
            </FormControl>
            </Grid>
            </>
            }
        </Grid>

</>
        <Table
        columns={columns}
        dataSource={dataRow}
        bordered
        size="middle"
        pagination={false}
        scroll={{ x: 'calc(700px + 50%)' }}
        searchable
        exportableProps={{ fileName: title + quotaType}}
    />,
    </>
    )
}

export default QuotaDashboard