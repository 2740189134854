import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import {
  Typography,
  CircularProgress
} from '@material-ui/core';
import QuestionRender from '../../QuestionRender';

const MediaElement = ({
  mediaArray,
  currentMedia,
  increaseAdCount,
  questionsAfterMedia,
  answerToQuestions,
  theme,
  preview,
  questionPreview
}) => {
  const [currIndex, setCurrentIndex] = useState(currentMedia);
  const [videoEnd, setVideoEnd] = useState(false);
  const [questionOrder, setQuestionOrder] = useState(0);
  const [answer, setAnswer] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const showNextMedia = () => {
    setQuestionOrder(0);
    setVideoEnd(false);
    increaseAdCount();
  };

  const setMediaEnd = () => {
    if (questionsAfterMedia.length > 0) {
      setQuestionOrder(0);
      setVideoEnd(true);
    } else {
      showNextMedia();
    }
  };

  const saveAnswer = (obj) => {
    setAnswer(obj);
  };

  const submitAnswer = (obj) => {
    setLoading(true);
    (async () => {
      try {
        if (!questionPreview) {
          await answerToQuestions({
            mediaIndex: currIndex,
            feQuestionId: questionsAfterMedia[questionOrder].feQId,
            answerType: questionsAfterMedia[questionOrder].answerType,
            answer: {
              ...answer,
              ...obj
            }
          });
        }
        setAnswer({});
        const nextQ = questionOrder + 1;
        if (questionsAfterMedia.length === nextQ) {
          showNextMedia();
        } else {
          setQuestionOrder(nextQ);
        }
      } catch (err) {
        setError(err);
        // error while submitting
      }
      setLoading(false);
    })();
  };

  const playVideo = (event) => {
    event.target.play();
  };

  useEffect(() => {
    setCurrentIndex(currentMedia);
  }, [currentMedia]);

  return (
    <RelDiv>
      {currIndex >= mediaArray.length ? (
        <Typography variant="h5">
          {mediaArray.length === 0 ? 'Media quota has been reached!' : 'Thank You for watching!'}
        </Typography>
      ) : (
        <>
          {videoEnd ? (
            <>
              {questionsAfterMedia.map((e, i) => {
                if (questionsAfterMedia.length > questionOrder) {
                  if (i === questionOrder) {
                    return (
                      <Fragment key={questionOrder}>
                        <QuestionRender
                          data={questionsAfterMedia[i]}
                          index={i}
                          preview={preview}
                          submit={submitAnswer}
                          save={saveAnswer}
                          theme={theme}
                          questionPreview={questionPreview}
                          allQuestions={questionsAfterMedia}
                        />
                      </Fragment>
                    );
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              })}
            </>
          ) : (
            <video
              className="videoContainer"
              // autoPlay
              controls={true}
              controlsList="nodownload"
              style={{maxWidth: '100%', width: '100%', height: 'auto', maxHeight: '60vh'}}
              src={mediaArray[currIndex]}
              onEnded={setMediaEnd}
              onLoadedMetadata={playVideo}
            />
          )}
        </>
      )}
      {error !== null && (
        <div>
          <Typography variant="subtitle1" color="error">
            Error while submitting the request
          </Typography>
        </div>
      )}
      {loading && (
        <Loader>
          <CircularProgress />
        </Loader>
      )}
    </RelDiv>
  );
};

export default MediaElement;

const RelDiv = styled.div`
  position: relative;
`;

const Loader = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff30;
  justify-content: center;
  align-items: center;
`;
