import React, { useState, useEffect, Fragment} from 'react';
import {
  Paper,
  Typography,
  Slider, Button,  Grid, TextField
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from '../../../../components/utils/AutoComplete';
import FormControl from '@material-ui/core/FormControl';
import CreateDialog from './dialog'
import AIAnalysisDialog from './dialogAi';
import { Authorization } from '../../../../Store/ApplicationStore';
import Hidden from '@material-ui/core/Hidden';
import { useHistory } from 'react-router-dom';
export default (props) => {
  const history = useHistory();
 
  const [gender, setGender] = useState([]);
  let ageInit = [];
  if (props.filters.age.min < props.data.age[0]) {
    ageInit[0] = props.data.age[0];
  }
  else {
    ageInit[0] = props.filters.age.min
  }
  if (props.filters.age.max < props.data.age[props.data.age.length-1]) {
    ageInit[1] = props.filters.age.max;
  }
  else {
    ageInit[1] = props.data.age[props.data.age.length-1];
  }
  const outputTypeObj= [
    {
        id:"graph",
        name: "Graph"
    },
  //   {
  //     id:"rawData",
  //     name: "Raw Data"
  // }
]

  const [age, setAge] = useState(ageInit);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCounties] = useState([]);
  const [outputType, setOutputType] = useState(outputTypeObj[0]);
  const [minAge, setMinAge] = React.useState(0);
  const [maxAge, setMaxAge] = React.useState(100);
  const [dialogFilterData, setDialogFilterData] = useState({
    gender: [],
    age: 0,
    ageOffset:0,
    cities: [],
    states: [],
    country: [],
    outputType:"graph"
  })
  const [permission, setPermission] = useState({
    isDownload: true,
    isForm: true,
    isAiAnalysis: true
  });

  const getPermission = async () => {
    const per = await Authorization.getPermission();
    setPermission(per);
  };

  useEffect(() => {
    getPermission();
  }, []);

  const handleAgeChange = (event, newValue) => {
    setAge(newValue);
  } 

  const handleOutputType = (event) =>{
    if (event != null){
      setOutputType(event)
      filterSubmit(null, event.id)
      }
    }


  
    const handleEdit = (e)=>{
      e.preventDefault()
      history.push({
          pathname: '/Manage',
          search: '?id='+ props.surveyId 
       });
  }
  
  const handleForm = (e)=>{
    e.preventDefault() 
      history.push({
          pathname: '/FormDashboard',
          search: '?id='+ props.surveyId 
       });
  }


  const filterSubmit = (event , eventOutputType=null) => {
    let filterData = {
      gender: getGenderData(gender),
      age: {
        min: minAge,
        max: maxAge
      },
      cities: cities,
      states: states,
      country: countries,
      outputType:outputType.id
    }
    if (eventOutputType != null){
      filterData.outputType = eventOutputType
    }
    setDialogFilterData(filterData)
    props.updateFilters(filterData);
  }

  const getGenderData = (arr)=> {
    let finalArr= []
    if (arr.length != 0){
      arr.forEach(function (x) {
        if (x === "Female"){
          finalArr.push(0)
        }
        else if (x === "Male"){
          finalArr.push(1)
        }
        else if (x === "others"){
          finalArr.push(2)
        }
        else if (x === "prefer not to answer"){
          finalArr.push(-1)
        }
      })
    }

    return finalArr
  }
  
  return (
    <Paper elevation={2} className="w-100 p-2 pt-4">
    <Fragment>
        <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={4} lg={5} md={4}>
              <Hidden only={['sm', 'md']}>
                <h3>{props.surveyName}</h3>
              </Hidden> 
              <Hidden only="lg">
                <h5>{props.surveyName}</h5>
              </Hidden> 
            </Grid>
            {/* <Grid item xs={2} lg={2} md={2}> */}
            {/* <Autocomplete 
              isMultiple={false}
              limit={1}
              label="Output Type"
              value={outputType}
              options={outputTypeObj}
              getOptionLabel={(option) => option.name}
              onChange={handleOutputType}
            /> */}
            {/* </Grid> */}
            <Grid item xs={4} lg={3} md={2}>
            <Autocomplete 
              limit={1}
              label="Gender"
              value={gender}
              options={props.data.gender}
              getOptionLabel={(option) => option}
              onChange={(value) => setGender(value)}
            />
            </Grid>
            <Grid item xs={6} lg={1} md={1}>
                  <TextField
                  variant="outlined"
                  label="Age Min"
                  fullWidth
                  placeholder="Enter Starting Age"
                  type="number"
                  value={minAge}
                  onChange={(event) => {setMinAge(event.target.value)}}
                  />
              </Grid>
              <Grid item xs={6} lg={1} md={1}>
                  <TextField
                  variant="outlined"
                  label="Age Max"
                  fullWidth
                  placeholder="Enter End Age"
                  type="number"
                  value={maxAge}
                  onChange={(event) => {setMaxAge(event.target.value)}}
              />
              </Grid>
            <Grid item xs={1} lg={1} md={2}>
              {permission.isAiAnalysis && (
                <AIAnalysisDialog  surveyId={props.surveyId} permission={permission} />
              )}
              {/* { props.analyseStatus ? (
                <Button variant="contained" color="secondary" onClick={props.analysisVideos} disabled>In Progress</Button>
              ) : (
                <Button variant="contained" color="secondary" onClick={props.analysisVideos } disabled={props.surveyType === 3 ? true: false}>Analyse videos</Button>
              )} */}
            </Grid>
            <Grid item xs={1} lg={1} md={2}>
            {permission.isDownload && (
              <CreateDialog permission={permission} filterData={dialogFilterData }  surveyData={props.surveyData} surveyId={props.surveyId} updateSurveyData={props.updateSurveyData}/>
            )}
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={4} lg={4} md={3}>
              <Autocomplete 
                limit={1}
                label="city"
                value={cities}
                options={props.data.city}
                getOptionLabel={(option) => option}
                onChange={(value) => setCities(value)}
              />
            </Grid>

            <Grid item xs={3} lg={3} md={3}>
            <Autocomplete 
              limit={1}
              label="state"
              value={cities}
              options={props.data.state}
              getOptionLabel={(option) => option}
              onChange={(value) => setStates(value)}
            />
            </Grid>

            <Grid item xs={2} lg={3} md={3}>
            <Autocomplete 
              limit={1}
              label="country"
              value={cities}
              options={props.data.country}
              getOptionLabel={(option) => option}
              onChange={(value) => setCounties(value)}
            />
            </Grid>

            <Grid item xs={2} lg={2} md={3}>
              <Button variant="contained" color="secondary" style={{marginLeft:"5px"}} onClick={filterSubmit}>Filter</Button>
              {permission.isForm && <Button variant="contained" color="secondary" style={{marginLeft:"5px"}}onClick={(e)=>handleForm(e)}>Form </Button>}
              <Button variant="contained" color="secondary" style={{marginLeft:"5px"}} onClick={(e)=>handleEdit(e)}>Edit</Button>
            </Grid>
 
            
        </Grid>
      </Fragment>
    </Paper>
  )
}