
import React, { Fragment, useState } from "react";
import "antd/dist/antd.css";
import { ThemeProvider } from '@material-ui/core/styles';
import { Table } from "antd";
import { Button, CircularProgress, Card, CardContent, List, ListItem ,Grid} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import './matrix.css'
export default (props) => {
  const rowValueSecNew =  ["Illiterate","School Upto 4 yrs", "School 5-9 yrs", "HSC/SSC", 
    "Some college but not Grad", "Grad/Post Grad Gen", "Grad/ Post Grad Prof"]
    
    const columnValueSecNew = ["Electricity connection","Ceiling fan", "LPG Stove", 
    "Two wheeler", "Colour TV", "Refrigerator","Washing machine", "Personal computer/ Laptop",
    "Car/ jeep/ Van", "Agricultural land"]

  const [checkedValue, setCheckedValue] = useState([])
  const [radioValue, setRadioValue] = React.useState('');
  const [answer, setAnswer] = useState('');
  const [progressbar, setProgressbar] = useState(false);
  const [isAnswered,setIsAnswered] = useState(false)
  
    const handleRadioChange = (event) => {
      setRadioValue(event.target.value);
      let answerOut = setNewAnswer(checkedValue.length , event.target.value)
      setAnswer(answerOut)
    };

    const handleCheckBoxChange = (event) =>{
      let data = [...checkedValue]
      const index = data.indexOf(event.target.value);
      if (index > -1){
        data.splice(index, 1);
      }else{
        data.push(event.target.value)
      }
      setCheckedValue(data)
      if (radioValue != ""){
        let answerOut = setNewAnswer(data.length , radioValue)
        setAnswer(answerOut)
      }
      
    }

    const handleTextRadioChange = (e, value) => {
      e.preventDefault()
      setRadioValue(value);
      let answerOut = setNewAnswer(checkedValue.length , value)
      setAnswer(answerOut)
    };

    const handleTextCheckBoxChange = (e, value) =>{
      e.preventDefault()
      let data = [...checkedValue]
      const index = data.indexOf(value);
      if (index > -1){
        data.splice(index, 1);
      }else{
        data.push(value)
      }
      setCheckedValue(data)
      if (radioValue != ""){
        let answerOut = setNewAnswer(data.length , radioValue)
        setAnswer(answerOut)
      }
      
    }


  const submitAnswer = () => {
  if ( answer  == ""){
    setIsAnswered(true)
  }
  else{
    setIsAnswered(false)
    setProgressbar(true)
  
    props.submit({
        value: [answer]
    })
  }
  }


  const setNewAnswer = (count, value)=>{
    if (count == 0){
      if ( ["Illiterate","School Upto 4 yrs", "School 5-9 yrs", "HSC/SSC", 
      "Some college but not Grad", "Grad/Post Grad Gen"].includes(value)){
        return "E"
      }else{
        return "D"
      }
    }
    if (count == 1){
      if ( ["Illiterate","School Upto 4 yrs", "School 5-9 yrs", "HSC/SSC"].includes(value)){
        return "E"
      }else{
        return "D"
      }
    }
    if (count == 2){
      if ( ["Illiterate","School Upto 4 yrs"].includes(value)){
        return "E"
      }else{
        return "D"
      }
    }
    if (count ==  3){
      if ( ["Illiterate","School Upto 4 yrs", "School 5-9 yrs", "HSC/SSC"].includes(value)){
        return "D"
      }else{
        return "C"
      }
    }
    if (count ==  4){
      if ( ["Illiterate"].includes(value)){
        return "D"
      }else if (["Illiterate","School Upto 4 yrs", "School 5-9 yrs", "HSC/SSC", 
        "Some college but not Grad"].includes(value)) {
        return "C"
      }else{
        return "B"
      }
    }
    if (count ==  5){
      if ( ["Illiterate","School Upto 4 yrs", "School 5-9 yrs"].includes(value)){
        return "C"
      }else{
        return "B"
      }
    }
    if (count == 6){
      if ( ["Illiterate"].includes(value)){
        return "C"
      }
      else if (["School Upto 4 yrs", "School 5-9 yrs", "HSC/SSC"].includes(value)) {
        return "B"
      }else{
        return "A"
      }
    }
    if (count ==  7){
      if ( ["Illiterate"].includes(value)){
        return "C"
      }
      else if (["School Upto 4 yrs", "School 5-9 yrs"].includes(value)) {
        return "B"
      }else{
        return "A"
      }
    }
    if (count ==  8){
      if ( ["Illiterate"].includes(value)){
        return "B"
      }
      else{
        return "A"
      }
    }
    if (count >= 9){
      if ( ["Illiterate"].includes(value)){
        return "B"
      }
      else{
        return "A"
      }
    }
  }
  

    return (
      <Fragment>
        <Grid container spacing={4} justify="center">
          <Grid item xs={11} lg={5} md={5}>
            <Card className="card-box mb-2"  style={{backgroundColor: props.theme.componentBgColorRgba, opacity: props.theme.componentOpacity}}>
                  <div className="card-header">
                      <div className="card-header--title">
                          <h5 className="mb-0 py-1 " style={{ fontFamily:props.theme.fontFamily , color: props.theme.fontColor }}>
                          Please take a look at this list and tell me which of these items do you have at home?
                          </h5>
                    </div>
                  </div>
                  <CardContent className="p-0">
                      <List className="my-1" >
                      {
                        columnValueSecNew.map((column,j) => (
                            <ListItem className="d-flex justify-content-between align-items-center py-1 border-0" key={j}>
                                      <Grid container spacing={1} >
                                        <Grid item xs={2} lg={2} md={2}>
                                            <input 
                                              type="checkBox"
                                              checked={checkedValue.indexOf(column) > -1}
                                              onChange={handleCheckBoxChange}
                                              name={column}
                                              value={column}
                                              style={{cursor: 'pointer' , height:'22px', width:'22px'} }
                                            />
                                        </Grid>
                                        <Grid item xs={10} lg={10} md={10}>
                                        <span  style={{ fontFamily:props.theme.fontFamily , 
                                          color: props.theme.fontColor , fontSize: props.theme.componentFontSize, cursor: 'pointer'}}
                                          onClick={(e)=>handleTextCheckBoxChange(e, column)}
                                          >
                                            {column}
                                        </span>
                                        </Grid>
                                      </Grid>
                                      </ListItem>
                          ))
                      }
                      </List>
                  </CardContent>
            </Card>
          </Grid>
          <Grid item xs={11} lg={5} md={5}>
                      <Card className="card-box mb-2"  style={{backgroundColor: props.theme.componentBgColorRgba, opacity: props.theme.componentOpacity}}>
                      <div className="card-header">
                          <div className="card-header--title">
                              <h5 className="mb-0 py-1 " style={{ fontFamily:props.theme.fontFamily , color: props.theme.fontColor }}>
                              Please select your Education
                              </h5>
                        </div>
                      </div>
                      <CardContent className="p-0">
                          <List className="my-1" >
                          {
                            rowValueSecNew.map((row,j) => (
                                <ListItem className="d-flex justify-content-between align-items-center py-1 border-0" key={j}>
                                          <Grid container spacing={1} >
                                            <Grid item xs={2} lg={2} md={2}>
                                                <input 
                                                  type="radio"
                                                  checked={radioValue == row}
                                                  onChange={handleRadioChange}
                                                  name={"education"}
                                                  value={row}
                                                  style={{cursor: 'pointer' , height:'22px', width:'22px'} }
                                                />
                                            </Grid>
                                            <Grid item xs={10} lg={10} md={10}>
                                            <span  style={{ fontFamily:props.theme.fontFamily , 
                                              color: props.theme.fontColor , fontSize: props.theme.componentFontSize, cursor: 'pointer'}}
                                              onClick={(e)=>handleTextRadioChange(e, row)}
                                              >
                                                {row}
                                            </span>
                                            </Grid>
                                          </Grid>
                                          </ListItem>
                              ))
                          }
                          </List>
                      </CardContent>
                </Card>
          </Grid>
      </Grid>
      {answer !="" ? <div>
                <h4 style={{textAlign:"center"}}> {answer} </h4>
            </div> : null}
      {isAnswered ? <div>
          <Alert severity="error">Please Answer </Alert>
          </div> : null}
        <div className="d-block" >
          {props.preview ? null : (<Button className="mt-3" variant={props.theme.buttonVariant} color={props.theme.color}  onClick={submitAnswer}>Submit</Button>)}
          { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
        </div>
    </Fragment>
    );

}
