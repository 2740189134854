import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';
import { Grid, Card, Typography, LinearProgress } from '@material-ui/core';
// import VideoPlayer from 'simple-react-video-thumbnail'

export default (props) => {
    let data = [...props.values];
    data.sort((a, b) => {
        return b.count - a.count;
    });

    return (
        <div style={{maxHeight:'500px', overflowY:'scroll'}}>
            {props.analyseStatus && props.type == 'video' ? (<LinearProgress />) : null}
            <div style={{padding: '10px'}} ></div>
            <Grid container spacing={3} >
                {data.map(
                    (e) =>
                        <Grid item xs={3} >
                            <Card className="card-body">
                                <div>
                                    {props.type == "video" && 
                                    <video width="350" height="250" controls src={e.url} />
                                        // <VideoPlayer style={{width:'250px', height:'250px'}} videoUrl={e.url} snapshotAt={10} />
                                    }
                                    {props.type == "audio" &&
                                        <div align="center">
                                            <audio controls>
                                                <source src={e.url} />
                                            </audio>
                                        </div>

                                    }
                                    {props.type == "file" &&
                                        <div>
                                            File
                                        </div>

                                    }
                                    <Typography variant="body2" component="p" align="center">
                                        <Grid container>
                                            <Grid item xs={6}>

                                                Age: {e.age || "NA"}
                                                <br />
                                        Gender: {e.gender || "NA"}
                                            </Grid>
                                          {/*< Grid item xs={6}>
                                                City: {e.city || "NA"}
                                                <br />
                                        State: {e.state || "NA"}
                                                <br />
                                        Country: {e.country || "NA"}
                                                <br /> 
                                            </Grid>*/}
                                        </Grid>
                                    </Typography>
                                </div>
                            </Card>
                        </Grid>
                )
                }
            </Grid>
        </div>
    );
}