import React, { useState, Fragment, useRef } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {
  IconButton,
  Button, 
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { CloudUpload, Delete, Description } from '@material-ui/icons';
import { CircularProgress} from '@material-ui/core';
export default (props) => {

  let accept = "";
  props.questionObject.options.forEach((e) => {
    accept += "," + e 
  })
  const [files, setFiles] = useState(props.answer.files || []);
  const [isAnswered,setIsAnswered] = useState(false)
  let fileUpload = useRef();
  const [progressbar, setProgressbar] = useState(false);
  const openFileUpload = () => {
    fileUpload.current.click();
  }

  const uploadFiles = (event) => {
    if (fileUpload.current.files.length > 0 && fileUpload.current.files[0].name) {
      let newFiles = [];
      if (props.questionObject.multiple) {
        newFiles = [ ...files ];
      }
      for (let i = 0; i < fileUpload.current.files.length; i++) {
        let e = fileUpload.current.files[i];
        let fileName = e.name.replace(/\s+/g,"_");
        newFiles.push(new File([e], fileName, { type: e.type }))
      }
      setFiles(newFiles);
      saveValue(newFiles);
    }
  }

  const removeFile = (index) => {
    let newFiles = [ ...files ];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    saveValue(newFiles);
  }

  const saveValue = (fileList) => {
    if (!props.preview) {
      props.save({
        files: fileList
      });
    }
  }

  const submitAnswer = () => {
    if (files.length === 0 )
    {
      setIsAnswered(true)
    }
    else{
      setIsAnswered(false)
      setProgressbar(true)
      props.submit({
        files: files
      })
    }
  }

  return (
    <Fragment>
      <ThemeProvider theme={props.theme.pallet}>
      <div className="ml-3">
        <List>
          {files.map((e, i) => (
            <ListItem  key={"x"+i}>
              <ListItemAvatar>
                <Avatar>
                  <Description />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={e.name}
              />
              <ListItemSecondaryAction>
                <IconButton color={props.theme.color} edge="end" aria-label="delete" onClick={removeFile.bind(null, i)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Button color={props.theme.color} variant={props.theme.buttonVariant} startIcon={<CloudUpload />} onClick={openFileUpload}>Upload</Button>
        <input type="file" accept={accept} multiple={props.questionObject.multiple || false} className="d-none" ref={fileUpload} onChange={uploadFiles} />
      </div>
      {isAnswered ? <div>
          <Alert severity="error">Please Answer </Alert>
          </div> : null}
      {props.preview ? null : (<Button className="mt-4" color={props.theme.color} variant={props.theme.buttonVariant} onClick={submitAnswer}>Submit</Button>)}
      { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
      </ThemeProvider>
    </Fragment>
  )
}