import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { SENTIMENTS } from './constant';
import { Survey } from './../../../Store/ApplicationStore';

const CONTENT_HEIGHT = 240;
const COLORS = Object.keys(SENTIMENTS).sort().map((e) => SENTIMENTS[e].color);

const ChartOptions = {
  chart: {
    id: 'respondentEmotion',
    type: 'scatter',
    height: CONTENT_HEIGHT,
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    }
  },
  colors: COLORS,
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left'
  },
  xaxis: {
    type: 'numeric',
    labels: {
      formatter: function(e) {
        return parseInt(e / 60) + ":" + parseInt(e % 60)
      }
    }
  },
  yaxis: {
    labels: {
      formatter: function(e) {
        const emot = Object.keys(SENTIMENTS);
        for (let i = 0; i < emot.length; i += 1) {
          if (SENTIMENTS[emot[i]].value === e) {
            return SENTIMENTS[emot[i]].text;
          }
        }
        return '';
      }
    }
  },
  grid: {
    borderColor: '#f1f1f1',
  }
};

const structureChartData = (emotionArr) => {
  const baseData = {};
  Object.keys(SENTIMENTS).forEach((e) => {
    baseData[e] = {
      name: SENTIMENTS[e].text,
      data: []
    };
  });

  Object.keys(emotionArr).forEach((time) => {
    const emotion = emotionArr[time].toLowerCase();
    baseData[emotion].data.push({
      x: parseInt(time, 10),
      y: SENTIMENTS[emotion].value
    })
  });

  const arrayConvert = [];
  Object.keys(baseData).sort().forEach((e) => {
    arrayConvert.push(baseData[e]);
  });
  return arrayConvert;
};

const RespondentData = ({
  batchId,
  questionId,
  videoId
}) => {
  const [info, setInfo] = useState({
    url: '',
    data: [],
    loading: true
  });

  const fetchFacialData = async (payload) => {
    try {
      const response = await Survey.getAdSurveyDashboard(payload);
      if (response.status === 'success') {
        const chartData = structureChartData(response.value[0].videoExpression || {});
        setInfo({
          url: response.value[0]?.videoUrl,
          loading: false,
          data: chartData
        });
      }
    } catch (err) {

    }
  };

  useEffect(() => {
    if (batchId && questionId && videoId) {
      fetchFacialData({
        batchId,
        questionId,
        videoId
      });
    }
  }, [batchId, questionId, videoId]);

  return (
    <Content>
      {videoId ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader
                title="Respondent's Video"
                titleTypographyProps={{
                  variant: 'h5'
                }}
              />
              <CardContent>
                {info.loading ? (
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={CONTENT_HEIGHT}
                  />
                ) : (
                  <VideoNode
                    src={info.url}
                    controls
                    controlsList="no-download"
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader
                title="Respindent's Sentiment"
                titleTypographyProps={{
                  variant: 'h5'
                }}
              />
              <CardContent>
                {info.loading ? (
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={CONTENT_HEIGHT}
                  />
                ) : (
                  <Chart
                    options={ChartOptions}
                    series={info.data}
                    height={CONTENT_HEIGHT}
                    type="scatter"
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Typography style={{ margin: 'auto', padding: '20px' }}>Select the user to see their response</Typography>
      )}
    </Content>
  );
};

export default RespondentData;

const Content = styled.div`
  display: flex;
  flex: 1;
  background-color: #eaeaea;
  padding: 15px;
`;

const VideoNode = styled.video`
  width: 100%;
  height: ${CONTENT_HEIGHT + 10}px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #000;
`;
