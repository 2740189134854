import React, { Fragment, useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Grid,
  Box,
  Typography,
  Dialog,
  Tabs,
  Tab,
  Card,
  Button,CircularProgress,
  TextField
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components';
import { Survey, Authorization } from '../../../Store/ApplicationStore';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};


function CreateDialog(props) {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleBreadcrumb,
    pageTitleIconBox,
    pageTitleDescription
  } = props;


  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [value, setValue] = React.useState(0);
  const [permission, setPermission] = useState({
    isDownload: true,
    isForm: true,
    isAiAnalysis: true,
    isVendor: false,
    isBrand:false
  });


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [excelFullList, setExcelFullList] = useState([])
  const [excelDoneList, setExcelDoneList] = useState([])
  const [transcriptFullList, setTranscriptFullList] = useState([])
  const [operationFullList, setOperationFullList] = useState([])
  const [operationDoneList, setOperationDoneList] = useState([])
  const [excelProgressbar, setExcelprogressbar] = useState(false)

  const [open, setOpen] = React.useState(false);

  const getPermission = async () => {
    const per = await Authorization.getPermission();
    setPermission(per);
  };

  useEffect(()=>{
    getPermission();
    getData()
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const getData = ()=>{
    Survey.getAllSurveys(props.surveyId).then((data) => {
      if (data.length != 0){
        let dataOut = data[0]
          try{
            setOperationDoneList(dataOut.excelDownload.hasOwnProperty('operation')  ? 
              dataOut.excelDownload.operation.hasOwnProperty('done') ?
              dataOut.excelDownload.operation.done : []: [])

              setOperationFullList(dataOut.excelDownload.hasOwnProperty('operation')  ? 
              dataOut.excelDownload.operation.hasOwnProperty('full') ?
              dataOut.excelDownload.operation.full : []: [])

              setExcelDoneList(dataOut.excelDownload.hasOwnProperty('excel')  ? 
              dataOut.excelDownload.excel.hasOwnProperty('done') ?
              dataOut.excelDownload.excel.done : []: [])

              setExcelFullList(dataOut.excelDownload.hasOwnProperty('excel')  ? 
              dataOut.excelDownload.excel.hasOwnProperty('full') ?
              dataOut.excelDownload.excel.full : []: [])
              
              setTranscriptFullList(dataOut.excelDownload.hasOwnProperty('transcript')  ? 
              dataOut.excelDownload.transcript : [])
            
          }catch(e){

          }
        
          }
      })
  }
  


  const generateExcel = (e,isOperation, onlyDone, excelType) =>{
    e.preventDefault()
    setExcelprogressbar(true)
    let Data = {
      'batchId': props.surveyId ,
      'isOperation':isOperation,
      'onlyDone':onlyDone,
      'excelType': excelType
    }

    Survey.getRawFullData(Data).then((e)=>{
      if (e.status === 'success'){
      
      const link = document.createElement('a');
      link.href = e.value[1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
      setExcelprogressbar(false)
    })
  }

 

const TableContentExcel = (props) => {
  let fullList = []
  let doneList = []
  let textFordownload = "Download Previous Only Done"
  if (props.operation){
    fullList = operationFullList
    doneList = operationDoneList
    textFordownload = "Download Previous Only OP-Done"
  }else{
    fullList = excelFullList
    doneList = excelDoneList
  }

  return (
    <Fragment>
  { fullList.length != 0 ?
          <Fragment>
              <tr>
              <td className="text-center">
                  {fullList[0]}
              </td>
  
              <td className="text-center" style={{minWidth: '30px'}}>
              <a href={fullList[1]} download  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary">Download Previous Full Dump</a>
              </td>
              
          </tr>
      </Fragment>   : null }
      { doneList.length != 0 ?
        <Fragment >
            <tr>
            <td className="text-center">
                {doneList[0]}
            </td>

            <td className="text-center" style={{minWidth: '30px'}}>
            <a href={doneList[1]} download  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary">{textFordownload}</a>
            </td>
            
        </tr>
    </Fragment>  :
     null }

  </Fragment>
    
    
    
    );
}




  return (
    <Fragment>   
        {!open && (
      <div className="d-flex align-items-center">
        <Button 
                    onClick={toggle1} 
                    // size="small"
                    // variant="outlined"
                    color="primary"
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary">
                    Download
        </Button>
      <Dialog scroll="body" maxWidth="lg" open={modal1} onClose={toggle1} >
        <Grid container spacing={0} style={{minWidth:'450px'}}>
          <Grid item xs={12} lg={12}>
            <div className="bg-white ">
            {permission.isVendor ? <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleChange}> 
                <Tab className="py-3" label="operation Data" />    
              </Tabs> :
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleChange}> 
              
                <Tab className="py-3" label="operation Data" />
                <Tab className="py-3" label="Transcript Data" />
                <Tab className="py-3" label="Full Data" />          
              </Tabs>
              } 
              <TabPanel value={value} index={0}>
                    <Fragment>
                    {permission.isVendor ? null :
              
                          <Grid container spacing={10} justifyContent='center' style={{ maxHeight: '500px', overflowY:'scroll', minWidth: '650px'}}>
                                  <Grid item xs={12} md={8} lg={10} >
                                  <Card className="card-box mb-4">
                                  <div className="table-responsive">
                                  <table className="table  table-hover table-bordered text-nowrap mb-0">
                                      <thead className="thead-light">
                                      <tr>
                                          <th className="text-center" style={{ width: '50%' }}>Date</th>
                                          <th  className="text-center" style={{ width: '50%'}}>DownloadLink</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                        <TableContentExcel operation={true}/>
                                      </tbody>
                                  </table>
                                  </div>
                                  
                              </Card>
                          </Grid>
                        </Grid>
                      }
                      </Fragment>
                      <Grid container justifyContent = "center" className="mt-2">
                        
                      <Button variant='contained' color="secondary" className="m-1" onClick={(e)=>generateExcel(e, true ,false, 'excel')}>Generate New Full Dump </Button>
                      <Button variant='contained' color="secondary" className="m-1" onClick={(e)=>generateExcel(e, true, true, 'excel')}>Generate New Only OP-Done </Button> 
                        { excelProgressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
                  
                      </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                    <Fragment>
                          <Grid container spacing={10} justifyContent='center' style={{ maxHeight: '500px', overflowY:'scroll', minWidth: '600px'}}>
                                  <Grid item xs={12} md={8} lg={10} >
                                  <Card className="card-box mb-4">
                                  <div className="table-responsive">
                                  <table className="table  table-hover table-bordered text-nowrap mb-0">
                                      <thead className="thead-light">
                                      <tr>
                                          <th className="text-center" style={{ width: '50%' }}>Date</th>
                                          <th  className="text-center" style={{ width: '50%'}}>DownloadLink</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      { transcriptFullList.length != 0 ?
                                              <Fragment >
                                                  <tr>
                                                  <td className="text-center">
                                                      {transcriptFullList[0]}
                                                  </td>

                                                  <td className="text-center" style={{minWidth: '30px'}}>
                                                  <a href={transcriptFullList[1]} download  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary">Download Previous</a>
                                                  </td>
                                                  
                                              </tr>
                                          </Fragment>  :
                                          null }
                                      </tbody>
                                  </table>
                                  </div>
                                  
                              </Card>
                          </Grid>
                        </Grid>
                      </Fragment>
                      <Grid container justifyContent = "center" className="mt-2">
                        
                      <Button variant='contained' color="secondary" className="m-1" onClick={(e)=>generateExcel(e, true ,false , 'transcript')}>Generate New Dump </Button>
                        { excelProgressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
                  
                      </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                    <Fragment>
                          <Grid container spacing={10} justifyContent='center' style={{ maxHeight: '500px', overflowY:'scroll', minWidth: '650px'}}>
                                  <Grid item xs={12} md={8} lg={10} >
                                  <Card className="card-box mb-4">
                                  <div className="table-responsive">
                                  <table className="table  table-hover table-bordered text-nowrap mb-0">
                                      <thead className="thead-light">
                                      <tr>
                                          <th className="text-center" style={{ width: '50%' }}>Date</th>
                                          <th  className="text-center" style={{ width: '50%'}}>DownloadLink</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                        <TableContentExcel operation={false}/>
                                      </tbody>
                                  </table>
                                  </div>
                                  
                              </Card>
                          </Grid>
                        </Grid>
                      </Fragment>
                      <Grid container justifyContent = "center" className="mt-2">
                      <Button variant='contained' color="secondary" className="m-1" onClick={(e)=>generateExcel(e, false, false, 'excel')}>Generate New Full Dump </Button>
                      <Button variant='contained' color="secondary"  className="m-1"onClick={(e)=>generateExcel(e, false, true, 'excel')}>Generate New Only Done </Button>
                        { excelProgressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
                  
                      </Grid>
              </TabPanel>
            </div> 
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )}
  </Fragment>
  );
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(CreateDialog);
