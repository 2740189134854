import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  IconButton, Button
} from '@material-ui/core';
import {
  Favorite,
  FavoriteOutlined,
  OpenInBrowser
} from '@material-ui/icons';
import {
  Survey
} from '../../../../Store/ApplicationStore';
import { NEGATIVE_COLOR, WOMAN_COLOR} from './../constants';
import { IoHeartOutline, IoHeartSharp,IoPlaySkipForwardCircle } from "react-icons/io5";

const useStyles = makeStyles({
  root: {
    height: 244,
    overflowY: 'auto'
  },
  media: {
    height: 180,
   
  },
});

const VideoElement = ({
  data,
  openVideo,
  expand
}) => {
  const [showTranscript, setShowTranscript] = useState(expand);
  const [isFavorite, setFavorite] = useState(data.isFavorite);
  const classes = useStyles();

  const toggleFavorite = () => {
    setFavorite((fav) => {
      Survey.toggleFavoriteVideo({
        isFavorite: !fav,
        id: data.id
      });
      return !fav;
    });
  };

  useEffect(() => {
    setShowTranscript(expand);
  }, [expand]);

  return (



    <Grid container spacing={ 0 } justifyContent="center">
      <Grid item xs={12} md={12} lg={10}>
            <div className="card card-img-wrapper rounded card-box-hover-rise p-4 m-2 align-item-center" style={{ cursor:"pointer"}}>
                <div className="card-badges card-badges-bottom">

                <IconButton
                  aria-label="add to favorites"
                  onClick={toggleFavorite}
                  style={{marginRight: '15px', marginBottom: '10px'}}
                 >
                {isFavorite ? (
                        <IoHeartSharp  style={{color: NEGATIVE_COLOR}}  size="24"  className="opacity-9"/>
                      ) : (
                        <IoHeartOutline  style={{ color: data.thumbnail != '' ? 'white' : NEGATIVE_COLOR}} size="24" />
                      )}</IconButton>

                </div> 
                { data.thumbnail != '' ? 
                <img alt="..." className="card-img-top rounded" src={data.thumbnail} onClick={(e) => openVideo(data.videoId) } style={{minWidth: '120px', minHeight:'120px', margin:'0 auto'}} /> :
                <div style={{minWidth: '100px', minHeight:'100px', margin:'0 auto' , textAlign:'center'} } onClick={(e) => openVideo(data.videoId)}>
                <IoPlaySkipForwardCircle  style={{color: WOMAN_COLOR}}  size="64"  className="opacity-9"/>
                </div> }

            </div>


       </Grid>
    </Grid>
  )
};

export default VideoElement;
