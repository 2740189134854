import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  LinearProgress
} from '@material-ui/core';
import MediaDisplay from './util/VideoDisplay';
import { Questionaire } from '../../../Store/ApplicationStore';

let promiseArrayRequested = 0;
let fullfilledPromises = 0;

const RandomizeRender = ({
  questionObject,
  extraInfo,
  save,
  submit,
  preview,
  questionPreview,
  theme,
  allQuestions,
  surveyId,
  questionId,
  beQuestionId
}) => {
  const [adOrder, setAdOrder] = useState([]);
  const [currentAd, setCurrentAd] = useState(0);
  const [selectSet, setSelectSet] = useState([]);
  const [fileNameOrder, setFileNameOrder] = useState([]);
  const [mediaAnswer, setMediaAnswer] = useState({});
  const [questionsAfterMedia, setQuestionsAfterMedia] = useState([]);

  const chooseSetToDisplay = (valueCount, answerValue) => {
    let inititalAdOrder = [];
    let selectedSet = [];
    let fileNames = [];
    const adNameMapping = {};

    const numberOfSets = extraInfo?.displayRule?.numberOfSetUserSee || 1;
    extraInfo.allMediaList.forEach((e) => {
      adNameMapping[e.fileName] = e.fileUrl;
    });
   
    const optionsSet = questionObject.options || [];
    const toChooseSet = [];
    optionsSet.forEach((e) => {
      const currentValue = valueCount[e] !== undefined ? valueCount[e] : 0;
      let maxValue = Infinity;
      if (answerValue !== null && extraInfo?.displayRule?.optionsRule?.values !== undefined) {
        const quotaValue = extraInfo.displayRule.optionsRule.values;
        if (quotaValue[answerValue] !== undefined && quotaValue[answerValue][e] !== undefined) {
          maxValue = quotaValue[answerValue][e] < 0 ? Infinity : quotaValue[answerValue][e];
        }
      }
      if (maxValue > currentValue) {
        toChooseSet.push({
          name: e,
          order: Math.random()
        });
      }
    });
    toChooseSet.sort((a, b) => {
      return a.order - b.order;
    })

    selectedSet = toChooseSet.slice(0, numberOfSets).map((e) => e.name);
    fileNames = [];
    for (let i = 0; i < extraInfo.setList.length; i += 1) {
      if (selectedSet.indexOf(extraInfo.setList[i].setName) >= 0) {
        extraInfo.setList[i].mediaList.forEach((name) => {
          fileNames.push({
            name,
            order: Math.random()
          });
        });
      }
    }
    fileNames.sort((a, b) => {
      return a.order - b.order;
    });

    inititalAdOrder = fileNames.map((e) => {
      if (adNameMapping[e.name]) {
        return adNameMapping[e.name];
      }
      return null;
    });

    return {
      inititalAdOrder,
      selectedSet,
      fileNames
    };
  };

  const increaseAdCount = () => {
    setCurrentAd((cnt) => cnt + 1);
  }

  const addAnswerToQuestions = ({
    mediaName,
    feQuestionId,
    answerType,
    answer
  }) => {
    setMediaAnswer((mediaAns) => {
      const ans = { ...mediaAns };
      if (ans[mediaName] === undefined) {
        ans[mediaName] = {};
      }
      ans[mediaName][feQuestionId] = {
        answerType,
        answer
      }
      return ans;
    });
  };

  const answerToQuestions = async ({
    mediaIndex,
    answerType,
    feQuestionId,
    answer
  }) => {
    const mediaName = fileNameOrder[mediaIndex].name;
    if (answer.hasOwnProperty('files')) {
      const allFilePromise = [];
      const newAnswer = {
        ...answer
      };
      answer.files.forEach(element => {
        allFilePromise.push(Questionaire.uploadFile(surveyId, questionId, element));
      });
      promiseArrayRequested += 1;
      try {
        newAnswer.value = await Promise.all(allFilePromise);
        delete newAnswer.files;
        fullfilledPromises += 1;
        addAnswerToQuestions({
          mediaName,
          feQuestionId,
          answerType,
          answer: newAnswer
        });
      }
      catch (err) {
        throw err;
      }
    }
    else {
      addAnswerToQuestions({
        mediaName,
        feQuestionId,
        answerType,
        answer
      });
    }
  };

  const submitAnswer = () => {
    submit({
      value: {
        order: fileNameOrder.map((e) => e.name),
        mediaQuestions: mediaAnswer
      },
      setValue: selectSet
    });
  };

  const getQuotaBasedList = async () => {
    try {
      let currentValues = null;
      let answerValue = null;
      if (extraInfo?.displayRule?.optionsRule?.questionId?.length > 0) {
        for (let i = 0; i < allQuestions.length; i += 1) {
          if (allQuestions[i].feQId === extraInfo.displayRule.optionsRule.questionId[0]) {
            answerValue = allQuestions[i].answer.value;
            currentValues = await Questionaire.getQuotaCount({
              answer: answerValue,
              questionId: allQuestions[i].questionId,
              quotaQuestionId: beQuestionId
            });
            break;
          }
        }
      }
      const valueCount = {};
      if (currentValues?.success === true && currentValues.value.length > 0) {
        currentValues.value.forEach((e) => {
          valueCount[e.value] = e.count
        });
      }
      const {
        inititalAdOrder,
        selectedSet,
        fileNames
      } = chooseSetToDisplay(valueCount, answerValue);
      setAdOrder(inititalAdOrder);
      setSelectSet(selectedSet);
      setFileNameOrder(fileNames);
    } catch (err) {}
  };

  useEffect(() => {
    getQuotaBasedList();
    setCurrentAd(0);
    promiseArrayRequested = 0;
    fullfilledPromises = 0;
  }, []);

  useEffect(() => {
    const tempList = [];
    if (extraInfo.displayRule?.questionsAfterMedia?.length > 0 && allQuestions?.length > 0) {
      allQuestions.forEach((q) => {
        if (extraInfo.displayRule.questionsAfterMedia.indexOf(q.feQId) >= 0) {
          tempList.push({
            ...q,
            answer: {}
          });
        }
      });
    }
    setQuestionsAfterMedia(tempList);
  }, [allQuestions]);

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <MediaDisplay
          mediaArray={adOrder}
          currentMedia={currentAd}
          increaseAdCount={increaseAdCount}
          questionsAfterMedia={questionsAfterMedia}
          answerToQuestions={answerToQuestions}
          theme={theme}
          preview={preview}
          questionPreview={questionPreview}
        />
      </Grid>
      <Grid item xs={12}>
        <div>
          {(currentAd < adOrder.length || promiseArrayRequested > fullfilledPromises) ? null : (
            <Button
              className="mt-3"
              variant={theme.buttonVariant}
              color={theme.color}
              onClick={submitAnswer}
            >
              Submit
            </Button>
          )}
          {promiseArrayRequested > fullfilledPromises && (
            <LinearProgress />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default RandomizeRender;
