import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  FormControl,
  Select,
  Input,
  Chip,
  MenuItem,
  Typography,
  Tooltip
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TableInput from './TableInput';
import { Questionaire } from '../../../../Store/ApplicationStore';

const questionIdMap = {};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    maxWidth: '300px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  table: {
    minWidth: 650,
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const QuotaSelection = ({
  setList,
  updateQuota,
  optionLimit,
  allQuestions,
  feQId
}) => {
  const [questionList, setQuestionList] = useState([]);
  const [questionSelected, setQuestionSelected] = useState(optionLimit?.questionId !== undefined ? optionLimit.questionId : []);
  const [limitValue, setLimitValue] = useState(optionLimit.values !== undefined ? optionLimit.values : {});
  const [currentCount, setCurrentCount] = useState({});
  const classes = useStyles();

  const updateQuestion = (event) => {
    const selValue = [event.target.value];
    setQuestionSelected(selValue);
    getCountForQuestion(selValue[0]);
    const values = {};
    if (selValue?.length > 0) {
      const id = selValue[0];
      for (let i = 0; i < allQuestions.length; i += 1) {
        if (allQuestions[i].feQId === id) {
          const options = allQuestions[i].questionObject.options;
          options.forEach((op) => {
            values[op] = {};
            setList.forEach((s) => {
              values[op][s.setName] = -1;
            });
          });
        }
      }
    }
    setLimitValue(values);
    updateQuota({
      questionId: selValue,
      values
    });
  };

  const updateLimit = (val, option, set) => {
    const newLimit = {...limitValue};
    newLimit[option][set] = val;
    setLimitValue(newLimit);
    updateQuota({
      questionId: questionSelected,
      values: newLimit
    });
  }

  const getCountForQuestion = async (id) => {
    const allPromiseArray = [];
    let optionValues = [];
    let quotaQuestionId = '';
    let questionId = '';
    for (let i = 0; i < allQuestions.length; i += 1) {
      if (allQuestions[i].feQId === id) {
        optionValues = allQuestions[i].questionObject.options;
        questionId = allQuestions[i].questionId || '';
      }
      if (allQuestions[i].feQId === feQId) {
        quotaQuestionId = allQuestions[i].questionId || '';
      }
    }
    const newVal = {};
    optionValues.forEach((op) => {
      newVal[op] = {};
      setList.forEach((s) => {
        newVal[op][s.setName] = 0;
      });
    });
    if (quotaQuestionId === '' || questionId === '') {
      setCurrentCount(newVal);
    } else {
      optionValues.forEach((op) => {
        allPromiseArray.push(Questionaire.getQuotaCount({
          answer: [op],
          questionId,
          quotaQuestionId
        }));
      });
      const resValue = await Promise.all(allPromiseArray);
      resValue.forEach((res, index) => {
        if (res.success) {
          const values = res.value;
          values.forEach((val) => {
            newVal[optionValues[index]][val.value] = val.count;
          });
        }
      });
      setCurrentCount(newVal);
    }
  };

  useEffect(() => {
    const list = [];
    allQuestions.forEach((q) => {
      questionIdMap[q.feQId] = q.questionText;
      if (feQId !== q.feQId && q?.questionObject?.options?.length > 0 && typeof q.questionObject.options[0] === 'string') {
        list.push({
          id: q.feQId,
          text: q.questionText
        });
      }
    });
    setQuestionList(list);
  }, [allQuestions]);

  useEffect(() => {
    if (optionLimit?.questionId?.length > 0) {
      getCountForQuestion(optionLimit.questionId[0]);
    }
  }, []);

  return (
    <>
      <Typography>Select Question To Base Quota Limit On</Typography>
      <FormControl fullWidth>
        <Select
          fullWidth
          value={questionSelected}
          onChange={updateQuestion}
          input={<Input />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={questionIdMap[value]} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {questionList.map((ques) => (
            <MenuItem key={ques.id} value={ques.id}>
              {ques.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ padding: '10px' }} />
      {questionSelected.length > 0 && (
        <>
          <Typography color="primary">
            Negative value signifies no limit
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Quota Table
                  </TableCell>
                  {Object.keys(limitValue).length > 0 && Object.keys(limitValue[Object.keys(limitValue)[0]]).map((s) => (
                    <TableCell key={s}>
                      {s}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(limitValue).map((r) => (
                  <TableRow key={r}>
                    <TableCell>
                      {r}
                    </TableCell>
                    {Object.keys(limitValue[r]).map((s) => (
                      <TableCell key={s}>
                        <Cell>
                          <TableInput
                            option={r}
                            set={s}
                            defaultValue={limitValue[r][s]}
                            updateLimit={updateLimit}
                          />
                          <Tooltip title={`Current count ${currentCount[r] !== undefined && currentCount[r][s] !== undefined ? currentCount[r][s] : 0}`}>
                            <Curr>
                              {currentCount[r] !== undefined && currentCount[r][s] !== undefined ? currentCount[r][s] : 0}
                            </Curr>
                          </Tooltip>
                        </Cell>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default QuotaSelection;

const Curr = styled.span`
  margin-left: 10px;
  padding: 10px;
  background-color: #EBEDEF;
  border-radius: 50%;
`;

const Cell = styled.div`
  display: flex;
`;
