import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';

export default (props) => {
  let data = [...props.values];
  data.sort((a, b) => {
    return b.count - a.count;
  });
  let categories = [];
  let values = [];
  data.forEach((e) => {
    categories.push(e.value);
    values.push(e.count);
  })


  const options = {
    chart: {
      type: 'area',
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: categories
    }
  };
  const series = [
    {
      data: values
    }
  ];

  return (
    <div style={{height: 350}}>
      <Chart options={options} series={series} height='100%' type="area" />
    </div>
  );
}