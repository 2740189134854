
import React, { Fragment } from "react";
import "antd/dist/antd.css";
import { ThemeProvider } from '@material-ui/core/styles';
import { Table } from "antd";
import { Button, CircularProgress, Card, CardContent, List, ListItem ,Grid} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import './matrix.css'
export default class MatrixCreate extends React.Component {
  constructor(props) {
    super(props);

    
    let _rowValueSecOld =  ["Illiterate","Literate but no formal schooling","School Upto 4 yrs", "School 5-9 yrs", "SSC/HSC", 
    "Some college but not Grad", "Graduate/ Post Graduate General", "Graduate/ Post Graduate Professional"]
    let _columnValueSecOld = ["Unskilled Workers","Skilled Workers", "Petty traders", 
    "Shop Owners", "Businessmen with No employees", "Businessmen with 1-9 employees","Businessmen with 10+ employees",
     "Self Employed professional","Clerical/Salesman", "Supervisory Level", "Officers/Executives-Junior", "Officers/Executives-Mid/Senior"]

    this.state = {
      isAnswered: false,
      progressbar: false,
      row: props.questionObject.hasOwnProperty('row') ? props.questionObject.row: _rowValueSecOld ,
      column: props.questionObject.hasOwnProperty('column') ? props.questionObject.column: _columnValueSecOld ,
      rowText: props.questionObject.hasOwnProperty('rowText') ? props.questionObject.rowText: "Please select occupation of CWE",
      columnText: props.questionObject.hasOwnProperty('columnText') ? props.questionObject.columnText: "Please select education of CWE",
      answer: "",
      occupationValue:"",
      educationValue:""
    };
  }


  onRadioChangeMobile = e => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    
    if (name === "occupation"){
      if (this.state.educationValue != ""){
        let ansewer = this.setAnswer( value, this.state.educationValue)
        this.setState({
          ...this.state,
          occupationValue:value,
          answer: [ansewer]
        });
      }else{
        this.setState({
          ...this.state,
          occupationValue:value
        });
      }
      
    }else{
      if (this.state.occupationValue != ""){
        let ansewer = this.setAnswer( this.state.occupationValue, value)
        this.setState({
          ...this.state,
          educationValue: value,
          answer: [ansewer]
        });
      }else{
        this.setState({
          ...this.state,
          educationValue:value
        });
      }
    
    }
  };

  onTextClick = (e , name , value) => {
    e.preventDefault()
    if (name === "occupation"){
      if (this.state.educationValue != ""){
        let ansewer = this.setAnswer( value, this.state.educationValue)
        this.setState({
          ...this.state,
          occupationValue:value,
          answer: [ansewer]
        });
      }else{
        this.setState({
          ...this.state,
          occupationValue:value
        });
      }
      
    }else{
      if (this.state.occupationValue != ""){
        let ansewer = this.setAnswer( this.state.occupationValue, value)
        this.setState({
          ...this.state,
          educationValue: value,
          answer: [ansewer]
        });
      }else{
        this.setState({
          ...this.state,
          educationValue:value
        });
      }

    }
}



  setAnswer = (name, value)=>{
    if (name == "Unskilled Workers"){
      if ( ["Illiterate","Literate but no formal schooling","School Upto 4 yrs",].includes(value)){
        return "E2"
      }
      else if ([  "School 5-9 yrs" ].includes(value)){
          return "E1"
      }else{
        return "D"
      }
    }
    if (name == "Skilled Workers"){
      if ( ["Illiterate"].includes(value)){
        return "E2"
      }
      else if (["Literate but no formal schooling","School Upto 4 yrs"].includes(value)){
          return "E1"
      }
      else if (["School 5-9 yrs"].includes(value)){
          return "D"
      }
      else if ([ "SSC/HSC", "Some college but not Grad"].includes(value)){
        return "C"
      }
      else{
        return "B2"
      }
    }
    if (name == "Petty traders"){
      if ( ["Illiterate"].includes(value)){
        return "E2"
      }
      else if (["Literate but no formal schooling","School Upto 4 yrs", "School 5-9 yrs"].includes(value)){
          return "D"
      }
      else if ([ "SSC/HSC", "Some college but not Grad"].includes(value)){
        return "C"
      }
      else{
        return "B2"
      }
    }
    if (name ==  "Shop Owners"){
      if ( ["Illiterate", "Literate but no formal schooling","School Upto 4 yrs"].includes(value)){
        return "D"
      }
      else if (["School 5-9 yrs"].includes(value)){
          return "C"
      }
      else if ([ "SSC/HSC"].includes(value)){
        return "B2"
      }
      else if ([ "Some college but not Grad"].includes(value)){
        return "B1"
      }
      else{
        return "A2"
      }
    }
    if (name ==   "Businessmen with No employees"){
      if ( ["Illiterate"].includes(value)){
        return "D"
      }
      else if ([ "Literate but no formal schooling","School Upto 4 yrs"].includes(value)){
          return "C"
      }
      else if ([ "School 5-9 yrs"].includes(value)){
        return "B2"
      }
      else if ([ "SSC/HSC"].includes(value)){
        return "B1"
      }
      else if (["Some college but not Grad", "Graduate/ Post Graduate General"].includes(value)){
        return "A2"
      }
      else{
        return "A1"
      }
    }
    if (name ==  "Businessmen with 1-9 employees"){
      if ( ["Illiterate"].includes(value)){
        return "C"
      }
      else if ([ "Literate but no formal schooling","School Upto 4 yrs", "School 5-9 yrs"].includes(value)){
          return "B2"
      }
      else if ([ "SSC/HSC"].includes(value)){
        return "B1"
      }
      else if ([ "Some college but not Grad"].includes(value)){
        return "A2"
      }
      else{
        return "A1"
      }
    }
    if (name ==  "Businessmen with 10+ employees"){
      if ( ["Illiterate","Literate but no formal schooling","School Upto 4 yrs"].includes(value)){
        return "B1"
      }
      else if ([  "School 5-9 yrs", "SSC/HSC" ].includes(value)){
          return "A2"
      }
      else{
        return "A1"
      }
    }

    if (name ==  "Self Employed professional"){
      if ( ["Illiterate","Literate but no formal schooling","School Upto 4 yrs", "School 5-9 yrs"].includes(value)){
        return "D"
      }
      else if ([ "SSC/HSC"].includes(value)){
        return "B2"
      }
      else if ([ "Some college but not Grad"].includes(value)){
        return "B1"
      }
      else if (["Graduate/ Post Graduate General"].includes(value)){
        return "A2"
      }
      else{
        return "A1"
      }
    }

    if (name == "Clerical/Salesman"){
      if ( ["Illiterate","Literate but no formal schooling","School Upto 4 yrs", "School 5-9 yrs"].includes(value)){
        return "D"
      }
      else if ([ "SSC/HSC"].includes(value)){
        return "C"
      }
      else if ([ "Some college but not Grad"].includes(value)){
        return "B2"
      }
      else{
        return "B1"
      }
    }
    if (name == "Supervisory Level"){
      if ( ["Illiterate","Literate but no formal schooling","School Upto 4 yrs"].includes(value)){
        return "D"
      }
      else if ([  "School 5-9 yrs", "SSC/HSC"].includes(value)){
          return "C"
      }
      else if ([ "Some college but not Grad"].includes(value)){
        return "B2"
      }
      else if ([  "Graduate/ Post Graduate General"].includes(value)){
        return "B1"
      }
      else{
        return "A2"
      }
    }

    if (name == "Officers/Executives-Junior"){
      if ( ["Illiterate","Literate but no formal schooling","School Upto 4 yrs",  "School 5-9 yrs"].includes(value)){
        return "C"
      }
      else if ([ "SSC/HSC"].includes(value)){
          return "B2"
      }
      else if ([ "Some college but not Grad"].includes(value)){
        return "B1"
      }
      else{
        return "A2"
      }
    }
    if (name == "Officers/Executives-Mid/Senior"){

      if (["Illiterate","Literate but no formal schooling","School Upto 4 yrs", "School 5-9 yrs", "SSC/HSC"].includes(value)){
          return "B1"
      }
      else if ([ "Some college but not Grad"].includes(value)){
        return "A2"
      }
      else{
        return "A1"
      }
    }
  }




  submitAnswer = () => {
  if ( this.state.answer  == ""){
    this.setState({
        isAnswered: true
    })
  }
  else{
    let answerVal = this.state.answer 
    this.setState({
      progressbar: true,
      isAnswered: false,
    });

    this.props.submit({
        value: answerVal
    })
  }
  }
  
  uiForMobile= () =>{



    const RowObj = () => {
      const tifOptions = Object.keys(this.state.row).map(row => (
        <Fragment key={row}>
          <ListItem className="d-flex justify-content-between align-items-center py-1 border-0" key={row}>
                <Grid container spacing={1} >
                  <Grid item xs={2} lg={1} md={1}>
                      <input 
                        type="radio"
                        checked={this.state.educationValue == row}
                        onChange={this.onRadioChangeMobile}
                        name={"education"}
                        value={row}
                        style={{cursor: 'pointer' , height:'20px', width:'20px'} }
                      />
                  </Grid>
                  <Grid item xs={10} lg={10} md={10}>
              <span  style={{ fontFamily:this.props.theme.fontFamily , cursor: 'pointer' , whiteSpace :"pre-wrap",
                color: this.props.theme.fontColor , fontSize:this.props.theme.componentFontSize}}
                onClick={(e)=>this.onTextClick(e,"education", row)}
                >
                  {this.state.row[row]}
              </span>
              </Grid>
            </Grid>
            </ListItem>
        </Fragment>
      ));
      return tifOptions;
    }
    
    const ColumObj = () => {
      const tifOptions = Object.keys(this.state.column).map(column => (
        <Fragment key={column}>
          <ListItem className="d-flex justify-content-between align-items-center py-1 border-0" key={column}>
              <Grid container spacing={1} >
                <Grid item xs={2} lg={1} md={1}>
                    <input 
                      type="radio"
                      checked={this.state.occupationValue == column}
                      onChange={this.onRadioChangeMobile}
                      name={"occupation"}
                      value={column}
                      style={{cursor: 'pointer' , height:'20px', width:'20px'} }
                    />
                </Grid>
                <Grid item xs={10} lg={10} md={10}>
                <span  style={{ fontFamily:this.props.theme.fontFamily ,  cursor: 'pointer',whiteSpace :"pre-wrap", 
                  color: this.props.theme.fontColor , fontSize: this.props.theme.componentFontSize}}
                  onClick={(e)=>this.onTextClick(e,"occupation", column)}
                  >
                   {this.state.column[column]}
                </span>
                </Grid>
              </Grid>
              </ListItem>
        </Fragment>
      ));
      return tifOptions;
    }

  return(
    <Fragment>
      <Grid container spacing={4} justify="center" >
        <Grid item xs={12} lg={5} md={5}>
          <Card className="card-box mb-2"  style={{backgroundColor: this.props.theme.componentBgColorRgba, opacity: this.props.theme.componentOpacity}}>
                <div className="card-header">
                    <div className="card-header--title">
                        <h5 className="mb-0 py-1 " style={{ fontFamily:this.props.theme.fontFamily , color: this.props.theme.fontColor,whiteSpace :"pre-wrap" }}>
                        {this.state.rowText}
                        </h5>
                  </div>
                </div>
                <CardContent className="p-0">
                    <List className="my-1" >
                      <RowObj/>
                    </List>
                </CardContent>
          </Card>
    </Grid>
    <Grid item xs={12} lg={5} md={5}>
        <Card className="card-box mb-2"  style={{backgroundColor: this.props.theme.componentBgColorRgba, opacity: this.props.theme.componentOpacity}}>
              <div className="card-header">
                  <div className="card-header--title">
                      <h5 className="mb-0 py-1 " style={{ fontFamily:this.props.theme.fontFamily , color: this.props.theme.fontColor, whiteSpace :"pre-wrap" }}>
                      {this.state.columnText}
                      </h5>
                </div>
              </div>
              <CardContent className="p-0">
                  <List className="my-1" >
                  <ColumObj />
                  </List>
              </CardContent>
        </Card>
    </Grid>
    </Grid>
    </Fragment>
  )
}

  

  render() {
    
 
    return (
      <Fragment>
        <ThemeProvider theme={this.props.theme.pallet}>
     
        {

        this.uiForMobile() 
        }

        {this.state.answer !="" ? <div>
                <h4 style={{textAlign:"center"}}>{this.state.answer[0]} </h4>
            </div> : null}
          {this.state.isAnswered ? <div>
                <Alert severity="error">Please Answer </Alert>
            </div> : null}
      <div className="d-block mt-2">
          {this.props.preview ? null : (
              <Button className={this.file ? "button save m-1" : "button save Disable m-1"} disabled={this.state.progressbar} variant={this.props.theme.buttonVariant} color={this.props.theme.color}  onClick={this.submitAnswer}>Submit</Button>
          )}
            { this.state.progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
      </div>
      </ThemeProvider>
    </Fragment>
    );
  }
}
