import React, { Fragment, useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Typography,
  Dialog,
  Tabs,
  Tab,
  Card,CardContent,
  Button,CircularProgress,
  TextField, FormControlLabel
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Survey , Authorization} from '../../../../Store/ApplicationStore';


function AIAnalysisDialog(props) {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleBreadcrumb,
    pageTitleIconBox,
    pageTitleDescription
  } = props;
  const [expanded, setExpanded] = React.useState(false);
  const history = useHistory();
  const [surveyId, setSurveyId] = useState(null)
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
 
  const [questions, setQusetion ] = useState([])
  const [permission, setPermission] = useState({
    isDownload: true,
    isForm: true,
    isAiAnalysis: true
  });

  const getPermission = async () => {
    const per = await Authorization.getPermission();
    setPermission(per);
  };

  useEffect(()=>{
    if (surveyId == null){
      getData(props.surveyId)
      setSurveyId(props.surveyId)
    }
    getPermission();
  }, []);

  const getData = async (id) =>{
    await Survey.getAIquestions({"batchId": id, "questionId":0}).then((e)=>{
      if (e.status == 'success'){
        setQusetion(e.value)
      }
    })
  }

  const handleOpen = () => {
    setOpen(true);
  };


let languages = [
  'English (United States)',
  'Hindi (India)',
  'Telugu (India)',
  'Kannada (India)',
  'Malayalam (India)',
  'Marathi (India)',
  'Tamil (India)',
  'German (Germany)',
  'Chinese, Mandarin',
  'Czech (Czech Republic)',
  'Danish (Denmark)',
  'French (France)',
  'Italian (Italy)',
  'Japanese (Japan)',
  'Portuguese (Brazil)',
  'Spanish (Spain)',
  'Turkish (Turkey)',
  'Malay (Malaysia)',
  'Manual Transcript'
]

const handleMainDownChange = (event, id) => {
  let questionObj = [...questions]
  let objIndex = questionObj.findIndex((obj => obj.id == id))
  if (questionObj[objIndex].analysis == null){
    questionObj[objIndex].analysis = { 'mainlanguage': event.target.value}
  }else{
    let analysis = {...questionObj[objIndex].analysis , 'mainlanguage': event.target.value}
    questionObj[objIndex].analysis = analysis
  }
  setQusetion(questionObj)

};

const handleSubDownChange = (event, id) => {
  let questionObj = [...questions]
  let objIndex = questionObj.findIndex((obj => obj.id == id))
  if (questionObj[objIndex].analysis == null){
    questionObj[objIndex].analysis = { 'subLanguage': event.target.value}
  }else{
    let analysis = {...questionObj[objIndex].analysis , 'subLanguage': event.target.value}
    questionObj[objIndex].analysis = analysis
  }
  setQusetion(questionObj)
};

const handleRadioChange = (event, id) => {
  let questionObj = [...questions]
  let objIndex = questionObj.findIndex((obj => obj.id == id))
  if (questionObj[objIndex].analysis == null){
    questionObj[objIndex].analysis = { 'transcriptValue': event.target.value}
  }else{
    let analysis = {...questionObj[objIndex].analysis , 'transcriptValue': event.target.value}
    questionObj[objIndex].analysis = analysis
  }
  setQusetion(questionObj)
};


const updateAnalysis = async (e, id)=>{
  e.preventDefault()
  let questionObj = [...questions]
  let objIndex = questionObj.findIndex((obj => obj.id == id))
  let analysis = { 'mainlanguage': 'English (United States)', 'transcriptValue':'transcript' ,'subLanguage':'English (United States)'}
  if (questionObj[objIndex].analysis != null){
      analysis = {...questionObj[objIndex].analysis }
  }
  await Survey.updateQuestionAiAnalysis({
    "analysis": analysis,
  "questionId": id}).then((e)=>{
    if (e.status == 'success'){
    getData(surveyId)
    }
  })
}

const addVideos = async (e,id)=>{
  e.preventDefault()
  let data = {
    'batchId': surveyId,
    "questionId":id
  }
 
  Survey.addVideosForAnalysis(data).then((e)=>{
    if (e.status == 'success'){
      getData(surveyId)
    }
  })
}

const handleTanscript = (e, questionId)=>{
  e.preventDefault()
  history.push({
      pathname: '/TranscriptDashboard',
      search: '?id='+surveyId + '&questionId=' + questionId
   });
}

const handleFaceDashboard = (e, questionId, question) =>{
  e.preventDefault()
  history.push({
      pathname: '/FaceAnalysisDashboard',
      search: '?batchId='+surveyId + '&questionId=' + questionId + "&question=" + question
   });
}


const handleAiDashbaord = (e, questionId, question) => {
  let AnomyousURL = encodeURI("/AiAnalysisDashboard?batchId="+surveyId+ "&questionId="+ questionId);
  window.open(AnomyousURL, "_blank")
}


  return (
    <Fragment>   
        {!open && (
      <div className="d-flex align-items-center">
        {permission.isAiAnalysis ?
          <Button 
                    onClick={toggle1} 
                    // size="small"
                    // variant="outlined"
                    color="primary"
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary">
                    AI Analysis
        </Button> : 
        null }
      <Dialog scroll="body" maxWidth="lg" open={modal1} onClose={toggle1} >
      <DialogTitle id="simple-dialog-title">Ai Questions</DialogTitle>
      <Grid container spacing={0} style={{ maxHeight:'600px', overflowY:'scroll',padding:'10px', minWidth:'700px'}}>
      <Grid item xs={12} lg={12} md={12} >
      <Button color="primary" variant="outlined" style={{float:"right"}} className="m-1" size="small" onClick={(e)=>{handleTanscript(e, 0)}}>All Transcript Dashboard</Button>
      </Grid>
      {questions.map((data,idx) => (
          <Grid item xs={12} lg={12} md={12} key={idx}>
              <Card className="card-box mb-4">
                  <div className="card-header">
                  <Grid container spacing={2}>
                  <Grid item xs={6} md={9} lg={9}>
                  <div className="font-size-lg d-block text-truncate">
                          <span className="text-black-50 pr-2">#Q-{data.OrderId}</span> {data.question.substring(0, 50)}
                      </div>
                  </Grid>

                  <Grid item xs={6} md={3} lg={3}>
                  {data.answerType == 'faceEmotion'?
                  <Button color="primary" variant="outlined" className="m-1" size="small" onClick={(e)=>{handleFaceDashboard(e, data.id, data.question)}}>Face Emotion Dashboard</Button>
                  :
                  <>
                  <Button color="primary" variant="outlined" className="m-1" size="small" onClick={(e)=>{handleTanscript(e, data.id)}}>Transcript Dashboard</Button>
                  {permission.isAiAnalysis && <Button color="primary" variant="outlined" className="m-1" size="small"onClick={(e)=>{handleAiDashbaord(e, data.id, data.question)}}>AI Dashboard</Button>}
                  </>
                  } 
                  </Grid>
                  </Grid>
                  </div>
                  <div className="font-size-lg" style={{float:'right'}}>
                          <span className="text-black-50 pr-2">{data.answerType}</span>
                  </div>
                
              
              <CardContent className="p-3">
              {data.answerType == 'faceEmotion'?
              <Grid container spacing={2}>
              <Grid item xs={4} md={6} lg={7}></Grid>
              <Grid item xs={2} md={3} lg={2} className="align-box-row ">
                        <Typography>Pending: {data.count}</Typography>
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <Button color="primary" variant="outlined" className="m-2"  onClick={(e)=>{addVideos(e, data.id)}}>Add For analysis</Button>
              </Grid>
              </Grid>
              :
                  <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                  <FormControl component="fieldset">
                      <FormLabel component="legend">Generate</FormLabel>
                      <RadioGroup aria-label="transcript" name="transcript" 
                      value={data.analysis != null ? data.analysis.hasOwnProperty('transcriptValue') ? data.analysis.transcriptValue : null : null} 
                      onChange={(e)=>handleRadioChange(e, data.id)}>
                        <FormControlLabel value="transcript" control={<Radio />} label="transcript" />
                        <FormControlLabel value="transcript_translate" control={<Radio />} label="transcript + translate to English" />
                      </RadioGroup>
                    </FormControl>
                    </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                      <FormControl size="small" variant="outlined" fullWidth>
                      <FormLabel component="legend">Language for Transcript</FormLabel>
                          <Select
                            className="mb-1"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.analysis != null ? data.analysis.hasOwnProperty('mainlanguage') ? data.analysis.mainlanguage : 'English (United States)' : 'English (United States)'}
                            onChange={(e)=>handleMainDownChange(e,data.id)}
                          >
                            {
                              languages.map((data,idx) => (
                                <MenuItem value={data} key={idx}>{data}</MenuItem>
                              ))
                            
                             }
                          </Select>
                        </FormControl>

                      {/* <FormControl size="small" variant="outlined" fullWidth>
                      <FormLabel component="legend">Alternate Language for Transcript</FormLabel>
                        
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.analysis != null ? data.analysis.hasOwnProperty('subLanguage') ? data.analysis.subLanguage : 'English (United States)' : 'English (United States)'}
                            onChange={(e)=>handleSubDownChange(e, data.id)}
                          >
                            {
                              languages.map((data,idx) => (
                                <MenuItem value={data} key={idx}>{data}</MenuItem>
                              ))
                            
                             }
                          </Select>
                        </FormControl> */}
                      </Grid>
                      
                      <Grid item xs={12} md={1} lg={1} className="align-box-row ">
                        <Typography>Pending: {data.count}</Typography>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3} className="align-box-row">
                        
                        <Button color="primary" variant="outlined" className="m-1" size="small" onClick={(e)=>{updateAnalysis(e, data.id)}}>Update</Button>
                        <Button color="primary" variant="outlined" className="m-1" size="small" onClick={(e)=>{addVideos(e, data.id)}}>Add To analysis</Button>
 
                      </Grid>
                  </Grid>
              }
              </CardContent>
              </Card>
 
        </Grid>
      ))}
        </Grid>
      </Dialog>
    </div>
  )}
  </Fragment>
  );
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(AIAnalysisDialog);
