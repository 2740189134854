import React ,{ useState , Fragment, useEffect}from 'react';

import {
  Box,
  Fab,
  Card,
  FormControl,
  TextField,
  InputAdornment,

} from '@material-ui/core';
import { Link } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Survey , Authorization} from '../../../Store/ApplicationStore';


export default (props) => {
    
    const [userId, setUserId] = useState(0)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const surveyId = urlParams.get('id');
    const [anomyousToken, setAnomyousToken] = useState('');
    const [title, setTitle] = useState('');

    // let reposneUrl = "https://response.enumerate.ai"
    let reposneUrl = "https://dev.videoapp.enumerate.ai"

    let AnomyousURL = encodeURI(reposneUrl + "/Survey?id=" + surveyId +"&token=" + anomyousToken);
    let AnomyousVendorURL = encodeURI(reposneUrl + "/Survey?id=" + surveyId +"&vendorId=" + userId +"&token=" + anomyousToken);
    let shareUrl  = props.permission.isVendor ? AnomyousVendorURL : AnomyousURL

    const getSurveyData = async() =>{
       await Survey.getAllSurveys(surveyId).then((data) => {
    
            if (data != null){
                if (data[0] != null){
                    setTitle(data[0].title.substring(0, 50))
                    setAnomyousToken(data[0].token)
                }
            }
            
        })
        let out =  Authorization.getUserId()
        setUserId(out)
    }

      useEffect(()=>{
        getSurveyData()
    },[title,userId])

 
    return (
        <div className="d-flex flex-column" style={{marginTop:'-30px'}}>
                <Box className="d-flex w-100 p-4 justify-content-center" >
                <h2 className="font-size-lg font-weight-bold mt-1 mb-1">
                   {title}
                </h2>
                </Box>
                <Box className="d-flex w-100 p-4 justify-content-center" >
                <Card className="card-box-alt card-border-top border-primary m-2 p-4">
                    <h3 className="font-size-lg font-weight-bold mt-5 mb-4">
                    Operation link
                    </h3>
                    <p className="card-text px-4 mb-4">
                    Copy the link below and share it with users.
                    </p>
                    <FormControl className="w-100" variant="outlined">
                    <TextField
                        variant="outlined"
                        value={shareUrl}
                        fullWidth
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <CopyToClipboard
                                text={shareUrl}>
                                <Fab size="small" color="primary">
                                <Link />
                                </Fab>
                            </CopyToClipboard>
                            </InputAdornment>
                        )
                        }}
                    />
                    </FormControl>
                </Card>
            </Box>
        </div>
    )
    }