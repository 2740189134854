
import React, { Fragment } from "react";
import "antd/dist/antd.css";
import { ThemeProvider } from '@material-ui/core/styles';
import { Table } from "antd";
import { Button, CircularProgress, Card, CardContent, List, ListItem ,Grid} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import './matrix.css'
export default class MatrixCreate extends React.Component {
  constructor(props) {
    super(props);
    let _rowValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('row') ? props.questionObject.options.row : [] :[]
    let _columnValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('column') ? props.questionObject.options.column : [] : []
    let _titleValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('title') ? props.questionObject.options.title : "" :""
    let _typeValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('type') ? props.questionObject.options.type : false : false
    let _answerAll = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('answerAll') ? props.questionObject.options.answerAll : false : false
    this.state = {
      isAnswered: false,
      progressbar: false,
      type: _typeValue,
      answerAll: _answerAll,
      task: { 
          row: _rowValue ,
          column:  _columnValue ,
          title: _titleValue != "" ? _titleValue : ""},
      selected: {}
    };
  }

  onRadioChange = e => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    this.setState({
      ...this.state,
      selected: { ...this.state.selected, [name]: value }
    });
  };

  onCheckBoxChange = e => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    let data = this.state.selected[name] != null ? this.state.selected[name] : []
    let val = []
    if (data.indexOf(value) !== -1){
      data.pop(value)
      val = data
    }else{
      val = data.concat([value])
    }
    let selectedData = {...this.state.selected}
    selectedData[name] = val.sort()
    this.setState({
      selected: selectedData
    });
  };

  onRadioChangeClick = (e, name ,value) => {
    e.preventDefault()
    this.state.selected[name] = value
    this.setState({
      ...this.state,
      selected: { ...this.state.selected, name: value }
    });
  };



  submitAnswer = () => {
  if ( Object.keys(this.state.selected).length !== this.state.task.column.length && this.state.answerAll == true){
    this.setState({
        isAnswered: true
    })
  }
  else{
    this.setState({
      progressbar: true,
      ...this.state,
      isAnswered: false,
      selected: {}
    });
    this.props.submit({
        value:  this.state.selected 
    })
  }
  }
  
  uiForMobile= () =>{
        const getFontSize = (fontSize) =>{
          if (window.innerWidth < 480){
            return "12px"
          }
          if (fontSize === "13px"){
            return "12px"
          }else {
            return "14px"
      
          }
        }

      return(
        <Fragment>
        {this.state.task.column.map((column,j) => (
          <Card className="card-box mb-2" key={column+j} style={{backgroundColor: this.props.theme.componentBgColorRgba, opacity: this.props.theme.componentOpacity}}>
              <div className="card-header">
                  <div className="card-header--title">
                      <h5 className="mb-0 py-1 " style={{ fontFamily:this.props.theme.fontFamily , color: this.props.theme.fontColor }}>
                          {column}
                      </h5>
                        </div>
                        </div>
                        <CardContent className="p-0">
                        {
                        this.state.task.row.map((row,i) => (
                            <List className="my-1" key={row+i}>
                                <ListItem className="d-flex justify-content-between align-items-center py-1 border-0">
                                <Grid container spacing={1} key={i} >
                                  <Grid item xs={2} lg={2} md={2}>
                                  {(!this.state.type)?
                                        <input 
                                          type="radio"
                                          checked={this.state.selected[column] == row}
                                          onChange={this.onRadioChange}
                                          name={column}
                                          value={row}
                                          style={{cursor: 'pointer' , height:'24px', width:'24px'} }
                                        />
                                        :
                                        <input 
                                          key={i}
                                          type="checkbox"
                                          onChange={this.onCheckBoxChange}
                                          name={column}
                                          value={row}
                                          style={{cursor: 'pointer', height:'24px', width:'24px'} }
                                        />
                                    }
                                  </Grid>
                                  <Grid item xs={10} lg={10} md={10}>
                                  <span  style={{ fontFamily:this.props.theme.fontFamily , 
                                    color: this.props.theme.fontColor , fontSize:"18px"}}>
                                      {row}
                                  </span>
                                  </Grid>
                                </Grid>
                                </ListItem>
                            </List>
                        ))
                        }
                        </CardContent>
            </Card>   
            
        ))
        }
        </Fragment>
      )
    }


  render() {
    let columns = [];
    columns.push({
      title: this.state.task.title ?? "",
      dataIndex: "name",
      key: "name",
      width: "28vw",
      fixed: 'left'
    });

    this.state.task.row.forEach((option, i) => {
      columns.push({
        title: option.trim(),
        key: option,
        
        render: row => {
          if (this.state.type === false){
            return (
              // <div style={{cursor: 'pointer'} } onClick={(e)=>{this.onRadioChangeClick(e,row.name,option)}, {this.state.selected[row.name] == option}}>
              <input 
                key={i}
                type="radio"
                checked={this.state.selected[row.name] == option}
                onChange={this.onRadioChange}
                name={row.name}
                value={option}
                style={{cursor: 'pointer'} }
              />
              // </div>
            );
          }else{
          return (
            // <div style={{cursor: 'pointer'} } onClick={(e)=>{this.onRadioChangeClick(e,row.name,option)}, {this.state.selected[row.name] == option}}>
            <input 
              key={i}
              type="checkbox"
              onChange={this.onCheckBoxChange}
              name={row.name}
              value={option}
              style={{cursor: 'pointer'} }
            />
            // </div>
          );
        }
        }
      });
    });

    let rowHeaders = [];
    this.state.task.column.forEach((target, i) => {
      rowHeaders.push({ name: target.trim(),key: i});
    });

    
    function detectMob() {
      if (navigator.userAgent.match(/Android/i) 
                || navigator.userAgent.match(/webOS/i) 
                || navigator.userAgent.match(/iPhone/i)  
                || navigator.userAgent.match(/iPad/i)  
                || navigator.userAgent.match(/iPod/i) 
                || navigator.userAgent.match(/BlackBerry/i) 
                || navigator.userAgent.match(/Windows Phone/i) || ( window.innerWidth <= 800 ) || (window.orientation > -1) )
      return true
      else{
        return false
      }
    }
 
    return (
      <Fragment>
        <ThemeProvider theme={this.props.theme.pallet}>
     
        {
        detectMob() ?
        
        this.uiForMobile() 
        :
        <Table
        style={{overflowY:"scroll"}}
          scroll={{ x: 'calc(500px + 50%)' }}
          columns={columns}
          dataSource={rowHeaders}
          size="middle"
          bordered
          pagination={false}
      />
        }
          {this.state.isAnswered ? <div>
                <Alert severity="error">Please Answer All the Questions</Alert>
            </div> : null}
      <div className="d-block mt-2">
          {this.props.preview ? null : (
              <Button className={this.file ? "button save m-1" : "button save Disable m-1"} disabled={this.state.progressbar} variant={this.props.theme.buttonVariant} color={this.props.theme.color}  onClick={this.submitAnswer}>Submit</Button>
          )}
            { this.state.progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
      </div>
          <React.Fragment>
            <style>
              {`
              .ant-table-cell {
                font-size: ${this.props.theme.componentFontSize};
                font-family: ${this.props.theme.fontFamily};
                color: ${this.props.theme.fontColor};
              }
              `}
            </style>
        </React.Fragment>  

      </ThemeProvider>
    </Fragment>
    );
  }
}
