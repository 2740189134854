import React , { Fragment }from 'react';
import styled from 'styled-components';
import {
  Card,
  CardHeader,
  CardContent, Divider , List, ListItem, Typography
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { POSITIVE_COLOR, NEGATIVE_COLOR, NEUTRAL_COLOR } from './constants';


const chooseColor = (type) => {
  switch(type) {
    case 'positive':
      return POSITIVE_COLOR;
    case 'negative':
      return NEGATIVE_COLOR;
    case 'neutral':
      return NEUTRAL_COLOR;
  }
  return NEUTRAL_COLOR;
};

const Sentence = ({
  sentences,
  clickSentence
}) => {
  const sortedSentence = [...sentences].sort((a, b) => b.score - a.score);
  return (

<>
    <Card className="card-box mb-2 pt-4 ">
    <div className="d-flex align-items-center px-1 mb-1 ">
        <div className="w-100">
            <a href="#/" onClick={e => e.preventDefault()} className="font-weight-bold font-size-lg pl-3" >Verbatim</a>            
        </div>
        <div className="d-flex  pt-2" style={{float:'right'}}>
      <Typography color="textSecondary" className="mr-5"><b>Score</b></Typography> 
     </div>
    </div>
    
    <Divider className="my-3" />


    <List className="py-0" style={{  maxHeight: '400px', overflowY: "scroll"}}>
    {sortedSentence.map((e ,i) => (
      <div key={'sentnce'+ e + i } style={{borderBottom: '1px solid #e0e0e0'}}>
        <ListItem button className="bg-white border-0 align-box-row"   onClick={() => clickSentence(e.text)}>
            <div className="align-box-row w-100">

                <p className="font-size-md opacity-8 mb-0" >{e.text}</p>
                <div className="ml-auto align-self-center">
                <span className="font-size-md m-2 badge opacity-9" style={{fontSize:"0.85rem", color:'white', backgroundColor:chooseColor(e.type)}}>{e.score}</span>
                </div>
            </div>
        </ListItem>      
        </div>
    ))}
    </List>
</Card>
    </>
  );
};

export default Sentence;

const StTable = styled.table`
  border: none;
  width:100%;
  tr {
    border-bottom: 1px solid #dadada;
    cursor: pointer;
  }
  th {
    font-weight: bold;
    color: #888;
    padding: 10px;
  }
  td {
    padding: 10px;
  }
`;
