import React, { Suspense, Fragment, useState, useEffect, useLayoutEffect } from 'react';
import { Switch, Route, Redirect, useLocation , useHistory} from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { hotjar } from 'react-hotjar';
import { ThemeProvider } from '@material-ui/styles';

import { RingLoader } from 'react-spinners';

import MuiTheme from './theme';
import { Authorization } from './Store/ApplicationStore';
// Layout Blueprints

import {
  PageWrapper,
  MinimalLayout
} from './layout-blueprints';

import SubmitSurvey from './containers/Survey/View';
import RegisterLogin from './containers/Authentication/Register/index';

const Routes = (props) => {
  //dev
  // hotjar.initialize(2160659, 6);
  //prod
  hotjar.initialize(2167274, 6);
  
  const history = useHistory();
  const location = useLocation();
  const [pathname, setRedirect] = useState("");
  const [surveyId, setSurveyId] = useState("");
  const [vendorId, setVendorId] = useState(null);
  const [surveyName, setSurveyName] = useState("");
  const [userName, setUserName] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [userType, setUserType] = useState(1);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const searchParam = new URLSearchParams(location.search);
    const surveyId = searchParam.get("id");
    const vendorId = searchParam.get("vendorId") ;
    const token = searchParam.get("token");
    const redirect = location.pathname.indexOf('Survey') >= 0 ? location.pathname + location.search : '';
    if (surveyId) {
      setSurveyId(surveyId);
      setSurveyName(surveyName);
    }
    if(vendorId){
      setVendorId(vendorId)
    }
    // setRedirect(redirect);
    if (token != null){
      Authorization.checkToken(token).then(res => {
        if(res) {
          if (res.auth === false){
            history.replace('/Login')
          }
          setIsAuthenticated(res.auth);
          setUserName(res.userName)
          setUserType(2);
          setUserId(res.userId);
          history.replace(redirect);
        }
      })
    }
    else{
    Authorization.isAuthenticated().then(res => {
      if(res) {
        setIsAuthenticated(res.auth);
        setUserName(res.userName);
        setUserId(res.userId);
        history.replace(redirect);
      }
      setIsAuthenticated(false);
      history.replace('/Login')
    })
  }
  }, []);
  
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };
  
  const login = async (email, password) =>{
    try {
      let result = await Authorization.login(email, password);
      if (result.success) {
        setIsAuthenticated(true);
        setIsValidLogin(true)
        let out = await Authorization.getUserDetails();
        setUserName(out.userName);
        history.replace(pathname);
      }
    }
    catch(err) {
      console.log(err);
    }
  }
  const logout= async () => {
    try {
      await Authorization.logout();
    }
    catch(err) {}
    setIsAuthenticated(false)
    history.replace('/Login');
  } 


  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <RingLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Your Survey Is Getting Ready 
          </div>
        </div>
      </Fragment>
    );
  };

  if (isAuthenticated === null){
    return(
      <ThemeProvider theme={MuiTheme}>
        <AnimatePresence>
          <SuspenseLoading />
        </AnimatePresence>
        </ThemeProvider>
    )
  }
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
          {!isAuthenticated ? (
            <Fragment>
              <Route exact path="/">
                <Redirect to="/Login" />
              </Route>
              <Route path="/Survey">
                <Redirect to="/Login" />
              </Route>
              <Route path={['/Login', '/Register']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Login" >
                        <RegisterLogin  login={login}  tab={0} isValidLogin={isValidLogin} showSignUp={false}/>
                      </Route>
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>
          </Fragment>
          ):(
            <Fragment>
              <Route exact path="/">
                <Redirect to="/Survey" />
              </Route>
              <Route path={['/Survey']}>
                <PageWrapper userName={userName} logout={logout} surveySubmit={true}>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Survey" >
                        <SubmitSurvey id={surveyId} vendorId={vendorId} title={surveyName} userId={userId} logout={logout} userType={userType} />
                      </Route>
                    </motion.div>
                  </Switch>
                </PageWrapper>
              </Route>
            </Fragment>
          )}
          </Switch>
        </Suspense>
      </AnimatePresence>
      <Fragment>
      </Fragment>
    </ThemeProvider>
  );
};

export default Routes;