import React, { Fragment, useState, useEffect } from 'react';
import {
  TextField,
  Grid
} from '@material-ui/core';

const _rowValueSecOld = {
  "Illiterate": "Illiterate",
  "Literate but no formal schooling": "Literate but no formal schooling",
  "School Upto 4 yrs": "School Upto 4 yrs",
  "School 5-9 yrs": "School 5-9 yrs" ,
  "SSC/HSC": "SSC/HSC", 
  "Some college but not Grad": "Some college but not Grad",
  "Graduate/ Post Graduate General": "Graduate/ Post Graduate General",
  "Graduate/ Post Graduate Professional": "Graduate/ Post Graduate Professional"
};
  
const _columnValueSecOld = {
  "Unskilled Workers": "Unskilled Workers",
  "Skilled Workers": "Skilled Workers",
  "Petty traders": "Petty traders", 
  "Shop Owners": "Shop Owners",
  "Businessmen with No employees": "Businessmen with No employees",
  "Businessmen with 1-9 employees": "Businessmen with 1-9 employees",
  "Businessmen with 10+ employees": "Businessmen with 10+ employees",
  "Self Employed professional": "Self Employed professional",
  "Clerical/Salesman": "Clerical/Salesman",
  "Supervisory Level": "Supervisory Level",
  "Officers/Executives-Junior": "Officers/Executives-Junior",
  "Officers/Executives-Mid/Senior": "Officers/Executives-Mid/Senior"
};

const options = ["A1", "A2", "B1", "B2", "C", "D" , "E1", "E2"];

export default (props) => {
  
  const [row, setRow] = useState(props.questionObject.hasOwnProperty('row') ? props.questionObject.row: _rowValueSecOld);
  const [column, setColumn] = useState(props.questionObject.hasOwnProperty('column') ? props.questionObject.column: _columnValueSecOld);
  const [rowText, setRowText] = useState(props.questionObject.hasOwnProperty('rowText') ? props.questionObject.rowText: "Please select occupation of CWE");
  const [columnText, setColumnText] = useState(props.questionObject.hasOwnProperty('columnText') ? props.questionObject.columnText: "Please select education of CWE");

  const submitChange = (obj = {}) => {
    props.updateQuestionObject({
      options,
      column,
      row,
      rowText,
      columnText,
      ...obj
    });
  }

  const updateRowText= (event, key)=>{
    const tempText = event.target.value;
    setRow({
      ...row,
      [key]: tempText
    });

  }

  const updateColumText= (event, key)=>{
    const tempText = event.target.value;
    setColumn({
      ...column,
      [key]: tempText
    });

  }

  const rowObj = () => {
    const tifOptions = Object.keys(row).map(key => (
      <Fragment key={key}>
        <TextField multiline value={row[key]} onBlur={() => submitChange()} onChange={(e)=> updateRowText(e, key)} fullWidth variant="standard"  variant="outlined" size="small"/>
        <p value={key} style={{ whiteSpace :"pre-wrap"}}>{key}</p>
      </Fragment>
    ));
    return tifOptions;
  }
  
  const cloumnObj = () => {
    const tifOptions = Object.keys(column).map(key => (
      <Fragment key={key}>
        <TextField multiline value={column[key]} onBlur={() => submitChange()} onChange={(e)=> updateColumText(e, key)} fullWidth variant="standard"  variant="outlined" size="small"/>
        <p value={key} style={{ whiteSpace :"pre-wrap"}}>{key}</p>
      </Fragment>
    ));
    return tifOptions;
  }

  useEffect(() => {
    submitChange();
  }, []);

  return (
    <>
      <Grid container spacing={2} >
        
        <Grid item xs={10} md={10} lg={5} >
        <TextField multiline value={rowText} onChange={(e)=> setRowText(e.target.value)} fullWidth variant="standard"  className="mb-4" variant="outlined" size="small" onBlur={(e)=>{submitChange()}}/>
          {rowObj()}
        </Grid>
        <Grid item xs={10} md={10} lg={5} >
        <TextField multiline value={columnText} onChange={(e)=> setColumnText(e.target.value)} fullWidth variant="standard" className="mb-4" variant="outlined" size="small" onBlur={(e)=>{submitChange()}}/>
          {cloumnObj()}
        </Grid> 
      </Grid>
    </>
  )
};
