import React, { Fragment, useState } from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Typography,
  Dialog,
  Tabs,
  Tab,
  Card,
  Button,
  TextField
} from '@material-ui/core';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Survey } from '../../Store/ApplicationStore';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};


function CreateDialog(props) {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleBreadcrumb,
    pageTitleIconBox,
    pageTitleDescription
  } = props;

  
const surveyTypes = {
  1:"Simple Survey",
  2:"Ad Testing",
  3:"Recruitment"
}


  const valuetext = (value) =>{
    return `${value}`;
  }

  const HoverDiv = styled.div`
	:hover {
		cursor: pointer;
  }
  transition: all ease 0.3s`
  
  const history = useHistory();
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  
  const [surveyType, setSurveyType] = React.useState(surveyTypes[1]);
  const [surveyTypeId, setSurveyTypeId] = React.useState(1)

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    const data= {
          title: name,
          notes: description,
          surveyTypeId:surveyTypeId
        }
    Survey.createNewSurvey(data).then(res=>{
      console.log(res)
      if (res.success === true) {
        const surveyId = res.survey_id
        history.push({
          pathname: '/Manage',
          search: '?id='+ surveyId + '&name=' + name
       });
      }
    })
  };


  const handleSurveyType = (id) => {
    setSurveyTypeId(id)
    setSurveyType(surveyTypes[id])
  };


  return (
    <Fragment>   
        {!open && (
      <div className="d-flex align-items-center">
          <Button 
                    onClick={toggle1} 
                    // size="small"
                    // variant="outlined"
                    color="primary"
                    className="font-weight-bold px-3MuiButtonBase-root MuiButton-root MuiButton-contained m-2 MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge">
                    Create survey
        </Button>
      <Dialog scroll="body" maxWidth="lg" open={modal1} onClose={toggle1}>
        <Grid container spacing={0}>
          <Grid item xs={12} lg={5}>
            <div className="hero-wrapper bg-composed-wrapper bg-plum-plate h-100">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div
                  className="bg-composed-wrapper--image "
                  style={{ backgroundImage: 'url(' + 'https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-1.jpg' + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                <div className="bg-composed-wrapper--content p-5">
                  <div className="text-white mt-3">
                    <h1 className="display-4 my-3 font-weight-bold" style={{color:'white'}}>
                      {surveyType}
                    </h1>
                    <p className="font-size-md mb-0 text-white-50">
                    A telephone survey says that 51 percent of college students 
                    drink until they pass out at least 
                    once a month. The other 49 percent didn't answer the phone. 
                    </p>
                    <div className="divider border-2 my-5 border-light opacity-2 rounded w-25" />

                  </div>
                </div>
              </div>
 
            </div>
          </Grid>
          <Grid item xs={12} lg={7}>
            
            <div className="bg-white ">
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleChange}>
                <Tab className="py-3" label="create" />
                {/* <Tab className="py-3" label="Demographic" /> */}

              </Tabs>
              <TabPanel value={value} index={0}>
                <Grid container spacing={4}>
                  <Grid item md={6} lg={4}>
                  <HoverDiv className=" card-box-border-bottom rounded card-box-hover-rise-alt"  onClick={(event) => { handleSurveyType(1) }}>
                    <Card className="card-box text-black-50 bg-secondary mb-5 p-3" >
                      <div className="divider mt-2 mb-3 border-2 w-25 bg-first rounded border-first" />
                      <div className="font-weight-bold font-size-sm text-uppercase">
                        Simple
                        <br />
                        Survey
                        <br/>
                      </div>
                    </Card>
                    </HoverDiv>
                  </Grid>
                  <Grid item md={6} lg={4}>
                  <HoverDiv className=" card-box-border-bottom rounded card-box-hover-rise-alt" onClick={(event) => {handleSurveyType(2)}} >
                    <Card className="card-box text-black-50 bg-secondary mb-5 p-3" >
                      <div className="divider mt-2 mb-3 border-2 w-25 bg-success rounded border-success" /> 
                      <div className="font-weight-bold font-size-sm text-uppercase">
                        Ad Testing
                        <br />
                        Survey
                      </div>
                    </Card>
                    </HoverDiv>
                  </Grid>
                  <Grid item md={6} lg={4}>
                  <HoverDiv className=" card-box-border-bottom rounded card-box-hover-rise-alt" onClick={(event) => {handleSurveyType(3)}} >
                    <Card className="card-box text-black-50 bg-secondary mb-5 p-3" >
                      <div className="divider mt-2 mb-3 border-2 w-25 bg-warning rounded border-warning" /> 
                      <div className="font-weight-bold font-size-sm text-uppercase">
                        Form 
                        <br />
                        Builder
                      </div>
                    </Card>
                    </HoverDiv>
                  </Grid>
                </Grid>
                <Fragment>
                    <div className="mb-3">
                    <TextField
                        variant="outlined"
                        label="Name"
                        fullWidth
                        placeholder="Enter a Name"
                        type="text"
                        value={name}
                        onChange={(event) => {setName(event.target.value)}}
                        />
                      
                    </div>
                    <div className="mb-3">
                    <TextField
                        variant="outlined"
                        label="Description"
                        fullWidth
                        placeholder="Description"
                        type="text"
                        value={description}
                        onChange={(event) => {setDescription(event.target.value)}}
                        />
                      
                    </div>
                  </Fragment>
                
                <div className="text-center">
                  <Button variant="outlined" color="primary" onClick={handleClose}>
                    Create Survey
                  </Button>
                </div>
              </TabPanel>
             
            </div> 
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )}
  </Fragment>
  );
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(CreateDialog);
