import React, { Fragment, useEffect, useRef, useState } from 'react';
import QuestionList from './QuestionBank';
import {
  Card,
  CardContent,
  Grid,
  Box
} from '@material-ui/core';
export default (props) => {
  const videoRef = useRef(null) 

  const QuestionComponent = QuestionList[props.data.answerType]["render"]({
    questionObject: props.data.questionObject,
    extraInfo: props.data.extraInfo || {},
    answer: props.data.answer || {},
    beQuestionId: props.data.questionId,
    questionId: props.data.feQId,
    preview: props.preview,
    submit: props.submit,
    save: props.save,
    answerType: props.data.answerType,
    videoRef:videoRef,
    theme: props.theme,
    allQuestions: props.allQuestions,
    surveyId: props.surveyId,
    logError: props.logError,
    questionPreview: props.questionPreview
  });

  const setMediaSize= (sizeObj)=>{
    if (sizeObj == undefined || !sizeObj.hasOwnProperty('mediaSize'))
    {
      return { xs:12 , md:6 , lg:4 }
    }
    else if (sizeObj.mediaSize == 'large'){
      return { xs:12 , md:12 , lg:12 }
    }
    else if (sizeObj.mediaSize == 'medium'){
      return { xs:12 , md:6 , lg:6 }
    }
    else{
      return { xs:12 , md:6 , lg:4 }
    }

  }
  const setQuestionarSize=(sizeObj)=>{
    if (sizeObj == undefined || !sizeObj.hasOwnProperty('mediaSize'))
    {
      return { xs:12 , md:6 , lg:8 }
    }
    else if (sizeObj.mediaSize == 'large'){
      return { xs:12 , md:12 , lg:12 }
    }
    else if (sizeObj.mediaSize == 'medium'){
      return { xs:12 , md:6 , lg: 6}
    }
    else{
      return { xs:12 , md:6 , lg:8 }
    }
  }

  const mediaCompSize = setMediaSize(props.data.questionObject)
  const questionSize = setQuestionarSize(props.data.questionObject)

  return (
    <Fragment>
      {props.data.questionUrl == "" ? (
        <Card className="card-box mt-2 mb-4" variant="outlined" style={{ backgroundColor: props.theme.componentBgColorRgba , opacity:props.theme.componentOpacity}}>
          <div className="card-header justify-content-between" style={{ display:'block', backgroundColor: props.theme.componentBgColorRgba , opacity:props.theme.componentOpacity}}>
            <div className="mr-1 w-100" style={{ fontSize:props.theme.questionFontSize, fontFamily:props.theme.fontFamily ,color:props.theme.fontColor,  whiteSpace :"pre-wrap"}}>
            <span className="text-black-100 pr-2">{ `#` + props.data.orderId + ` : ` + props.data.questionText + ' '}</span> 
            </div>
            {props.data.questionSubText != '' ?
            <div className="ml-2 mt-1 w-100" style={{fontSize:props.theme.questionSubFontSize, fontFamily:props.theme.fontFamily ,color:props.theme.fontColor,  whiteSpace :"pre-wrap"}}>
            <span className="text-black-100 pr-2">{  props.data.questionSubText}</span> 
            </div>: null}
          </div>
          <CardContent className="p-3" >
            {props.data.answerType ? QuestionComponent : null}
          </CardContent>
        </Card>
      ) : (
        props.data.questionTypeName.indexOf('audio') >=0 ?
        <Card className="card-box mt-2 mb-4" variant="outlined" style={{ backgroundColor: props.theme.componentBgColorRgba , opacity:props.theme.componentOpacity}}>
          <div className="card-header justify-content-between" style={{ display:'block',backgroundColor: props.theme.componentBgColorRgba , opacity:props.theme.componentOpacity}}>
            <div className="mr-1 w-100" style={{fontSize:props.theme.questionFontSize, fontFamily:props.theme.fontFamily ,color:props.theme.fontColor,  whiteSpace :"pre-wrap"}}>
              <span className="text-black-100 pr-2">{ `#` + props.data.orderId + `:` + props.data.questionText + ' '}</span> 
            </div>
            {props.data.questionSubText != '' ?
            <div className="ml-2 mt-1 w-100" style={{fontSize:props.theme.questionSubFontSize, fontFamily:props.theme.fontFamily ,color:props.theme.fontColor,  whiteSpace :"pre-wrap"}}>
              <span className="text-black-100 pr-2">{  props.data.questionSubText}</span> 
            </div> : null}
          </div>
          <CardContent className="p-3" >
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} md={6} lg={6}>
            <audio className="rounded opacity-9 w-100" controls controlsList="nodownload" ref={videoRef} autoPlay={true}>
                  <source src={props.data.questionUrl} />
            </audio>
            </Grid>
            </Grid>
            {props.data.answerType ? QuestionComponent : null}
          </CardContent>
        </Card>
        :
        <Card className="card-box mt-2 mb-4" variant="outlined" style={{ backgroundColor: props.theme.componentBgColorRgba, opacity:props.theme.componentOpacity}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="card-header justify-content-between" style={{display:'block', backgroundColor: props.theme.componentBgColor, opacity:props.theme.componentOpacity}}>
                <div className="mr-1  w-100" style={{ fontSize:props.theme.questionFontSize, fontFamily:props.theme.fontFamily ,color:props.theme.fontColor,  whiteSpace :"pre-wrap"}}>
                    <span className="text-black-100 pr-2">{ `#` + props.data.orderId + `:` + props.data.questionText + ' '}</span> 
                </div>
                {props.data.questionSubText != '' ?
                <div className="ml-2 mt-1  w-100" style={{fontSize:props.theme.questionSubFontSize, fontFamily:props.theme.fontFamily ,color:props.theme.fontColor,  whiteSpace :"pre-wrap"}}>
                  <span className="text-black-100 pr-2">{props.data.questionSubText}</span> 
                </div> : null}
              </div>
            </Grid>
            <Grid item xs={mediaCompSize.xs} md={mediaCompSize.md} lg={mediaCompSize.lg} style={{borderRight:'1px solid #e0e0e2'}}>
              <div className="d-flex justify-content-center w-100 h-100 p-2" style={{maxWidth:'800px',maxHeight:'800px', margin: 'auto'}}>
                {props.data.questionTypeName.indexOf('image') >= 0 ? (
                  <img className="rounded opacity-9 img-fluid" src={props.data.questionUrl} />
                ) : (
                  <video className="rounded opacity-9 w-100" controls controlsList="nodownload" ref={videoRef}>
                    <source src={props.data.questionUrl} />
                  </video>
                )}
              </div>
            </Grid>
            <Grid item xs={questionSize.xs} md={questionSize.md} lg={questionSize.lg}>
              <Box className="p-3" s>
                {QuestionList.hasOwnProperty(props.data.answerType) ? QuestionComponent : null}
              </Box>
            </Grid>
          </Grid>
        </Card>
        

      )}
    </Fragment>
  )
}