import React, { Fragment, useState, useEffect } from 'react';
import {
  TextField,
  Grid,
  InputLabel, FormControlLabel ,Switch, Typography
} from '@material-ui/core';


  

export default (props) => {

  const _rowValueAge = {
    "Less than 18 years": {'name' : "Less than 18 years", 'id' : "Less than 18 years"},
    "18-24 years": {'name' : "18-24 years" , 'id' : "18-24 years"},
    "25-30 years":{'name' : "25-30 years" , 'id' : "25-30 years"},
    "25-30 years": {'name' : "25-30 years" , 'id' : "25-30 years"},
    "31-35 years": {'name' : "31-35 years", 'id' : "31-35 years"},
    "36-40 years": {'name' : "36-40 years" , 'id' : "36-40 years"},
    "Above 40 years": {'name' : "Above 40 years", 'id' : "Above 40 years"}
  };

  const _rowValueGender = {
    "Male": {'name' : "Male",  'id' : 1},
    "Female":{'name' : "Female", 'id' : 0}
  };

  const _rowValueGenderFull = {
    "Male": {'name' :"Male", 'id' : 1},
    "Female": {'name' :"Female", 'id' : 0},
    "Prefer Not to answer": {'name' :"Prefer Not to answer",  'id' : -1},
    "Others": {'name' : "Others",  'id' : 2}
  };
  
  const [row, setRow] = useState(props.questionObject.hasOwnProperty('row') ? props.questionObject.row: props.type == "age" ? _rowValueAge : _rowValueGender);
  const [orderBelow, setOrderBelow] = useState(props.questionObject.hasOwnProperty('orderBelow') ? props.questionObject.orderBelow : false );
  const [genderValue, setGenderValue] = useState(props.questionObject.hasOwnProperty('genderValue') ? props.questionObject.genderValue : false );

  
  const submitChange = (obj = {}) => {
    props.updateQuestionObject({
      row,
      ...obj
    });
  }

  const updateRowText= (event, key)=>{
    const tempText = event.target.value;
    let tempRow = {...row}
    let tempObj = { ...tempRow[key] }
    tempObj.name = tempText
    tempRow[key] = {...tempObj}
    setRow(tempRow);

  }

  function updateOrderBelow(event) {
    const tempShow = event.target.checked;
    setOrderBelow(tempShow);
    submitChange({
      orderBelow: tempShow
    });
  }
  
  function genderFullChange(event) {
    const tempShow = event.target.checked;
    setGenderValue(tempShow);
    let tempRow = {}
    if (tempShow){
      tempRow = _rowValueGenderFull
    }else{
      tempRow = _rowValueGender 
    }
    setRow(tempRow)
    submitChange({
      row: tempRow,
      genderValue: tempShow
    });
  }


  const rowObj = () => {
    const tifOptions = Object.keys(row).map(key => (
      <Fragment key={key}>
        <TextField multiline value={row[key].name} onBlur={() => submitChange()} onChange={(e)=> updateRowText(e, key)} fullWidth variant="standard"  variant="outlined" size="small"/>
        <p value={key} style={{ whiteSpace :"pre-wrap"}}>{key}</p>
      </Fragment>
    ));
    return tifOptions;
  }
  

  useEffect(() => {
    submitChange();
  }, []);

  return (
    <>
    
      <Grid container spacing={2} justify="center" >

        <Grid item xs={10} md={10} lg={10} >
          {rowObj()}
        </Grid>
        <Grid item xs={2} md={2} lg={2} >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={orderBelow}
                          onChange={updateOrderBelow}
                          name="checkedC"
                          color="primary"
                        />
                      }
                      label="Order one below other"
                    />
            {props.type === 'gender'?
              <FormControlLabel
                      control={
                        <Switch
                          checked={genderValue}
                          onChange={genderFullChange}
                          name="checkedC"
                          color="primary"
                        />
                      }
                      label="Full Value"
                    />
              :
              null}
          </Grid>
      </Grid> 
    </>
  )
};
