import React, { useState, Fragment, useEffect } from 'react';

import {
  FormControlLabel,
  Switch,
  Checkbox,
  FormHelperText,
  FormGroup,
} from '@material-ui/core';

export default (props) => {
  const types = {
    image: {
      name: "Image files",
      type: "image/*"
    },
    audio: {
      name: "Audio files",
      type: "audio/*"
    },
    video: {
      name: "Video files",
      type: "video/*"
    },
    word: {
      name: "Word files",
      type: "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    pdf: {
      name: "PDFs",
      type: "application/pdf"
    }
  };
  let initFileType = props.questionObject.options || [];
  let initialize = false;
  if (initFileType.length == 0) {
    initialize = true;
    Object.keys(types).forEach((e) => {
      initFileType.push(types[e]["type"]);
    })
  }
  const [multiple, setMultiple] = useState(props.questionObject.multiple || false);
  const [fileType, setFileType] = useState(initFileType);

  useEffect(() => {
    if (initialize) {
      props.updateQuestionObject({
        options: initFileType
      });
    }
  }, [])

  const selectFileType = (file) => {
    let index = fileType.indexOf(types[file]["type"]);
    if (index != 0 || fileType.length > 1) {
      let arr = [ ...fileType ];
      if (index >= 0) {
        arr.splice(index, 1);
      }
      else {
        arr.push(types[file]["type"]);
      }
      setFileType(arr);
      props.updateQuestionObject({
        options: arr
      });
    }
  }

  const selectMultiple = (event) => {
    setMultiple(event.target.checked);
    props.updateQuestionObject({
      multiple: event.target.checked
    });
  }

  return (
    <Fragment>
      <div className="d-block">
        <FormControlLabel
          control={
            <Switch
              checked={multiple}
              onChange={selectMultiple}
              name="checkedB"
              color="primary"
            />
          }
          label="Can user upload multiple files?"
        />
      </div>
      <FormGroup row>
        {Object.keys(types).map((e) => (
          <FormControlLabel
            key={e}
            control={
              <Checkbox
                checked={fileType.indexOf(types[e]["type"]) >= 0}
                onChange={selectFileType.bind(null, e)}
                name={e}
                color="primary"
              />
            }
            label={types[e]["name"]}
          />
        ))}
      </FormGroup>
      <FormHelperText>Atleast 1 file type should be selected</FormHelperText>
    </Fragment>
  )
}