import React, { useState } from 'react';
import {
  Grid,
  Paper,
  TextField,
  Chip,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  Input
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const SetRow = ({
  name,
  mediaList,
  setIndex,
  options,
  updateSet,
  removeSet
}) => {
  const [value, setValue] = useState(name);
  const [list, setList] = useState(mediaList);
  const theme = useTheme();
  const classes = useStyles();

  const updateSetValueParent = (obj) => {
    const newSetObj = {
      setName: value,
      mediaList: list,
      ...obj
    };
    if (newSetObj.setName !== '' && newSetObj.mediaList.length > 0) {
      updateSet(newSetObj, setIndex);
    }
  };

  const setName = (event) => {
    const newName = event.target.value;
    setValue(newName);
    updateSetValueParent({
      setName: newName
    });
  };

  const updateList = (event) => {
    const newList = event.target.value;
    setList(newList);
    updateSetValueParent({
      mediaList: newList
    });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs>
            <TextField label="Set Name" variant="outlined" value={value} onChange={setName} />
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <Select
                fullWidth
                multiple
                value={list}
                onChange={updateList}
                input={<Input />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {options.map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, list, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <IconButton
              aria-label="delete"
              onClick={removeSet}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default SetRow;
