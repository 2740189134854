import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import {
  Modal,
  Divider,
  Grid,
  Chip,
  Card,
  Button,
  Typography,
  CardMedia,
  CircularProgress ,Dialog, DialogActions ,DialogContent, DialogTitle
} from '@material-ui/core';

import {
  PinDrop
} from '@material-ui/icons';
import { IoManSharp, IoWomanSharp } from "react-icons/io5";
import { Survey } from '../../../../Store/ApplicationStore';
import { MAN_COLOR, WOMAN_COLOR } from '../constants';
import { layerGroup } from 'leaflet';

const INITIAL_DETAIL = {
  id: '',
  videoId: '',
  url: '',
  transcript: [],
  sentence: '',
  age: '',
  gender: ''
};

const cardStyle = {
  width: '150px',
  height: '150px',
  margin: '10px',
  borderRadius: '5px',
  border: '5px solid transparent'
};

const VideoModal = ({
  videoList,
  sentence,
  modalStatus,
  closeModal
}) => {
  const [open, setOpen] = useState(modalStatus);
  const [video, setVideo] = useState(INITIAL_DETAIL);
  const [loading, setLoading] = useState(false);

  const fetchVideoDetail = async ({
    videoId
  }) => {
    const detail = await Survey.getVideoDetail({
      videoId,
      sampleSize: 1
    });
    const selVid = videoList.filter((e) => e.videoId === videoId);
    let transcriptText = detail.transcript;
    let textArr = [transcriptText];
    if (sentence && transcriptText.indexOf(sentence) >= 0) {
      textArr = transcriptText.split(sentence);
    }
    setVideo({
      ...selVid[0],
      id: detail.id,
      videoId,
      url: detail.videoUrl,
      transcript: textArr,
      sentence: sentence || '',
      age: detail.age,
      gender: detail.gender
    });
    setLoading(false);
  };

  const openVideo = (videoId) => {
    setLoading(true);
    fetchVideoDetail({
      videoId
    });
  };

  useEffect(() => {
    setOpen(modalStatus);
  }, [modalStatus]);

  useEffect(() => {
    if (videoList.length > 0) {
      setLoading(true);
      fetchVideoDetail({
        videoId: videoList[0].videoId
      });
    }
  }, [videoList]);

  return (
    <>
    <Dialog
    fullWidth={true}
    maxWidth='lg'
    open={open}
    onClose={closeModal}
  >
    <DialogTitle></DialogTitle>
    <DialogContent>
    {loading ? (
          <CircularProgress size={40} />
        ) : (
          <>
          <Grid container spacing={4} className="mb-4">
          <Grid item xs={12} md={6} lg={6} style={{borderRight : '1px solid #e0e0e0'}}>
          <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} md={10} lg={10} style={{borderBottom : '1px solid #e0e0e0'}}>
                <video src={video.url} className="mb-1" style={{ width: '100%', height: 'auto', maxHeight: '300px' , borderRadius:'5px'}} controls autoPlay />
              </Grid>
              <Grid item xs={12} md={10} lg={10}>
            
                  <div className="font-size-md p-3 bg-secondary rounded-sm">
        
                      <div className="d-flex justify-content-between">
                          <span className="font-weight-bold ">
                              Gender:
                          </span>
                          <span className="opacity-8">{video.gender == 1 ? 'Male' : 'Female' } </span>
                      </div>
                      <div className="d-flex justify-content-between py-2">
                          <span className="font-weight-bold">
                              Age:
                          </span>
                          <span className="opacity-8">{video.age}</span>
                      </div>
                      <div className="d-flex justify-content-between">
                          <span className="font-weight-bold">
                              Location:
                        </span>
                          <span className="opacity-8">{`${video.city}, ${video.state}, ${video.country}`}</span>
                      </div>
                    </div>
                </Grid>
              </Grid>
          </Grid>
          
          <Grid item xs={12} md={6} lg={6} className="mt-3 opacity-8 p-3">
              {video.transcript.map((text, index) => {
                    return (
                      <Fragment key={index}>
                        <p className="font-size-md opacity-9">
                        {text}
                      </p>
                        {index < video.transcript.length - 1 && (
                          <p className="font-size-lg">
                           {video.sentence}
                          </p>
                        )}
                      </Fragment>
                    );
                  })}
              


          </Grid>
      </Grid>



          {/* <Grid container spacing={2} className="mb-2">
            <Grid item xs={6}  md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}  md={12} lg={12}>
                  <video src={video.url} style={{ width: '100%', height: 'auto', maxHeight: '300px' }} controls autoPlay />
                </Grid>
                <Grid item xs={2}  md={2} lg={1}>
                {video.gender === 1 && (
                    <IoManSharp size={18}
                      color={MAN_COLOR}
                    />
                  )}
                  {video.gender === 0 && (
                    <IoWomanSharp size={18}
                      color={WOMAN_COLOR}
                    />
                  )}
                </Grid>
                <Grid item xs={3}  md={3} lg={3}>
                <Chip
                    variant="default"
                    color="primary"
                    label={`Age: ${video.age} years`}
                  />
                </Grid>
                <Grid item xs={3}  md={3} lg={3}>
                <Chip
                    variant="default"
                    color="primary"
                    icon={
                      <PinDrop />
                    }
                    label={`${video.city}, ${video.state}, ${video.country}`}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}  md={6} lg={6}>
              {video.transcript.map((text, index) => {
                    return (
                      <Fragment key={index}>
                        <Typography style={{fontSize: '16px'}}>
                          {text}
                        </Typography>
                        {index < video.transcript.length - 1 && (
                          <Typography variant="h5">
                            {video.sentence}
                          </Typography>
                        )}
                      </Fragment>
                    );
                  })}
            </Grid>
        </Grid> */}
        </>
        )}
        {/* <Divider  className="mb-2"/>
        <h5>
          Video List
        </h5>
        <VideoList>
          {videoList.length > 0 && videoList.map((vid) => (
            <Card
              key={vid.videoId}
              onClick={() => openVideo(vid.videoId)}
            >
              <CardMedia
                style={vid.videoId === video.videoId ? cardStyle : {
                  ...cardStyle,
                  borderColor: MAN_COLOR
                }}
                image={vid.thumbnail}
              />
            </Card>
          ))}
        </VideoList> */}
    </DialogContent>
    <Divider  className="mb-2"/>
    <DialogActions className="mb-2">
      <Button onClick={closeModal} variant="contained" color="secondary">Close</Button>
    </DialogActions>
  </Dialog>

  </> 
  );
};

export default VideoModal;

const VideoList = styled.div`
  display: block;
  width: 100%;
  height: 180px;
  overflow-x: auto;
`;
