import React from 'react';

import {
  Card, Hidden, Grid, Typography
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import { POSITIVE_COLOR, NEUTRAL_COLOR , NEGATIVE_COLOR} from './constants';
const SentimentChart = ({
  sentimentScore,
  totalCount
}) => {
  sentimentScore = parseFloat(sentimentScore)
  
  
  const chooseColor = (type) => {
    if (type == 0){
      return NEUTRAL_COLOR
    }
    if (type > 6.5){
      return POSITIVE_COLOR;
    }
    if (6.4 > type && type >3.4){
      return NEUTRAL_COLOR
    }
    if (type < 3.4){
      return NEGATIVE_COLOR
    }

  };
  const series = [sentimentScore * 10]

  const options = {
    chart: {
      height: 450,
      type: 'radialBar',
      offsetY: -10
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          value: {
            offsetY: 0,
            fontSize: '32px',
            // color: chooseColor(sentimentScore),
            formatter: function (val) {
              return val / 10;
            }
          }
        }
      }
    },
    fill: {
      colors: chooseColor(sentimentScore),
      type: 'gradient',
      gradient: {
          shade: 'dark',
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91]
      },
    },
    stroke: {
      dashArray: 4
    },
    labels: [''],
  }
  
  return (

    <Card className="card-box mb-4">
      <div className="card-header pr-2" style={{padding:' 0.10rem 1.25rem'}}>
          <div className="card-header--title mb-2 font-size-md font-weight-bold pt-3 pb-2" >
              Overall Sentiment Score
          </div>
      </div>
      <div className="d-flex justify-content-center opacity-9" style={{marginTop: '-18px'}}>
          <Hidden mdDown smDown> 
              <Chart options={options} series={series} type="radialBar" height={279} />
          </Hidden>
          <Hidden lgUp> 
              <Chart options={options} series={series} type="radialBar" height={300} />
          </Hidden> 
          </div>
      <div className="d-flex justify-content-center mb-1">
          <Typography variant="h5">
            {`Total Responses: ${totalCount}`}
         </Typography>
      </div>
  </Card>
    

  );
};

export default SentimentChart;
