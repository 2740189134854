import React from 'react';
import ReactDOM from 'react-dom';
import CreationApp from './CreationApp';
import SubmissionApp from './SubmissionApp';
import OperationApp from './OperationApp';
import * as serviceWorker from './serviceWorker';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '00f7037b8992521e94ee994921739350',
  plugins: [new BugsnagPluginReact()]
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

let App = null;
switch(process.env.REACT_APP_SURVEY_TYPE) {
  case 'submit':
    App = SubmissionApp;
    break;
  case 'create':
    App = CreationApp;
    break;
  case 'operation':
    App = OperationApp;
    break;
  default:
    App = null;
}

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
