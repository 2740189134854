import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import {percentageConevertor} from './util'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Chip,
  Button, Divider ,List, ListItem,
  IconButton,
  Hidden,
} from '@material-ui/core';
import {
  AccountCircle,
  EmojiEmotions,
  Refresh
} from '@material-ui/icons';
import { WOMAN_COLOR } from './constants';
import { IoHappyOutline, IoVideocamOutline ,IoReloadSharp } from "react-icons/io5";

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  buttonStyle: {
    width: '25px',
    height: '25px',
    padding:'5px',
    marginRight: '2px'
  },
});

const Category = ({
  title,
  catList,
  filters,
  setFilter,
  totalCount
}) => {
  const [sort, setSort] = useState('score');
  const [list, setList] = useState([]);
  const [catSelected, setCatSelected] = useState([...filters]);
  const classes = useStyles();
  const toggleCat = (text) => {
    const newCat = [...catSelected];
    const index = newCat.indexOf(text);
    if (index < 0) {
      newCat.push(text);
    } else {
      newCat.splice(index, 1);
    }
    setCatSelected(newCat);
    setFilter(newCat);
  };

  const resetFilter = () => {
    setCatSelected([]);
    setFilter([]);
  };

  

  useEffect(() => {
    const tempList = [...catList];
    tempList.sort((a, b) => {
      if (sort === 'score') {
        return b.score - a.score;
      } else {
        return b.count - a.count;
      }
    });
    setList(tempList);
  }, [catList, sort]);

  return (
<>
    <Card className="card-box mb-2 pt-4 ">
        <div className="d-flex align-items-center px-2 mb-3 ">
            <div className="w-100">
              
                <a href="#/" onClick={e => e.preventDefault()} className="font-weight-bold font-size-lg pl-3" >{title}</a>
                <div className="d-flex  pt-2" style={{float:'right'}}> 
                  <Hidden mdDown smDown> <Typography color="textSecondary" className="mr-2"><b>Sort By: </b></Typography> </Hidden>
                    <Button variant="contained"   color="secondary" className={classes.buttonStyle } onClick={() => setSort('score')}>Score</Button>
                    <Button variant="contained" color="secondary" className={classes.buttonStyle} onClick={() => setSort('count')}>Percent</Button>
                    <IconButton
                    style={{marginTop: '-11px'}}
                      color="primary"
                      aria-label="refresh"
                      onClick={resetFilter}
                      disabled={filters.length === 0}
                    >
                      <IoReloadSharp />
                    </IconButton>
                </div>
            </div>
        </div>
        <Divider className="my-2" />
        <List className="py-0" style={{ minHeight: '440px', maxHeight: '440px', overflowY: "scroll"}}>
        {list.map((e, i) => (
          <div key={title + e.text} style={{borderBottom: '1px solid #e0e0e0'}}>
            <ListItem button className="bg-white border-0 align-box-row"   onClick={() => toggleCat(e.text)}>
                <div className="align-box-row w-100">
                    <div>
                        <p className="font-size-md opacity-9 m-1">{e.text}</p>
                    </div>
                    <div className="ml-auto align-self-center">
                    <Chip
                    style={{minWidth: '67px'}}
                        variant="outlined"
                        size="medium"
                        className="mr-2"
                        color="secondary"
                        icon={<IoHappyOutline size="24"/>}
                        label={`${e.score}`}
                      />
                      <Chip
                      style={{minWidth: '75px'}}
                          variant="outlined"
                          size="medium"
                          color="primary"
                          icon={<IoVideocamOutline  size="24"/>}
                          label={`${percentageConevertor(e.count, totalCount)} %`}
                        />
                    </div>
                </div>
            </ListItem>
            {/* <Divider /> */}
            
            </div>
        ))}      
        </List>

    </Card>
    
    </>
  );
};

export default Category;

const Content = styled.div`
  display: block;
  height: 300px;
  width: 100%;
  overflow-y: auto;
`;

const ButtonDiv = styled.div`
  display: flex;
  margin: 5px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #dadada;
  box-shadow: 2px 2px 2px 0px #dadada;
  cursor: pointer;
  padding: 10px 15px;
  color: #555;
  &:hover {
    box-shadow: 2px 2px 10px 0px #dadada;
  }
  &.active {
    color: #fff;
    background-color: ${WOMAN_COLOR};
  }
  .pills {
    display: flex;
    align-items: center;
  }
`;