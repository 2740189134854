import React, { Fragment, useState, useEffect } from 'react';


export default (props) => {
  useEffect(() => {
    props.updateQuestionObject({
      options: ["A", "B", "C", "D", "E"]
    });
  }, []);

  return (
    <Fragment>
      <h4>NCCS Grid</h4>
    </Fragment>
  )
}