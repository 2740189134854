import React, { useState, Fragment } from 'react';
import { 
  Button,
  TextField
} from '@material-ui/core';
import { CircularProgress} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
export default (props) => {
  const useStyles = makeStyles({
    input: {
      backgroundColor:props.theme.componentBgColor , 
      opacity:props.theme.componentOpacity,
      fontSize:props.theme.componentFontSize, 
      color:props.theme.fontColor,
      fontFamily:props.theme.fontFamily
    },
  });
  const classes = useStyles();

  const [value, setValue] = useState("");
  const [progressbar, setProgressbar] = useState(false);
  const [isAnswered,setIsAnswered] = useState(false)
  const [isInvalid ,setIsInvalid] = useState(false)
  function saveValue() {
    if (!props.preview && value) {
      props.save({
        value: value
      });
    }
  }

  function submitAnswer() {
    if (value.length === 0 )
    {
      setIsAnswered(true)
    }
    else{
      setIsAnswered(false)
      setProgressbar(true)
      let v = value;
      if (props.type.indexOf('number') >= 0) {
        v = parseFloat(v);
        if (props.questionObject.hasOwnProperty('min')){
          if (v >= parseFloat(props.questionObject.min)   &&  v  <= parseFloat(props.questionObject.max)){
              setIsInvalid(false)
              props.submit({
                value: v
              });
          }else{
            setIsAnswered(false)
            setProgressbar(false)
            setIsInvalid(true)
          }
        }else{
          props.submit({
            value: v
          });
        }
      }else{
        props.submit({
          value: value
        });
      }
     
    }

  }

  function updateValue(event) {
    let v = event.target.value;
    setValue(v);
  }

  return (
    <Fragment>
      <ThemeProvider theme={props.theme.pallet}>
        <TextField
          InputLabelProps={props.type.indexOf('date') >= 0 || props.type.indexOf('time') >= 0 ? { shrink: true } : {}}
          InputProps={{ inputProps: {  type: 'number', min: props.questionObject.min, max: props.questionObject.max, step: 1 } }}
          type={props.type}
          label="Answer"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          variant={props.theme.conponentVarient}
          color={props.theme.color}
          multiline={props.answerType == "textarea"}
          value={value}
          onChange={updateValue}
          onBlur={saveValue}
          InputProps={{
            className: classes.input
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {isInvalid ? <div>
          <Alert severity="error">Is not a valid Number </Alert>
          </div> : null}
        {isAnswered ? <div>
          <Alert severity="error">Please Answer </Alert>
          </div> : null}
        <div className="d-block" >
          {props.preview ? null : (<Button className="mt-3" variant={props.theme.buttonVariant} color={props.theme.color} disabled={value == ""} onClick={submitAnswer}>Submit</Button>)}
          { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
        </div>
      </ThemeProvider>
      
    </Fragment>
  )
}