import {Questionaire} from '../../../Store/ApplicationStore'

const Validation = [
    {
      id: "equals",
      validate: (answer, value) => {
          if (answer.hasOwnProperty("value")){
            let answerVal = answer.value[0].toLowerCase();
            let val = value.toLowerCase();
            if (answerVal == val) {
              return true;
            }
            return false;
          }
        
      }
    },
    {
        id: "Not Contains in , seperated list",
        validate: (answer, value) => {
          if (answer.hasOwnProperty("value")){
          let val = value.toLowerCase().split(",");
          let val2 = val.map(function(x){ return x.trim(); })
          let answerVal = answer.value[0].toLowerCase();
          if (val2.indexOf(answerVal) >= 0) {
              return false;
          }
            return true;
          }
        }
      },
      {
        id: "Contains in , seperated list",
        validate: (answer, value) => {
        if (answer.hasOwnProperty("value")){
        let val = value.toLowerCase().split(",");
        let val2 = val.map(function(x){ return x.trim(); })
        let answerVal = answer.value[0].toLowerCase()
        if (val2.indexOf(answerVal) >= 0) {
            return true;
        }
        return false;
        }
      }
      },
      {
        id: "count",
        validateCount: async (answer, value, questionId) => {
          if (answer === undefined ){
            return false
          }
          if (value.indexOf("=") > -1)
          {
            let out = value.split("=")
            if (out.length == 2 ){
              let count = parseInt(out[1])
              let valValue = out[0].trim()
              let outobj = await Questionaire.getAnswerCount({
                "isOrder": false,
                "questionId": questionId
              })
  
              if (outobj.success == true){
                let outObjValue = outobj.value
                for (let i = 0; i < outObjValue.length; i++) {
                  if (outObjValue[i].value == valValue  &&  valValue === answer.value[0] && outObjValue[i].count >= count){
                    return true
                  }
                }
              }
            
            }
          }
          return false
        }
      },
  ];
  
  export default Validation;