import React, { Fragment, useEffect, useReducer , useState, useRef} from 'react';
import { connect } from 'react-redux';
import {
  IconButton,
  Tooltip,
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  AppBar,
  Container,
  Toolbar,
  Typography
} from '@material-ui/core';
import { QuestionRender } from '../../../components/Questions';
import { Questionaire ,Survey  ,Authorization} from '../../../Store/ApplicationStore';
import questionList from '../../../components/Questions/QuestionBank';
import { Refresh } from '@material-ui/icons';
import {PreviewTheme} from'../../../theme/preview';
import { ThemeProvider } from '@material-ui/core/styles';
import { ExitToApp} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AnimatePresence, motion } from 'framer-motion';
import Alert from '@material-ui/lab/Alert';
import { RingLoader } from 'react-spinners';
import PageContainer from '../Manage/Preview/page'
import '../Manage/Manage.css'
const reducer = (state, action) => {
  let newQuestions = [];
  switch (action.type) {
    case 'add_questions':
      return {
        ...state, questions: action.data
      }
    case 'select_question':
      return {
        ...state, selected: action.index
      }
    case 'save_answer':
      newQuestions = [ ...state.questions ];
      newQuestions[state.selected] = { ...state.questions[state.selected], answer: { ...action.data }, status: 'SAVE' };
      return {
        ...state, questions: newQuestions
      }
    case 'submit_answer':
      newQuestions = [ ...state.questions ];
      newQuestions[action.index] = { ...state.questions[action.index], answer: { ...action.data }, status: 'SUBMIT' };
      return {
        ...state, questions: newQuestions
      }
    case 'submit_progress':
      newQuestions = [ ...state.questions ];
      newQuestions[state.selected] = { ...state.questions[state.selected], answer: { ...action.data }, status: 'PROGRESS' };
      return {
        ...state, questions: newQuestions
      }
    case 'start_over':
      newQuestions = [ ...state.questions ];
      newQuestions.forEach((e) => {
        e.answer = {};
        e.status = ''
      });
      return {
        ...state, questions: newQuestions, selected: 0
      }
    case 'change_state':
      return {
        ...state, state: action.data
      }
  }
}

const ViewSurvey = (props) => {
  const {
    sidebarToggle
  } = props;
  const surveyId = props.id;
  const vendorId = props.vendorId
  const [surveyName, setSurveyName] = useState("")
  const [age, setAge] = useState(0)
  const [gender, setGender] = useState(-1)
  const [city, setCity] = useState("")
  const [countryList, setCountryList] = React.useState([]);
  const [country, setCountry] = React.useState('');
  const [cityList, setCityList] = React.useState([]);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [welcomePageTitle, setWelcomePageTitle] = useState("")
  const [welcomePageText, setWelcomePageText] = useState("")
  const [welcomePageSubText, setWelcomePageSubText] = useState("")
  const [showWelcomePage, setShowWelcomePage] = useState(true)
  const [questionLegth, setQuestionLength] = useState(0)
  const [thankYouPage, setThankYouPage] = useState("Thank you for taking the time to complete this survey. We truly value the information you have provided.");
  const [terminatePage, setTerminatePage] = useState('Thank you for taking Survey. The survey has terminated.');
  const [logo, setLogo] = useState(null)
  const [logoWidth, setLogoWidth] = useState(200)
  const [logoHeight, setLogoHeight] = useState(50)
  const [isAnswered, setIsAnswered] = useState(false)
  const [errorText, setErrorText] = useState("Please Answer All the fields");
  const [submitError, setSubmitError] = useState(false);
  

  const [anonymousUserId, SetAnonymousUserId] = useState(0)
  const [state, dispatch] = useReducer(reducer, {
    selected: 0,
    questions: [],
    state: 'start'
  })
  const [anonymousUserRequiredFields, setAnonymousUserRequiredFields] = useState({
    age: false,
    gender: false,
    city: false,
    name:false,
    email:false,
    phone: false
  });
  const [anonymousDetails, setAnonymousDetails] = React.useState(null)
  const [theme, setTheme] = useState(PreviewTheme())
  const [mystyle, setMystyle] = useState(null) 
  const [initalLoad, setInitalLoad] = useState(null) 
  useEffect(() => {
    Questionaire.preFetchRequiredData().then(data => {
      if (data && surveyId) {
        Questionaire.getAllQuestions(surveyId).then((data) => {
          setQuestionLength(data.length)
          dispatch({ type: 'add_questions', data: data })
        })
      }
    })
    fetchSurveyDetails(surveyId)
  }, []);


const logError = ({
  error,
  errorObject
}) => {
  Questionaire.submitAnswerError({
    batchId: surveyId,
    questionId: state.questions[state.selected]["questionId"],
    answerUserId: anonymousUserId,
    error,
    errorObject
  });
};

  
  const welcomeContinue = () =>{
    setShowWelcomePage(false)
  }

  const fetchSurveyDetails = (surveyId) => {
    Survey.getAllSurveys(surveyId).then((data) => {
      if (data.length != 0){
        let dataOut = data[0]
        
        setSurveyName(dataOut.title)
        if (dataOut.theme != null){
          let qFontSize = dataOut.theme.questionFontSize 
          let cFontSize = dataOut.theme.componentFontSize 
          if (window.innerWidth < 480){
            qFontSize = "16px"
            cFontSize = "14px"
          }
          setTheme(PreviewTheme(dataOut.theme.color, dataOut.theme.buttonVariant, dataOut.theme.componentVariant ,dataOut.theme.backgroundColor,
            dataOut.theme.componentBgColor,  dataOut.theme.componentOpacity, qFontSize, dataOut.theme.backgroundImage , cFontSize,
            dataOut.theme.fontColor ,dataOut.theme.fontFamily))
            let _mystyle = dataOut.theme.backgroundImage!= "None" ? {
              backgroundImage: "url(" + dataOut.theme.backgroundImage + ")",
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              minHeight:'100vh'
            } : {backgroundColor: dataOut.theme.backgroundColor, minHeight:'100vh'};
            setMystyle(_mystyle)
            setWelcomePageTitle(dataOut.theme.welcomePageTitle)
            setWelcomePageText(dataOut.theme.welcomePageText)
            setWelcomePageSubText(dataOut.theme.welcomePageSubText)
            setThankYouPage(dataOut.theme.thankYouPage);
            setTerminatePage(dataOut.theme.terminatePage);
            setLogo(dataOut.theme.logo)
            setLogoWidth(dataOut.theme.logoWidth)
            setLogoHeight(dataOut.theme.logoHeight)
        }
        if (dataOut.getDemographic != null){
          setAnonymousUserRequiredFields(dataOut.getDemographic)
          setAnonymousDetails(dataOut.getDemographic.name || dataOut.getDemographic.email || dataOut.getDemographic.phone || dataOut.getDemographic.age || dataOut.getDemographic.gender || dataOut.getDemographic.city)
        }
        else{
          setAnonymousDetails(false)
        }
      }
      setInitalLoad(true)
    })
  }
  const selectQuestion = (index) => {
    dispatch({ type: 'select_question', index: index })
  }

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()  
  
  const submitAnswer = async (answer) => {
    
    let answerSubmitObject = {
      "answer": {},
      "answerType": questionList[state.questions[state.selected]["answerType"]].id,
      "batchId": surveyId,
      "questionId": state.questions[state.selected]["questionId"],
      "anonymousUserId": anonymousUserId
    }
    
    if (answer.hasOwnProperty('files')) {
      let allPromiseURL = [];
      answer.files.forEach(element => {
        allPromiseURL.push(Questionaire.uploadFile(surveyId, state.questions[state.selected]["questionId"], element));
      });
      try {
        answerSubmitObject.answer.value = await Promise.all(allPromiseURL);
        setSubmitError(false);
      }
      catch (err) {
        logError({
          error: 'Media file upload failed',
          errorObject: err
        });
        setSubmitError(err);
        return err;
      }
    }
    else {
      answerSubmitObject.answer = answer
    }
    let index = state.selected;
    dispatch({ type: 'submit_progress', data: answerSubmitObject.answer });
    try {
      let nextIndex = await validateConditionAndReturnNextQuestion(state.questions[state.selected], answer );
      const respData = await Questionaire.submitAnswer(answerSubmitObject);
      if (respData.success) {
        dispatch({ type: 'submit_answer', data: answerSubmitObject.answer, index: index })
      } else {
        throw 'Error while submit';
      }
      if (nextIndex.index >= state.questions.length) {
        Questionaire.submitAnswerStatus({
          "answerStatus": nextIndex.value || 'end',
          "batchId": surveyId,
          "anonymousUserId": anonymousUserId
        });
        dispatch({ type: 'change_state', data: nextIndex.value || 'end' });
      }
      else {
        selectQuestion(nextIndex.index);
      }
      
      executeScroll();
      setSubmitError(false);
    } catch (err) {
      logError({
        error: 'Answer submit failed',
        errorObject: err
      });
      setSubmitError(err);
      return err;
    }
  }


  const validateConditionAndReturnNextQuestion = async (question ,answer) => {
    let condition = question.condition;
    let nextIndex = state.selected + 1;
    if (!condition.hasOwnProperty('list')) {
      return {
        index: nextIndex,
        value: ''
      };
    }
    for(let i = 0; i < condition.list.length; i++) {
      let tempFeQId = condition.list[i].jump;
      let conditionFlag = false;
      let prevCondition = 'or';
      for(let j = 0; j < condition.list[i].list.length; j++) {
        if (condition.list[i].list[j].hasOwnProperty('logic')) {
          prevCondition = condition.list[i].list[j].logic;
        }
        else {
          let currValidateQ = findQuestionByFeQId(condition.list[i].list[j].question).index;
          let validateValue = false;
          if (currValidateQ !== null) {
            let _answer = null
            if (condition.list[i].list[j].question === question.feQId){
              _answer = answer
            }
            else{
              _answer =   state.questions[currValidateQ].answer;
            }
            let conditionList = questionList[state.questions[currValidateQ].answerType].condition;
            for (let c = 0; c < conditionList.length; c++) {
              if (conditionList[c].id == condition.list[i].list[j].condition) {
                if (condition.list[i].list[j].condition == 'count'){
                  validateValue = await conditionList[c].validateCount(_answer, condition.list[i].list[j].value, question.questionId);
                }
                else{
                  validateValue = conditionList[c].validate(_answer, condition.list[i].list[j].value);
                }
                break;
              }
            }
          }
          if (prevCondition == 'or') {
            conditionFlag = conditionFlag || validateValue;
          }
          else if (prevCondition == 'and') {
            conditionFlag = conditionFlag && validateValue;
          }
        }
      }
      if (conditionFlag) {
        return findQuestionByFeQId(tempFeQId);
      }
    }
    return {
      index: nextIndex,
      value: ''
    };
  }

  const findQuestionByFeQId = (feId) => {
    for(let i = 0; i < state.questions.length; i++) {
      if (state.questions[i].feQId == feId) {
        return {
          index: i,
          value: feId
        };
      }
    }
    return {
      index: state.questions.length,
      value: feId
    }
  }

  const saveAnswer = (answer) => {
    dispatch({ type: 'save_answer', data: answer })
  }

  const startOver = () => {
    dispatch({ type: 'start_over' });
  }
  
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  function validatePhoneNumber(input_str) 
{
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return re.test(input_str);
}

  const checkDetailsValue = ()=>{
    if (anonymousUserRequiredFields.name )
    {
      if (name.length <= 2 )
      {
        setErrorText("Enter Proper Name")
        return false
      }
    }
    if(anonymousUserRequiredFields.email )
    {
      if ( !validateEmail(email)){
        setErrorText("Enter Valid Email")
        return false
      }
    }
    if( anonymousUserRequiredFields.phone )
    {
      if(!validatePhoneNumber(phone)){
        setErrorText("Enter Valid Phone")
        return false
      } 
    }
    if(anonymousUserRequiredFields.age && age =='')
    {
      setErrorText("Enter Valid age")
      return false
    }
    if(anonymousUserRequiredFields.city && city =='' )
    {
      setErrorText("Enter Valid city")
      return false
    }
    return true
  }
  const startSurvey = () => {
    let checkDetails = checkDetailsValue()
    if (checkDetails)
    {
      setAnonymousDetails(false)
      let UserData = {
          "age": age,
          "city": city,
          "country": country,
          "email": email,
          "gender": gender,
          "name": name != '' ? name :'Anonymous',
          "phone": phone,
          "batchId":surveyId,
          "vendorId": vendorId
        }
      Questionaire.submitgetAnswerUser(UserData).then((e)=> {
        if (e.success){
          let data = {
            "answerStatus": "start",
            "batchId": surveyId,
            "anonymousUserId": e.id
          }
          SetAnonymousUserId(e.id)
          Questionaire.submitAnswerStatus(data)
        }
      })
      
      dispatch({ type: 'change_state', data: 'questions' });
    }
    else{
      setIsAnswered(true)
    }
  }

  const ageContainer =()=>{
    return (
      <Fragment>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Age"
            fullWidth
            placeholder="Enter your Age"
            type="number"
            value={age}
            onChange={(event) => {setAge(event.target.value)}}
            autoComplete='off'
            />
        </div>
      </Fragment>
    )
  }

  const nameContainer =()=>{
    return (
      <Fragment>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Name"
            fullWidth
            placeholder="Enter your Name"
            type="text"
            value={name}
            onChange={(event) => {setName(event.target.value)}}
            autoComplete='off'
            />
        </div>
      </Fragment>
    )
  }

  const emailContainer =()=>{
    return (
      <Fragment>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Email"
            fullWidth
            placeholder="Enter your Email"
            type="text"
            value={email}
            onChange={(event) => {setEmail(event.target.value)}}
            autoComplete='off'
            />
        </div>
      </Fragment>
    )
  }

  const phoneContainer =()=>{
    return (
      <Fragment>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Phone Number"
            fullWidth
            placeholder="Enter your Phone Number"
            type="text"
            value={phone}
            onChange={(event) => {setPhone(event.target.value)}}
            autoComplete='off'
            />
        </div>
      </Fragment>
    )
  }
  
  const genderContainer =()=>{
    const genderObjList= [
      {
          id:0,
          name: "Female"
      },
      {
        id:1,
        name: "Male"
      },
      {
        id:2,
        name: "others"
      },
      {
        id:-1,
        name: "prefer not to answer"
      }
  ]
    return (
      <Fragment>
        <div className="mb3">
          <Autocomplete className="my-3"
            id="gender"
            size="medium"
            options={genderObjList}
            getOptionLabel={option => option.name}
            defaultValue={genderObjList[3]}
            onChange={(event, values) => values ? setGender(values.id): null}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Gender"
                placeholder="Gender"
                fullWidth
                autoComplete='off'
              />
            )}
            autoComplete='off'
          />
        </div>
      </Fragment>
    )
  }
  const cityContainer =()=>{
    if (!countryList.length){
      Authorization.getCountry().then(response => {
          setCountryList(response)
      })
    }
    
    const handleCountryChange = (event, values) =>{
      setCountry(values.name)
        Authorization.getCity(values.name).then(response => {
          setCityList(response)
        })
    }
    return (
      <Fragment>
        <div className="mb3">
                <Autocomplete className="my-3"
                    id="country"
                    size="medium"
                    options={countryList}
                    getOptionLabel={option => option.name}
                    onChange={handleCountryChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Country"
                        placeholder="Country"
                        fullWidth
                        autoComplete='off'
                      />
                    )}
                    autoComplete='off'
                  />
        </div>
        <div className="mb3">
          <Autocomplete className="my-3"
                    id="city"
                    size="medium"
                    options={cityList}
                    getOptionLabel={option => option.name}
                    onChange={(event, values) => values? setCity(values.name) : null}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="City"
                        placeholder="City"
                        fullWidth
                        autoComplete='off'
                      />
                    )}
                    autoComplete='off'
                  />
        </div>
      </Fragment>
    )
  }

 const anonymousContaner = (theme) =>{
   return(
     <Fragment>
       <Grid container spacing={4} alignItems="center" justify="center">
            <Grid item xs={8} lg={8}>
                <span>{name}</span>
                <Card className="mt-4" style={{ backgroundColor: theme.componentBgColorRgba, opacity:theme.componentOpacity}}>
                  <CardContent className="p-3">
                  <div className="justify-content-between mb-4"  >
                    {anonymousUserRequiredFields.name ? nameContainer():  null }
                    {anonymousUserRequiredFields.email ? emailContainer():  null }
                    {anonymousUserRequiredFields.phone ? phoneContainer():  null }
                    {anonymousUserRequiredFields.age ? ageContainer():  null }
                    {anonymousUserRequiredFields.gender ? genderContainer():  null }
                    {anonymousUserRequiredFields.city ? cityContainer() :  null }
                  </div>
                  {isAnswered ? <div>
                  <Alert severity="error">{errorText}</Alert>
                  </div> : null}
                  </CardContent>
                </Card>
            </Grid>
        </Grid>
     </Fragment>
   )
 }
 const SuspenseLoading = () => {
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <RingLoader color={'#5383ff'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Your Survey Is Getting Ready 
        </div>
      </div>
    </Fragment>
  );
};
if (initalLoad === null){
  return(
      <AnimatePresence>
        <SuspenseLoading />
      </AnimatePresence>
  )
}

  return (
    <div className="p-2 app-wrapper" style={{ ...mystyle, minHeight: '100vh', marginBottom: '65px' }}>
      <ThemeProvider theme={theme.pallet}>
      <div className="app-submission-header" >
          <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item xs={1} lg={1} md={1} >
            <Tooltip title="Start over">
                  <IconButton size="medium" color={theme.color} onClick={startOver}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={4} lg={4} md={4} >

            </Grid>
          <Grid item xs={2} lg={2} md={2} >
          {(logo != "" && logo !="None" && logo != null)?
              // <div className="card-img-wrapper" >
              <img src={logo}  className="card-img-top rounded" alt="..." style={{maxWidth: logoWidth+"px", maxHeight:logoHeight+"px" , 
                display:"block", marginLeft:0, marginRight:0}}/>
            // </div>
            : null }
          </Grid>
          <Grid item xs={4} lg={4} md={4} >
              
          </Grid>
          <Grid item xs={1} lg={1} md={1} >
          {props.userType === 2 ? null :
              <Tooltip title="logout">
              <IconButton size="small" color={theme.color} onClick={props.logout}>
                  <ExitToApp />
                </IconButton>
              </Tooltip>
              }
          </Grid>
          </Grid>
        </div>
        {(welcomePageTitle != "" || welcomePageText !=  "" || welcomePageSubText !== "") && showWelcomePage == true ? 
          <PageContainer theme={theme} welcomePageTitle={welcomePageTitle} 
          welcomePageText={welcomePageText} welcomePageSubText={welcomePageSubText} type={"welcome" } welcomeContinue={welcomeContinue}/> : 
            <div className="d-block p-2 w-100" ref={myRef}>
              {(anonymousDetails && props.userType === 2 )? anonymousContaner(theme) : null}
              {state.state == 'start' && (
                <div className="p-4 m-4 text-center">
                  <Button color={theme.color} variant={theme.buttonVariant} size="large" onClick={startSurvey}>
                    Start Survey
                  </Button>
                </div>
              )}
              {state.state == 'end' && (
                <PageContainer theme={theme} data={thankYouPage} type={"thankyou"}/>
              )}
              {state.state == 'terminate' && (
                <PageContainer theme={theme} data={terminatePage} type={"thankyou"}/>
              )}
              {state.state == 'questions' && state.questions.length > 0 ? (
                <Fragment>
                  <div style={{textAlign:"center"}}><span style={{ color:theme.fontColor, fontSize:theme.componentFontSize , fontFamily: theme.fontFamily }}> {state.selected + 1} / {questionLegth} </span></div>
                  <QuestionRender
                    key={state.questions[state.selected].feQId}
                    data={state.questions[state.selected]}
                    index={state.selected}
                    preview={false}
                    submit={submitAnswer}
                    save={saveAnswer}
                    previewColor="secondary"
                    theme={theme}
                    questionPreview={false}
                    allQuestions={state.questions}
                    surveyId={surveyId}
                    logError={logError}
                  />
                {submitError !== false && (
                  <Alert>
                    Error while submitting the answer
                  </Alert>
                )}
                </Fragment>
              ) : null}
            </div> 
          }
        
        <AppBar position="fixed" color="transparent" style={{ top: 'auto', bottom: 0}}>
          <Container maxWidth="100%">
            <Toolbar>
              <Typography variant="body1" color="inherit">
                © 2021 Enumerate.AI
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    </div>
  )
}

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

export default connect(mapStateToProps)(ViewSurvey);