import React from 'react';
import {
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';

const Summary = ({
  text
}) => {
  return (
    <Card className="card-box mb-4">
      <div className="card-header pr-2" style={{padding:' 0.10rem 1.25rem'}}>
          <div className="card-header--title mb-2 font-size-md font-weight-bold pt-3 pb-2" >
          Summary
          </div>
      </div>
      <div className="d-flex justify-content-center" style={{maxHeight: '250px' ,  minHeight:'250px', overflowY:'scroll'}}>
      {text}
      </div>
  </Card>

  );
};

export default Summary;
