const Validation = [
  {
    id: "is answered",
    validate: (answer, value) => {
      if (answer?.setValue?.length > 0) {
        return true;
      }
      return false;
    }
  },
  {
    id: "has reached quota",
    validate: (answer, value) => {
      if (answer?.setValue?.length > 0) {
        return false;
      }
      return true;
    }
  }
];

export default Validation;