import React, { useState } from 'react';
import UploadMedia from './util/UploadMedia';
import MediaSet from './util/CreateMediaSet';
import DisplaySet from './util/DisplayRules';

const RandomizedCreate = ({
  surveyId,
  feQId,
  questionObject,
  extraInfo,
  allQuestions,
  updateQuestionObject,
  updateExtraQuestionInfo
}) => {
  const [mediaList, setMediaList] = useState(extraInfo.allMediaList || []);
  const [mediaSets, setMediaSets] = useState(extraInfo.setList || []);
  const [mediaNameList, setMediaNameList] = useState([]);
  const [displayRules, setDisplayRules] = useState(extraInfo.displayRule || {});
  
  const saveAllmediaList = (list) => {
    setMediaList(list);
    updateExtraQuestionInfo({
      allMediaList: list
    });
  };

  const saveMediaSets = (list) => {
    setMediaSets(list);
    updateExtraQuestionInfo({
      setList: list
    });
    const options = list.map((e) => e.setName);
    updateQuestionObject({
      options
    });
  };

  const updateDisplayRule = (obj) => {
    setDisplayRules(obj);
    updateExtraQuestionInfo({
      displayRule: obj
    });
  };

  return (
    <>
      <UploadMedia
        uploadFileList={setMediaNameList}
        saveFiles={saveAllmediaList}
        files={mediaList}
        surveyId={surveyId}
        feQId={feQId}
      />
      <div style={{ padding: '5px' }} />
      <MediaSet
        sets={mediaSets}
        mediaNameList={mediaNameList}
        saveSets={saveMediaSets}
        feQId={feQId}
      />
      <div style={{ padding: '5px' }} />
      <DisplaySet
        setList={mediaSets}
        displayRules={displayRules}
        updateDisplayRule={updateDisplayRule}
        allQuestions={allQuestions}
        feQId={feQId}
      />
    </>
  );

};

export default RandomizedCreate;
