import React, { Fragment, useState } from 'react';
import Chart from 'react-apexcharts';
import {Select, FormControl, Grid, MenuItem
} from '@material-ui/core';

export default (props) => {
  const [displayType, setDisplayType] = useState(2)
  let data = [...props.values];

  data.sort((a, b) => {
    return b.count - a.count;
  });
  let categories = [];
  let values = [];
  data.forEach((e) => {
    categories.push(e.value);
    values.push(e.count);
  })

  const test = (value)=>{
    if(Number.isInteger(value))
    {
      let out = parseInt(value) / values.reduce((a, b) => a + b, 0) * 100
      return Math.round(out) + " %"
    }
    else{
      return value
    }
  }

  const barOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    xaxis: {
      categories: categories
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return   value ;
        }
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
          return test(val)
      },
      textAnchor: 'middle',
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: '',
          colors: ['white']
      }
    }
    
  };
  const barSeries = [
    {
      name: '',
      data: values
    }
  ];

  const PieOption = {
    
    labels: categories,
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return  Math.round(val) + "%"
      }
    },
    theme: {
      mode: 'light', 
      palette: 'palette2', 
      monochrome: {
          enabled: false,
          color: '#255aee',
          shadeTo: 'dark',
          shadeIntensity: 0.65
      },
  }
  }
    const PieSeries = values

  const handleChange =(event)=>{
    setDisplayType(event.target.value)
  }

  return (
    <>
  
  <Grid container spacing={2} >
  <Grid item xs={7} lg={9} md={8}>
    </Grid>
      <Grid item xs={5} lg={3} md={4}>
        <FormControl className="">
            
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={displayType}
              variant="outlined"
              onChange={handleChange}

            >
              <MenuItem value={1}>Bar Graph</MenuItem>
              <MenuItem value={2}>Pie Graph</MenuItem>
              <MenuItem value={3}>Donut Graph</MenuItem>
              {/* <MenuItem value={3}>Table Percent</MenuItem>
              <MenuItem value={4}>Table number</MenuItem> */}
            </Select>
          </FormControl>
    </Grid>
    </Grid>

    <div style={{height: 350}}>
      {displayType === 1 ?
      <Chart options={barOptions} series={barSeries} height='100%' type="bar" />
      : null}
      {displayType === 2  ?
       <div className="d-flex justify-content-center opacity-9 ">
      <Chart options={PieOption} series={PieSeries} type="pie" width="450" />
      </div>
      : null}
      {displayType === 3  ?
       <div className="d-flex justify-content-center opacity-9 ">
      <Chart options={PieOption} series={PieSeries} type="donut" width="450" />
      </div>
      : null}
    </div>
    </>
  );
}