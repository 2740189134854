import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { loginAPI, authAPI, logoutAPI, getAllTrack, createTrack, createSurvey, modifyQuestion, deleteQuestion, getAllQuestion,
  createQuestion, registerAPI, getAllVideo, getAllSurvey, getSignedURL, uploadFile, addVideo, updateVideoStatus, 
   getIndustryCategoryProduct, getCountryList, getCityList , emailExists, surveyAnswer,
  updateSurvey, deleteSurvey, getQuestionIdsList, getAnswerIdsList ,copySurvey, makeFilePublic, getDemographyInformation, getDashboardData, 
  getAnonymousToken, answerStatus, getPPTData, getRawData, getAnswerUser , getAnswerCount, getBatchIndustryCategoryTaxonomy, 
  updateBatchIndustryCategoryTaxonomy, getMySavedTaxonomy ,getTaxonomyById, addMyTaxonomy, editMyTaxonomy, getAdSurveyDashboard, getPermissionAPI,
  getTranscriptDashboard, updateVideoTranscript, deleteVideoTranscript, getAIquestions,updateQuestionAiAnalysis,approveVideoTranscript,
  getQuotaCount, addVideoForAi , addVideoForSentimentAnalysis ,getFormDashboardData, UpdateFormDashboardData, surveyAnswerErrorLogging,
  getAnalysisScoresForQuestion, getAttributesForQuestion, getAllVideosForQuestion, toggleFavoriteVideo, getVideoDetail, getQuotaDashboard,
  getShareToUsers, updateShareToUsers ,deleteShareToUsers, addShareToUsers
} from './API';

const statusMapping = {
  "0": "ongoing",
  "1": "done"
}

var questionIdsMapping = [];
var answerIdsMapping = [];

function loadAllMappings(header) {
  let questionIdPromise = new Promise ((resolve, reject) => {
    if (questionIdsMapping.length > 0) {
      resolve(questionIdsMapping);
    }
    else {
      getQuestionIdsList(header).then(response => {
        let data = JSON.parse(response.data);
        if (data.status == 'success') {
          questionIdsMapping = data.value;
        }
        resolve(questionIdsMapping);
      }).catch(err => {
        reject(err);
      });
    }
  });
  let answerIdPromise = new Promise ((resolve, reject) => {
    if (answerIdsMapping.length > 0) {
      resolve(answerIdsMapping);
    }
    else {
      getAnswerIdsList(header).then(response => {
        let data = JSON.parse(response.data);
        if (data.status == 'success') {
          answerIdsMapping = data.value;
        }
        resolve(answerIdsMapping);
      }).catch(err => {
        reject(err);
      });
    }
  });
  return Promise.all([questionIdPromise, answerIdPromise])
}

function getAnswerIdsMapping(beToFe, key) {
  for(let i = 0; i < answerIdsMapping.length; i++) {
    if (beToFe && answerIdsMapping[i]["id"] == key) {
      return answerIdsMapping[i]["feAnswerTypeId"];
    }
    else if (!beToFe && answerIdsMapping[i]["feAnswerTypeId"] == key) {
      return answerIdsMapping[i]["id"];
    }
  }
}

function getQuestionIdsMapping(beToFe, key) {
  for(let i = 0; i < questionIdsMapping.length; i++) {
    if (beToFe && questionIdsMapping[i]["id"] == key) {
      return questionIdsMapping[i]["name"].toLowerCase();
    }
    else if (!beToFe && questionIdsMapping[i]["name"] == key) {
      return questionIdsMapping[i]["id"];
    }
  }
}

function uploadFileAndLink(header, batchId, questionId, file) {
  return new Promise(async (resolve, reject) => {
    try {
      let signedURLResp = await getSignedURL(header, {
        batchId: batchId,
        questionId: questionId,
        fileName: file.name
      });

      let data = JSON.parse(signedURLResp.data);
      if (data.status !=="success") {
        throw "did not get signed url";
      }
      let url = data.value.substring(0, data.value.indexOf('?'));
      let fileHeader = {};
      fileHeader['Content-Type'] = file.type;
      await uploadFile(fileHeader, data.value, file).then(function(resolve){
        makeFilePublic(header, { "url": url });
      });
      if (url != null)
      {
        url = url.replace('https://enumerate-videos-mumbai.s3.amazonaws.com/','https://d2zub0gh4qihh8.cloudfront.net/')
      }
      resolve(url);
    }
    catch(err) {
      reject(err);
    }
  })
}

const Authorization = (function() {
  var token = "";
  var user_id = "";
  var userType = "";
  var user_name = "";
  var isAuthenticated = false;
  var refreshAuth;
  let permissionObj = null;

  function refreshToken() {
    refreshAuth = setInterval(function() {
      let header = {
        "Authorization": "Bearer " + token
      }
      authAPI(header).then(res => {
        let data = res.data;
        user_name = data.data.user_name;
        user_id = data.data.user_id;
      });
    }, 59*60*1000);
  }

  async function fetchPermission() {
    try {
      const header = Authorization.getHeader();
      const response = await getPermissionAPI(header);

      const data = JSON.parse(response.data);
      if (data.status === 'success') {
        permissionObj = { ...data.value };
      } else {
        permissionObj = {
          isDownload: true,
          isForm: true,
          isAiAnalysis: true,
          hideUserFromForm: false,
          isBrand: false,
          isAgnecy: false,
          isVendor: false
        };
      }
    } catch (err) {
      permissionObj = {
        isDownload: true,
        isForm: true,
        isAiAnalysis: true,
        hideUserFromForm: false,
        isBrand: false,
        isAgnecy: false,
        isVendor: false
      };
    }
  }

  return {
    login: function(email, password) {
      permissionObj = null;
      return new Promise((resolve, reject) => {
        loginAPI(email, password).then(res => {
          let data = res.data;
          if (data.status === "success") {
            refreshToken();
            token = data.auth_token;
            window.sessionStorage.setItem('accessToken', token);
            userType = data.is_admin ? "ADMIN" : "CLIENT";
            isAuthenticated = true;
            resolve({
              success: true
            })
          }
          else{
            resolve({
              success: false
            })
          }
        }).catch(err => {
          reject(err);
        })
      });
    },
    register: function(name, dob, gender, email, password, city, lat, lon) {
      permissionObj = null;
      return new Promise((resolve, reject) => {
        registerAPI(name, dob, gender, email, password, city, lat, lon).then(res => {
          let data = res.data;
          if (data.status === "success") {
            refreshToken();
            token = data.auth_token;
            window.sessionStorage.setItem('accessToken', token);
            userType = data.is_admin ? "ADMIN" : "CLIENT";
            isAuthenticated = true;
            resolve({
              success: true
            })
          }
          reject();
        })
      });
    },
    getPermission: async () => {
      if (permissionObj === null) {
        await fetchPermission();
      }
      return permissionObj;
    },
    getCountry: async function(){
        let header = Authorization.getHeader();
        let structure = await getCountryList(header)
        let data = JSON.parse(structure.data);
        if (data.status === "success")
        {
          let valueout = [];
          data.value.forEach((i)=>{
            valueout.push({
              id: i,
              name: i,
            })
          });
          return valueout
        }
    },
    getCity: async function(coutry, state=''){
      let header = Authorization.getHeader();
      let structure = await getCityList(header, coutry, state)
      let data = JSON.parse(structure.data);
      if (data.status === "success")
        {
          let valueout = [];
          data.value.forEach((i)=>{
            valueout.push({
              id: i[0],
              name: i[1],
            })
          });
          return valueout
        }
    },
    checkEmailExists: async function(email){
      let structure = await emailExists(email)
      let data = structure.data;
      let out = data.status === "success" ?  true : false
      return out
    },
    isAuthenticated: async function() {
      if (isAuthenticated) {
        return isAuthenticated;
      }
      if (token === "" && window.sessionStorage.getItem('accessToken')) {
        token = window.sessionStorage.getItem('accessToken');
        try {
          let res = await authAPI(this.getHeader());
          if (res.data.status === "success") {
            user_id = res.data.data.user_id;
            userType = res.data.data.admin ? "ADMIN" : "MEMBER";
            user_name = res.data.data.user_name;
            isAuthenticated = true;
            return {
              auth: isAuthenticated,
              userName: user_name,
              userId: user_id
            }
          }
          else {
            return {
              auth: isAuthenticated,
              userName: '',
              userId: null
            }
          }
        }
        catch(err) {
          isAuthenticated = false;
          return false;
        }
      }
    },
    getUserDetails: async function() {
      let res = await authAPI(this.getHeader());
      if (res.data.status === "success") {
        user_id = res.data.data.user_id;
        userType = res.data.data.admin ? "ADMIN" : "MEMBER";
        user_name = res.data.data.user_name;
        return {
          userName: user_name,
          userType: userType,
          userId: user_id
        }
      }
    },
    logout: function() {
      clearInterval(refreshAuth);
      permissionObj = null;
      let header = {
        "Authorization": "Bearer " + token
      }
      logoutAPI(header).then(data => {
        isAuthenticated = false;
        token = "";
        window.sessionStorage.clear();
      })
    },
    getHeader: function() {
      return {
        "Authorization": "Bearer " + token
      }
    },
    getToken: function() {
      return token;
    },
    getUserId: function() {
      return user_id;
    },
    checkToken: async function(_token){
      let header = {
        "Authorization": "Bearer " + _token
      }
      let res = await authAPI(header);
      if (res.data.status === "success") {
        token = _token
        window.sessionStorage.setItem('accessToken', _token);
        isAuthenticated = true;
        user_id = res.data.data.user_id;
        userType = res.data.data.admin ? "ADMIN" : "MEMBER";
        user_name = res.data.data.user_name;
        return {
          userName: user_name,
          userType: userType,
          auth: isAuthenticated,
          userId: user_id
        }
      }else{
        return {
          auth:false
        }
      }
    }
  }
})();

const Survey = (function() {
  var project_id = false;
  var track_id = false;
  var surveyList = [];
  var old_survey_ids = [];
  var indCatProdMapping = {
    status: false,
  };

  function modifySelectionOptionsMapping(header, options, batchId, feQId) {
    let PromiseArr = [];
    options.forEach((e) => {
      PromiseArr.push(new Promise(async (res, rej) => {
        let response = {
          finUrl: ""
        };
        try {
          if (e.file) {
            response.finUrl = await uploadFileAndLink(header, batchId, feQId, e.file);
          }
          else {
            response.finUrl = e.finUrl;
          }
        }
        catch(err) {
        }
        res(response);
      }))
    })
    return Promise.all(PromiseArr);
  }

  function editQuestion(header, question, batchId) {
    return new Promise(async (resolve, reject) => {
      try {
        let payload = {
          answerType: question.answerType,
          condition: question.condition,
          extraInfo: question.extraInfo || {},
          feQId: question.feQId,
          orderId: question.orderId,
          questionId: question.questionId,
          questionObject: question.questionObject,
          questionText: question.questionText,
          questionSubText: question.questionSubText,
          questionType: question.questionType ,
          questionUrl: question.questionTypeName !== 'text' ? question.questionUrl : ''
        }
        if (question.questionFile) {
          let url = await uploadFileAndLink(header, batchId, question.feQId, question.questionFile);
          payload.questionUrl = url;
        }
        if (question.answerType.indexOf('Image') >= 0) {
          let optionsArray = await modifySelectionOptionsMapping(header, question.questionObject.options, batchId, question.feQId);
          payload.questionObject.options = optionsArray;
        }

        let out = await modifyQuestion(header, payload);

        resolve({
          id: question.feQId,
          success: true
        });
      }
      catch(err) {
        reject(err);
      }
    });
  }

  function deleteSurveyQuestion(header, questionId) {
    return deleteQuestion(header, { questionId: questionId });
  }

  function addQuestion(header, question, batchId) {
    return new Promise(async (resolve, reject) => {
      try {
        let payload = {
          answerType: question.answerType,
          batchId: batchId,
          condition: question.condition,
          extraInfo: question.extraInfo || {},
          feQId: question.feQId,
          orderId: question.orderId,
          questionObject: question.questionObject,
          questionText: question.questionText,
          questionSubText: question.questionSubText,
          questionType: question.questionType,
          questionUrl: question.questionUrl
        }
        if (question.questionFile) {
          let url = await uploadFileAndLink(header, batchId, question.feQId, question.questionFile);
          payload.questionUrl = url;
        }
        if (question.answerType.indexOf('Image') >= 0) {
          payload.questionObject.options = await modifySelectionOptionsMapping(header, question.questionObject.options, batchId, question.feQId);
        }
        await createQuestion(header, payload);
        resolve({
          id: question.feQId,
          success: true
        });
      }
      catch(err) {
        reject(err);
      }
    });
  }

  return {
    getVideoDetail: async (payload) => {
      const header = Authorization.getHeader();
      try {
        const response = await getVideoDetail(header, payload);
        const data = JSON.parse(response.data);
        return data;
      } catch (err) {
      }
      return {};
    },
    toggleFavoriteVideo: async (payload) => {
      const header = Authorization.getHeader();
      try {
        await toggleFavoriteVideo(header, payload);
        return true;
      } catch (err) {
        return false;
      }
    },
    getVideoListForQuestion: async ({
      surveyId,
      questionId
    }) => {
      const header = Authorization.getHeader();
      try {
        const response = await getAllVideosForQuestion(header, {
          age: 0,
          ageOffset: 0,
          batchId: surveyId,
          count: 0,
          gender: -1,
          offset: 0,
          question_id: questionId
        });
        const data = JSON.parse(response.data);
        if (data.status === 'success') {
          return data.value;
        }
      } catch (err) {
      }
      return [];
    },
    getAttributesFromAnalysis: async ({
      surveyId,
      questionId
    }) => {
      const header = Authorization.getHeader();
      try {
        const response = await getAttributesForQuestion(header, {
          age: 0,
          ageOffset: 0,
          batchId: surveyId,
          count: 0,
          gender: -1,
          offset: 0,
          question_id: questionId,
          attribute: "",
          features: "",
          sentiment: -2,
          videoId: 0
        });
        const data = JSON.parse(response.data);
        if (data.status === 'success') {
          return {
            positive: data.positive,
            negative: data.negative,
            neutral: data.neutral,
            keyWords: data.keyWords,
            summary: data.summary,
            question:data.question
          };
        }
      } catch (err) {
      }
      return {
        positive: {},
        negative: {},
        neutral: {},
        keyWords: [],
        summary: ''
      };
    },
    getAnalyticsScore: async ({
      surveyId,
      questionId
    }) => {
      const header = Authorization.getHeader();
      try {
        const response = await getAnalysisScoresForQuestion(header, {
          batchId: surveyId,
          question_id: questionId
        });
        const data = JSON.parse(response.data);
        if (data.status === 'success') {
          return {
            sentimentScore: data.sentimentScore,
            confidentScore: data.confidentScore
          };
        }
      } catch (err) {
      }
      return {
        sentimentScore: 0,
        confidentScore: 0
      };
    },
    getAIquestions : async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await getAIquestions(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    updateQuestionAiAnalysis : async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await updateQuestionAiAnalysis(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    getTranscriptDashboard : async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await getTranscriptDashboard(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
     updateVideoTranscript : async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await updateVideoTranscript(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    deleteVideoTranscript: async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await deleteVideoTranscript(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    approveVideoTranscript: async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await approveVideoTranscript(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    getDashboardData: async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await getDashboardData(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    getFormDashboardData: async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await getFormDashboardData(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    getQuotaDashboard: async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await getQuotaDashboard(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    getAdSurveyDashboard: async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await getAdSurveyDashboard(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    UpdateFormDashboardData: async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await UpdateFormDashboardData(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    addVideosForAnalysis: async (obj) =>{
      let header = Authorization.getHeader();
      try {
        let response = await addVideoForAi(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    addVideosForSentimentAnalysis: async (obj) =>{
      let header = Authorization.getHeader();
      try {
        let response = await addVideoForSentimentAnalysis(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    getRawFullData: async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await getRawData(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },


    getPPtData: async (obj) => {
      let header = Authorization.getHeader();
      try {
        let response = await getPPTData(header, obj);
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    getDemographyInformation: async (id) => {
      let header = Authorization.getHeader();
      try {
        let response = await getDemographyInformation(header, {
          batchId: parseInt(id)
        });
        let data = JSON.parse(response.data);
        return data;
      }
      catch {
        return {
          status: false
        }
      }
    },
    getQuestionIdsMapping: async () => {
      if (questionIdsMapping.length > 0) {
        return questionIdsMapping;
      }
      else {
        let header = Authorization.getHeader();
        let response = await getQuestionIdsList(header);
        let data = JSON.parse(response.data);
        if (data.status == 'success') {
          questionIdsMapping = data.value;
        }
        return questionIdsMapping;
      }
    },
    getAnswerIdsMapping: async () => {
      if (answerIdsMapping.length > 0) {
        return answerIdsMapping;
      }
      else {
        let header = Authorization.getHeader();
        let response = await getAnswerIdsList(header);
        let data = JSON.parse(response.data);
        if (data.status == 'success') {
          answerIdsMapping = data.value;
        }
        return answerIdsMapping;
      }
    },
    submitQuestions: async (questions, removeQuestions, batchId) => {
      let header = Authorization.getHeader();
      let PromisesArr = [];
      let i = 0
      removeQuestions.forEach((e) => {
        if (e.questionId) {
          PromisesArr.push(deleteSurveyQuestion(header, e.questionId));
        }
      });
      questions.forEach((e) => {
        i = i + 1
        e.orderId = i
        if (e.questionId && e.edited) {
          PromisesArr.push(editQuestion(header, e, batchId));
        }
        else if (e.questionId === false) {
          PromisesArr.push(addQuestion(header, e, batchId));
        }
      });
      return Promise.all(PromisesArr);
    },
    getAllSurveys: async function(batchId=0) {
      let header = Authorization.getHeader();
      surveyList = [];
      old_survey_ids = [];
      function getFormattedDate(date) {
        var dateObject = new Date(date);
        let year = dateObject.getFullYear();
        let month = (1 + dateObject.getMonth()).toString().padStart(2, '0');
        let day = dateObject.getDate().toString().padStart(2, '0');
      
        return month + '/' + day + '/' + year;
    }
      try {
        let surveys = await getAllSurvey(header,{
          "batchId": batchId
        })
        surveys = JSON.parse(surveys.data);
        if (surveys.status === "success") {
          surveys.value.forEach((e) => {
            surveyList.push({
              title: e.name,
              notes: e.notes,
              survey_id: e.id,
              surveyTypeId: e.surveyTypeId,
              status: e.status,
              language: e.language ,
              created_at: getFormattedDate(e.created_at),
              updated_at: getFormattedDate(e.updated_at),
              expectedesponses:e.expectedResponse,
              countryList:e.countryList,
              surveyTypeName:e.surveyTypeName,
              genderGroup:e.genderGroup,
              ageGroup:e.ageGroup,
              averageTimeTaken:e.averageTimeTaken,
              startCount:e.startCount,
              completedCount:e.completedCount,
              theme:e.theme,
              token:e.token,
              getDemographic:e.getDemographic,
              expectedEndDate:e.expectedEndDate,
              pannelGender:e.pannelGender,
              pannelGenderBreakup:e.pannelGenderBreakup,
              pannelExtraInfo:e.pannelExtraInfo,
              pannelRequested:e.pannelRequested,
              industryId:e.industryId,
              categoryId:e.categoryId,
              excelDownload:e.excelDownload,
              pptDownload:e.pptDownload,
              statusText:e.statusText,
              summary:e.summary

          } );
          })
        }
        else {
          surveyList = [];
        }
      }
      catch(err) {}
      return surveyList;
    },
    getAllQuestions: async function(surveyId) {
      try {
        let header = Authorization.getHeader();
        let response = await getAllQuestion(header, {
          batchId: surveyId,
          count: 0,
          offset: 0,
          questionId: 0
        });
        let data = JSON.parse(response.data)
        if (data.status == 'success') {
          let questions = [];
          data.value.forEach((e) => {
            questions.push({
              feQId: e.feQId || "",
              orderId: e.orderId || "",
              questionId: e.id,
              questionText: e.question || "",
              questionSubText: e.questionSubText || "",
              questionType: e.questionTypeId,
              questionTypeName: getQuestionIdsMapping(true, e.questionTypeId),
              questionUrl: e.questionUrl || "",
              questionFile: false,
              answerType:  e.answerType,
              condition: e.condition || {},
              questionObject: e.questionObject || {},
              extraInfo: e.extraInfo || {},
              analysis: e.analysis || {}
            })
          });
          return questions;
        }
        else {
          return data.error;
        }
      }
      catch(err) {
        return [];
      }
    },
    getSurvey: function(id) {
      let survey = {};
      for(let i = 0; i < surveyList.length; i++) {
        if (id === surveyList[i]["survey_id"]) {
          survey = Object.assign({}, surveyList[i]);
          break;
        }
      }
      return survey;
    },
    createNewSurvey: async function(payload) {
      let header = Authorization.getHeader();
      try {
        let out = await createSurvey(header, {
          title: payload.title,
          notes:payload.notes,
          surveyTypeId:payload.surveyTypeId
        });
        let res = JSON.parse(out.data)
        if (res.status === "success") {
        return {success: true, survey_id: res.id};
        }
        else{ return {success: false } }
      }
      catch(err) {
        return {success: false};
      }
    },
    deleteExistingSurvey: async function(payload){
      let header = Authorization.getHeader();
      try {
        await deleteSurvey(header, payload);
        return {success: true};
      }
      catch(err) {
        return {success: false};
      }
    },
    copySurvey: async function(payload){
      let header = Authorization.getHeader();
      try {
        let response = await copySurvey(header, payload);
        let res = JSON.parse(response.data);
      if (res.status === "success") {
        return {success: true, survey_id: res.id};
        }
        else{ return {success: false }}
      }
      catch(err) {
        return {success: false};
      }
    },
    updateExistingSurvey: async function(payload){
      let header = Authorization.getHeader();
      let _surveyData = {
        "ageGroup": payload.ageGroup ?? [],
        "backgroundUrl": payload.backgroundUrl ?? "",
        "batchId": payload.survey_id,
        "categoryId": payload.categoryId ?? 0,
        "cityList": payload.cityList ?? [],
        "countryList": payload.countryList ?? [],
        "expectedEndDate": payload.expectedEndDate ?? "",
        "industryId": payload.industryId ?? 0,
        "language": payload.language ?? "",
        "notes": payload.notes ?? "",
        "numberOfRecipents": payload.numberOfRecipents ?? 0,
        "pannelExtraInfo": payload.pannelExtraInfo ?? "",
        "pannelGender": payload.pannelGender ?? [],
        "pannelGenderBreakup": payload.pannelGenderBreakup ?? {},
        "pannelRequested": payload.pannelRequested ?? false,
        "processStatus": payload.processStatus ?? 0,
        "stateList": payload.stateList ?? [],
        "surveyTypeId": payload.surveyTypeId ?? 0,
        "theme": payload.theme ?? {},
        "title": payload.title ?? ""
      }

      try {
        let response = await updateSurvey(header, _surveyData);
        let res = JSON.parse(response.data);
 
        return {success: true};
      }
      catch(err) {
        return {success: false};
      }
    },
    createNewQuestion: async function(payload) {
      let header = Authorization.getHeader();
      return createQuestion(header, {
        batchId: payload.survey_id,
        qType: 0,
        question: payload.question
      });
    },
    getAllVideos: async function(payload) {
      let header = Authorization.getHeader();
      try {
        let res = await getAllVideo(header, {
          batchId: payload.survey_id,
          projectId: project_id,
          questionId: payload.question_id,
          trackId: track_id,
          videoId: 0
        });
        let data = JSON.parse(res.data);
        let ans = [];
        if (data.status === "success") {
          data.value.forEach((e) => {
            ans.push({
              id: e.videoId,
              url: e.videoUrl,
              status: e.status,
              thumbnail: false
            })
          })
        }
        for(let i = 0; i < surveyList.length; i++) {
          if (payload.survey_id === surveyList[i]["survey_id"]) {
            surveyList[i]['answers'][payload.question_id] = ans;
            break;
          }
        }
        return [...ans];
      }
      catch(err) {
        return {
          status: 'fail'
        }
      }
    },
    uploadVideo: async function(payload) {
      try {
        for(let i = 0; i < surveyList.length; i++) {
          if (payload.survey_id === surveyList[i]["survey_id"]) {
            if (surveyList[i]['answers'].hasOwnProperty(payload.question_id)) {
              surveyList[i]['answers'][payload.question_id].push({
                id: payload.temp_vid_id,
                url: "",
                status: "-1",
                thumbnail: false
              });
            }
            else {
              surveyList[i]['answers'][payload.question_id] = [{
                id: payload.temp_vid_id,
                url: "",
                status: "-1",
                thumbnail: false
              }];
            }
            break;
          }
        }
        let header = Authorization.getHeader();
        let signedURLResp = await getSignedURL(header, {
          batchId: payload.survey_id,
          questionId: payload.question_id,
          fileName: payload.fileName
        });
        let data = JSON.parse(signedURLResp.data);
        if (data.status !=="success") {
          throw "did not get signed url";
        }
        let url = data.value.substring(0, data.value.indexOf('?'));
        let fileHeader = {};
        if (url.indexOf('.mp4') >= 0) {
          fileHeader['Content-Type'] = 'video/mp4';
        }
        else if (url.indexOf('.webm') >= 0) {
          fileHeader['Content-Type'] = 'video/webm';
        }
        await uploadFile(fileHeader, data.value, payload.file);
        let associate = await addVideo(header, {
          batchId: payload.survey_id,
          moduleType: "",
          projectId: project_id,
          questionId: payload.question_id,
          title: "",
          trackId: track_id,
          transcription: "",
          appType: 2,
          videoUrl: url
        });
        let status = JSON.parse(associate.data).status;
        if (status === "success") {
          return {
            success: true,
            url: url
          };
        }
        else {
          throw 'association fail'
        }
      }
      catch(err) {
        for(let i = 0; i < surveyList.length; i++) {
          if (payload.survey_id === surveyList[i]["survey_id"]) {
            let newAns = [];
            for(let j = 0; j < surveyList[i]["answers"][payload.question_id].length; j++) {
              if (payload.temp_vid_id !== surveyList[i]["answers"][payload.question_id][j]["id"]) {
                newAns.push(surveyList[i]["answers"][payload.question_id][j]);
              }
            }
            surveyList[i]["answers"][payload.question_id] = newAns;
            break;
          }
        }
        return {
          success: false
        }
      }
    },
    approveVideo: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let data = await updateVideoStatus(header, {
          moduleType: "",
          title: "",
          transcription: "",
          videoId: payload.videoIds,
          videoStatus: 1,
          videoUrl: ""
        });
        if (JSON.parse(data.data).status !=="success") {
          throw 'could not update video status';
        }
        for(let i = 0; i < surveyList.length; i++) {
          if (payload.survey_id === surveyList[i]["survey_id"]) {
            for(let j = 0; j < surveyList[i]["answers"][payload.question_id].length; j++) {
              if (payload.videoIds.indexOf(surveyList[i]["answers"][payload.question_id][j]["id"]) >= 0) {
                surveyList[i]["answers"][payload.question_id][j]["status"] = "1";
              }
            }
            break;
          }
        }
        return {
          success: true
        }
      }
      catch(err) {
        return {
          success: false
        }
      }
    },

    getIndustryCategoryProduct: async function() {
      if (indCatProdMapping.status) {
        return indCatProdMapping;
      }
      try {
        let header = Authorization.getHeader();
        let structure = await getIndustryCategoryProduct(header, {
          categoryId: 0,
          industryId: 0,
          productId: 0
        })
        let data = JSON.parse(structure.data);
        indCatProdMapping.industry = data.industry;
        indCatProdMapping.category = data.category;
        indCatProdMapping.status = true;
        return indCatProdMapping;
      }
      catch(err) {
        return indCatProdMapping;
      }
    },
    getBatchIndustryCategoryTaxonomy: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await getBatchIndustryCategoryTaxonomy(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true, value : data.value};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
    updateBatchIndustryCategoryTaxonomy: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await updateBatchIndustryCategoryTaxonomy(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
    getMySavedTaxonomy: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await getMySavedTaxonomy(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true, output : data.value};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
    getTaxonomyById: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await getTaxonomyById(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true, output : data.value};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
    addMyTaxonomy: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await addMyTaxonomy(header, payload)
        let data = JSON.parse(out.data);
        return data
      }
      catch(err) {
        console.log(err)
        return {success: false};;
      }
    },
    editMyTaxonomy: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await editMyTaxonomy(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
    deleteMyTaxonomy: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await editMyTaxonomy(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
     getShareToUsers: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await getShareToUsers(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true, output : data.value};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
    updateShareToUsers: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await updateShareToUsers(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true, output : data.value};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
    deleteShareToUsers: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await deleteShareToUsers(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true, output : data.value};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
    addShareToUsers: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let out = await addShareToUsers(header, payload)
        let data = JSON.parse(out.data);
        if (data.status == "success") {
          return {success: true, output : data.value};
        }
        else {
          return {success: false};
        }
      }
      catch(err) {
        return {success: false};;
      }
    },
    AnonymousToken: async function(payload) {
      let header = Authorization.getHeader();
      try {
        let surveys = await getAnonymousToken(header, {
            batchId: payload.batchId,
            time: payload.time,
            getDemographic: payload.demogrophy
        });
        surveys = JSON.parse(surveys.data);
          if (surveys.status === "success") {
            return {token : surveys.auth_token, success: true}
          }
        return {success: false};
      }
      catch(err) {
        return {success: false};
      }
    }
  }
})();

const Questionaire = (function() {
  var Survey = {
    title: "",
    id: null,
    industry: "",
    category: "",
    product: "",
    questions: []
  };

  return {
    preFetchRequiredData: async () => {
      try {
        let header = Authorization.getHeader();
        await loadAllMappings(header);
        return true;
      }
      catch (err) {
        return false;
      }
    },
    getSurvey: async (survey_id, track_id, project_id) => {
      if (Survey.questions.length === 0) {
        let header = Authorization.getHeader();
        Survey.id = survey_id;
        try {
          let surveys = await getAllSurvey(header, {
            "batchId": survey_id,
            "trackId":track_id
          })
          surveys = JSON.parse(surveys.data);
          if (surveys.status === "success") {
            Survey.title = surveys.value[0]['name'];
            Survey.industry = surveys.value[0]['industry'];
            Survey.category = surveys.value[0]['category'];
            Survey.product = surveys.value[0]['product'];
            surveys.value[0]['questions'].forEach((e) => {
              Survey.questions.push({
                id: e.id,
                question: e.question,
                answer: {
                  url: "",
                  status: "0"
                }
              })
            })
          }
          else {
          }
        }
        catch(err) {}
      }
      return Survey;
    },
    getAllQuestions: async function(surveyId) {
      try {
        let header = Authorization.getHeader();
        let response = await getAllQuestion(header, {
          batchId: surveyId,
          count: 0,
          offset: 0,
          questionId: 0
        });
        let data = JSON.parse(response.data);
        if (data.status == 'success') {
          let questions = [];
          data.value.forEach((e) => {
            questions.push({
              feQId: e.feQId || "",
              questionId: e.id,
              questionText: e.question || "",
              questionSubText: e.questionSubText || "",
              questionType: e.questionTypeId,
              questionTypeName: getQuestionIdsMapping(true, e.questionTypeId),
              questionUrl: e.questionUrl || "",
              questionFile: false,
              answerType: e.answerType,
              condition: e.condition || {},
              questionObject: e.questionObject || {},
              extraInfo: e.extraInfo || {},
              orderId: e.orderId || 0
            })
          });
          return questions;
        }
        else {
          throw 'BE status false';
        }
      }
      catch(err) {
        return [];
      }
    },
    uploadFile: function(surveyId, questionId, file) {
      let header = Authorization.getHeader();
      return uploadFileAndLink(header, surveyId, questionId, file);
    },
    submitAnswer: async (payload) => {
      let header = Authorization.getHeader();
      try {
        let response = await surveyAnswer(header, payload);
        let data = JSON.parse(response.data);
        if (data.status == "success") {
          return {success: true};
        }
        else {
          return {success: false};
        }
      }
      catch (err) {
        return {success: false};
      }
    },
    submitAnswerError: async (payload) => {
      let header = Authorization.getHeader();
      try {
        const newPayload = {
          ...payload,
          userAgent: navigator.userAgent
        };
        let response = await surveyAnswerErrorLogging(header, newPayload);
        let data = JSON.parse(response.data);
        if (data.status == "success") {
          return {success: true};
        }
        else {
          return {success: false};
        }
      }
      catch (err) {
        return {success: false};
      }
    },
    submitAnswerStatus: async (payload) => {
      let header = Authorization.getHeader();
      try {
        let response = await answerStatus(header, payload);
        let data = JSON.parse(response.data);
        if (data.status == "success") {
          return {success: true};
        }
        else {
          return {success: false};
        }
      }
      catch (err) {
        return {success: false};
      }
    },
    submitgetAnswerUser: async (payload) => {
      let header = Authorization.getHeader();
      const newPayload = {
        ...payload,
        userAgent: navigator.userAgent
      };
      try {
        let response = await getAnswerUser(header, newPayload);
        let data = JSON.parse(response.data);
        if (data.status == 'success') {
          return {success: true , id: data.id};
        }else{
          return { success: false }
        }
      }
      catch (err) {
        return {success: false};
      }
    },
    getAnswerCount: async (payload) => {
      let header = Authorization.getHeader();
      try {
        let response = await getAnswerCount(header, payload);
        let data = JSON.parse(response.data);
        if (data.status == "success") {
          return {success: true,  value : data.value};
        }
        else {
          return {success: false};
        }
      }
      catch (err) {
        return {success: false};
      }
    },
    getQuotaCount: async (payload) => {
      let header = Authorization.getHeader();
      try {
        let response = await getQuotaCount(header, payload);
        let data = JSON.parse(response.data);
        if (data.status == "success") {
          return {success: true,  value : data.value};
        }
        else {
          return {success: false};
        }
      }
      catch (err) {
        return {success: false};
      }
    },
    SubmitSurveyComponents: async function(payload) {
      let header = Authorization.getHeader();
      try {
        await surveyAnswer(header, {
            answer: payload.answer,
            answerTypeId: payload.answerTypeId,
            batchId: payload.batchId,
            questionId: payload.questionId
        });
        return {success: true};
      }
      catch(err) {
        return {success: false};
      }
    }
  }
})();

export { Authorization, Survey, Questionaire , uploadFileAndLink};