import React from 'react';
import {
  TextFieldsOutlined,
  ExposureZeroOutlined,
  ShortTextOutlined,
  TodayOutlined,
  ScheduleOutlined,
  EventNoteOutlined,
  PublishOutlined,
  RadioButtonCheckedOutlined,
  CheckBoxOutlined,
  ArrowDropDownOutlined,
  StarBorderOutlined,
  SentimentVerySatisfiedOutlined,
  OndemandVideoOutlined,
  MoreHorizOutlined,
  GridOnOutlined,
  FaceOutlined
} from '@material-ui/icons';
import { FaCity } from "react-icons/fa";
import { IoPersonAddOutline, IoMaleFemale } from "react-icons/io5";

import { InputCreate, InputRender, InputValidate } from './InputType';
import { FileUploadCreate, FileUploadRender, FileUploadValidation } from './FileUpload';
import { SelectionCreate, SelectionRender, SelectionValidate } from './Selections';
import { EmoticonsCreate, EmoticonsRender, EmoticonsValidate } from './EmotionIcons'
import { DropdownCreate, DropdownRender, DropdownValidate } from './Dropdown';
import { RatingCreate, RatingRender, RatingValidate } from './Rating';
import { VideoCreate, VideoRender, VideoValidate } from './Video';
import { FaceEmotionCreate, VideoRecorderWidget, FaceEmotionValidate } from './FaceEmotion';
import { AudioCreate, AudioRender, AudioValidate } from './Audio';
import { LikertCreate, LikertRender, LikertValidate } from './Likert'
import { MatrixCreate, MatrixRender, MatrixtValidate } from './Matrix'
import { VideoEmotionCreate, VideoEmotionRender, VideoEmotionValidate } from './VideoEmotion';
import {SECCreate, SECRender, SECValidate} from './SecGrid'
import {NCCSCreate, NCCSRender, NCCSValidate} from './NccsGrid';
import { RandomizeMediaCreate, RandomizeMediaRender, RandomizeMediaValidate } from './RandomizedMedia';
import { CustomSelectCreate, CustomSelectRender ,CitySelectCreate, CustomSelectValidation } from './CustomSelect';
import IosVideocamOutline  from 'react-ionicons/lib/IosVideocam'
import IosApps from 'react-ionicons/lib/IosApps'
import IosMicOutline from 'react-ionicons/lib/IosMic'
import IosImagesOutline from 'react-ionicons/lib/IosImages'
import IosImageOutline from 'react-ionicons/lib/IosImage'
import IosShuffleOutline from 'react-ionicons/lib/IosShuffle';


const questionList = {
  video: {
    name: "Video",
    id: "video",
    answerCode: 1,
    icon: <IosVideocamOutline color="var(--primary)"/>,
    create: (props) => <VideoCreate  {...props} />,
    render: (props) => <VideoRender {...props} />,
    condition: VideoValidate
  },
  audio: {
    name: "Audio",
    id: "audio",
    answerCode: 2,
    icon: <IosMicOutline color="var(--primary)"/>,
    create: (props) => <AudioCreate  {...props} />,
    render: (props) => <AudioRender {...props} />,
    condition: AudioValidate
  },
  text: {
    name: "Input",
    id: "text",
    answerCode: 8,
    icon: <TextFieldsOutlined color="primary" />,
    create: (props) => <InputCreate range={false} type="text" {...props} />,
    render: (props) => <InputRender type="text" {...props} />,
    condition: InputValidate
  },
  number: {
    name: "Number",
    id: "number",
    answerCode: 17,
    icon: <ExposureZeroOutlined color="primary" />,
    create: (props) => <InputCreate range={true} type="number" {...props} />,
    render: (props) => <InputRender type="number" {...props} />,
    condition: InputValidate
  },
  textarea: {
    name: "Text Box",
    id: "textarea",
    answerCode: 18,
    icon: <ShortTextOutlined color="primary" />,
    create: (props) => <InputCreate range={false} type="text" {...props} />,
    render: (props) => <InputRender type="text" {...props} />,
    condition: InputValidate
  },
  
  singleTextSelect: {
    name: "Single Text Selection",
    id: "singleTextSelect",
    answerCode: 21,
    icon: <RadioButtonCheckedOutlined color="primary" />,
    create: (props) => <SelectionCreate type="single" content="text" {...props} />,
    render: (props) => <SelectionRender type="single" content="text" {...props} />,
    condition: SelectionValidate.text
  },
  singleImageSelect: {
    name: "Single Image Selection",
    id: "singleImageSelect",
    answerCode: 22,
    icon: <IosImageOutline color="var(--primary)"/>,
    create: (props) => <SelectionCreate type="single" content="image" {...props} />,
    render: (props) => <SelectionRender type="single" content="image" {...props} />,
    condition: SelectionValidate.image
  },
  multiTextSelect: {
    name: "Multiple Text Selection",
    id: "multiTextSelect",
    answerCode: 10,
    icon: <CheckBoxOutlined color="primary" />,
    create: (props) => <SelectionCreate type="multi" content="text" {...props} />,
    render: (props) => <SelectionRender type="multi" content="text" {...props} />,
    condition: SelectionValidate.text
  },
  multipleImageSelect: {
    name: "Multiple Image Selection",
    id: "multipleImageSelect",
    answerCode: 23,
    icon: <IosImagesOutline color="var(--primary)"/>,
    create: (props) => <SelectionCreate type="multi" content="image" {...props} />,
    render: (props) => <SelectionRender type="multi" content="image" {...props} />,
    condition: SelectionValidate.image
  },
  age: {
    name: "Age",
    id: "age",
    answerCode: 102,
    icon: <IoPersonAddOutline color="primary" />,
    create: (props) => <CustomSelectCreate  type="age" {...props} />,
    render: (props) => <CustomSelectRender   type="age" {...props} />,
    condition: CustomSelectValidation
  },
  gender: {
    name: "Gender",
    id: "gender",
    answerCode: 103,
    icon: <IoMaleFemale color="primary" />,
    create: (props) => <CustomSelectCreate  type="gender" {...props} />,
    render: (props) => <CustomSelectRender   type="gender" {...props} />,
    condition: CustomSelectValidation
  },
  city: {
    name: "City",
    id: "city",
    answerCode: 104,
    icon: <FaCity color="primary" />,
    create: (props) => <CitySelectCreate   {...props} />,
    render: (props) => <CustomSelectRender   {...props} />,
    condition: CustomSelectValidation
  },
  matrix: {
    name: "Matrix",
    id: "matrix",
    answerCode: 28,
    icon: <IosApps color="var(--primary)" />,
    create: (props) => <MatrixCreate  {...props} />,
    render: (props) => <MatrixRender {...props} />,
    condition: MatrixtValidate
  },
  ratingSelect: {
    name: "Rating / NPS",
    id: "ratingSelect",
    answerCode: 24,
    icon: <StarBorderOutlined color="primary" />,
    create: (props) => <RatingCreate type="single" content="text" {...props} />,
    render: (props) => <RatingRender type="single" content="text" {...props} />,
    condition: RatingValidate
  },
  likert: {
    name: "likert",
    id: "likert",
    answerCode: 25,
    icon: <MoreHorizOutlined color="primary" />,
    create: (props) => <LikertCreate  {...props} />,
    render: (props) => <LikertRender {...props} />,
    condition: LikertValidate
  },
  secGrid: {
    name: "SEC Grid",
    id: "secGrid",
    answerCode: 30,
    icon: <GridOnOutlined color="primary" />,
    create: (props) => <SECCreate {...props} />,
    render: (props) => <SECRender {...props} />,
    condition: SECValidate
  },
  nccsGrid: {
    name: "Nccs Grid",
    id: "nccsGrid",
    answerCode: 31,
    icon: <GridOnOutlined color="primary" />,
    create: (props) => <NCCSCreate {...props} />,
    render: (props) => <NCCSRender {...props} />,
    condition: NCCSValidate
  },
  emotionIcons: {
    name: "Emotion Icons",
    id: "emotionIcons",
    answerCode: 16,
    icon: <SentimentVerySatisfiedOutlined color="primary" />,
    create: (props) => <EmoticonsCreate {...props}/>,
    render: (props) => <EmoticonsRender {...props} />,
    condition: EmoticonsValidate
  },
  dropDown: {
    name: "Dropdown",
    id: "dropDown",
    answerCode: 9,
    icon: <ArrowDropDownOutlined color="primary" />,
    create: (props) => <DropdownCreate type="single" content="text" {...props} />,
    render: (props) => <DropdownRender type="single" content="text" {...props} />,
    condition: DropdownValidate
  },
  videoEmotion: {
    name: "Video Emotion",
    id: "videoEmotion",
    answerCode: 20,
    icon: <OndemandVideoOutlined color="primary" />,
    create: (props) => <VideoEmotionCreate  {...props} />,
    render: (props) => <VideoEmotionRender {...props} />,
    condition: VideoEmotionValidate
  },
  date: {
    name: "Date Only",
    id: "date",
    answerCode: 5,
    icon: <TodayOutlined color="primary" />,
    create: (props) => <InputCreate range={true} type="date" {...props} />,
    render: (props) => <InputRender type="date" {...props} />,
    condition: InputValidate
  },
  time: {
    name: "Time Only",
    id: "time",
    answerCode: 19,
    icon: <ScheduleOutlined color="primary" />,
    create: (props) => <InputCreate range={true} type="time" {...props} />,
    render: (props) => <InputRender type="time" {...props} />,
    condition: InputValidate
  },
  datetime: {
    name: "Date & Time",
    id: "datetime",
    answerCode: 6,
    icon: <EventNoteOutlined color="primary" />,
    create: (props) => <InputCreate range={true} type="datetime-local" {...props} />,
    render: (props) => <InputRender type="datetime-local" {...props} />,
    condition: InputValidate
  },
  file: {
    name: "File Upload",
    id: "file",
    answerCode: 20,
    icon: <PublishOutlined color="primary" />,
    create: (props) => <FileUploadCreate {...props} />,
    render: (props) => <FileUploadRender {...props} />,
    condition: FileUploadValidation
  },
  randomizedMediaDisplay: {
    name: "Randomise Media Display",
    id: "randomizedMediaDisplay",
    answerCode: 100,
    icon: <IosShuffleOutline color="var(--primary)" />,
    create: (props) => <RandomizeMediaCreate {...props} />,
    render: (props) => <RandomizeMediaRender {...props} />,
    condition: RandomizeMediaValidate
  },
  faceEmotion: {
    name: "Face Emotion",
    id: "faceEmotion",
    answerCode: 101,
    icon: <FaceOutlined color="primary" />,
    create: (props) => <FaceEmotionCreate {...props} />,
    render: (props) => <VideoRecorderWidget {...props} />,
    condition: FaceEmotionValidate
  }
  
}

export default questionList;
