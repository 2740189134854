import React, { Component, Fragment, useRef, useState } from 'react';

import { Button, Grid,  ButtonGroup, Divider, CircularProgress } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import FlipCameraIos from '@material-ui/icons/FlipCameraIos';
import Camera from '@material-ui/icons/Camera';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';
import { isMobile } from 'react-device-detect';
import supportedMimeTypes from '../Video/util';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
function makeid() {
    return Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000
}


class VideoRecorderWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stream: "",
            source: "",
            constraint: {
                video: {
                    width: { min: 640, ideal: 1280 },
                    height: { min: 400, ideal: 720 },
                    facingMode: 'user'
                },
                audio: true
            },
            recording: false,
            review: false,
            previewDisable:false,
            webcamReview: false,
            videoInputs: [],
            audioInputs: [],
            started:false,
            progressbar: false,
            isAnswered :false,
            videoSource: false,
            error: false,
            play:   false,
            videoPlayed: false,
            fileUrl : props.questionObject.hasOwnProperty('url') ? props.questionObject.url : null


        }
        this.vidRef = React.createRef(null);
        this.selectSource = this.selectSource.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.startRecording = this.startRecording.bind(this);
        this.shiftCamera = this.shiftCamera(this);
        this.submitAnswer = this.submitAnswer.bind(this);
        this.resetSubmission = this.resetSubmission.bind(this);
        this.previewVideo = this.previewVideo.bind(this);
        this.resetSubmission = this.resetSubmission.bind(this);
        this.playVideo = this.playVideo.bind(this);
        this.hasUserMedia = this.hasUserMedia.bind(this)
        this.setMediaEnd = this.setMediaEnd.bind(this);
        this.webcam = React.createRef(null);
        this.uploadFile = React.createRef(null);
        this.stream = null;
        this.recording = null;
        this.data = [];
        this.file = null;
      
    }


    hasUserMedia() { 
        //check if the browser supports the WebRTC 
        return !!(navigator.getUserMedia || navigator.webkitGetUserMedia || 
           navigator.mozGetUserMedia); 
     }
    

    async componentDidMount() {
        let inputDevices = [];
        try {
            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                inputDevices = await navigator.mediaDevices.enumerateDevices();
            }
        }
        catch (err) { }
        let videoDevices = [], audioDevices = [];
        inputDevices.forEach((e) => {
            if (e.kind == "videoinput") {
                videoDevices.push(e);
            }
            else if (e.kind == "audioinput") {
                audioDevices.push(e);
            }
        })
        this.setState({
            videoInputs: videoDevices,
            audioInputs: audioDevices
        })

        this.selectSource('webcam')
    }

    componentWillUnmount() {
        try {
            if (this.stream) {
                let tracks = this.stream.getTracks();
                tracks.forEach(function (track) {
                    track.stop();
                });
            }
            if (this.webcam?.current?.srcObject) {
                this.webcam.current.srcObject = null;
            }
            this.stream = null;
        } catch (err) {}
    }

    shiftCamera() {
        if (this.state.recording || this.state.videoInputs.length <= 1) {
        }
        else {
            this.setState((state, props) => {
                let cons = Object.assign({}, state.constraint);
                cons.video.facingMode = cons.video.facingMode == "user" ? "environment" : "user";
                return {
                    constraint: cons
                }
            })
        }
    }

    async selectSource(source) {
        if (source == "webcam") {
            if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                try {
                    let stream = await navigator.mediaDevices.getUserMedia(this.state.constraint);
                    this.webcam.current.srcObject = stream;
                    this.stream = stream;
                    this.setState({
                        videoSource: true,
                        error: false
                    });
                }
                catch (err) {
                    let error = 'Permission denied to capture video/audio from browser. Please allow the permission to submit the answer.';
                    this.props.logError({
                        error,
                        errorObject: err
                    });
                    this.setState({
                        videoSource: false,
                        error
                    });
                }
            } else {
                let error = 'Your browser does not support the latest features. Please use Chrome/Firefox to fill the survey. In case you are using Chrome/Firefox, please update your browser.';
                this.props.logError({
                    error,
                    errorObject: {}
                });
                this.setState({
                    error
                });
            }
        }
        else {
            if (this.stream) {
                let tracks = this.stream.getTracks();
                tracks.forEach(function (track) {
                    track.stop();
                });
                this.webcam.current.srcObject = null;
                this.stream = null;
            }
            this.setState({
                videoSource: false
            });
            this.uploadFile.current.click();
        }
        this.setState({
            source: source == "webcam" ? "webcam" : this.state.source,
            review: false
        })
    }

    async startRecording() {
        if (this.webcam.current.srcObject === null) {
            try {
                await this.selectSource('webcam');
            }
            catch (err) { }
        }
        this.file = null;

        try {
            if (isMobile) {
                this.recording = new MediaRecorder(this.stream);
            } else {
                this.recording = new MediaRecorder(this.stream, {
                    mimeType: supportedMimeTypes[0]
                });
            }
            this.data = [];
            this.recording.ondataavailable = (event) => {
                this.data.push(event.data);
            };
            this.recording.start();
            this.setState({
                recording: true,
                review: false,
                webcamReview: false,
                previewDisable:false,
                error: false
            })
        } catch (err) {
            let error = 'Browser does not support recording media.';
            this.props.logError({
                error,
                errorObject: {}
            });
            this.setState({
                error
            });
        }
    }

    stopRecording() {
        this.recording.onstop = (event) => {
            let fileType = supportedMimeTypes[0];
            if (isMobile) {
                fileType = this.recording.mimeType;
            }
            if (fileType.indexOf(';') >= 0) {
                fileType = fileType.split(';')[0];
            }
            this.file = new Blob(this.data, { type: fileType });
        }

        this.recording.stop();
        this.setState({
            recording: false,
            webcamReview: true,
            previewDisable:true
        })
        this.state.started = true
    }

    previewVideo() {
        if (this.stream) {
            let tracks = this.stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            this.webcam.current.srcObject = null;
            this.stream = null;
            if (this.file !== null){
                this.webcam.current.children[0].src = URL.createObjectURL(this.file);
                this.webcam.current.load();
            }  
        }
        this.setState({
            review: true,
            webcamReview: false
        })
    }


    submitAnswer() {
        if (this.state.videoPlayed == true)
        {
            if (this.file == null){
                this.setState({
                    review: false,
                    source: "",
                    progressbar: true,
                    isAnswered: false
                });
                this.props.submit({
                    files: []
                });
            }
            else{
                this.setState({
                    review: false,
                    source: "",
                    progressbar: true,
                    isAnswered: false
                });
                this.file.lastModifiedDate = new Date();
                this.file.name = makeid() + '.webm';
                this.props.submit({
                    files: [this.file]
                });
            }
        }else{
            this.setState({
                isAnswered: true
            })
        }
        
    }

    resetSubmission() {
        if (this.stream) {
            let tracks = this.stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            this.webcam.current.srcObject = null;
            this.stream = null;
        }
        this.setState({
            recording: false,
            review: false,
            source: ""
        });
        this.file = null;
    }

    async playVideo (){
        this.setState({ play: true})
        this.vidRef.current.play();
        if (this.hasUserMedia){
            this.startRecording()
        }
       
    };

    async setMediaEnd () {
        this.setState({
            videoPlayed: true,
            play: false
        })
        if (this.hasUserMedia){
            this.stopRecording()
        }
    }
 

    render() {
        return (
            <Fragment>
                 <Grid container spacing={1} justify="center">
            <Grid item xs={12} lg={10} md={12} >
            {this.state.fileUrl != null?
            <>
            <video
              className="videoContainer"
              ref={this.vidRef}
              controls={false}
              controlsList="nodownload"
              style={{maxWidth: '100%', width: '100%', height: 'auto', maxHeight: '60vh'}}
              src={this.state.fileUrl}
              onEnded={this.setMediaEnd}
            //   onLoadedMetadata={this.playVideo}
            />
            
            </>
            :null}
            </Grid>
            <Grid item xs={10} lg={4} md={4} style={{textAlign:"center"}}>
            <Button  color={this.props.theme.color}
            style={{width:"100%"}}
             variant={this.props.theme.buttonVariant}
             disabled={this.state.play}
              onClick={this.playVideo} size="large">play</Button>

            </Grid>
            <Grid item xs={2} lg={2} md={2} style={{textAlign:"center"}}>
            {this.state.error ? (
                    <div className="card-body justify-content-between">
                        {this.state.error}
                    </div>
                ) : (
                    <>
                    <div>
                        <video className="videoContainer" autoPlay={!this.state.review} 
                            ref={this.webcam} muted={!this.state.review} 
                            controls={this.state.review} controlsList="nodownload" 
                            style={{maxWidth: '100px', maxHeight: '100px'}}>
                            <source />
                        </video>
                    </div>
                    <div>
                    {this.state.play ?<span style={{color:'red'}}><FiberManualRecordIcon style={{color:'red'}}/> REC </span> : null}
                    </div>
                    </>
                )}
            </Grid>
        </Grid>
                {/* <div className="d-block w-100"> */}
                
                {this.state.isAnswered ? <div>
                    <Alert severity="error">Please Watch completely </Alert>
                </div> : null}
                <div className="d-block mt-2">
                    {this.props.preview ? null : (
                        <Button className={this.file ? "button save m-1" : "button save Disable m -1"} disabled={this.state.progressbar || this.state.recording} variant={this.props.theme.buttonVariant} color={this.props.theme.color}  onClick={this.submitAnswer}>Submit</Button>
                    )}
                     
                     { this.state.progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
                </div>
            </Fragment>
        )
    }
}




export default VideoRecorderWidget 